import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import axios from "axios";

import { Box, Popover, Rating } from "@mui/material";
import { useLocalStorage } from "../../../hooks";
import location_tile from "../../../assets/location_tile.svg";
import { getUrlForServices } from "../../../utils/confighelpers";

export default function VenueTile({
  popover,
  setLoading,
  handleSessionClick,
  reload,
  item,
}) {
  const [eventStatusColors, setEventStatusColors] = React.useState({
    "draft": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "false": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "published": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "true": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "cancelled": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033" // #ff404033
    },
    "refunded": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033"
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userToken] = useLocalStorage("userToken");
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const deleteVenues = async (id) => {
    setLoading(true)
    const manageClassesConfig = {
      method: "delete",
      url: `${getUrlForServices()}venue/${id}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const responce = await axios(manageClassesConfig);
    if (responce.status === 200) {
    }
    reload();
    setLoading(false)
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  return (
    <div
      className={`my-3 p-3 flex flex-row items-center gap-4 cursor-pointer 
        } bg-white hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl`}
    >
      <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
        <div
          className=""
        >
          <img
            className={`${"w-[50px] max-w-[50px] h-[50px] rounded-xl"
              } object-fill`}
            src={location_tile}
            alt={"session img"}
          />
        </div>
        <div className={`flex item-top grow`}>
          <div className={`flex flex-col item-center w-full gap-1`}>
            <h3
              className={`text-brown-coffee font-bold text-base capitalize`}
            >
              {item?.venueName || item.address.addressName}
            </h3>
            <div className="grid grid-cols-2 items-top">
              <div className={`flex flex-col item-center`}>
                <div className={"flex flex-row items-center gap-2"}>

                  <p
                    className={`text-brown-coffee text-xs`}
                  >
                    {item.address.address}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {popover && (
        <>
          <div className={"flex flex-col items-center ml-auto"}>
            <div
              className={
                "grid place-items-center rounded-xl cursor-pointer"
              }
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon
                color={anchorEl ? "secondary" : "primary"}
              />
            </div>

            <Popover
              id={popoverId}
              open={popoverMenuOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                my: 1,
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#EBEBEB",
                  borderRadius: 3,
                },
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                <button
                  onClick={() => {
                    handlePopoverClose();
                    handleSessionClick();
                    // handleDateTimeModalOpen();
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Edit
                  </p>
                </button>
                {/* <button
                  onClick={() => {
                    handlePopoverClose();
                    // handleSessionClick();
                    deleteVenues(item._id);
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Delete
                  </p>
                </button> */}
              </div>
            </Popover>
          </div>
        </>
      )}
    </div>
  );
}
