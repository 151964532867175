import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Popover } from "@mui/material";

import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";


import ErrorDialog from "../../confirmationModals/ErrorDialog";

import Alert from "../../../assets/Alert.png";
import { getUrlForBookings } from "../../../utils/confighelpers";

export default function PurchaseSessionPopover({
  purchasingDetails,
  handleUpdate,
  setLoading
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);

  const {
    purchaseId,
    selectedPassDetail,
  } = purchasingDetails;
  const [popoverOptionsDisabled, setPopoverOptionsDisabled] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);

  const handleOpenErrorDialog = () => {
    handleClose();
    setOpenErrorDialog(true);
  };
  const handleCloseErrorDialog = () => setOpenErrorDialog(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const viewDetails = () => {
    navigate("/purchase/view", {
      state: {
        passId: selectedPassDetail.passId,
        purchaseId: purchaseId
      },
    })
  }

  const popoverOptions = [
    {
      text: "View Details",
      onClick: viewDetails,
      disabled: false,
      icon: Alert,
    },
  ];

  console.log("purchasingDetails", purchasingDetails);

  return (

    <Box>
      <IconButton sx={{ p: 0, m: "6px" }} onClick={handleOpen}>
        <MoreVertIcon sx={{ color: "black" }} />
      </IconButton>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          my: 1,
          "& .MuiPopover-paper": {
            boxShadow: "none",
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 3,
          },
        }}
      >
        <div className={"flex flex-col gap-3 py-5"}>
          {popoverOptions.map((opt, idx) => (
            <button
              key={idx}
              disabled={opt.disabled}
              className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${opt.disabled
                ? ""
                : "active:bg-light-brown-coffee active:text-white"
                } disabled:opacity-60 disabled:cursor-default`}
              onClick={opt.onClick}
            >
              <p className={"text-sm"}>{opt.text}</p>
              {/* <img className={"w-4 bg-brown-coffee rounded-full"} src={opt.icon} alt={""} /> */}
            </button>
          ))}
        </div>
      </Popover>

      <ErrorDialog
        open={openErrorDialog}
        text={errorText}
        onClose={handleCloseErrorDialog}
      />
    </Box>
  );
}
