import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import downarrow_back from "../../../assets/back.png";
import { InputAdornment, InputBase } from "@mui/material";

const formInputStyle = {
  maxWidth: 197.7,
  minHeight: 51,

  py: 1,
  px: 3,
  borderRadius: 3,
  borderColor: "#EBEBEB",
  backgroundColor: "rgba(0, 0, 0, 0.04)",

  "&.Mui-disabled": {
    border: 1,
    borderColor: "#EBEBEB",
    backgroundColor: "#EBEBEB",
    color: "#808080",
  },
  ".MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: 0,
      borderColor: "rgba(0, 0, 0, 0)",
    },
};

const formInputClasses = {
  input: {
    "&::placeholder": {
      color: "#808080",
      fontWeight: 300,
    },
  },
};

const ReactTimePicker = ({ time, placeholderLabel, handleTimeChange }) => {
  const [openTimeModal, setOpenTimeModal] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label="Basic example"
        value={
          time
            ? moment(
                moment().format("YYYY-MM-DD") + " " + time,
                "YYYY-MM-DD h:mm A"
              )
            : ""
        }
        onChange={(time) => {
          handleTimeChange(time);
        }}
        open={openTimeModal}
        onOpen={() => {
          setOpenTimeModal(true);
        }}
        onClose={() => {
          setOpenTimeModal(false);
        }}
        renderInput={(params) => {
          const { inputRef, inputProps } = params;
          const { placeholder, classes, ...restinputProps } = inputProps;
          return (
            <InputBase
              ref={inputRef}
              {...restinputProps}
              placeholder={placeholderLabel}
              classes={formInputClasses}
              sx={{
                ...formInputStyle,
              }}
              onClick={() => {
                setOpenTimeModal(!openTimeModal);
              }}
              endAdornment={
                <InputAdornment position={"end"} className={"cursor-pointer"}>
                  <img className={"w-3"} src={downarrow_back} alt={""} />
                </InputAdornment>
              }
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default ReactTimePicker;
