import React from "react";
import axios from "axios";
import { Popover } from "@mui/material";

import { useLocalStorage } from "../hooks";

import bell_png from "../assets/bell.png";
import NotificationItem from "./NotificationItem";
import { getUrlForUsers } from "../utils/confighelpers";

export default function Notifications() {
  const [userToken] = useLocalStorage("userToken");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifList, setNotifList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const config = {
        method: "get",
        url: `${getUrlForUsers()}notificationsList?page=${0}&limit=${20}&sortBy=${"asc"}`,
        headers: {
          "x-access-token": userToken,
        },
      };
      const request = await axios(config);
      if (request.status === 200) {
        if (request.data.statusCode === 200) {
          setNotifList([...request.data.data.notificationList]);
        }
      }
    })();
  }, [userToken]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  return (
    <div>
      <div className={"indicator cursor-pointer"} onClick={handleOpen} title={"Notifications"}>
        {/* <img className={"w-6 sm:w-5 md:w-5 sm:mx-1"} src={bell_png} alt={""} />
        <span
          className={
            "indicator-item badge bg-portland-orange border-0 -translate-y-[0.1rem] translate-x-1 badge-xs sm:-translate-y-0 sm:translate-x-0"
          }
        /> */}
        <svg width="57" height="57" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#EEEEEE" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9959 14.5146C19.5616 14.5146 17.6349 18.5299 17.6349 21.1842C17.6349 23.168 17.9226 22.5842 16.8245 25.0042C15.4835 28.4527 20.8759 29.8623 23.9959 29.8623C27.1149 29.8623 32.5073 28.4527 31.1673 25.0042C30.0692 22.5842 30.3568 23.168 30.3568 21.1842C30.3568 18.5299 28.4292 14.5146 23.9959 14.5146Z" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M26.3055 32.5127C25.0113 33.9584 22.9922 33.9756 21.6855 32.5127" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          {/* <circle cx="31" cy="17" r="3" fill="#F75555" /> */}
        </svg>        
      </div>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            px: 4,
            py: 2,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "visible",
          },
        }}
      >
        <h3
          className={
            "font-semibold text-light-brown-coffee text-[24.05px] mb-4"
          }
        >
          Notifications
        </h3>
        {notifList.map((data) => (
          <NotificationItem
            key={data.notificationId}
            imgSrc={data.senderInfo.profilePic}
            displayText={data.notificationBody}
            personName={data.senderInfo.fullName}
            timeElapsed={data.createdAt}
          />
        ))}
      </Popover>
    </div>
  );
}
