import { Checkbox, FormControlLabel, FormHelperText, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { Calendar } from "react-multi-date-picker";
import ArrowDown from "../../../assets/arrow.svg";
import CalendarIcon from "../../../assets/calender_black.svg";
import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { schoolState } from "../../../redux/reducers/schoolReducer";
import DayTimeSelector from "../venue/DayTimeSelector";


export const openDayAndTimeObject = [
  {
    day: "monday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "tuesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "wednesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "thursday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "friday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "saturday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "sunday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
];
export default function MyResourceAvailableTimeForm({
  navigation,
  profileDetails,
  setProfileDetails,
  reloadData,
  formErrors,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const [userToken] = useLocalStorage("userToken");

  const [loading, setLoading] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const schoolInfo = dashboardReduxState.schoolData;
  // console.log("schoolInfo", schoolInfo);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openDayAndTime, setOpenDayAndTime] = useState(profileDetails?.openDayAndTime?.length ? profileDetails.openDayAndTime : openDayAndTimeObject);

  React.useEffect(() => {
    console.log("useEffect openDayAndTime", openDayAndTime !== profileDetails.openDayAndTime);
    console.log("useEffect openDayAndTime", openDayAndTime);
    console.log("useEffect profileDetails.openDayAndTime", profileDetails.openDayAndTime);
    setProfileDetails((prevValues) => ({
      ...prevValues,
      openDayAndTime,
    }));

  }, [openDayAndTime])

  React.useEffect(() => {
    console.log("reloadData", profileDetails.openDayAndTime);
      setOpenDayAndTime(profileDetails?.openDayAndTime?.length ? profileDetails.openDayAndTime : openDayAndTimeObject);
  }, [reloadData])


  const onDateRangeSelect = (validityStartDate, validityEndDate) => {
    setProfileDetails((prevValues) => ({
      ...prevValues,
      validityStartDate,
      validityEndDate
    }));
    setOpenCalendar(false);
  };

  const renderDatePicker = () => {
    return (
      <>
        <div className="flex flex-col mt-7">
          <p className="text-base leading-[18px] font-normal text-black/[0.6]">
            Enter Validity period for this schedule
          </p>
          <div className="flex justify-between items-center mt-[11px] pr-[11px] pl-[27px] pt-[27px] pb-[23px] border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-[19px]"
            onClick={() => {
              if (profileDetails.scheduleWithoutValidity) {
                setOpenCalendar((prev) => false);
              } else {
                setOpenCalendar((prev) => !prev);
              }
            }}
          >
            <div className="flex flex-1 cursor-pointer">
              <img
                src={CalendarIcon}
                alt="calendar icon"
                className="h-[22px] w-[18px] mr-[17px]"
              />
              <div
                className={`mr-20 w-full bg-transparent text-lg leading-[22px] font-normal ${profileDetails.scheduleWithoutValidity ? "text-[#cdcdcd]" : "text-[#212121]"}`}
              >
                {
                  profileDetails?.validityStartDate && profileDetails.validityEndDate
                    ? `${moment(profileDetails?.validityStartDate).format(
                      "Do-MMM-YYYY"
                    )} to ${moment(profileDetails?.validityEndDate).format("Do-MMM-YYYY")}`
                    : ""
                }
              </div>
            </div>
            <div className="flex justify-end">
              <img
                src={ArrowDown}
                alt="arrow down"
                className="rotate-90 h-[15px] w-2 cursor-pointer"
              />
            </div>
          </div>          
          {(formErrors["validityStartDate"] || formErrors["validityEndDate"]) && (
            <FormHelperText className="!text-red-600">{formErrors["validityStartDate"] || formErrors["validityEndDate"]}</FormHelperText>
          )}
        </div>
        {openCalendar && (
          <Calendar
            className="bookzy-calendar bookzy-border bookzy-range-calendar"
            range
            rangeHover
            minDate={new Date()}
            value={[profileDetails?.validityStartDate, profileDetails?.validityEndDate]}
            numberOfMonths={2}
            onChange={(event) => {
              const startDate = event?.[0]?.toDate();
              const endDate = event?.[1]?.toDate();
              if (startDate && endDate) {
                onDateRangeSelect(startDate, endDate);
              }
            }}
          />
        )}
      </>
    );
  };

  const onToggleSwitch = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((obj) => {
      if (obj.day === item.day) {
        let isOpen = !obj.isOpen;
        obj.isOpen = isOpen;
        if (isOpen) {
          obj.openTime = [
            {
              startTime: "9:00 AM",
              endTime: "05:00 PM",
            },
          ];
        } else obj.openTime = [];
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeChange = (openTime, index) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj, timeIndex) => {
      if (index === timeIndex) {
        dayAndTimeObj.openTime = openTime;
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeRangeCopy = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj) => {
      dayAndTimeObj.openTime = item.openTime;
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  return (
    <div className={``}>
      {renderDatePicker()}
      <div className="flex flex-col gap-3 mt-8">
        <FormControlLabel control={
          <Checkbox
            name="scheduleWithoutValidity"
            checked={profileDetails.scheduleWithoutValidity}
            onChange={(event) => {
              const { name, checked } = event.target;
              setProfileDetails((prevValues) => ({
                ...prevValues,
                [name]: checked,
                validityStartDate: moment().format("YYYY-MM-DD"),
                validityEndDate: moment().add(10, 'years').format("YYYY-MM-DD")
              }));

            }}
          />
        } label="Apply this schedule without any specific validity period" />
      </div>
      <div className="flex flex-col gap-3 mt-8">
        <p className="text-base leading-[18px] font-normal text-black/[0.6]">
          Add your Working Hours
        </p>
        {openDayAndTime?.map((item, index) => {
          return (
            <DayTimeSelector
              key={index}
              item={item}
              onToggleSwitch={onToggleSwitch}
              onTimeRangeCopy={onTimeRangeCopy}
              onTimeChange={(openTime) => onTimeChange(openTime, index)}
            />
          );
        })}
      </div>
    </div>
  );
}
