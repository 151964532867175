import { Box, Popover } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "../../../assets/Alert.png";
import BookingEmailConfirmationDialog from "../../../components/BookingEmailConfirmationDialog";
import { useLocalStorage } from "../../../hooks";
import { userState } from "../../../redux/reducers/userReducer";
import { getUrlForBookings } from "../../../utils/confighelpers";

export default function PurchaseDetailActionPopover({
  purchasingDetails,
  handleUpdate,
  anchorEl,
  setAnchorEl,
  setLoading
}) {
  const navigate = useNavigate();
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const { purchaseId } = purchasingDetails;
  const username = purchasingDetails?.consumer_detail?.username;
  const email = purchasingDetails?.consumer_detail?.email;
  const isPaid = (["paid", "refund-pendding", "refunded"].indexOf(purchasingDetails?.paymentStatus?.toLowerCase()) > -1) ? true : false;

  const [bookingEmailConfirmationDialogOpen, setBookingEmailConfirmationDialogOpen] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const getConsumerInfo = () => {
    let consumerDetails = {}

    // if (bookingInfo && bookingInfo?.schedule_class_detail?.participants?.length) {
    //     consumerDetails = bookingInfo?.schedule_class_detail?.participants.find((item) => item?.purchaseId === bookingInfo.purchaseId)
    // }

    if (purchasingDetails) {
      consumerDetails = purchasingDetails?.consumer_detail
    }

    return consumerDetails;
  }

  const getTeacherInfo = () => {
    let teacherDetails = {}

    if (purchasingDetails) {
      teacherDetails = purchasingDetails?.teacher_detail
    }

    return teacherDetails;
  }

  const generatePurchaseOrder = () => {
    let retObj = {};

    if (purchasingDetails && purchasingDetails?.ticketsBought?.length) {
      purchasingDetails?.ticketsBought.forEach((item, i) => {
        retObj['bookingQty' + (i + 1)] = `${item?.bookingQty} x`;
        retObj['ticketType' + (i + 1)] = item?.ticketType;
        retObj['ticketTotalPrice' + (i + 1)] = `€ ${item?.totalPrice}`;
      })
    }

    if (purchasingDetails && purchasingDetails?.addonsBought?.length) {
      purchasingDetails?.addonsBought.forEach((item, i) => {
        retObj['addonQty' + (i + 1)] = `${item?.bookingQty} x`;
        retObj['addonName' + (i + 1)] = item?.addonName;
        retObj['addonTotalPrice' + (i + 1)] = `€ ${item?.totalPrice}`;
      })
    }

    return retObj
  }

  const calculateServiceFees = () => {
    let serviceFees = 0;

    if (purchasingDetails && purchasingDetails?.ticketsBought?.length) {
      purchasingDetails?.ticketsBought.forEach((item) => {
        // serviceFees = serviceFees + (parseFloat((item?.serviceFee) ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
        serviceFees = serviceFees + (parseFloat((item?.serviceFee) ? item?.serviceFee : 0))
      })
    }

    if (purchasingDetails && purchasingDetails?.addonsBought?.length) {
      purchasingDetails?.addonsBought.forEach((item) => {
        serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
      })
    }

    return parseFloat(serviceFees).toFixed(2)
  }

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    let serviceFees = 0;

    if (purchasingDetails?.totalStripeAmount) {
      return parseFloat(purchasingDetails?.totalStripeAmount || 0).toFixed(2)
    } else if (purchasingDetails?.amount) {
      return parseFloat(purchasingDetails?.amount || 0).toFixed(2)
    } else {
      if (purchasingDetails && purchasingDetails?.ticketsBought?.length) {
        purchasingDetails?.ticketsBought.forEach((item) => {
          serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
          totalPrice = totalPrice + parseFloat(item.totalPrice)
        })
      }

      // return parseFloat(totalPrice + serviceFees).toFixed(2)
      return parseFloat(totalPrice).toFixed(2)
    }
  }

  const sendMail = async () => {
    handleClose();
    cogoToast.loading("Loading...");
    setLoading(true);
    const body = {
      fromName: getTeacherInfo()?.myBusinessProfile?.businessName,
      toName: getConsumerInfo().fullName,
      toEmail: getConsumerInfo().email,
      bookingType: userReduxState.businessType,
      variables: {
        customer_name: getConsumerInfo().fullName,
        ...(userReduxState.businessType === "event" ? {

          eventPhoto: purchasingDetails?.event_schedule_detail?.eventPhoto,
          eventName: purchasingDetails?.event_schedule_detail?.eventName,
          eventDate: moment(purchasingDetails?.event_schedule_detail?.eventDate).format('dddd, MMMM Do'),
          eventTime: moment(purchasingDetails?.event_schedule_detail?.eventDate).format('hh:mm A') + " - " + moment(purchasingDetails?.event_schedule_detail?.eventEndDate).format('hh:mm A'),
          eventLocation: purchasingDetails?.event_schedule_detail?.location?.addressName,
          eventAddress: purchasingDetails?.event_schedule_detail?.location?.address,
          mapUrl: `https://www.google.com/maps/dir//${purchasingDetails?.event_schedule_detail?.location?.address}`,
        } : {}),
        eventOrganizer: getTeacherInfo()?.myBusinessProfile?.businessName,
        ...(generatePurchaseOrder()),
        // serviceFee: `€ ${calculateServiceFees()}`,
        serviceFee: `€ ${purchasingDetails?.totalPaymentServiceFee}`,
        totalPayableAmount: `€ ${calculateTotalPrice()}`,
        paymentMethod: `${purchasingDetails?.paymentMethod}`,
        bookingId: `${purchasingDetails?.purchaseId}`,
        paymentStatus: `${purchasingDetails?.paymentStatus}`,
      }
    };
    const config = {
      method: "post",
      url: `${getUrlForBookings()}sendBookingConfirmation`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        setBookingEmailConfirmationDialogOpen(true)
        // cogoToast.success("Success!");
        console.log(request);
      } else {
        cogoToast.error(request.data.message);
        console.log(request);
      }
    }
  };

  const handleContactClick = () => {
    handleClose();
    if (email) {
      window.open(`mailto:${email}`);
    } else {
      const usernameCopy = username;
      const number = usernameCopy.replace(/[^\w\s]/gi, "").replace(/ /g, "");
      window.open(`https://web.whatsapp.com/send?phone=${number}`);
    }
  };

  const popoverOptions = [
    {
      text: "Contact",
      onClick: handleContactClick,
      disabled: false,
      icon: Alert,
    },
    {
      text: "Send Email",
      onClick: sendMail,
      disabled: getConsumerInfo()?.email ? false : true,
      icon: Alert,
    },
  ];

  return (

    <Box>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          my: 1,
          "& .MuiPopover-paper": {
            boxShadow: "none",
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 3,
          },
        }}
      >
        <div className={"flex flex-col gap-3 py-5"}>
          {popoverOptions.map((opt, idx) => (
            <button
              key={idx}
              disabled={opt.disabled}
              className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${opt.disabled
                ? ""
                : "active:bg-light-brown-coffee active:text-white"
                } disabled:opacity-60 disabled:cursor-default`}
              onClick={opt.onClick}
            >
              <p className={"text-sm"}>{opt.text}</p>
            </button>
          ))}
        </div>
      </Popover>

      <BookingEmailConfirmationDialog
        title={`Booking details sent to your ${getConsumerInfo().email}`}
        // subtitle={`Booking details sent to your ${getConsumerInfo().email}`}
        open={bookingEmailConfirmationDialogOpen}
        handleClose={() => setBookingEmailConfirmationDialogOpen(false)}
      />
    </Box>
  );
}
