import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, InputBase, MenuItem, Select, Slide } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});



const formLableStyle = {
  my: 1,
  color: "rgba(0, 0, 0, 0.6);"
};

const formInputStyle = {
  p: 2,
  // border: 1,
  borderRadius: 3,
  // borderColor: formErrors["className"] ? "#d32f2f" : "#CCCCCC",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  minWidth: 300,
  maxWidth: 300,
  "&:hover, &.Mui-focused": {
    border: 1,
    borderColor: "#7E00DD"
  },
  '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
};

const formInputClasses = {
  input: {
    "&::placeholder": {
      color: "#808080",
      fontWeight: 300,
    },
  },
};

const formSelectClasses = {
  sx: {
    "& .MuiMenu-paper": {
      border: 1,
      borderColor: "#424242",
      borderRadius: 3,
      maxHeight: 250,
      mt: 1,
    },
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
  },
};
export default function StripeCreatePaymentProfileModal({
  loading, open, text, onClose, onSubmit
}) {
  const bodyData = {
    email: "",
    country: "NL",
  };
  const [formValues, setFormValues] = React.useState(bodyData);
  const [formErrors, setFormErrors] = React.useState({});

  const [countryOpts, setEventCategoryOpts] = React.useState([
    { iso: "US", name: "United States" },
    { iso: "GB", name: "United Kingdom" },
    { iso: "NL", name: "Netherlands" },
    { iso: "IN", name: "India" },
  ]);

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      <DialogTitle>Create Stripe Account</DialogTitle>
      <DialogContent>
        <Grid container xs={12} direction={"column"}>
          <Grid item xs={12}>
            <FormControl error={formErrors["email"] ? true : false}>
              <FormLabel sx={formLableStyle}>Email</FormLabel>
              <InputBase
                name={"email"}
                // placeholder={"Email"}
                value={formValues.tagLine}
                onChange={handleFormValueChange}
                inputProps={{ maxLength: 30 }}
                classes={formInputClasses}
                sx={formInputStyle}
              />
              {formErrors["email"] && (
                <FormHelperText>{formErrors["email"]}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={formErrors["country"] ? true : false}>
              <FormLabel sx={formLableStyle}>Country</FormLabel>
              <Select
                disableunderline="true"
                MenuProps={formSelectClasses}
                renderValue={(value) => (
                  <div className={"flex flex-row items-center gap-3"}>
                    {/* <img src={select_start_adornment} alt={""} /> */}
                    {(value.name) || (
                      <span className={"font-light text-plain-gray"}>
                        Select Country
                      </span>
                    )}
                  </div>
                )}
                // value={formValues.country}
                // value={{ iso: "NL", name: "Netherlands" }}
                defaultValue={countryOpts[2]}
                onChange={(event) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    country: event.target.value.iso,
                  }));
                  console.log("event.target.value.category", event.target.value);
                  // if (event.target.value.category) {
                  //   setCategoryOpts(event.target.value.category);
                  // }
                }}
                sx={{
                  ...formInputStyle,
                  py: 0.5,
                }}
              >
                {countryOpts.map((country, idx) => (
                  <MenuItem value={country} key={idx} selected={country.iso === "NL"}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors["country"] && (
                <FormHelperText>{formErrors["country"]}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          disabled={loading}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={() => {
            const errors = {};
            const emailpattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            if (!formValues.email) {
              errors.email = "Required!";
            } else if (formValues.email.length > 0 && emailpattern.test(formValues.email) === false) {
              errors.email = "Please enter valid email";
            }
            if (!formValues.country) {
              errors.country = "Required!";
            }
            console.log("errors", errors);
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
              onSubmit(formValues);
            }
          }}
        >
          Start Onboarding
        </Button>
      </DialogActions>
    </Dialog>
  );
}
