import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import { Box, Popover } from "@mui/material";
import time_png from "../../assets/Clock_Dark.svg";
import location_png from "../../assets/location.svg";
import { cutString } from "../../helpers";

export default function SessionClassTile({
  imgSrc,
  title,
  time,
  schedule,
  venue,
  totalSeats,
  seatsBooked,
  seatsBookedPerc,
  bookedPeople,
  popover,
  popoverLine,
  selectedForSessionDisplay,
  insideDetailView,
  handleSessionClick,
  item,
}) {
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  const [eventStatusColors, setEventStatusColors] = React.useState({
    "draft": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "published": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "cancelled": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033" // #ff404033
    },
    "refunded": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033"
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  return (
    <div
      className={`${"my-3"} p-4 flex flex-row items-center gap-4 ${insideDetailView ? "cursor-pointer" : ""
        } ${selectedForSessionDisplay ? "bg-white" : "bg-white hover:bg-[#e1e1e1]"
        } shadow-md border border-bright-gray rounded-2xl`}
    >
      <div className="flex flex-row items-top gap-4" onClick={() => { handleSessionClick(); }}>
        <div
          className=""
        >
          <img
            className={`${"w-[127.28px] max-w-[127.28px] h-[101px] rounded-xl"
              } object-fill`}
            src={imgSrc}
            alt={"session img"}
          />
        </div>
        <div className={`flex item-top`}>
          <div className={`flex flex-col item-center w-full`}>
            <h3
              className={`${selectedForSessionDisplay ? "text-white" : "text-[#481B3A]"
                } font-bold text-base`}
            >
              {title}
            </h3>
            <div className={"flex flex-row items-center gap-2"}>
              <p
                className={`${selectedForSessionDisplay ? "text-white" : "text-[#676767]"
                  } text-xs`}
              >
                {(false && schedule !== "") ? (<h4 className={"font-bold text-[20px] text-[#676767]"}>{schedule}</h4>) : ""}
              </p>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              <img className={"w-3 h-3"} src={time_png} alt={""} />
              <p
                className={`${selectedForSessionDisplay ? "text-white" : "text-granite-gray"
                  } text-xs`}
              >
                {time}
              </p>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              <img className={"w-3 h-3"} src={location_png} alt={""} />
              <p
                className={`${selectedForSessionDisplay ? "text-white" : "text-granite-gray"
                  } text-xs`}
              >
                {cutString(venue, 30, true)}
              </p>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              <Box
                className={`p-1 px-2 mt-2 text-xs rounded-full`}
                sx={{ ...(item?.eventStatus ? eventStatusColors[item.eventStatus?.toLowerCase()] : {}) }}
              >
                {item?.eventStatus}
              </Box>
            </div>
          </div>
        </div>
        <div className={"flex flex-col items-center"}>
          <div className={"flex flex-col items-center"}>
            <div
              className={"radial-progress text-light-brown-coffee w-[40px] h-[40px] after:bg-transparent"}
              style={{
                "--value": Number(seatsBookedPerc),
                "--size": isMobile
                  ? "3rem"
                  : isTablet
                    ? "3rem"
                    : isLaptopL
                      ? "3rem"
                      : "3.5rem",
                "--thickness": "1.5px",
              }}
            >
              <div
                className={
                  "w-[35px] h-[35px] flex justify-center items-center bg-light-brown-coffee rounded-full border-2 border-white"
                }
              >
                <p className={"font-bold text-white text-[8px]"}>
                  {Number(seatsBookedPerc).toFixed(1)}%
                </p>
              </div>
            </div>
            <h3
              className={
                "text-light-brown-coffee font-bold text-sm sm:text-sm md:text-sm lg:text-sm"
              }
            >
              {seatsBooked}/{totalSeats}
            </h3>
            <p className={"text-granite-gray text-xs text-center"}>
              Seats Booked
            </p>
          </div>
          <div className={"flex flex-row flex-wrap items-center"}>
            {Array.isArray(bookedPeople) && bookedPeople.length > 3 ? (
              <div
                className={`flex flex-row items-center mt-2 -space-x-2 overflow-hidden`}
              >
                {bookedPeople.slice(0, 3).map((person, i) => (
                  <div className='avatar' key={i}>
                    <div className='rounded-full w-[22px]'>
                      <Tooltip title={person.fullName}>
                        <img src={person.profilePic} alt={person.fullName} />
                      </Tooltip>
                    </div>
                  </div>
                ))}
                <div className='avatar placeholder'>
                  <div
                    className={`${selectedForSessionDisplay
                      ? "text-[#413131] bg-white"
                      : "text-white bg-light-brown-coffee"
                      } rounded-full font-semibold text-[8px] w-[22px]`}
                  >
                    <span>
                      +{bookedPeople.slice(bookedPeople.length - 2).length}
                    </span>
                  </div>
                </div>
              </div>
            ) : bookedPeople.length < 4 ? (
              <div
                className={`flex flex-row items-center mt-2 -space-x-2 overflow-hidden`}
              >
                {bookedPeople.map((person, i) => (
                  <div className='avatar' key={i}>
                    <div className='rounded-full w-[22px]'>
                      <Tooltip title={person.fullName}>
                        <img src={person.profilePic} alt={person.fullName} />
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      {popover && (
        <>
          <div className={"flex flex-col items-center ml-auto"}>
            <div
              className={
                "grid place-items-center rounded-xl cursor-pointer"
              }
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon
                color={anchorEl ? "secondary" : "primary"}
              />
            </div>

            <Popover
              id={popoverId}
              open={popoverMenuOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                my: 1,
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#EBEBEB",
                  borderRadius: 3,
                },
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                <button
                  onClick={() => {
                    handlePopoverClose();
                    handleSessionClick();
                    // handleDateTimeModalOpen();
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    View
                  </p>
                </button>
              </div>
            </Popover>
          </div>
        </>
      )}
    </div>
  );
}
