import { Box } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MyResourceTile from "./MyResourceTile";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import { Button, useMediaQuery } from '@mui/material';
import NeewSidebar from "../../../components/NewSideBar";
import "../../../components/Sidebar.css";
import { LoadingBackdrop } from "../../../layouts";

import noEventImage from "../../../assets/noEventImage.svg";
import { getUrlForServices, getUrlForUsers } from "../../../utils/confighelpers";
import WarningDialog from "../../confirmationModals/WarningDialog";

export default function MyResourceList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const [loading, setLoading] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState({});
  const [allowAddResource, setAllowAddResource] = React.useState(false);
  const [resourceList, setResourceList] = React.useState([]);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      await getUserInfo();
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (userDetail?.myPlatformFeeProfile?.myResource && userDetail?.myPlatformFeeProfile?.myResource?.numberOfAllowResource !== undefined) {
      setAllowAddResource(resourceList.length < userDetail?.myPlatformFeeProfile?.myResource?.numberOfAllowResource ? true : false)
    } else {
      setAllowAddResource(resourceList.length < 1 ? true : false)
    }
  }, [userDetail, resourceList]);


  const handleSessionClick = async (id, serviceData) => {
    // dispatch(dashboardActions.setCalendarClassData(serviceData));
    dispatch(dashboardActions.setResourceId(id));
    navigate("/myresource/update", {
      state: {
        from: "list",
      },
      replace: true
    });
  };


  const getUserInfo = async () => {
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    setLoading(true);
    const responce = await axios(manageClassesConfig);
    setLoading(false);
    if (responce.status === 200) {
      setUserDetail(responce.data.data);
      if (responce.data.data && Array.isArray(responce.data.data.myResource)) {
        setResourceList(responce.data.data.myResource);
      } else {
        setResourceList([]);
      }
    }
  };

  const renderResources = (data) => {
    return (
      <Box>
        {data.length > 0 ? (
          data.map((item, idx) => {
            const modifiedData = {
              popover: true,
              item: item,
              allResources: data,
            };
            return (<div
              key={idx}
              className={"cursor-pointer"}
            // onClick={() => handleSessionClick(modifiedData.id, item)}
            >
              <MyResourceTile
                {...modifiedData}
                key={idx}
                setLoading={setLoading}
                handleSessionClick={() => {
                  handleSessionClick(item._id, item);
                }}
                reload={() => {
                  getUserInfo();
                }}
              />
            </div>
            );
          })
        ) : (
          <div className={"text-black"}>no services are available</div>
        )}
      </Box>
    );
  };

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {/* {title} */}
        </h3>
      }
      subHeader={
        <div className={"flex gap-3"}>
          <div className={"w-[49%] flex gap-3 pl-6"}>
            <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
              {title}
            </h3>
          </div>
          <div className={"flex flex-row items-start mr-28 gap-2"}>
            <Button
              sx={{
                color: "common.white",
                backgroundColor: "secondary.main",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                width: 231,
                height: 52,
                fontSize: 16,
              }}
              variant={"contained"}
              onClick={() => {
                if (allowAddResource) {
                  dispatch(dashboardActions.setResourceId(-1));
                  navigate("/myresource/add", {
                    state: {
                      from: "list",
                    },
                    replace: true
                  });
                } else {
                  setOpenWarningDialog(true)
                }
              }}
            >
              Add New Resource
            </Button>
          </div>
        </div>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {resourceList.length ? (<div
            className={"grid grid-cols-2 w-full px-3 pt-5"}
          >
            <div className={"flex flex-col justify-center items-center"}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <div className={`self-start px-3`}>
                  {renderResources(resourceList)}
                </div>
              </Box>
            </div>
            <div className={"flex justify-end items-top"}>
              <div className={"flex-col items-end mr-28"}>
                {/* <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    if (allowAddResource) {
                      dispatch(dashboardActions.setResourceId(-1));
                      navigate("/myresource/add", {
                        state: {
                          from: "list",
                        },
                        replace: true
                      });
                    } else {
                      setOpenWarningDialog(true)
                    }
                  }}
                >
                  Add New Resource
                </Button> */}
              </div>
            </div>
          </div>) : loading === false ? (
            <Box className="flex flex-col justify-center items-center w-full mt-28">

              <Box className="flex flex-col justify-center items-center text-center">
                <img
                  className={"w-[235px]"}
                  src={noEventImage}
                  alt={""}
                />
                <Box sx={{
                  color: "#481B3A",
                  fontSize: "18px",
                  fontWeight: "700",
                  mt: 3,
                }}>
                  No Resources Configured
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-full mt-5">
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    dispatch(dashboardActions.setResourceId(-1));
                    navigate("/myresource/add", {
                      state: {
                        from: "list",
                      },
                      replace: true
                    });
                  }}
                >
                  Add  a new  Resource
                </Button>
              </Box>
            </Box>
          ) : ""}
          <WarningDialog
            open={openWarningDialog}
            text={`You are only allowed ${userDetail?.myPlatformFeeProfile?.myResource?.numberOfAllowResource || 1} resources as per your subscription. If you need to add more resources you will have to upgrade your subscription.`}
            isCloseBtn={false}
            btnSubmitText={"Close"}
            onClose={() => setOpenWarningDialog(false)}
            onSubmit={async () => {
              setOpenWarningDialog(false)
              // navigate("/businessprofilesetup/subscription", {
              //   state: {
              //     origin: "paymentprofile",
              //   },
              //   replace: true
              // });
            }}
          />
        </Box>
      </LoadingBackdrop>} />
  );
}
