import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import SmallScreenNotAllow from "../modals/error/SmallScreenNotAllow";

export default function LoadingBackdrop(props) {
  const { loading, children, className } = props;
  const [screenSizeType, setScreenSizeType] = React.useState("");
  React.useEffect(() => {
    if (screenSizeType === "small" || screenSizeType === "medium") {
      // window.location.href = "/small-screen-not-allow";
      // return false;
    }
  }, [screenSizeType]);
  React.useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // const screenWidth = window.outerWidth;

      if (screenWidth < 768) {
        setScreenSizeType("small");
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setScreenSizeType("medium");
      } else {
        setScreenSizeType("large");
      }
    };

    // Initial call to set screen size on component mount
    handleResize();

    // Event listener to update screen size on resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    (screenSizeType === "small") ? (
      <SmallScreenNotAllow />
    ) : (<div className={className}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(5px)",
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {children}
    </div>)
  );
}
