import React from "react";
import TimePicker from "antd/lib/time-picker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import './TimeInput.css';

export default function TimeInput(props) {
  return (
    <TimePicker
      use12Hours
      format='hh:mm a'
      allowClear={false}
      suffixIcon={<AccessTimeIcon sx={{ color: "#808080" }} />}
      {...props}
    />
  );
}
