export function minutesToDuration(minutes) {
  let mins = minutes;
  let hours = 0;
  for (let i = 0; mins >= 60; mins -= 60) {
    hours += 1;
  }
  return {
    hours,
    mins,
  };
}
