import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cogoToast from "cogo-toast";
import { useLocation } from "react-router-dom";

import { useLocalStorage } from "../../hooks";
import * as schoolActions from "../../redux/reducers/schoolReducer";
import SchoolProfileForm from "./SchoolProfileForm";
import SchoolProfileImgInput from "./SchoolProfileImgInput";
import SchoolProfileSelectDropdown from "./SchoolProfileSelectDropdown";
import CategoryBox from "../../components/CategoryBox";
import Container from "../../components/Container";
import { LoadingBackdrop } from "../../layouts";
import CategoryBoxSelected from "../../components/CategoryBoxSelected";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function SchoolProfileSetup({ navigation }) {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const [catsSelected, setCatsSelected] = React.useState(
    location.state !== null ? location.state.categories : []
  );
  const [contactAddress, setContactAddress] = React.useState(
    location.state !== null ? location.state.address : ""
  );
  const [postAddressConfig, setPostAddressConfig] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const formik = useFormik({
    initialValues: {
      categories: location.state !== null ? location.state.categories : [],
      logo: "",
      coverPhoto: "",
      instituteName:
        location.state !== null ? location.state.instituteName : "",
      contactAddress:
        location.state !== null ? location.state.contactAddress : "",
      contactEmail: location.state !== null ? location.state.contactEmail : "",
      website: location.state !== null ? location.state.website : "",
      aboutInstitute:
        location.state !== null ? location.state.aboutInstitute : "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.instituteName) {
        errors.instituteName = "Required!";
      }
      if (!values.contactAddress) {
        errors.contactAddress = "Required!";
      }
      if (!values.contactEmail) {
        errors.contactEmail = "Required!";
      }
      if (!values.aboutInstitute) {
        errors.aboutInstitute = "Required!";
      }
      if (!values.coverPhoto) {
        errors.coverPhoto = "Required!";
      }
      if (Array.isArray(values.categories) && !values.categories?.length) {
        errors.categories = "Required!";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      cogoToast.loading("Loading...").then(async () => {
        const body1 = {
          seletedCategories: values.categories,
        };
        const config1 = {
          method: "put",
          url: `${getUrlForUsers()}updateProfile`,
          headers: {
            "x-access-token": userToken.toString(),
            "Access-Control-Allow-Headers": "*",
          },
          data: body1,
        };
        const addLocationRequest = await axios(postAddressConfig);
        if (addLocationRequest.data.statusCode !== 200) {
          if (addLocationRequest.data.statusCode === 500) {
            cogoToast.error(addLocationRequest.data.message);
            console.log(addLocationRequest);
            setSubmitting(false);
            setLoading(false);
            return;
          }
          cogoToast.error("Error adding location.");
          console.log(addLocationRequest);
          setSubmitting(false);
          setLoading(false);
          return;
        }
        const locationObj = addLocationRequest.data.data;
        const requestCategories = await axios(config1);
        if (requestCategories.status === 200) {
          dispatch(
            schoolActions.setCategories(
              requestCategories.data.data.seletedCategories
            )
          );
          const body2 = {
            email: values.contactEmail,
          };
          const config2 = {
            method: "put",
            url: `${getUrlForUsers()}updateProfile`,
            headers: {
              "x-access-token": userToken.toString(),
              "Access-Control-Allow-Headers": "*",
            },
            data: body2,
          };
          const requestEmail = await axios(config2);
          if (requestEmail.status === 200) {
            if (requestEmail.data.statusCode === 403) {
              cogoToast.error(requestEmail.data.message);
              setSubmitting(false);
              setLoading(false);
              return;
            }
            setLoading(false);
            cogoToast.success("Success!");
            setSubmitting(false);
            // navigation("../schoolprofilesetup/verifyemail", {
            navigation("../businessprofilesetup/verifyemail", {
              state: {
                logo: values.logo,
                coverPhoto: values.coverPhoto,
                categories: values.categories,
                name: values.instituteName,
                email: values.contactEmail,
                address: contactAddress,
                locationObj,
                website: values.website,
                about: values.aboutInstitute,
                response: requestEmail.data,
              },
            });
          } else {
            setSubmitting(false);
            setLoading(false);
            console.log(requestEmail);
          }
        } else {
          setSubmitting(false);
          setLoading(false);
          console.log(requestCategories);
        }
      });
    },
  });

  React.useEffect(() => {
    const { setFieldValue } = formik;
    setFieldValue("categories", catsSelected);
    setFieldValue("contactAddress", contactAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catsSelected, contactAddress]);

  const handleAddCategory = (element) => {
    let catsCopy = Array.isArray(catsSelected) ? [...catsSelected] : [];
    Array.isArray(catsCopy) && catsCopy.indexOf(element) === -1
      ? (catsCopy = [...catsCopy, element])
      : (catsCopy = catsCopy.filter((item) => item !== element));
    setCatsSelected(catsCopy);
  };

  const handleRemoveCategory = (element) => {
    let catsCopy = [...catsSelected];
    if (Array.isArray(catsCopy)) {
      catsCopy = catsCopy.filter((item) => item !== element);
    }
    setCatsSelected(catsCopy);
  };

  if (location.state === null) {
    navigation("/");
  }

  return (
    <LoadingBackdrop loading={loading}>
      <div
        className={
          "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
        }
      >
        <h4
          className={
            "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
          }
          sx={{ color: "#481B3A" }}
        >
          School Profile
        </h4>
      </div>
      <Container className={"mx-32 sm:mx-0 md:mx-4"}>
        <Box sx={{ my: 5 }}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div className={"mx-auto max-w-[82.5rem]"}>
              <SchoolProfileSelectDropdown
                catsSelected={catsSelected}
                handleAddCategory={handleAddCategory}
                setLoading={setLoading}
              />
              <div
                className="flex flex-wrap mx-5 sm:mx-0 mb-2">
                {Array.isArray(formik.values.categories) &&
                  formik.values.categories.length > 0 &&
                  formik.values.categories.map((element) => (
                    <CategoryBoxSelected
                      key={element.catId}
                      add={false}
                      remove={true}
                      removeOnClick={() => handleRemoveCategory(element)}
                      elementData={element}
                      label={element.catName}
                      vector={element.catImage}
                      bgColor={"#481B3A"}
                      svgFill={"#FFFFFF"}
                    />
                  ))}
              </div>
            </div>
            <Box
              className={
                "grid grid-cols-2 gap-[8rem] sm:gap-0 md:gap-0 mx-auto max-w-7xl sm:flex sm:flex-col"
              }
            >
              <SchoolProfileImgInput
                setFieldValue={formik.setFieldValue}
                values={formik.values}
              />
              <SchoolProfileForm
                form={formik}
                formErros={formik.errors}
                contactAddress={contactAddress}
                setContactAddress={setContactAddress}
                setPostAddressConfig={setPostAddressConfig}
              />
            </Box>
            <Box
              className={
                "grid grid-cols-1 grid-flow-col max-w-[83.5rem] mx-auto justify-items-end sm:justify-items-center md:justify-items-center"
              }
            >
              <Button
                sx={{
                  backgroundColor: "#481B3A",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#481B3A",
                  },
                  fontWeight: "bold",
                  width: isTablet ? 265 : 195,
                  height: isMobile || isTablet ? 55 : 65,
                  fontSize: isMobile || isTablet ? 16 : 20,
                  borderRadius: 3,
                }}
                variant={"contained"}
                disabled={
                  !(formik.isValid && formik.dirty) && !formik.isSubmitting
                }
                onClick={formik.submitForm}
              >
                Continue
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </LoadingBackdrop>
  );
}
