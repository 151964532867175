import { uploadFile } from "react-s3";

const AWSConfig = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
};

export const handleUploadImageToS3 = async (file) => {
  return uploadFile(file, AWSConfig)
    .then((data) => data)
    .catch((err) => console.error(err));
};
