import { Box, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";


import { useMediaQuery } from '@mui/material';
import NeewSidebar from "../../../components/NewSideBar";
import "../../../components/Sidebar.css";
import {
  LoadingBackdrop
} from "../../../layouts";

import charetleft from "../../../assets/charetleft.svg";
import { userState } from "../../../redux/reducers/userReducer";
import PurchasedSessionPass from "./PurchasedSessionPass";
import SessionPassBooking from "./SessionPassBooking";

export default function SessionPassDetail({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const anchorEl = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [classInfo, setClassInfo] = React.useState({});
  const [selectCustomerBooking, setSelectCustomerBooking] = React.useState([]);
  const [selectBuyingDetail, setSelectBuyingDetail] = React.useState({});
  const [passPurchaseList, setPassPurchaseList] = React.useState({ data: [], total: 0 });
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  React.useEffect(() => {
    (async () => {
      // console.log("dashboardReduxState.sessionPassData", dashboardReduxState.sessionPassData);
      setClassInfo(dashboardReduxState.sessionPassData)
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.sessionPassData]);


  const onBackClick = () => {
    if (selectCustomerBooking.length) {
      setSelectCustomerBooking([])
      setSelectBuyingDetail({})
    } else {
      navigate("/myclasses/detail", { replace: true });
    }
  };

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          <img src={charetleft} alt="" className="inline align-baseline mr-8 cursor-pointer" onClick={() => {
            onBackClick();
          }} />{title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main' className="flex flex-col w-full mt-5">
          <div className={"flex w-fullpy-2 px-7"}>
            <div className={"flex-1"}>
              <h2 className={"font-bold text-[30px] text-brown-coffee"}>
                {/* {classInfo.className} */}
                {classInfo?.selectedPassDetail?.passName}
              </h2>
            </div>
          </div>
          <Box className="grid grid-cols-1 w-full py-2 px-7 gap-2 ">
            <div className="p-4 pt-3 pb-8 border-2 border-[#EBEBEB] /*hover:border-[#7E00DD]*/ rounded-lg bg-[#f4f4f4]">
              {/* <Stack direction={"column"} spacing={3}>
                <Stack direction={"row"} alignItems={"center"}>
                  <div className={"flex w-full"}>
                    <div className={"flex-1"}>
                      <h2 className={"font-bold text-[30px] text-brown-coffee"}>
                        {classInfo.className}
                      </h2>
                    </div>
                  </div>
                </Stack>
              </Stack> */}
              <div className="grid grid-cols-4 gap-6">
                {/* <div className="mt-0">
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Pass Name
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.passName}</span>
                  </p>
                </div> */}
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    No Of Sessions
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.noOfAllowedSessions}</span>
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Price
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>€{classInfo?.selectedPassDetail?.amount}</span>
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Validity
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.validUntilConsumed === true ? "Until Consumed" : classInfo?.selectedPassDetail?.validityDays}</span>
                  </p>
                  <p className={"font-light text-sm text-[#B5B5C3]"}>
                    From the purchase date
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Passes Sold
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{passPurchaseList?.total || 0}</span>
                  </p>
                </div>

              </div>
            </div>
            {/* <Stack direction={"column"} spacing={5} className="mt-0">
              <div className="mt-10 p-4 pt-5 pb-10 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg grid grid-cols-2 gap-6">
                <div className="mt-5">
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Pass Name
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.passName}</span>
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Sessions Capacity
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.noOfAllowedSessions}</span>
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Price
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>€{classInfo?.selectedPassDetail?.amount}</span>
                  </p>
                </div>
                <div>
                  <h4 className={"font-bold text-lg text-brown-coffee"}>
                    Validity Days
                  </h4>
                  <p className={"font-light text-base"}>
                    <span>{classInfo?.selectedPassDetail?.validUntilConsumed === true ? "Until Consumed" : classInfo?.selectedPassDetail?.validityDays}</span>
                  </p>
                </div>

              </div>
            </Stack> */}
          </Box>
          {selectCustomerBooking.length ? (<SessionPassBooking
            navigation={navigation}
            loading={setLoading}
            setLoading={setLoading}
            classInfo={classInfo}
            selectCustomerBooking={selectCustomerBooking}
            selectBuyingDetail={selectBuyingDetail}
          />) : (<PurchasedSessionPass
            navigation={navigation}
            loading={setLoading}
            setLoading={setLoading}
            classInfo={classInfo}
            selectCustomerBooking={setSelectCustomerBooking}
            selectBuyingDetail={setSelectBuyingDetail}
            setPassPurchaseList={setPassPurchaseList}
          />)}
        </Box>

      </LoadingBackdrop>} />
  );
}
