const findZipcode = (components) => {
  let compsArray = components.map(
    (comp) => comp.types[0].startsWith("postal") && comp
  );
  if (!compsArray.length) {
    return "";
  }
  compsArray = compsArray.filter((comp) => comp !== false);
  if (!compsArray.length) {
    return "";
  }
  return compsArray[0].long_name;
};

export { findZipcode };
