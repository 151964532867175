import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Popover } from "@mui/material";

import { useLocalStorage } from "../../../../hooks";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";


import MarkAsPaidDialog from "../../../../components/MarkAsPaidDialog";
import ErrorDialog from "../../../confirmationModals/ErrorDialog";
import WarningDialog from "../../../confirmationModals/WarningDialog";

import Alert from "../../../../assets/Alert.png";
import BookingLinkModal from "../../../../components/BookingLinkModal";
import { LoadingBackdrop } from "../../../../layouts";
import moment from "moment";
import { getUrlForBookings } from "../../../../utils/confighelpers";

export default function SessionBookingPopover({
  bookingDetails,
  handleUpdate,
  loading,
  setLoading
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);

  const {
    _id,
    bookingId,
    service_id,
    studentId,
  } = bookingDetails;
  const username = bookingDetails?.student_detail?.username;
  const email = bookingDetails?.student_detail?.email;
  const userId = bookingDetails.studentId;
  const isPackage = bookingDetails.isPackage;
  const isPaid = (["paid", "refund-pendding", "refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) ? true : false;
  const [popoverOptionsDisabled, setPopoverOptionsDisabled] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = React.useState(false);
  const [openPatmentProfileWarningDialog, setOpenPatmentProfileWarningDialog] = React.useState(false);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const [openCancelWarningDialog, setOpenCancelWarningDialog] = React.useState(false);
  const [warningText, setWarningText] = React.useState("");
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const [bookingLinkModalOpen, setBookingLinkModalOpen] = React.useState(false);
  const [shareLink, setShareLink] = React.useState("false");


  const handleOpenMarkAsPaidDialog = () => {
    handleClose();
    setMarkAsPaidDialogOpen(true);
  };
  const handleCloseMarkAsPaidDialog = () => setMarkAsPaidDialogOpen(false);
  const handleOpenWarningDialog = () => {
    handleClose();
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = () => setOpenWarningDialog(false);
  const handleOpenErrorDialog = () => {
    handleClose();
    setOpenErrorDialog(true);
  };
  const handleCloseErrorDialog = () => setOpenErrorDialog(false);

  const handleBookingLinkModalOpen = () => setBookingLinkModalOpen(true);
  const handleBookingLinkModalClose = () => setBookingLinkModalOpen(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const sendTikkiePromise = async (type) => {
    // cogoToast.loading("Loading...");
    // setLoading(true);
    const body = {
      bookingId,
      forceSendLink: type,
      bookingFor: "Tikkie",
    };
    const config = {
      method: "post",
      url: `${getUrlForBookings()}payments/generateTikkiePmtLink`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    // setLoading(false);
    return request;
  };

  const sendTikkie = async () => {
    handleClose();
    cogoToast.loading(
      <LoadingBackdrop loading={true}></LoadingBackdrop>
    ).then(async () => {

      const request = await sendTikkiePromise(false);
      if (request.status === 200) {
        if (request.data.statusCode === 417) {
          setErrorText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
          handleOpenErrorDialog();
        } else if (request.data.statusCode === 406) {
          setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
          // handleOpenWarningDialog();
          setOpenWarningDialog(true);
        } else if (request.data.statusCode === 403) {
          setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
          handleClose();
          handleCloseWarningDialog();
          setOpenPatmentProfileWarningDialog(true)
        } else if (request.data.statusCode === 200) {
          /* const usernameCopy = username;
          const number = usernameCopy.replace(/[^\w\s]/gi, "").replace(/ /g, "");
          let url = `https://web.whatsapp.com/send?phone=${number}`;
          const message = `Hi! Here's the tikkie link for the payment of my session.\n${request.data.generatedUrl}`;
          url += `&text=${encodeURI(message)}&app_absent=0`;
          window.open(url); */
          setShareLink(request.data.data.generatedUrl);
          handleBookingLinkModalOpen();
          // handleClose();
        }
      }
      return request;
    });
  };

  const viewDetails = () => {
    navigate("/myservices/schedule/detail", {
      state: {
        bookingId: _id
      },
    })
  }

  const handleMarkAsPaid = async () => {
    handleClose();
    handleCloseMarkAsPaidDialog();
    // cogoToast.loading("Loading...");
    cogoToast.loading(
      <LoadingBackdrop loading={true}></LoadingBackdrop>
    ).then(async () => {
      const body = {
        bookingId,
        paymentStatus: "Paid",
        paymentMode: "Cash",
      };
      const config = {
        method: "put",
        url: `${getUrlForBookings()}payments/updatePaymentStatus`,
        headers: {
          "x-access-token": userToken,
        },
        data: body,
      };
      console.log(config);
      setLoading(true);
      const request = await axios(config);
      setLoading(false);
      if (request.status === 200) {
        if (request.data.statusCode === 200) {
          cogoToast.success("Success!");
          handleUpdate();
          console.log(request);
        } else {
          cogoToast.error(request.data.message);
          console.log(request);
        }
      }
    })
  };

  const handleDelete = async () => {
    handleClose();
    setOpenCancelWarningDialog(prevState => !prevState);
  };

  const handleContactClick = () => {
    handleClose();
    if (email) {
      window.open(`mailto:${email}`);
    } else {
      const usernameCopy = username;
      const number = usernameCopy.replace(/[^\w\s]/gi, "").replace(/ /g, "");
      window.open(`https://web.whatsapp.com/send?phone=${number}`);
    }
  };

  const popoverOptions = [
    {
      text: "View Details",
      onClick: viewDetails,
      disabled: false,
      icon: Alert,
    },
    {
      text: "Send Tikkie",
      onClick: async () => await sendTikkie(),
      disabled: (bookingDetails?.status == "Cancelled") || (["paid", "refund-pendding", "refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) || parseInt(bookingDetails.amount) <= 0,
      icon: Alert,
    },
    {
      text: "Mark as paid",
      onClick: handleOpenMarkAsPaidDialog,
      // disabled: isPaid,
      disabled: (bookingDetails?.status == "Cancelled") || (["paid", "Not-refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) || parseInt(bookingDetails.amount) <= 0,
      icon: Alert,
    },
    {
      text: "Cancel booking",
      onClick: handleDelete,
      disabled: (bookingDetails.status == "Cancelled") ? true : moment(bookingDetails.slot_date + " " + bookingDetails.slot_start_time).subtract({ hours: 0 }).isBefore(),
      icon: Alert,
    },
    /* {
      text: "View History",
      onClick: () => undefined,
      disabled: true,
      icon: Alert,
    }, */
    {
      text: "Contact",
      onClick: handleContactClick,
      disabled: false,
      icon: Alert,
    },
  ];

  // console.log("bookingDetails", bookingDetails);

  return (

    <Box>
      <IconButton sx={{ p: 0, m: "6px" }} onClick={handleOpen}>
        <MoreVertIcon sx={{ color: "black" }} />
      </IconButton>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          my: 1,
          "& .MuiPopover-paper": {
            boxShadow: "none",
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 3,
          },
        }}
      >
        <div className={"flex flex-col gap-3 py-5"}>
          {popoverOptions.map((opt, idx) => (
            <button
              key={idx}
              disabled={opt.disabled}
              className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${opt.disabled
                ? ""
                : "active:bg-light-brown-coffee active:text-white"
                } disabled:opacity-60 disabled:cursor-default`}
              onClick={opt.onClick}
            >
              <p className={"text-sm"}>{opt.text}</p>
              {/* <img className={"w-4 bg-brown-coffee rounded-full"} src={opt.icon} alt={""} /> */}
            </button>
          ))}
        </div>
      </Popover>

      <MarkAsPaidDialog
        open={markAsPaidDialogOpen}
        handleClose={handleCloseMarkAsPaidDialog}
        onSubmit={handleMarkAsPaid}
      />
      <WarningDialog
        open={openWarningDialog}
        text={warningText}
        onClose={handleCloseWarningDialog}
        onSubmit={async () => {
          handleCloseWarningDialog();
          cogoToast.loading(
            <LoadingBackdrop loading={true}></LoadingBackdrop>
          ).then(async () => {
            const request = await sendTikkiePromise(true);
            if (request.status === 200) {
              if (request.data.statusCode === 200) {
                // const usernameCopy = username;
                // const number = usernameCopy
                //   .replace(/[^\w\s]/gi, "")
                //   .replace(/ /g, "");
                // let url = `https://web.whatsapp.com/send?phone=${number}`;
                // const message = `Hi! Here's the tikkie link for the payment of my session.\n${request.data.generatedUrl}`;
                // url += `&text=${encodeURI(message)}&app_absent=0`;
                // window.open(url);
                setShareLink(request.data.data.generatedUrl);
                handleBookingLinkModalOpen();
                handleClose();
                handleCloseWarningDialog();
              } else if (request.data.statusCode === 403) {
                setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
                handleClose();
                handleCloseWarningDialog();
                setOpenPatmentProfileWarningDialog(true)
              }
            }
          })
        }}
      />

      <WarningDialog
        open={openCancelWarningDialog}
        text={"Are you sure, you want to cancel booking?"}
        onClose={() => setOpenCancelWarningDialog(false)}
        onSubmit={async () => {
          setOpenCancelWarningDialog(false)
          cogoToast.loading(
            <LoadingBackdrop loading={true}></LoadingBackdrop>
          ).then(async () => {
            const body = {
              bookingId,
              service_id: service_id,
              participantId: studentId,
              // isRefundApplicable: true,
            };
            const config = {
              method: "delete",
              url: `${getUrlForBookings()}getServiceBooking`,
              headers: {
                "x-access-token": userToken,
              },
              data: body,
            };
            const request = await axios(config);
            // setLoading(false);
            if (request.status === 200) {
              if (request.data.statusCode === 200) {
                console.log(request);
                cogoToast.success("Removed Participant");
                handleUpdate();
              } else {
                console.log(request);
                cogoToast.error(request.data.message);
              }
            }
          })
        }}
      />

      <WarningDialog
        open={openPatmentProfileWarningDialog}
        text={"You have not enabled your payment settings. Do you want to set it now?"}
        onClose={() => setOpenPatmentProfileWarningDialog(false)}
        onSubmit={async () => {
          cogoToast.loading("Loading...");
          navigate("/payment/profile", { replace: true })
        }}
      />
      <ErrorDialog
        open={openErrorDialog}
        text={errorText}
        onClose={handleCloseErrorDialog}
      />
      <BookingLinkModal
        title={"Payment link for " + bookingDetails?.className}
        subtitle="Payment Link Generated"
        open={bookingLinkModalOpen}
        handleClose={handleBookingLinkModalClose}
        bookingLink={shareLink}
      />
    </Box>
  );
}
