import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";

import { Box, Popover, Rating } from "@mui/material";
import { useLocalStorage } from "../../../hooks";
import ServiceBookingLinkModal from "./ServiceBookingLinkModal";
import ErrorDialog from "../../confirmationModals/ErrorDialog";
import DeleteDialog from "../../../components/DeleteDialog";
import { getUrlForServices } from "../../../utils/confighelpers";
import { cutString } from "../../../helpers";

export default function ServiceTile({
  popover,
  setLoading,
  handleSessionClick,
  reload,
  item,
}) {
  const [eventStatusColors, setEventStatusColors] = React.useState({
    "draft": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "false": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "published": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "true": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "cancelled": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033" // #ff404033
    },
    "refunded": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033"
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showBookingLink, setShowBookingLink] = React.useState(false);
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteDialogClose = () => {
    handlePopoverClose();
    setDelDialogOpen(false);
  };


  const handleDeleteDialogOpen = () => {
    handlePopoverClose();
    setDelDialogOpen(true);
  };


  const deleteServices = async (id) => {
    
    handleDeleteDialogClose();
    const manageClassesConfig = {
      method: "delete",
      url: `${getUrlForServices()}deleteService/${id}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    setLoading(true)
    const responce = await axios(manageClassesConfig);    
    setLoading(false)
    if (responce.status === 200) {
      if (responce.data.statusCode === 200) {

      } else if (responce.data.statusCode === 403) {
        setErrorText(responce.data.message)
        setOpenErrorDialog(true);
        return;
      }
    }
    reload();
  };

  const updateStatus = async (eventData, status) => {
    console.log("classData", eventData);
    cogoToast.loading("Loading...");
    const config = {
      method: "post",
      // url: `${process.env.REACT_APP_API_URL_1}/service/updatestatus/${eventData._id}`,
      url: `${getUrlForServices()}updateServiceStatus/${eventData._id}`,
      headers: {
        "x-access-token": userToken,
      },
      data: { status }
    };
    const request = await axios(config);
    console.log("request.data", request.data.data);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        reload();
      }
    }
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  const appUrl = item?.appUrl ? item?.appUrl : process.env.REACT_APP_WEBAPP_URL;
  const bookingLink = appUrl + (item?.bookingLink ? item?.bookingLink : "");

  return (
    <div
      className={`my-3 1-p-3 px-3 flex flex-row items-center gap-4 cursor-pointer 
        } -bg-white -hover:bg-[#e1e1e1] -shadow-md -border border-bright-gray rounded-2xl`}
    >
      <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
        <div
          className=""
        >
          <img
            className={`${"w-[77px] max-w-[77px] h-[77px] rounded-xl"
              } object-fill`}
            src={item.servicePhoto}
            alt={"session img"}
          />
        </div>
        <div className={`flex item-top grow`}>
          <div className={`flex flex-col item-center w-full gap-1`}>
            <h3
              className={`text-brown-coffee font-bold text-base capitalize`}
            >
              {item.serviceName}
            </h3>
            <div className="grid grid-cols-3 items-top">
              <div className={`flex flex-col item-center`}>
                <div className={"flex flex-row items-center gap-2"}>
                  <Rating
                    sx={{
                      color: "primary.main",
                      fontSize: 17,
                    }}
                    value={
                      Array.isArray(item.rating) ? (item.rating.length ? item.rating[0] : 0) : item.rating
                    }
                    precision={0.5}
                    readOnly
                  />
                  <p
                    className={`text-brown-coffee text-xs`}
                  >
                    {item.rating}
                  </p>
                </div>
                <div className={"flex flex-row items-center gap-2"}>
                  <Box
                    className={`p-1 px-2 mt-2 text-xs rounded-full`}
                    sx={{ ...(item?.serviceStatus ? eventStatusColors[item.serviceStatus.toString().toLowerCase()] : eventStatusColors["false"]) }}
                  >
                    {item?.serviceStatus === "Draft" ? "Unavailable" : "Available"}
                  </Box>
                </div>
              </div>
              <div className={"flex flex-col items-start"}>
                <h3
                  className={`text-brown-coffee font-bold text-base`}
                >
                  Duration
                </h3>
                <p
                  className={`text-granite-gray text-xs`}
                >
                  {cutString(item?.slots ? item.slots.map((slot, i) => {
                    return slot.duration + " min"
                  }).join(" - ") : "", 15, true)}
                </p>
              </div>
              <div className={"flex flex-col items-start"}>
                <h3
                  className={`text-brown-coffee font-bold text-base`}
                >
                  Pricing
                </h3>
                <p
                  className={`text-granite-gray text-xs`}
                >
                  from €{item?.slots ? item.slots[0].price : "0"}
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      {popover && (
        <>
          <div className={"flex flex-col items-center ml-auto"}>
            <div
              className={
                "grid place-items-center rounded-xl cursor-pointer"
              }
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon
                color={anchorEl ? "secondary" : "primary"}
              />
            </div>

            <Popover
              id={popoverId}
              open={popoverMenuOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                my: 1,
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#EBEBEB",
                  borderRadius: 3,
                },
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                {/* <button
                  onClick={() => {
                    handlePopoverClose();
                    setShowBookingLink(true);
                  }}
                  disabled={item?.serviceStatus === "Draft"}
                >
                  <p
                    className={`${item?.serviceStatus === "Draft" ? "text-light-gray" : "text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Share Booking Link
                  </p>
                </button> */}
                <button
                  onClick={() => {
                    handlePopoverClose();
                    handleSessionClick();
                    // handleDateTimeModalOpen();
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Edit
                  </p>
                </button>
                <button
                  onClick={() => {
                    handleDeleteDialogOpen();
                    // deleteServices(item._id);
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Delete
                  </p>
                </button>
                <button
                  onClick={() => {
                    handlePopoverClose();
                    updateStatus(item, item?.serviceStatus === "Draft" ? "Published" : "Draft");
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Make it {item?.serviceStatus === "Draft" ? "Available" : "Unavailable"}
                  </p>
                </button>
              </div>
            </Popover>
          </div>
        </>
      )}
      <ServiceBookingLinkModal
        title={"Booking Link for " + item.serviceName}
        subtitle="Booking Link Generated"
        open={showBookingLink}
        handleClose={() => {
          setShowBookingLink(false);
        }}
        bookingLink={bookingLink}
      />
      <DeleteDialog
        open={delDialogOpen}
        title={"WARNING"}
        type={""}
        handleClose={handleDeleteDialogClose}
        handleDelete={() => deleteServices(item._id)}
      />
      <ErrorDialog
        open={openErrorDialog}
        text={errorText}
        onClose={() => setOpenErrorDialog(false)}
      />
    </div>
  );
}
