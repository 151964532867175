import { Box } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";

import { useLocalStorage } from "../../../hooks";

import { useLocation } from "react-router-dom";
import charetleft from "../../../assets/charetleft.svg";
import NeewSidebar from "../../../components/NewSideBar";
import {
    LoadingBackdrop
} from "../../../layouts";
import { getUrlForBookings } from "../../../utils/confighelpers";
import { displayAvailablePaymentMethods } from "../../../utils/constants";
import PurchaseDetailActionPopover from "./PurchaseDetailActionPopover";


export default function PurchaseDetails({ navigation }) {
    const location = useLocation();
    const [userToken] = useLocalStorage("userToken");
    const [purchaseInfo, setPurchaseInfo] = React.useState();
    const [loading, setLoading] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const fetchBookingsDetails = async () => {
        console.log("location.state.purchaseId", location.state.purchaseId);
        setLoading(true)
        const config = {
            method: "get",
            // url: `${getUrlForBookings()}getMyPurchases?purchaseId=${location.state.purchaseId}&passId=${location.state.passId}`,
            url: `${getUrlForBookings()}getMyPurchases?purchaseId=${location.state.purchaseId}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const request = await axios(config);
        if (request.status === 200) {
            if (request.data.statusCode === 200) {
                setPurchaseInfo(request.data.data)
            }
        }
        setLoading(false)
    }

    React.useEffect(() => {
        fetchBookingsDetails()
    }, [])

    const calculateBoughtTickets = () => {
        let totalBookedTickets = 0;

        if (getPaymentMethod() === "classPass") {
            purchaseInfo?.passesBought.forEach((item) => {
                totalBookedTickets = totalBookedTickets + parseInt(item.purchasingQty)
            })
        } else if (purchaseInfo && purchaseInfo?.passesBought?.length) {
            purchaseInfo?.passesBought.forEach((item) => {
                totalBookedTickets = totalBookedTickets + parseInt(item.purchasingQty)
            })
        }

        return totalBookedTickets
    }

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        let serviceFees = 0;

        if (purchaseInfo?.amount) {
            return parseFloat(purchaseInfo?.amount || 0).toFixed(2)
        } else {
            if (purchaseInfo && purchaseInfo?.passesBought?.length) {
                purchaseInfo?.passesBought.forEach((item) => {
                    serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.purchasingQty))
                    totalPrice = totalPrice + parseFloat(item.totalPrice)
                })
            }

            // return parseFloat(totalPrice + serviceFees).toFixed(2)
            return parseFloat(totalPrice).toFixed(2)
        }
    }

    const calculateUsersReceiveTotalPrice = () => {
        let amountToUser = 0;

        if (purchaseInfo && purchaseInfo?.passesBought?.length) {
            purchaseInfo?.passesBought.forEach((item) => {
                amountToUser = amountToUser + parseFloat(item?.amountToUser || 0)
            })
        }

        return parseFloat(amountToUser).toFixed(2)

    }

    const calculateServiceFees = () => {
        let serviceFees = 0;

        if (purchaseInfo && purchaseInfo?.passesBought?.length) {
            purchaseInfo?.passesBought.forEach((item) => {
                serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.purchasingQty))
            })
        }

        return parseFloat(serviceFees).toFixed(2)
    }

    const calculatePaymentServiceFees = () => {

        if (purchaseInfo?.totalPaymentServiceFee) {
            return parseFloat(purchaseInfo?.totalPaymentServiceFee).toFixed(2)
        }
        return parseFloat(0).toFixed(2)
    }

    const getConsumerInfo = () => {
        let consumerDetails = {}

        // if (purchaseInfo && purchaseInfo?.schedule_class_detail?.participants?.length) {
        //     consumerDetails = purchaseInfo?.schedule_class_detail?.participants.find((item) => item?.purchaseId === purchaseInfo.purchaseId)
        // }

        if (purchaseInfo) {
            consumerDetails = purchaseInfo?.consumer_detail
        }

        return consumerDetails;
    }

    const getPaymentStatus = () => {
        let paymentStatus = purchaseInfo?.paymentStatus;
        // if ((purchaseInfo?.paymentStatus == "Paid" || purchaseInfo?.paymentStatus == "Free") && purchaseInfo.paymentMethod == "NA" && parseInt(purchaseInfo.amount) <= 0) {
        //     paymentStatus = 'Free';
        // }
        var displayContent = (<button
            className={
                "h-[30px] bg-[#5B274B] rounded-lg ml-4"
            }
            onClick={() => null}
            style={{ padding: '0px 10px' }}>
            <p className={"font-bold text-sm text-white text-center"}>
                {paymentStatus}

            </p>
        </button>)
        if (purchaseInfo?.status == "Cancelled" && (purchaseInfo?.paymentStatus == "Unpaid" || purchaseInfo?.paymentStatus == "Not-refunded") && purchaseInfo.paymentMode != "Online") {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>Customer never paid for it</span></span>);
        } else if (purchaseInfo?.status == "Cancelled" && purchaseInfo?.paymentStatus == "Not-refunded" && purchaseInfo.paymentMode == "NA" && parseInt(purchaseInfo.amount) <= 0) {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>It was a free service</span></span>);
        }

        return displayContent
    };

    const getPaymentMethod = () => {
        if (purchaseInfo?.paymentMethod && purchaseInfo?.paymentMethod !== 'NA') {
            return Object.keys(displayAvailablePaymentMethods).indexOf(purchaseInfo?.paymentMethod) >= 0 ? displayAvailablePaymentMethods[purchaseInfo?.paymentMethod] : "UNKNOWN";
        } /* else if (purchaseInfo?.paymentStatus == "Unpaid" && purchaseInfo.paymentMethod == "NA" && purchaseInfo.paymentMode != "Online" && parseInt(purchaseInfo.amount) > 0) {
            return 'pay at venue';
        } else if ((purchaseInfo?.paymentStatus == "Paid" || purchaseInfo?.paymentStatus == "Free") && purchaseInfo.paymentMethod == "NA" && parseInt(purchaseInfo.amount) <= 0) {
            //return 'Free';
            return 'Not Applicable';
        } */
        return 'UNKNOWN';
    };

    const getAmountPaid = () => {

        let amountPaid = "€0.00";

        if (purchaseInfo && purchaseInfo?.passesBought?.length) {
            purchaseInfo?.passesBought.forEach((item) => {
                amountPaid = `€${parseFloat(parseFloat(item?.amount ? item?.amount : 0)).toFixed(2)} `
            })
        }

        return amountPaid
    }

    console.log("purchaseInfo", purchaseInfo)

    return (
        <NeewSidebar
            navBarTitleComp={
                <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigation(-1)}>
                    <img src={charetleft} className="inline align-baseline mr-3 cursor-pointer" />
                    <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
                        Purchase Details
                    </h3>
                </span>
            }
            children={<LoadingBackdrop loading={loading}>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        py: 2,
                        px: 3,
                        overflowX: "hidden",
                        minHeight: "100vh",
                    }}
                    className="grid"
                >
                    <Box className="flex flex-col w-full">
                        <Box className="flex justify-end p-3">
                            <Box className="flex-none">
                                {/* <button
                                    className={"bg-[#3699FF] text-white rounded w-[160px] p-3"}
                                    onClick={handlePopoverOpen}
                                >
                                    <span className={"flex flex-row items-center"}>
                                        <p className={"w-full"}>Action</p>
                                        <img src={dropdownbottom} alt={""} />
                                    </span>
                                </button> */}
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Purchase ID</p>
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold', color: '#00000099' }}>: {purchaseInfo?.purchaseId}</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((purchaseInfo?.status && purchaseInfo?.status === "Cancelled") ? { color: '#FF4040' } : {}) }}>{purchaseInfo?.status}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{moment(purchaseInfo?.bookingDate).format('DD-MM-YYYY')}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Cancellation Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{purchaseInfo?.status?.toLowerCase() === "cancelled" ? moment(purchaseInfo?.cancellationDate).format('DD-MM-YYYY') : 'Not Applicable'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Payment Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'left' }}>
                                    <span>:</span>
                                    {getPaymentStatus(purchaseInfo)}
                                    {/* <button
                                        className={
                                            "h-[30px] bg-[#5B274B] rounded-lg ml-4"
                                        }
                                        onClick={() => null}
                                        style={{ padding: '0px 10px' }}>
                                        <p className={"font-bold text-sm text-white text-center"}>
                                            {purchaseInfo?.paymentStatus}
                                        </p>
                                    </button> */}
                                </span>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Customer Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Full Name</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getConsumerInfo()?.fullName ? getConsumerInfo()?.fullName : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Email</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#7E00DD' }}>{getConsumerInfo()?.email ? getConsumerInfo()?.email : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Mobile Number</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getConsumerInfo()?.username ? "" + getConsumerInfo()?.username : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            {/* <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>City</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{purchaseInfo?.schedule_class_detail?.location?.city}</span>
                                </span>
                            </Box> */}
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Purchase Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Bought</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    {/* <span style={{ marginLeft: '20px' }}>{purchaseInfo?.schedule_class_detail?.className ? purchaseInfo?.schedule_class_detail?.className : purchaseInfo?.className}</span> */}
                                    <span style={{ marginLeft: '20px' }}>{purchaseInfo?.selectedPassDetail?.passName ? purchaseInfo?.selectedPassDetail?.passName : purchaseInfo?.className}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Total Allowed Sessions</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    {/* <span style={{ marginLeft: '20px' }}>{purchaseInfo?.schedule_class_detail?.className ? purchaseInfo?.schedule_class_detail?.className : purchaseInfo?.className}</span> */}
                                    <span style={{ marginLeft: '20px' }}>{purchaseInfo?.selectedPassDetail?.noOfAllowedSessions ? purchaseInfo?.selectedPassDetail?.noOfAllowedSessions : "0"}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Expiry Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    {/* <span style={{ marginLeft: '20px' }}>{purchaseInfo?.schedule_class_detail?.className ? purchaseInfo?.schedule_class_detail?.className : purchaseInfo?.className}</span> */}
                                    <span style={{ marginLeft: '20px' }}>{purchaseInfo?.selectedPassDetail?.validUntilConsumed ? "Until Consumed" : `${purchaseInfo?.selectedPassDetail?.validityDays} Days`}</span>
                                </span>
                            </Box>
                            {/* <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((purchaseInfo?.schedule_class_detail && purchaseInfo?.schedule_class_detail?.eventStatus === "Cancelled") ? { color: '#FF4040' } : {}) }}>{purchaseInfo?.schedule_class_detail?.eventStatus ? (purchaseInfo?.schedule_class_detail?.eventStatus === "Cancelled" ? "This event is cancelled" : purchaseInfo?.schedule_class_detail?.eventStatus) : 'UNKNOWN'}</span>
                                </span>
                            </Box> */}

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Amount Paid</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getAmountPaid()}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Platform Service Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{`€${calculateServiceFees()}`}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Method</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right', textTransform: 'capitalize' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}> {getPaymentMethod()}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Processing Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{`€${calculatePaymentServiceFees()}`}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <Box className="flex px-3 py-1 rounded bg-[#D9D9D9]">
                                    <p style={{ fontSize: '16px', lineHeight: '24px', width: '188px' }}>Total Amount Paid</p>
                                    <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                        <span>:</span>
                                        <span style={{ marginLeft: '20px' }}>{`€${calculateTotalPrice()} `} <span style={{ color: '#00000099' }}>Including VAT & Service Fee</span></span>
                                    </span>
                                </Box>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Your Payout</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{`€${calculateUsersReceiveTotalPrice()}`} </span>
                                </span>
                            </Box>
                        </Box>

                    </Box>
                    {purchaseInfo ? <PurchaseDetailActionPopover
                        purchasingDetails={purchaseInfo}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleUpdate={fetchBookingsDetails}
                        setLoading={setLoading}
                    /> : null}

                </Box>
            </LoadingBackdrop>} />
    );
}
