import React from "react";
import Dropzone from "react-dropzone";
import { Box, FormHelperText, IconButton, Tooltip } from "@mui/material";

import { handleUploadImageToS3 } from "../../helpers";

import add_photo from "../../assets/add_img.png";

export default function SchoolProfileImgInput({ setFieldValue, values }) {
  const [logo, setLogo] = React.useState();
  const [coverPhoto, setCoverPhoto] = React.useState();
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => {
    setFieldValue("logo", logo);
    setFieldValue("coverPhoto", coverPhoto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo, coverPhoto]);

  return (
    <div className={"flex flex-col justify-center items-center"}>
      <Box className={"my-3"}>
        <h6
          className={"text-xl sm:text-base md:text-base font-bold text-center"}
          style={{
            color: "#32282F",
          }}
        >
          Your Logo
        </h6>
        <p
          className={"text-base sm:text-sm md:text-sm text-center"}
          style={{ color: "#32282F" }}
        >
          (Optional)
        </p>
      </Box>
      <Dropzone
        accept="image/png, image/jpeg"
        noDrag={true}
        onDrop={async (files) => {
          const errors = {};
          if (files[0]) {
            const file = await handleUploadImageToS3(files[0]);
            if (file.result.ok) {
              setLogo(file.location);
            }
          } else {
            setLogo(values?.logo || "");
            errors.photo = "Only PNG and JPG formats are allowed";
          }
          setFormErrors({ ...errors });
        }}
        // getFilesFromEvent={async (files) => {
        //   const file = await files[0].getFile();
        //   const uploadedImageResponse = await handleUploadImageToS3(file);
        //   if (uploadedImageResponse.result.ok) {
        //     const publicUrl = uploadedImageResponse.location;
        //     setLogo(publicUrl);
        //   }
        // }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps({ className: "dropzone" })}>
              <div
                className={`flex flex-row justify-center items-center w-36 sm:w-28 md:w-28 h-36 sm:h-28 md:h-28 rounded-full ${
                  values.logo ? "border-0" : "border"
                } border-neutral-500`}
              >
                {values.logo ? (
                  <img
                    className={
                      "w-36 sm:w-28 md:w-28 h-36 sm:h-28 md:h-28 rounded-full"
                    }
                    src={values.logo}
                    alt={"your-logo"}
                  />
                ) : (
                  <p className={"text-base sm:text-sm text-center text-black"}>
                    Add
                    <br />
                    Logo
                  </p>
                )}
              </div>
              <input {...getInputProps()} />
              <Box
                className={
                  "w-14 sm:w-12 md:w-12 -translate-y-12 translate-x-24 sm:translate-x-20 md:translate-x-20"
                }
              >
                <Tooltip title='Upload Image'>
                  <IconButton component='span'>
                    <img src={add_photo} alt={"add-pic-btn"} />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          );
        }}
      </Dropzone>
      {formErrors["photo"] && (
        <FormHelperText error={formErrors?.photo != ""}>{formErrors["photo"]}</FormHelperText>
      )}
      <h6
        className={
          "pb-4 text-xl sm:text-base md:text-base font-bold text-center"
        }
        style={{
          color: "#32282F",
        }}
      >
        Add a Cover Photo
      </h6>
      <Dropzone
        noDrag={true}
        onDrop={async (files) => {
          const file = await handleUploadImageToS3(files[0]);
          if (file.result.ok) {
            setCoverPhoto(file.location);
          }
        }}
        // getFilesFromEvent={async (files) => {
        //   const file = await files[0].getFile();
        //   const uploadedImageResponse = await handleUploadImageToS3(file);
        //   if (uploadedImageResponse.result.ok) {
        //     const publicUrl = uploadedImageResponse.location;
        //     setCoverPhoto(publicUrl);
        //   }
        // }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps({ className: "dropzone" })}>
              <div
                className={`w-[31rem] sm:w-72 md:w-80 h-[12rem] sm:h-32 md:h-32 rounded-xl flex flex-col justify-center items-center ${
                  values.coverPhoto ? "border-0" : "border"
                } border-neutral-500`}
              >
                {!values.coverPhoto ? (
                  <Box>
                    <p
                      className={
                        "text-lg sm:text-base md:text-base text-center font-semibold"
                      }
                      style={{
                        color: "#32282F",
                      }}
                    >
                      Cover Photo
                    </p>
                    <p
                      className={"text-sm sm:text-xs md:text-xs text-center"}
                      style={{
                        color: "#32282F",
                      }}
                    >
                      This is what your customers will see on your home
                      <br /> page on Bookzy
                    </p>
                  </Box>
                ) : (
                  <img
                    className={
                      "w-[31rem] sm:w-72 md:w-80 h-[12rem] sm:h-32 md:h-32 rounded-xl"
                    }
                    src={values.coverPhoto}
                    alt={"your-cover-pic"}
                  />
                )}
              </div>
              <input {...getInputProps()} />
              <Box
                className={
                  "w-14 sm:w-12 md:w-12 -translate-y-14 sm:-translate-y-12 md:-translate-y-12 translate-x-[27.5rem] sm:translate-x-[15rem] md:translate-x-[17rem]"
                }
              >
                <Tooltip title='Upload Image'>
                  <IconButton component='span'>
                    <img src={add_photo} alt={"add-pic-btn"} />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
}
