import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import home_png from "../assets/Home.png";
// import menu_adornment from "../assets/white-back.png";
import schedule_png from "../assets/Schedule.png";
import plus_png from "../assets/Plus.png";
import class_png from "../assets/Class.png";
import settings_png from "../assets/Settings.png";
import menu_home from "../assets/menu_home.svg";
import menu_schedule from "../assets/menu_schedule.svg";
import menu_event from "../assets/menu_event.svg";
import menu_setting from "../assets/menu_setting.svg";
import menu_location from "../assets/menu_location.svg";
import whatsapp_png from "../assets/WhatsApp.png";
import axios from "axios";
import { useLocalStorage } from "../hooks";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData, userState } from "../redux/reducers/userReducer";
import { clearSchoolData } from "../redux/reducers/schoolReducer";
import {
  setCalendarOpen,
  setCalendarSessionId,
  clearDashboardData,
} from "../redux/reducers/dashboardReducer";
import { getUrlForUsers } from "../utils/confighelpers";


export default function NewSidebarMenuList() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReduxState = useSelector(userState);
  const [menuList, setMenuList] = React.useState([]);
  const [userToken, setUserToken] = useLocalStorage("userToken");

  const classList = [
    {
      id: 2,
      label: "My Schedule",
      icon: menu_schedule,
      navigate: { to: "/myclasses/myschedule", state: { title: "Schedule List" } },
    },
    {
      id: 3,
      label: "My Classes",
      icon: menu_event,
      navigate: { to: "/myclasses", state: { title: "Events List" } },
    },
    {
      id: 4,
      label: "My Locations",
      icon: menu_location,
      navigate: { to: "/mylocation", state: { title: "My Locations" } },
    },
  ];
  const eventList = [
    {
      id: 2,
      label: "My Schedule",
      icon: menu_schedule,
      navigate: { to: "/myschedule", state: { title: "Schedule List" } },
    },
    {
      id: 3,
      label: "My Events",
      icon: menu_event,
      navigate: { to: "/myevents", state: { title: "Events List" } },
    },
    {
      id: 4,
      label: "My Locations",
      icon: menu_location,
      navigate: { to: "/mylocation", state: { title: "My Locations" } },
    },
  ];
  const serviceList = [
    {
      id: 2,
      label: "My Schedule",
      icon: menu_schedule,
      navigate: { to: "/myservices/schedule", state: { title: "Services List" } },
    },
    {
      id: 3,
      label: "My Services",
      icon: menu_event,
      navigate: { to: "/myservices", state: { title: "Services List" } },
    },
    {
      id: 4,
      label: "My Venues",
      icon: menu_event,
      navigate: { to: "/myvenue", state: { title: "Venues List" } },
    },
    {
      id: 4,
      label: "My Resource",
      icon: menu_event,
      navigate: { to: "/myresource", state: { title: "Resource List" } },
    },
  ];
  const settingList = [
    {
      label: "My Profile",
      navigate: { to: "/myprofile" },
    },
    {
      label: "Business Profile",
      navigate: { to: "/businessprofile/view" },
    },
    {
      label: "Payment Profile",
      navigate: { to: "/payment/profile" },
    },
    {
      label: "Logout",
    },
  ];
  const list = [
    {
      id: 0,
      label: "Home",
      icon: menu_home,
      navigate: { to: "/home/dashboard", state: null },
    },

    /* {
      id: 3,
      label: "Events",
      icon: menu_event,
      subMenu: [
        {
          label: "Add New Event",
          navigate: { to: "/myevents/new", state: { title: "Events" } },
        },
        {
          label: "My Events",
          navigate: { to: "/myevents", state: { title: "Events List" } },
        }
      ],
    }, */
    // (userReduxState.businessType == "service") ? { ...serviceList } : { ...eventList },
    {
      id: 5,
      label: "Settings",
      icon: menu_setting,
      subMenu: settingList,
    },
    // {
    //   id: 6,
    //   label: "Get Support",
    //   icon: whatsapp_png,
    //   // active: true,
    //   navigate: { to: `https://web.whatsapp.com/send?phone=${"+31680068100".replace(/[^\w\s]/gi, "").replace(/ /g, "")}`, target:"newwindows" },
    // },
  ];

  React.useEffect(() => {
    if ((userToken === undefined || userToken == "")) {
      // navigation("/home/dashboard");
      window.location.href = "/";
    }
    let addmenu = [];
    if (userReduxState.businessType === "service") {
      addmenu = serviceList;
      list.map((e) => {
        if (e.label === "Settings" && userReduxState.businessType === "service") {
          settingList.splice(1, 0, ...[/* {
            label: "My Available Time",
            navigate: { to: "/my-available-time" },
          }, */{
              label: "My Connected Apps",
              navigate: { to: "/my-connected-apps" },
            },{
              label: "Online booking",
              navigate: { to: "/manage-online-booking" },
            }])
          console.log("settingList", settingList);
          e.subMenu = settingList
        }
        return e;
      })
    }
    if (userReduxState.businessType === "class") {
      addmenu = classList;
    }
    if (userReduxState.businessType === "event") {
      addmenu = eventList;
    }
    let i = 1;
    addmenu.length && addmenu.map((data) => {
      list.splice(i++, 0, data)
      return data;
    });
    setMenuList(list.map((e) => ({ ...e, isOpen: false })));
  }, []);

  const handleCollapse = (item) => {
    item.subMenu &&
      setMenuList(
        menuList.map((e) =>
          e.id === item.id ? { ...e, isOpen: !e.isOpen } : e
        )
      );
  };

  const Logout = () => {
    cogoToast.loading("Logging out...").then(async () => {
      try {
        await axios.put(
          `${getUrlForUsers()}logoutUser`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userToken,
            },
          }
        );
      } catch (err) {
        console.log("error", err);
      } finally {
        localStorage.removeItem("userToken");
        localStorage.removeItem("persist:root");
        dispatch(clearUserData());
        dispatch(clearSchoolData());
        dispatch(clearDashboardData());
        await setUserToken("");
        // navigate("/", { replace: true });
        window.location.href = "/";
      }
    });
  };

  const openCalendar = () => {
    dispatch(setCalendarOpen(true));
  };

  if (!userToken || userToken.length < 1) {
    // navigate("/", { replace: true });
    window.location.href = "/";
  }

  return (
    <List>
      {menuList.map((item, i) => (
        <Box
          component={"section"}
          key={i}
          sx={{
            my: 1,
            "& .MuiListItem-button": {
              borderRadius: 3,
              width: 200,
              mx: "auto",
              px: 1,
              backgroundColor: item?.active && item.active === true ? "primary.light" : "primary.main",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <ListItem
            button
            onClick={() => {
              if (item.subMenu) {
                handleCollapse(item);
              } /* else if (item.label === "Schedule") {
                return openCalendar();
              } */ else if (item.navigate) {
                document.body.style.overflowY = ''
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                if (location.pathname !== item.navigate.to) {
                  if (item.navigate.to === "/home/dashboard") {
                    dispatch(setCalendarSessionId(-1));
                  } else if (item.navigate?.target && item.navigate.target === "newwindows") {
                    window.open(item.navigate.to);
                    return;
                  }
                  navigate(item.navigate.to, { state: item.navigate.state, ...item.navigate });
                }
              }
            }}
          >
            <div className={"flex flex-row items-center"} style={{ width: "100%" }}>
              <ListItemIcon className={""}>
                <img
                  className={
                    "w-[27px] h-[27px] sm:w-5 sm:h-5 md:w-5 md:h-5 lg:w-5 lg:h-5 xl:w-5 xl:h-5"
                  }
                  src={item.icon}
                  alt={""}
                />
              </ListItemIcon>
              <ListItemText
                className={"w-40 sm:w-24 md:w-24 lg:w-24 xl:w-20"}
                primary={item.label}
                sx={{
                  "& 	.MuiListItemText-primary": {
                    fontSize: 18,
                    fontWeight: 500,
                    "&:hover": { fontWeight: 700 },
                    color: "common.white",
                  },
                }}
              />
              <div>
                {item.subMenu &&
                  (item.isOpen ? (
                    <ExpandLess
                      sx={{
                        width: 24,
                        height: 24,
                        color: "common.white",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        width: 24,
                        height: 24,
                        color: "common.white",
                      }}
                    />
                  ))}
              </div>
            </div>
            {item.subMenu && (
              <Collapse
                in={item.isOpen}
                timeout={"auto"}
                mountOnEnter
                sx={{
                  "& .MuiListItem-button": {
                    pl: 7,
                    my: 1,
                    backgroundColor: "primary.light",
                    "&:hover": { backgroundColor: "primary.main" },
                  },
                }}
              >
                <List component={"div"} disablePadding>
                  {Array.isArray(item.subMenu) &&
                    item.subMenu.map((sub, idx) => (
                      <ListItem
                        button
                        key={idx}
                        onClick={() => {
                          if (sub.label === "Logout") {
                            return Logout();
                          }
                          navigate(sub.navigate.to, {
                            state: sub.navigate.state,
                          });
                        }}
                      >
                        <ListItemText
                          sx={{
                            "& 	.MuiListItemText-primary": {
                              fontSize: 14,
                              fontWeight: 500,
                              "&:hover": { fontWeight: 700 },
                              color: "common.white",
                            },
                          }}
                          primary={sub.label}
                        />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            )}
          </ListItem>
        </Box>
      ))}
    </List>
  );
}
