import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Slide, Button, Box, useMediaQuery, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OtpInput from "react-otp-input";
import cogoToast from "cogo-toast";

import { useLocalStorage } from "../hooks";
import * as schoolActions from "../redux/reducers/schoolReducer";
import * as dashboardActions from "../redux/reducers/dashboardReducer";
import { getUrlForClasses, getUrlForUsers } from "../utils/confighelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function OtpModal({
  open,
  handleClose,
  title,
  subTitle,
  values,
  categories,
  reqToken,
  locationChanged,
  updateLocationConfig,
  setLoading,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("userToken");
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  const handleChange = (otp) => setOtp(otp);

  const handleSubmit = async () => {
    cogoToast.loading("Loading...");
    setLoading(true);
    const otpBody = {
      code: otp,
      token: reqToken,
    };
    const otpConfig = {
      method: "put",
      url: `${getUrlForUsers()}verifyPrimaryFieldsOtpAndUpdate`,
      headers: {
        "x-access-token": userToken,
      },
      data: otpBody,
    };
    const otpRequest = await axios(otpConfig);
    setLoading(false);
    if (otpRequest.data.statusCode !== 200) {
      setError(true);
      cogoToast.error(otpRequest.data?.message);
      return;
    } else {
      cogoToast.loading("Processing new school data...");
      setLoading(true);
      console.log("values", values);
      onSubmit();
      return false; /** new user myBusinessProfile update **/
      if (locationChanged) {
        const updateLocationRequest = await axios(updateLocationConfig);
        if (updateLocationRequest.status === 200) {
          if (updateLocationRequest.data.statusCode === 200) {
            const categoriesBody = {
              seletedCategories: categories,
            };
            const categoriesConfig = {
              method: "put",
              url: `${getUrlForUsers()}updateProfile`,
              headers: {
                "x-access-token": userToken.toString(),
                "Access-Control-Allow-Headers": "*",
              },
              data: categoriesBody,
            };
            const categoriesRequest = await axios(categoriesConfig);
            if (categoriesRequest.status === 200) {
              if (categoriesRequest.data.statusCode === 200) {
                dispatch(
                  schoolActions.setCategories(
                    categoriesRequest.data.data.seletedCategories
                  )
                );
                const schoolUpdateBody = {
                  schoolId: values._id,
                  schoolName: values.schoolName,
                  businessName: values.businessName || values.schoolName,
                  logo: values.logo,
                  images: values.images,
                  about: values.about,
                  typeOfBusiness: "",
                  workNumber: values.workNumber,
                  workEmail: values.workEmail,
                  workWebsite: values.workWebsite,
                  locationObj: { ...updateLocationRequest.data.data },
                  socialLink: {
                    ...values.socialLink,
                  },
                  businessTypes: values.businessTypes,
                  businessProfileType: values.businessProfileType,
                };
                const schoolUpdateConfig = {
                  method: "put",
                  url: `${getUrlForClasses()}updateSchool`,
                  headers: {
                    "x-access-token": userToken,
                  },
                  data: schoolUpdateBody,
                };
                const schoolUpdateRequest = await axios(schoolUpdateConfig);
                if (schoolUpdateRequest.status === 200) {
                  if (schoolUpdateRequest.data.statusCode === 200) {
                    setLoading(false);
                    dispatch(schoolActions.setName(values.schoolName));
                    dispatch(schoolActions.setLogo(values.logo));
                    dispatch(schoolActions.setCover(values.images[0]));
                    dispatch(
                      dashboardActions.setSchoolData(
                        schoolUpdateRequest.data.data
                      )
                    );
                    cogoToast.success("School Updated Successfully");
                    dispatch(dashboardActions.setCalendarSessionId(-1));
                    dispatch(dashboardActions.setCalendarOpen(false));
                    navigate("/businessprofile/view");
                    return;
                  } else {
                    setLoading(false);
                    cogoToast.error(schoolUpdateRequest.data.message);
                    console.log(schoolUpdateRequest);
                    return;
                  }
                }
              } else {
                setLoading(false);
                cogoToast.error(categoriesRequest.data.message);
                console.log(categoriesRequest);
                return;
              }
            }
          } else {
            setLoading(false);
            cogoToast.error("Cannot update location, please select another");
            console.log(updateLocationRequest);
            return;
          }
        }
      }
      const categoriesBody = {
        seletedCategories: categories,
      };
      const categoriesConfig = {
        method: "put",
        url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        },
        data: categoriesBody,
      };
      const categoriesRequest = await axios(categoriesConfig);
      if (categoriesRequest.data.statusCode === 200) {
        dispatch(
          schoolActions.setCategories(
            categoriesRequest.data.data.seletedCategories
          )
        );
        const schoolUpdateBody = {
          schoolId: values._id,
          schoolName: values.schoolName,
          logo: values.logo,
          images: values.images,
          about: values.about,
          typeOfBusiness: "",
          workNumber: values.workNumber,
          workEmail: values.workEmail,
          workWebsite: values.workWebsite,
          locationObj: values.locationObj,
          socialLink: {
            ...values.socialLink,
          },
          businessTypes: values.businessTypes,
          businessProfileType: values.businessProfileType,
        };
        const schoolUpdateConfig = {
          method: "put",
          url: `${getUrlForClasses()}updateSchool`,
          headers: {
            "x-access-token": userToken,
          },
          data: schoolUpdateBody,
        };
        const schoolUpdateRequest = await axios(schoolUpdateConfig);
        if (schoolUpdateRequest.data.statusCode === 200) {
          setLoading(false);
          dispatch(schoolActions.setName(values.schoolName));
          dispatch(schoolActions.setLogo(values.logo));
          dispatch(schoolActions.setCover(values.images[0]));
          dispatch(
            dashboardActions.setSchoolData(schoolUpdateRequest.data.data)
          );
          cogoToast.success("School Updated Successfully");
          dispatch(dashboardActions.setCalendarSessionId(-1));
          dispatch(dashboardActions.setCalendarOpen(false));
          navigate("/businessprofile/view");
        } else {
          setLoading(false);
          console.log(
            "ERROR SUBMIT FORM, SCHOOL UPDATE:",
            schoolUpdateRequest.data
          );
        }
      } else {
        setLoading(false);
        console.log(
          "ERROR SUBMIT FORM, CATEGORIES UPDATE",
          categoriesRequest.data
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      PaperProps={{
        sx: {
          minWidth: 550,
          maxWidth: 550,
          border: 1,
          borderColor: "#EBEBEB",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
    >
      <div className={"grid place-items-center"}>
        <h4 className={"font-bold text-brown-coffee text-lg"}>{title}</h4>
        <div className={"flex justify-center items-center"}>
          <p className={"text-base text-center"}>
            Please enter the 4 digit code sent to {subTitle}
          </p>
        </div>
        <OtpInput
          containerStyle={{
            marginTop: !error ? "15%" : "7.5%",
            marginBottom: "10%",
            marginRight: isMobile ? 0 : isTablet ? 0 : 25,
            marginLeft: isMobile ? 0 : isTablet ? 0 : 25,
          }}
          inputStyle={{
            width: isMobile ? 50 : isTablet ? 50 : 75,
            height: isMobile ? 50 : isTablet ? 50 : 75,
            borderRadius: 6.48248,
            borderWidth: 1.2965,
            borderColor: "#BFD1E5",
            borderStyle: "solid",
            fontSize: isMobile ? "1.5em" : isTablet ? "1em" : "2em",
          }}
          errorStyle={{
            borderColor: "red",
          }}
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<Box sx={{ mx: 1.5 }} />}
          hasErrored={error}
          shouldAutoFocus
          isInputNum
        />
        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 200,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          sx={{
            borderRadius: 3,
            width: 200,
            height: 52,
            fontSize: 16,
            textTransform: "none",
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        </Stack>
      </div>
    </Dialog>
  );
}
