import {
  Box,
  Button, Checkbox, Drawer, FormControl,
  FormHelperText, FormLabel, IconButton, InputBase, MenuItem, Select, Stack, Table, TableBody, TableContainer, TableHead
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useLocalStorage } from "../../../../hooks";
import * as dashboardActions from "../../../../redux/reducers/dashboardReducer";
import { userState } from "../../../../redux/reducers/userReducer";

import delete_svg from "../../../../assets/delete_svg.svg";
import edit_svg from "../../../../assets/edit_svg.svg";
import { defaultAvailablePaymentMethods } from "../../../../utils/constants";
import axios from "axios";
import { getUrlForBookings } from "../../../../utils/confighelpers";
import { cutString } from "../../../../helpers";

export default function PricingTab({
  profileFor,
  formValues,
  setFormValues,
  setTabCurrentState,
  edit = false,
  setLoading = null,
  handleUpdate,
  handleCancel,
  isFromDetail = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function createData(
    duration,
    bufferTime,
    priceType,
    description,
    price,
    absorbServiceFee,
  ) {
    return { duration, bufferTime, priceType, description, price, absorbServiceFee };
  }

  const userReduxState = useSelector(userState);
  const [isEdit, setIsEdit] = React.useState(-1);
  const [isFirstLoad, setIsFirstLoad] = React.useState(false);
  const [formPriceErrors, setFormPriceErrors] = React.useState({});
  const [formPriceValues, setFormPriceValues] = React.useState({ duration: 0, bufferTime: 0, priceType: "Fixed", price: 0, absorbServiceFee: false });
  const [slots, setSlots] = React.useState([]);
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [addTicket, setAddTicket] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextMenuOpen, setContextMenuOpen] = React.useState(Boolean(anchorEl));
  const [userToken] = useLocalStorage("userToken");
  const [slotDurationOpts, setSlotDurationOpts] = React.useState([
    "15",
    "30",
    "45",
    "60",
    "75",
    "90",
    "105",
    "120",
    "135",
    "150",
    "165",
    "180",
    "195",
    "210",
    "225",
    "240",
    "255",
    "270",
    "285",
    "300",
    "315",
    "330",
    "345",
    "360",
    "375",
    "390",
    "405",
    "420",
    "435",
    "450",
    "465",
    "480",
  ]);
  const slotsDisplay = {
    "15": "15 minutes",
    "30": "30 minutes",
    "45": "45 minutes",
    "60": "60 minutes",
    "75": "1 hours 15 minutes",
    "90": "1 hours 30 minutes",
    "105": "1 hours 45 minutes",
    "120": "2 hours",
    "135": "2 hours 15 minutes",
    "150": "2 hours 30 minutes",
    "165": "2 hours 45 minutes",
    "180": "3 hours",
    "195": "3 hours 15 minutes",
    "210": "3 hours 30 minutes",
    "225": "3 hours 45 minutes",
    "240": "4 hours",
    "255": "4 hours 15 minutes",
    "270": "4 hours 30 minutes",
    "285": "4 hours 45 minutes",
    "300": "5 hours",
    "315": "5 hours 15 minutes",
    "330": "5 hours 30 minutes",
    "345": "5 hours 45 minutes",
    "360": "6 hours",
    "375": "6 hours 15 minutes",
    "390": "6 hours 30 minutes",
    "405": "6 hours 45 minutes",
    "420": "7 hours",
    "435": "7 hours 15 minutes",
    "450": "7 hours 30 minutes",
    "465": "7 hours 45 minutes",
    "480": "8 hours",
  }
  const [slotBufferTimeOpts, setSlotBufferTimeOpts] = React.useState([
    "0",
    "15",
    "30",
    "45",
    "60",
    "75",
    "90",
    "105",
    "120",
    "135",
    "150",
    "165",
    "180",
  ]);
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState(defaultAvailablePaymentMethods);
  const slotBufferTimeDisplay = {
    "0": "None",
    "15": "15 minutes",
    "30": "30 minutes",
    "45": "45 minutes",
    "60": "60 minutes",
    "75": "1 hours 15 minutes",
    "90": "1 hours 30 minutes",
    "105": "1 hours 45 minutes",
    "120": "2 hours",
    "135": "2 hours 15 minutes",
    "150": "2 hours 30 minutes",
    "165": "2 hours 45 minutes",
    "180": "3 hours",
  }

  const handleDialogOpen = () => {
    setDelDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDelete = async (price) => {
    let pricesCopy = [...slots];
    if (Array.isArray(pricesCopy)) {
      pricesCopy = pricesCopy.filter((item) => item !== price);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      slots: [...pricesCopy],
    }));
    setSlots(prevValues => pricesCopy);
    setIsEdit(-1);
    handleDialogClose();
  };

  const handleSubmit = async () => {
    const errors = {};
    if (formValues.slots.length <= 0) {
      errors.slots = "Please add at least one price!";
    }
    setFormPriceErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    setTabCurrentState("3");
  };

  const clearForm = () => {
    setFormPriceValues({ duration: 0, bufferTime: 0, priceType: "Fixed", description: "", price: 0, absorbServiceFee: false });
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormPriceValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuOpen(null);
  };


  const getAvailablePaymentMethods = async () => {
    const requestConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/getAvailablePaymentMethods?teacherID=${userReduxState.userData?._id}&paymentMethodsFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        if (response.data.data) {
          setAvailablePaymentMethods(response.data.data)
        }
      }
    }
  };

  const calculatbuyerCost = (amount, onlyServicefee = true) => {
    let defaultPercentage = 0;
    if (availablePaymentMethods?.bookzyPlatformFee) {
      let flatFee = 0;
      let percentageFee = 0;
      if (availablePaymentMethods?.bookzyPlatformFee?.flatFee > 0) {
        flatFee = availablePaymentMethods?.bookzyPlatformFee?.flatFee;
      }
      if (availablePaymentMethods?.bookzyPlatformFee?.percentageFee > 0) {
        percentageFee = availablePaymentMethods?.bookzyPlatformFee?.percentageFee;
      }
      return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0) + parseFloat(flatFee) + (parseFloat(amount) * parseFloat(percentageFee / 100))).toFixed(2) : 0;
    }
    return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0) + (parseFloat(amount) * parseFloat(defaultPercentage / 100))).toFixed(2) : 0;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      paddingLeft: "16px",
      paddingRight: "16px",
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.head}:first-child`]: {
      paddingLeft: isFromDetail === false ? "0px" : "60px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
      height: "77px",
      maxHeight: "77px",
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "rgba(244, 244, 244, 0.4)",
      padding: "16px",
      // minHeight: "72px",
      height: "77px",
      maxHeight: "77px",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 13,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    /* [`&:first-child`]: {
      borderRadius: "10px 0 0 10px",
    },
    [`&:last-child`]: {
      borderRadius: "0 10px 10px 0"
    }, */
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
      paddingLeft: isFromDetail === false ? "16px" : "60px",
    },
    [`&:last-child div`]: {
      borderRadius: "0 12px 12px 0",
    },
    padding: "10px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 1,
    border: 1,
    borderRadius: 3,
    borderColor: "rgba(0, 0, 0, 0.04)",
    // borderColor: formPriceErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    textAlign: "left",
    // textAlign: "center",
    // minWidth: 200,
    // maxWidth: 200,
    minWidth: 494,
    maxWidth: 494,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
    '.MuiInputBase-input': {
      // textAlign: "center",
      textAlign: "left",
    },
    '.MuiSelect-select.MuiOutlinedInput-input': {
      paddingTop: "12px",
      paddingBottom: "12px"
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };


  React.useEffect(() => {
    if (edit && isFirstLoad) handleUpdate()
    setIsFirstLoad(true)
  }, [formValues.slots]);


  React.useEffect(() => {
    let pricesCopy = [...formValues.slots]
    if (!formValues.slots.length) {
      pricesCopy.push(createData("30", "0", "Fixed", "Fixed", "0", false))
      setFormValues((prevValues) => ({
        ...prevValues,
        slots: pricesCopy,
      }));
    }
    setIsEdit(-1);
    setSlots(pricesCopy);
    getAvailablePaymentMethods();
  }, []);

  return (
    <Stack direction={"column"} className={`py-6 px-8 w-4/5`} spacing={2}>
      <div className={"grid grid-cols-1 w-full"}>
        <Box className="flex justify-end my-5 w-full">
          <Button
            variant={"contained"}
            className="place-items-end"
            sx={{
              width: 152,
              height: 48,
              borderRadius: 3,
              textTransform: "none",
              backgroundColor: "secondary.mainLight",
            }}
            onClick={() => {
              clearForm(); setAddTicket(true);
            }}
          >
            Add Slot
          </Button>
        </Box>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="25%" align={"center"}>Slot Duration</StyledTableCell>
              {/* <StyledTableCell width="25%" align={"left"}>Pricing Type</StyledTableCell> */}
              <StyledTableCell width="20%" align={"left"}>Description</StyledTableCell>
              <StyledTableCell width="20%" align={"left"}>Transition period</StyledTableCell>
              <StyledTableCell width="15%" align={"left"}>Price €</StyledTableCell>
              <StyledTableCell width="15%" align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slots.map((row, i) => (
              <StyledTableRow key={i} >
                <StyledTableCell align={"center"}>
                  <Box>
                    {slotsDisplay[row.duration]}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align={"left"}>
                  <Box>
                    <p>{cutString(row?.description, 40, true)}</p>
                  </Box>
                </StyledTableCell>
                {/* <StyledTableCell align={"left"}>
                  <Box>
                    {row.priceType}
                  </Box>
                </StyledTableCell> */}
                <StyledTableCell align={"left"}>
                  <Box>
                    {slotBufferTimeDisplay[row.bufferTime]}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align={"left"}>
                  <Box>
                    €{row.price}
                    <p>Cost to buyer: €{(row.absorbServiceFee === true) ? parseFloat(row.price) : calculatbuyerCost(row.price)}</p>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center"><Box>

                  <IconButton sx={{ my: 1, p: 0 }} onClick={() => {
                    handleClose();
                    setIsEdit(i);
                    setFormPriceValues({
                      ...row,
                      bufferTime: row?.bufferTime || "0",
                    });
                    setAddTicket(true);
                  }}>
                    <img
                      src={edit_svg}
                      alt={`Edit`}
                    />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} onClick={() => {
                    setIsEdit(i);
                    handleDialogOpen()
                  }}>
                    <img
                      src={delete_svg}
                      alt={`Delete`}
                    />
                  </IconButton>

                </Box></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={delDialogOpen}
        title={"Remove " + profileFor}
        type={"service"}
        handleClose={handleDialogClose}
        handleDelete={() => handleDelete(slots[isEdit])}
      />
      <FormControl error={formPriceErrors["slots"] ? true : false}>
        {formPriceErrors["slots"] && (
          <FormHelperText>{formPriceErrors["slots"]}</FormHelperText>
        )}</FormControl>
      {isFromDetail === false ? (<Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (edit) {
              handleCancel()
            } else {
              setTabCurrentState("1");
            }
          }}
        >
          {edit ? "Cancel" : "Back"}
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => {
            if (edit) {
              handleUpdate()
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Continue"}
        </Button>
      </Stack>) : ""}

      <Drawer
        ModalProps={{
          onBackdropClick: () => { },
          keepMounted: true,
        }}
        open={addTicket}
        anchor="right"
      >
        <div
          className={"grid grid-rows-7 p-5"}
        ><Stack direction={"column"} spacing={2}>
            <Stack spacing={2}>
              <h4 style={{
                fontWeight: '600',
                fontSize: '34.4898px',
                borderBottom: "1.97085px solid #F4F4F4"
              }}>{(isEdit >= 0) ? "Update" : "Add"} Slots</h4>
              <FormControl error={formPriceErrors["duration"] ? true : false}>
                <FormLabel sx={formLableStyle}>Slot Duration</FormLabel>
                <Select
                  name={"duration"}
                  disableunderline="true"
                  MenuProps={formSelectClasses}
                  renderValue={(value) => (
                    <div className={"flex flex-row items-center gap-3"}>
                      {value !== "" ? slotsDisplay[value] : (
                        <span className={"font-light text-plain-gray"}>
                          Select Service available for...
                        </span>
                      )}
                    </div>
                  )}
                  value={formPriceValues.duration.toString()}
                  // defaultValue={formPriceValues.duration.toString()}
                  onChange={handleFormValueChange}
                  sx={{
                    ...formInputStyle,
                    py: 0,
                  }}
                >
                  {slotDurationOpts.map((option, idx) => (
                    <MenuItem value={option} key={idx}>
                      {slotsDisplay[option]}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Enter a ticket type differentiating pricing and access rights</FormHelperText> */}
                {formPriceErrors["duration"] && (
                  <FormHelperText>{formPriceErrors["duration"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["bufferTime"] ? true : false}>
                <FormLabel sx={formLableStyle}>Transition period</FormLabel>
                <Select
                  name={"bufferTime"}
                  disableunderline="true"
                  MenuProps={formSelectClasses}
                  renderValue={(value) => (
                    <div className={"flex flex-row items-center gap-3"}>
                      {value !== "" ? slotBufferTimeDisplay[value] : (
                        <span className={"font-light text-plain-gray"}>
                          Select Service Buffer Time
                        </span>
                      )}
                    </div>
                  )}
                  value={formPriceValues?.bufferTime}
                  // defaultValue={formPriceValues.bufferTime.toString()}
                  onChange={handleFormValueChange}
                  sx={{
                    ...formInputStyle,
                    py: 0,
                  }}
                >
                  {slotBufferTimeOpts.map((option, idx) => (
                    <MenuItem value={parseInt(option)} key={idx}>
                      {slotBufferTimeDisplay[option]}
                      {/* {option+ " minutes"} */}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Enter a ticket type differentiating pricing and access rights</FormHelperText> */}
                {formPriceErrors["bufferTime"] && (
                  <FormHelperText>{formPriceErrors["bufferTime"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["description"] ? true : false}>
                <FormLabel sx={formLableStyle}>Description</FormLabel>
                <InputBase
                  name={"description"}
                  type="text"
                  value={formPriceValues.description}
                  inputProps={{}}
                  multiline={true}
                  rows={3}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formPriceErrors["description"] && (
                  <FormHelperText>{formPriceErrors["description"]}</FormHelperText>
                )}
              </FormControl>
              {/* <FormControl error={formPriceErrors["priceType"] ? true : false}>
                <FormLabel sx={formLableStyle}>Price Type</FormLabel>
                <InputBase
                  name={"priceType"}
                  type="text"
                  value={formPriceValues.priceType}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formPriceErrors["priceType"] && (
                  <FormHelperText>{formPriceErrors["priceType"]}</FormHelperText>
                )}
              </FormControl> */}
              <FormControl error={formPriceErrors["price"] ? true : false}>
                <FormLabel sx={formLableStyle}>Pricing</FormLabel>
                <InputBase
                  name={"price"}
                  type="number"
                  value={formPriceValues.price}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Cost to buyer: €{(formPriceValues.absorbServiceFee === true) ? parseFloat(formPriceValues.price) + ", excluding" : calculatbuyerCost(formPriceValues.price) + ", including"} €{calculatbuyerCost(formPriceValues.price, false)} service fee</FormHelperText>
                {formPriceErrors["price"] && (
                  <FormHelperText>{formPriceErrors["price"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["absorbServiceFee"] ? true : false}>
                <FormLabel sx={formLableStyle}>
                  <Checkbox
                    name={"absorbServiceFee"}
                    // placeholder={"Title"}
                    checked={formPriceValues.absorbServiceFee | false}
                    inputProps={{ maxLength: 30 }}
                    onChange={(e) => {
                      const { name, value, checked } = e.target;
                      console.log(checked);

                      setFormPriceValues((prevValues) => ({
                        ...prevValues,
                        absorbServiceFee: checked,
                      }));
                    }}
                    label="Absorb Service fee"
                  />
                  Absorb Service fee </FormLabel>
              </FormControl>
              {formPriceErrors["text"] && (
                  <FormHelperText className="px-3 !text-red-600">{formPriceErrors["text"]}</FormHelperText>
                )}
            </Stack>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: 600,
                  border: 2,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  setIsEdit(-1);
                  clearForm();
                  setAddTicket(false);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                onClick={() => {
                  const errors = {};
                  if (!formPriceValues.duration) {
                    errors.duration = "Required!";
                  }
                  if (!formPriceValues.description) {
                    errors.description = "Required!";
                  }
                  if (!formPriceValues.priceType) {
                    errors.priceType = "Required!";
                  }

                  if (true) {
                    slots.map((row, i) => {
                      console.log(parseInt(formPriceValues.duration) + "===" + parseInt(row.duration));
                      // if (isEdit !== i && parseInt(formPriceValues.duration) === parseInt(row.duration)) {
                      if (isEdit !== i
                        && parseInt(formPriceValues.duration) === parseInt(row.duration)
                        && parseFloat(formPriceValues.price) === parseFloat(row.price)
                        // && formPriceValues.priceType === row.priceType
                        && formPriceValues.description.replace(/\s+/g, '-').toLowerCase() === row.description.replace(/\s+/g, '-').toLowerCase()
                      ) {
                        errors.text = "Same slot detail is not allow!";
                      }
                      return row;
                    });
                  }
                  if (formPriceValues.price < 0) {
                    errors.price = "Required!";
                  }
                  console.log(errors);
                  setFormPriceErrors({ ...errors });
                  if (Object.keys(errors).length > 0) {
                    return;
                  }
                  let pricesCopy = []
                  if (isEdit >= 0) {
                    pricesCopy = slots.map((row, i) => {
                      if (isEdit === i) {
                        return formPriceValues;
                      }
                      return row;
                    });
                  } else {
                    pricesCopy = [...slots, formPriceValues]
                  }
                  setIsEdit(-1);
                  setSlots(pricesCopy);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    slots: pricesCopy,
                  }));
                  clearForm();
                  setAddTicket(false);
                }}>
                {(isEdit >= 0) ? "Update" : "Add Slot"}
              </Button>
            </Stack>
          </Stack>
        </div>
      </Drawer>
    </Stack>
  );
}
