import { Combobox } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import {
  Button, Dialog, Divider, FormControl,
  FormHelperText, FormLabel, IconButton, InputBase, Slide
} from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { usePopper } from "react-popper";

import { useLocalStorage } from "../../hooks";

import marker_img from "../../assets/Location.png";
import { getUrlForUsers } from "../../utils/confighelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function AddEditLocationModal({
  open,
  handleClose,
  handleUpdate,
  loading,
  setLoading,
  isEdit,
  selectedLocationData,
}) {
  const [userToken] = useLocalStorage("userToken");
  const [userLocations, setUserLocations] = React.useState([]);
  const [sessionDetails, setSessionDetails] = React.useState(null);
  const [query, setQuery] = React.useState("");
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const [contactAddress, setContactAddress] = React.useState("");
  const [locationName, setLocationName] = React.useState("");
  const [postalAddress, setPostalAddress] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });
  /* React.useEffect(() => {

  }, []); */

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 2,
    // border: 1,
    borderRadius: 3,
    // borderColor: formErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  // const postAddress = async (placeDetails, placeGeocode, coordinates) => {
  const postAddress = async () => {
    const errors = {};
    if (!locationName) {
      errors.locationName = "Required!";
    }
    if (!Object.keys(selectedLocationData).length && !Object.keys(postalAddress).length) {
      errors.postalAddress = "Required!";
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      cogoToast.error("Please fix errors below", { hideAfter: 3 });
      return;
    }
    let body = {}
    if (isEdit === true) {
      body = {
        ...selectedLocationData,
        locationId: selectedLocationData._id
      }
    }

    if (Object.keys(postalAddress).length) {
      let placeDetails = postalAddress.placeDetails;
      let placeGeocode = postalAddress.placeGeocode;
      let coordinates = postalAddress.coordinates;
      body = {
        ...body,
        addressType: "current",
        address: placeDetails.description,
        houseNumber: placeDetails.structured_formatting.main_text,
        city: placeGeocode.address_components[
          placeGeocode.address_components.length - 3
        ].long_name,
        zip: placeGeocode.address_components[
          placeGeocode.address_components.length - 1
        ].types.find((type) => type.startsWith("postal"))
          ? placeGeocode.address_components[
            placeGeocode.address_components.length - 1
          ].long_name
          : "",
        addressName: locationName,
        location: {
          type: "Point",
          coordinates,
        },
      };
    }

    const config = {
      method: isEdit === true ? 'put' : "post",
      url: isEdit === true ? `${getUrlForUsers()}updateLocation` : `${getUrlForUsers()}addLocation`,
      headers: {
        "x-access-token": userToken.toString(),
        "Access-Control-Allow-Headers": "*",
      },
      data: body,
    };

    // console.log(config);
    setLoading(true);
    const addLocationRequest = await axios(config);

    setLoading(false);
    if (addLocationRequest.data.statusCode === 200) {
      handleClose();
      setLocationName("");
      setPostalAddress({});
      handleUpdate();
    } else if (addLocationRequest.data.statusCode !== 200) {
      if (addLocationRequest.data.statusCode === 500) {
        cogoToast.error(addLocationRequest.data.message);
        console.log(addLocationRequest);
        setLoading(false);
        return;
      }
      cogoToast.error("Error adding location.");
      console.log(addLocationRequest);
      return;
    }

  };

  React.useEffect(() => {
    if (isEdit && isEdit === true) {
      setPostalAddress({
        placeDetails: {
          description: selectedLocationData.address,
          structured_formatting: { main_text: selectedLocationData.houseNumber },
        },
        placeGeocode: {
          address_components: [
            {
              long_name: selectedLocationData.address,
              short_name: selectedLocationData.address,
              types: ["locality", "political"],
            },
            {
              long_name: selectedLocationData.address,
              short_name: selectedLocationData.address,
              types: ["locality", "political"],
            },
            {
              long_name: selectedLocationData.address,
              short_name: selectedLocationData.address,
              types: ["locality", "political"],
            },
            {
              ...(selectedLocationData?.zip && selectedLocationData.zip !== "") ? {
                long_name: selectedLocationData.zip,
                short_name: selectedLocationData.zip,
                types: ["postal_code"],
              } : {
                long_name: selectedLocationData.address,
                short_name: selectedLocationData.address,
                types: ["locality", "political"],
              }
            },
          ]
        },
        coordinates: selectedLocationData.location.coordinates
      });
      setLocationName(selectedLocationData.addressName)
      // console.log("edit postalAddress", postalAddress);

    } else {
      setPostalAddress({})
      setLocationName("")
    }
  }, [isEdit, selectedLocationData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 700,
          minHeight: 500,
          // maxWidth: 500,
          border: 1,
          borderColor: "#EBEBEB",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className={"flex flex-col gap-6"}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"font-bold text-2xl text-brown-coffee"}>
              {isEdit ? `Update` : `Add`} Location
            </h3>
            <IconButton onClick={handleClose}>
              <Close color={"primary"} />
            </IconButton>
          </div>
          <Divider />
        </div>
        <FormControl error={formErrors["postalAddress"] ? true : false}>
          <div
            className={`w-full flex flex-row items-center border border-neutral-200 hover:border-[#7E00DD] rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3`}
            ref={setReferenceElement}
          >
            <img className={"mr-2"} src={marker_img} alt={""} />
            <Combobox
              as={"div"}
              className={"flex-1"}
              value={postalAddress?.placeDetails}
              onChange={async (e) => {
                // console.log("e", e);
                placesService.getDetails(
                  {
                    placeId: e.place_id,
                  },
                  (placeDetails) => {
                    // console.log("placeDetails", placeDetails);
                    const lat = placeDetails.geometry.location.lat();
                    const lng = placeDetails.geometry.location.lng();
                    const coordinates = [lat, lng];
                    // postAddress(e, placeDetails, coordinates);
                    setPostalAddress({ placeDetails: e, placeGeocode: placeDetails, coordinates: coordinates });
                  }
                );
                setContactAddress(e);
              }}
            >
              <Combobox.Input
                className={
                  "focus:outline-0 w-full sm:text-sm md:text-sm"
                }
                placeholder={"Business Address"}
                displayValue={(value) => value && value.description}
                onChange={(e) => {
                  getPlacePredictions({ input: e.target.value });
                  setQuery(e.target.value);
                }}
              />
              <Combobox.Options
                className={
                  "mt-0.5 min-w-[365px] w-full max-h-[200px] overflow-y-auto bg-white z-20 border border-zinc-400 rounded-xl p-3 shadow-lg"
                }
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {!isPlacePredictionsLoading &&
                  placePredictions.map((place, idx) => {
                    return (
                      <Combobox.Option
                        className={
                          "py-1 px-1 cursor-pointer hover:bg-zinc-200 rounded-lg sm:text-sm"
                        }
                        key={idx}
                        value={place}
                      >
                        {place.description}
                      </Combobox.Option>
                    );
                  })}
              </Combobox.Options>
            </Combobox>
          </div>
          {formErrors["postalAddress"] && (
            <div className="text-[#d32f2f]">{formErrors["postalAddress"]}</div>
          )}
        </FormControl>
        <FormControl error={formErrors["locationName"] ? true : false}>
          <FormLabel sx={formLableStyle}>Location Name</FormLabel>
          <InputBase
            name={"locationName"}
            // placeholder={"Tag line (Optional)"}
            value={locationName}
            onChange={(e) => {
              setLocationName(e.target.value);
            }}
            inputProps={{ maxLength: 30 }}
            classes={formInputClasses}
            sx={formInputStyle}
          />
          {formErrors["locationName"] && (
            <FormHelperText>{formErrors["locationName"]}</FormHelperText>
          )}
        </FormControl>

        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            // width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          disabled={loading}
          onClick={() => {
            postAddress();
          }}
        >
          {isEdit ? `Update` : `Add`} Location
        </Button>
      </div>
    </Dialog>
  );
}
