import axios from "axios";
import { format, parseISO } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, MenuItem, Rating, Select, Stack, Table, TableBody, TableContainer, TableHead, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import profile_img from "../../../assets/avatar_placeholder.png";
import defaultImage from "../../../assets/defaultImage.svg";
import filter_img from "../../../assets/Filter.png";
import greenlocation from "../../../assets/greenlocation.svg";
import greentime from "../../../assets/greentime.svg";
import SessionBookingPopover from "./SessionBookingPopover";
import { userState } from "../../../redux/reducers/userReducer";
import { getUrlForBookings } from "../../../utils/confighelpers";
import { cutString } from "../../../helpers";

export default function SessionBooking({
  dataToDisplay,
  sessionDataToDisplay,
  sessionRoute,
  updateComp,
  setLoading,
  backState,
  setBackState,
  displayHeader,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [listLoading, setListLoading] = React.useState(false);
  const [bookingFilter, setBookingFilter] = React.useState('all');
  const [recentBookingsList, setRecentBookingsList] = React.useState([]);
  const [showViewMore, setShowViewMore] = React.useState(false);
  const [recentBookingsPageNumber, setRecentBookingsPageNumber] = React.useState(1);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  const [popoverOptionsDisabled, setPopoverOptionsDisabled] = React.useState(false);

  /* React.useEffect(() => {
    const { daysInWeek, allEventSchedules, scheduleClassBookings } = sessionDataToDisplay;

    if (scheduleClassBookings) {
      setRecentBookingsList(scheduleClassBookings);
    }
  }, [sessionDataToDisplay, updateComp]); */

  /* React.useEffect(() => {
    getRecentBookings();
  }, []); */
  React.useEffect(() => {
    getRecentBookings(recentBookingsPageNumber);
  }, [recentBookingsPageNumber, bookingFilter]);

  const getRecentBookings = async (page = 1) => {
    setShowViewMore(false);
    setListLoading(true);
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForBookings()}getMybookings?eventScheduleId=${sessionDataToDisplay._id}&page=${page}&filterBy=${bookingFilter}&listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageClassesRequest = await axios(manageClassesConfig);
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.data.data.length) {
        setShowViewMore(true);
        console.log("recentBookingsPageNumber", recentBookingsPageNumber);
        if (recentBookingsPageNumber > 1) {
          console.log([...recentBookingsList, manageClassesRequest.data.data.data]);
          setRecentBookingsList([...recentBookingsList, ...manageClassesRequest.data.data.data]);
        } else {
          setRecentBookingsList(manageClassesRequest.data.data.data);
        }
      }
    }
    setListLoading(false);
  };

  const ticketAmount = (row) => {
    let ticketAmount = 0;
    let serviceFees = 0;
    if (row?.totalStripeAmount) {
      return parseFloat(row?.totalStripeAmount || 0).toFixed(2)
    } else if (row?.amount) {
      return parseFloat(row?.amount || 0).toFixed(2)
    } else {
      if (row.ticketsBought && row.ticketsBought.length > 0) {
        row.ticketsBought.map((data) => {
          serviceFees = serviceFees + (parseFloat(data?.serviceFee ? data?.serviceFee : 0) * parseFloat(data?.bookingQty))
          ticketAmount = (parseFloat(ticketAmount) + parseFloat(data.totalPrice))
        })
        // ticketAmount = parseFloat(ticketAmount + parseFloat(serviceFees))
        ticketAmount = parseFloat(ticketAmount).toFixed(2)
      } else {
        ticketAmount = (row.amount ? row.amount : 0);
      }
      return parseFloat(ticketAmount).toFixed(2);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      fontWeight: "bold",
      border: 0,
      padding: "0px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body} > div`]: {
      // backgroundColor: "rgba(244, 244, 244, 0.4)",
      backgroundColor: "#fff",
      padding: "16px",
      height: "72px",
      maxHeight: "72px",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 11,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
    },
    [`&:last-child div`]: {
      borderRadius: "0 12px 12px 0",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "0px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));

  const handleFilterChange = (event) => {
    const { target: { value } } = event;
    setRecentBookingsPageNumber(1)
    setBookingFilter(value);
    setRecentBookingsList([])
  };
  // console.log("SessionBooking", sessionDataToDisplay);

  return (
    <div className={""}>
      {displayHeader ? (<Box className="grid grid-cols-2 w-full py-2 px-3">
        <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
          <Stack direction={"column"} spacing={3}>
            <div className={"w-full h-[283px]"}>
              {sessionDataToDisplay.eventId.eventPhoto ? (
                <img
                  className={
                    "w-full h-[283px] object-cover object-fill rounded-2xl"
                  }
                  src={sessionDataToDisplay.eventId.eventPhoto}
                  alt={""}
                />
              ) : (<img
                className={
                  "w-full h-[283px] object-cover rounded-2xl"
                }
                src={defaultImage}
                alt={""}
              />)}
            </div>
            <Stack direction={"row"} alignItems={"center"}>
              <div className={"grow"}>
                <h2 className={"font-bold text-[30px] text-brown-coffee"}>
                  {sessionDataToDisplay.eventName}
                </h2>
                <p className={"text-[15.35px]"}>
                  {sessionDataToDisplay.eventId.tagLines}
                </p>
                {(sessionDataToDisplay.scheduleName !== "") ? (<h4 className={"font-bold text-[20px] text-[#FF5733]"}>{sessionDataToDisplay.scheduleName}</h4>) : ""}
                <div
                  className={
                    "min-w-[165px] pt-2 flex items-left bg-white rounded-lg"
                  }
                >
                  <Stack direction={"row"} spacing={1}>
                    <Box className="pt-[2px]">
                      <Rating
                        sx={{
                          color: "#FFA800",
                          fontSize: 17,
                        }}
                        value={
                          Array.isArray(sessionDataToDisplay.eventId.rating) ? (sessionDataToDisplay.eventId.rating.length ? sessionDataToDisplay.eventId.rating[0] : 0) : sessionDataToDisplay.eventId.rating
                        }
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                    <p className={"text-sm text-[#FFA800] bg-[#F4F4F4] p-[2px]"}>
                      {`${Array.isArray(sessionDataToDisplay.eventId.rating) ? (sessionDataToDisplay.eventId.rating.length ? sessionDataToDisplay.eventId.rating[0] : 0) : sessionDataToDisplay.eventId.rating}`}
                    </p>
                  </Stack>
                </div>
              </div>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
          <Box className={`grid grid-cols-1 w-full`}>
            <Box className="flex flex-col justify-center items-left ">
              <Box className="flex justify-center items-center w-4/5 py-3">
                <Box className="flex-none">
                  <img
                    className={""}
                    src={greenlocation}
                    alt={""}
                  />
                </Box>
                <Box className="flex-1 pl-2">
                  <div className="text-[#00000099] text-sm">Where </div>
                  <div className="text-[#000000] text-base">
                    <p className="font-bold">{sessionDataToDisplay && sessionDataToDisplay.location ? sessionDataToDisplay.location.addressName : ""}</p>
                    <p>{sessionDataToDisplay && sessionDataToDisplay.location ? sessionDataToDisplay.location.address : ""}</p>
                  </div>
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-4/5 py-3">
                <Box className="flex-none">
                  <img
                    className={""}
                    src={greentime}
                    alt={""}
                  />
                </Box>
                <Box className="flex-1 pl-2">
                  <div className="text-[#00000099] text-sm">When </div>
                  <div className="text-[#000000] text-base">
                    {sessionDataToDisplay.eventDate ? format(parseISO(sessionDataToDisplay.eventDate), "EEEE, MMMM d 'at' hh:mm aa") + " - " + format(parseISO(sessionDataToDisplay.eventEndDate), "hh:mm aa") : ""}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>) : ""}
      <div
        className={`max-w-[1221px] min-h-[500px] px-5 py-2 pb-10 bg-white rounded-2xl ${displayHeader ? "mt-16" : "mt-0"}`}
      >
        <div className="flex justify-end mb-10">
          <Select
            disableunderline={true}
            MenuProps={{
              sx: {
                "& .MuiMenu-paper": {
                  border: 0,
                },
              },
            }}
            sx={{
              mr: 2,
              '.MuiOutlinedInput-input': {
                borderWidth: 0,
                p: "0 !important",
              },
              '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
              },
            }}
            IconComponent={null}
            renderValue={(value) => (
              <div className={"flex flex-row items-center gap-3"}>
                {value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                  return letter.toUpperCase();
                }) || ""}
                <img class="w-4" src={filter_img} alt={""} />
              </div>
            )}
            value={bookingFilter}
            onChange={handleFilterChange}
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={'confirmed'}>Confirmed</MenuItem>
            <MenuItem value={'cancelled'}>Cancelled</MenuItem>
            <MenuItem value={'refunded'}>Refunded</MenuItem>
          </Select>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">CUSTOMER</StyledTableCell>
                <StyledTableCell align="left"><Box>BOOKING ID</Box></StyledTableCell>
                <StyledTableCell align="left">ACTIVITY BOOKED</StyledTableCell>
                <StyledTableCell align="left">SEATS BOOKED</StyledTableCell>
                <StyledTableCell align="left">AMOUNT PAID</StyledTableCell>
                <StyledTableCell width="10%" align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listLoading ? (
                <StyledTableRow key={0} >
                  <StyledTableCell align="center" colSpan={6}>
                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                      <p>Loading...</p>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (recentBookingsList.length ? recentBookingsList.map((row, i) => (
                <StyledTableRow key={i} >
                  <StyledTableCell align="left">
                    <Box>
                      <Box className="flex justify-center items-center w-4/5">
                        <Box className="flex-none">
                          {row?.student_detail?.profilePic ? (<img
                            className="w-[33px] h-[33px] rounded-full mr-3"
                            src={row.student_detail.profilePic}
                            alt={``}
                          />) : (<img
                            className="w-[33px] h-[33px] rounded-full mr-3"
                            src={profile_img}
                            alt={``}
                          />)}
                        </Box>
                        <Box className=" p-0 w-28">
                          <div>{cutString(row.studentName, 10, true)} </div>
                          <div className="text-[#7E00DD]">{cutString(row.student_detail?.countryCode ? row.student_detail.countryCode + " " + row.student_detail.phoneNumber : "" + row?.student_detail?.username, 10, true)}</div>
                        </Box>
                      </Box>

                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box>
                      {row.bookingId}
                      <p><span className={`${(row.status === "Cancelled") ? "text-[#FF5733]" : "text-[#468C11]"}`}>{row.status}</span> | {format(parseISO(row.bookingDate), "MMM d")}</p>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box>
                      {row?.eventName || row?.event_schedule_detail?.eventName || ""}
                      <p>{row.event_schedule_detail && row.event_schedule_detail.eventDate ? format(parseISO(row.event_schedule_detail.eventDate), "MMM d") : ""}</p>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box>
                      {row.bookingQty ? row.bookingQty : "1"}

                      <p title={row?.ticketsBought ? row.ticketsBought.map((ticket, i) => {
                        return ticket.bookingQty + " x " + ticket.ticketType + " "
                      }).join(" | ") : ""}>
                        {cutString(row?.ticketsBought ? row.ticketsBought.map((ticket, i) => {
                          return ticket.bookingQty + " x " + ticket.ticketType + " "
                        }).join(" | ") : "", 25, true)}

                      </p>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box>
                      €{ticketAmount(row)}
                      <p>{(row.paymentStatus.toLowerCase() === "paid") ? "Paid Online" : row.paymentStatus}</p>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box>
                      <SessionBookingPopover
                        bookingDetails={row}
                        handleUpdate={() => {
                          getRecentBookings(recentBookingsPageNumber)
                          return sessionRoute
                            ? dispatch(
                              dashboardActions.setUpdateSessionComp(
                                dashboardReduxState.updateSessionComp + 1
                              )
                            )
                            : ""
                        }}
                        setLoading={setLoading}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )) : (
                <StyledTableRow key={0} >
                  <StyledTableCell align="center" colSpan={6}>
                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                      <p>There is no {bookingFilter !== "all" ? bookingFilter + " " : ""}bookings</p>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showViewMore ? (<div className={"flex flex-row justify-center my-4"}>
          <button
            className={
              "text-white text-sm cursor-pointer bg-[#3699FF] rounded-lg p-2 px-5"
            }
            onClick={() => {
              setRecentBookingsPageNumber(recentBookingsPageNumber + 1);
            }}
          >
            View More
          </button>
        </div>) : ""}
      </div>
    </div>
  );
}
