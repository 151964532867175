import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Button, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, InputBase, Stack
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../hooks";
import { userState } from "../../../redux/reducers/userReducer";

import cogoToast from "cogo-toast";
import lock_img from "../../../assets/password.svg";
import { getUrlForUsers } from "../../../utils/confighelpers";

export default function ChangePassword({
    setLoading,
    onCancel
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userReduxState = useSelector(userState);
    const [formValues, setFormValues] = React.useState({
        // ...userReduxState.userData,
        password: "",
        repassword: "",
    });
    const [formErrors, setFormErrors] = React.useState({});
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [repasswordVisible, setRepasswordVisible] = React.useState(false);
    const [isThereChange, setIsThereChange] = React.useState(true);
    const [userToken] = useLocalStorage("userToken");

    React.useEffect(() => {
        setTimeout(() => {
            setIsThereChange(true)
        }, 1 * 1000);
    }, [])
    React.useEffect(() => {
        setIsThereChange(false)
    }, [formValues])

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        setSubmitting(true);

        const errors = {};
        if (!formValues.password) {
            errors.password = "Required!";
        } else if (formValues.password.length < 8) {
            errors.password = "Must Contain 8 Characters";
        }
        if (!formValues.repassword) {
            errors.repassword = "Required!";
        } else if (formValues.password !== formValues.repassword) {
            errors.repassword = "Passwords do NOT match";
        }
        setFormErrors({ ...errors });
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            setSubmitting(false);
            cogoToast.error("Please fix errors below", { hideAfter: 3 });
            return;
        }

        let body = {
            password: formValues.password,
        }
        console.log("body", body);
        cogoToast.loading("Loading...").then(async () => {
            const result = await axios.put(
                `${getUrlForUsers()}updateProfile`,
                {
                    ...body,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "x-access-token": userToken,
                    },
                }
            );
            setLoading(false);
            if (result.data.statusCode === 200) {
                /* dispatch(
                    userActions.setUserData(
                        Object.assign({}, userReduxState.userData, body)
                    )
                ); */
                setSubmitting(false);
                onCancel();
            } else {
                cogoToast.error(result.data.message);
                setSubmitting(false);
            }
        });

    };

    const formLableStyle = {
        my: 1,
        color: "#32282F99"
    };

    const formInputStyle = {
        p: 2,
        borderRadius: 3,
        border: 1,
        borderColor: "#EBEBEB",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&:hover, &.Mui-focused": {
            border: 1,
            borderColor: "#7E00DD"
        },
        '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'rgba(0, 0, 0, 0)',
        },
    };

    const formInputClasses = {
        input: {
            "&::placeholder": {
                color: "#808080",
                fontWeight: 300,
            },
        },
    };

    return (
        <div className={"flex flex-col p-3"}>
            <div className={"flex-1 my-12 w-full grow"}>
                <h4 className="text-2xl text-brown-coffee text-center my-5">Choose New password</h4>
                <Stack direction={"column"} spacing={1}>
                    <FormControl error={formErrors["password"] ? true : false}>
                        <FormLabel sx={formLableStyle}>Enter Password</FormLabel>
                        <InputBase
                            name={"password"}
                            // value={formValues.password}
                            onChange={handleInputChange}
                            type={passwordVisible ? "text" : "password"}
                            placeholder={"Enter Password"}
                            startAdornment={
                                <InputAdornment position={"start"}>
                                    <img className={"w-6"} src={lock_img} alt={""} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position={"end"}>
                                    {passwordVisible ? (
                                        <IconButton
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                        >
                                            <Visibility style={{ color: "black" }} />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                        >
                                            <VisibilityOff style={{ color: "black" }} />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            }
                            classes={formInputClasses}
                            sx={formInputStyle}
                            fullWidth
                        />
                        {formErrors["password"] && (
                            <FormHelperText>{formErrors["password"]}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl error={formErrors["repassword"] ? true : false}>
                        <FormLabel sx={formLableStyle}>Re-enter Password</FormLabel>
                        <InputBase
                            name={"repassword"}
                            // value={formValues.password}
                            onChange={handleInputChange}
                            type={repasswordVisible ? "text" : "password"}
                            placeholder={"Re-enter Password"}
                            startAdornment={
                                <InputAdornment position={"start"}>
                                    <img className={"w-6"} src={lock_img} alt={""} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position={"end"}>
                                    {repasswordVisible ? (
                                        <IconButton
                                            onClick={() => setRepasswordVisible(!repasswordVisible)}
                                        >
                                            <Visibility style={{ color: "black" }} />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={() => setRepasswordVisible(!repasswordVisible)}
                                        >
                                            <VisibilityOff style={{ color: "black" }} />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            }
                            classes={formInputClasses}
                            sx={formInputStyle}
                            fullWidth
                        />
                        {formErrors["repassword"] && (
                            <FormHelperText>{formErrors["repassword"]}</FormHelperText>
                        )}
                    </FormControl>
                </Stack>
            </div>
            <div className={"grid place-items-center"}>
                <div className={"flex flex-row items-center gap-2"}>
                    <Button
                        sx={{
                            color: "secondary.mainLight",
                            backgroundColor: "common.white",
                            borderColor: "secondary.mainLight",
                            border: 2,
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "secondary.mainLight",
                                color: "common.white",
                                boxShadow: "none",
                            },
                            fontWeight: 600,
                            borderRadius: 3,
                            width: 231,
                            height: 52,
                            fontSize: 16,
                        }}
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            color: "common.white",
                            backgroundColor: "primary.main",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                            },
                            fontWeight: 600,
                            borderRadius: 3,
                            width: 231,
                            height: 52,
                            fontSize: 16,
                        }}
                        variant={"contained"}
                        disabled={isSubmitting || isThereChange}
                        onClick={handleSubmit}
                    >
                        Update password
                    </Button>
                </div>
            </div>
        </div>
    );
}
