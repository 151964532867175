import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

import Container from "../../components/Container";
import Logo from "../../components/Logo";
import undraw_vector from "../../assets/celebration_re_kc9k 1.png";

export default function SignUpComplete({ navigation }) {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  // TODO: Uncomment in prod
  // if (location.state === null) {
  //   navigation("/signup");
  // }
  console.log("location.state", location.state);
  return (
    <Container className={"bg-white"}>
      <Logo />
      <Box className={"flex flex-col justify-center items-center"}>
        <img
          className={"-mt-24 sm:-mt-0 md:-mt-16 w-[35%] sm:w-[100%] md:w-[60%]"}
          src={undraw_vector}
          alt={"vector"}
        />
        <h4
          className={
            "text-4xl sm:text-2xl md:text-xl font-bold my-4 sm:my-2 md:my-2 text-center"
          }
          style={{
            color: "#481B3A",
          }}
        >
          Welcome {location.state !== null && location.state.name},<br />
          Sign Up Successful!
        </h4>
        <p
          className={
            "text-center text-2xl sm:text-lg md:text-lg my-4 sm:my-2 md:my-2"
          }
          style={{ color: "#481B3A" }}
        >
          Let's setup your Business Profile
        </p>
        <Button
          sx={{
            backgroundColor: "#481B3A",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#481B3A",
            },
            fontWeight: "bold",
            width: isMobile ? 270 : isTablet ? 345 : 470,
            height: isMobile ? 50 : isTablet ? 50 : 65,
            fontSize: isMobile ? 15 : 20,
            borderRadius: 3,
            my: 2,
            color: "white",
          }}
          onClick={() => {
            // navigation("/schoolprofilesetup", {
            // navigation("/businessprofilesetup", {
            //   state: { sentFromSignupComplete: true, socialType: location.state.socialType, username: location.state.username },
            // })
            let bodyForNumber = location.state?.socialType === "normal" ? {
              countryCode: location.state.countryCode,
              phoneNumber: location.state.number
            } : {}
            navigation("/businesstype", {
              state: {
                ...location.state,
                from: "list",
                sentFromSignupComplete: true, socialType: location.state.socialType, username: location.state.username, },
            })
          }
          }
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}
