import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { sizes } from "./breakpoints";

let sizesForMuiTheme = [];

for (const size in sizes) {
  const number = Number(sizes[size].split("px")[0]);
  sizesForMuiTheme.push(number);
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#5B274B",
      main: "#481B3A",
      inputBackground: "#F4F4F4",
    },
    secondary: {
      main: "#FF5733",
      mainLight: "#F64E60",
    },
    progress: {
      main: "#33BE97",
    },
    action: {
      // active: '#7E00DD',
    },
  },

  breakpoints: {
    values: {
      xs: sizesForMuiTheme[0],
      sm: sizesForMuiTheme[1],
      md: sizesForMuiTheme[2],
      lg: sizesForMuiTheme[3],
      xl: sizesForMuiTheme[4],
    },
  },
  typography: {
    fontFamily: "Mulish",
  },
});

theme = responsiveFontSizes(theme);

export { theme };
