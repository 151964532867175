import React from "react";
import axios from "axios";
import { Field, FormikProvider } from "formik";
import { InputBase } from "formik-mui";
import { Box, InputAdornment, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Combobox } from "@headlessui/react";
import { usePopper } from "react-popper";
import cogoToast from "cogo-toast";

import { useLocalStorage } from "../../hooks";

import institute_img from "../../assets/Institute.png";
import marker_img from "../../assets/Location.png";
import email_img from "../../assets/Email.png";
import web_img from "../../assets/Website.png";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function SchoolProfileForm(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [userToken] = useLocalStorage("userToken", "");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = React.useState("");
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const postAddress = (placeDetails, placeGeocode, coordinates) => {
    // let city = placeGeocode.address_components[placeGeocode.address_components.length - 3].long_name;
    let city = placeDetails.description;
    const body = {
      addressType: "current",
      address: placeDetails.description,
      houseNumber: placeDetails.structured_formatting.main_text,
      city: placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name,
      zip: placeGeocode.address_components[
        placeGeocode.address_components.length - 1
      ].types.find((type) => type.startsWith("postal"))
        ? placeGeocode.address_components[
            placeGeocode.address_components.length - 1
          ].long_name
        : "",
      addressName: city ? city : "Business Address",
      location: {
        type: "Point",
        coordinates,
      },
    };

    const config = {
      method: "post",
      url: `${getUrlForUsers()}addLocation`,
      headers: {
        "x-access-token": userToken.toString(),
        "Access-Control-Allow-Headers": "*",
      },
      data: body,
    };

    props.setPostAddressConfig(config);

    // const request = await axios(config);

    // if (request.status === 200) {
    //   if (request.data.statusCode === 403) {
    //     cogoToast.error(
    //       "Location already registered, please select another location"
    //     );
    //     props.setContactAddress("");
    //     return;
    //   } else if (request.data.statusCode === 500) {
    //     cogoToast.error(request.data.message);
    //     props.setContactAddress("");
    //     return;
    //   } else {
    //     props.form.setFieldValue("locationObj", request.data.data);
    //   }
    // }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormikProvider value={props.form}>
          <Field
            component={InputBase}
            form={props.form}
            name={"instituteName"}
            placeholder={"Name of your School/Classes"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img className={"w-5"} src={institute_img} alt={""} />
              </InputAdornment>
            }
            inputProps={{ maxLength: 30 }}
            sx={{
              m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
              p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
              borderRadius: 3,
              border: 1,
              borderColor: "#EBEBEB",
              fontSize: isMobile ? 14 : isTablet ? 13 : 17,
              minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
            }}
            size={isMobile || isTablet ? "small" : "medium"}
          />
          <div
            className={`flex flex-row items-center border border-neutral-200 rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3`}
            ref={setReferenceElement}
          >
            <img className={"mr-2"} src={marker_img} alt={""} />
            <Combobox
              as={"div"}
              className={"relative"}
              value={props.contactAddress}
              onChange={async (e) => {
                placesService.getDetails(
                  {
                    placeId: e.place_id,
                  },
                  (placeDetails) => {
                    const lat = placeDetails.geometry.location.lat();
                    const lng = placeDetails.geometry.location.lng();
                    const coordinates = [lat, lng];
                    postAddress(e, placeDetails, coordinates);
                  }
                );
                props.setContactAddress(e);
              }}
            >
              <Combobox.Input
                className={
                  "focus:outline-0 w-[36rem] sm:w-64 md:w-72 sm:text-sm md:text-sm"
                }
                placeholder={"Business Address"}
                displayValue={(value) => value && value.description}
                onChange={(e) => {
                  getPlacePredictions({ input: e.target.value });
                  setQuery(e.target.value);
                }}
              />
              <Combobox.Options
                className={
                  "mt-0.5 min-w-[620px] max-h-[200px] overflow-y-auto bg-white z-20 border border-zinc-400 rounded-xl p-3 shadow-lg"
                }
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {!isPlacePredictionsLoading &&
                  placePredictions.map((place, idx) => {
                    return (
                      <Combobox.Option
                        className={
                          "py-1 px-1 cursor-pointer hover:bg-zinc-200 rounded-lg sm:text-sm"
                        }
                        key={idx}
                        value={place}
                      >
                        {place.description}
                      </Combobox.Option>
                    );
                  })}
              </Combobox.Options>
            </Combobox>
          </div>
          <Field
            component={InputBase}
            form={props.form}
            name={"contactEmail"}
            placeholder={"Contact Email"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img src={email_img} alt={""} />
              </InputAdornment>
            }
            sx={{
              m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
              p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
              borderRadius: 3,
              border: 1,
              borderColor: "#EBEBEB",
              fontSize: isMobile ? 14 : isTablet ? 13 : 17,
              minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
            }}
            size={isMobile || isTablet ? "small" : "medium"}
          />
          <Field
            component={InputBase}
            form={props.form}
            name={"website"}
            placeholder={"Your Website (Optional)"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img src={web_img} alt={""} />
              </InputAdornment>
            }
            sx={{
              m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
              p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
              borderRadius: 3,
              border: 1,
              borderColor: "#EBEBEB",
              fontSize: isMobile ? 14 : isTablet ? 13 : 17,
              minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
            }}
            size={isMobile || isTablet ? "small" : "medium"}
          />
          <Field
            component={InputBase}
            form={props.form}
            name={"aboutInstitute"}
            multiline
            placeholder={
              "Write a few lines about your self, your classes, experiences your customers will have while attending your classes and courses..."
            }
            sx={{
              m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
              p: isMobile ? "0.45rem" : isTablet ? "0.75rem" : 2,
              minHeight: 148,
              maxHeight: 148,
              overflowY: "auto",
              flexWrap: "wrap",
              borderRadius: 7,
              border: 1,
              borderColor: "#EBEBEB",
              fontSize: isMobile ? 14 : isTablet ? 13 : 17,
              minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
            }}
            size={isMobile || isTablet ? "small" : "medium"}
          />
        </FormikProvider>
      </Box>
    </div>
  );
}
