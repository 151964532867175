import { Grid } from "@mui/material";
import React from "react";




import payment from "../../assets/Payment.svg";
import info_i from "../../assets/i.svg";

import IOSSwitch from "../../components/IOSSwitch";

export default function StripPaymentMethod(props) {
  const { title, subTitle, infoMessage, defaultSwitch, disabled, onSwitchChange, defaultChargeToCustomerSwitch, onSwitchChangeChargeToCustomer } = props;


  return (
    <div
      className={
        `w-full px-6 py-6 flex bg-white border border-brown-coffee rounded-2xl`
      }
    >
      {/* <img className={"w-17 mt-1.5"} src={payment} alt={""} /> */}
      <div className={"flex flex-col flex-1 content-start"}>
        <div className={"flex flex-row items-center gap-3"}>
          <h4 className={"text-lg text-brown-coffee font-bold"}>
            {title}
          </h4>
          {/* <Tooltip title={ "Allowing offline payments" }>
              <img className={"w-3 h-3"} src={info_i} alt={""} />
            </Tooltip> */}
        </div>
        <p className={"text-sm text-[#676767] mt-1"}>
          {subTitle}
        </p>
        <p className={"text-sm text-[#676767] mt-1"}>
          <IOSSwitch
            className={"self-start justify-self-end"}
            name={"paymentAtVenue"}
            type={"checkbox"}
            checked={defaultChargeToCustomerSwitch | false}
            disabled={disabled}
            onChange={onSwitchChangeChargeToCustomer}
          /> Charge to the customer
        </p>
        {infoMessage && (<p className={"text-sm text-[#676767] mt-1"}>
          <img className={"w-4 h-4 inline mr-2 align-text-top"} src={info_i} alt={""} /> {infoMessage}
        </p>)}
      </div>
      {/* <div className={"grid justify-items-end content-start"}> */}
      <div className={"flex flex-col content-start"}>
        <IOSSwitch
          className={"self-start justify-self-end"}
          name={"paymentAtVenue"}
          type={"checkbox"}
          checked={defaultSwitch | false}
          disabled={disabled}
          onChange={onSwitchChange}
        />
        <div className="flex-1"></div>
      </div>
    </div>
  );
}
