import React from "react";
import { Box, useMediaQuery } from "@mui/material";

import Container from "../../components/Container";
import Logo from "../../components/Logo";
import SignUpForm from "./SignUpForm";
import { LoadingBackdrop } from "../../layouts";
import "../../styles/SignUp.css";

export default function SignUp({ navigation }) {
  const [loading, setLoading] = React.useState(false);
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  return (
    <LoadingBackdrop loading={loading}>
      <Container>
        <Logo />
        <Box className={"grid grid-flow-col place-items-center"}>
          <Box className={"px-32 sm:px-4 md:px-4"}>
            <h3
              className={
                "text-5xl text-brown-coffee sm:text-4xl md:text-3xl xl:text-5xl text-center py-2 md:pb-1 font-extrabold"
              }
            >
              Sign Up
            </h3>
            <Box className={"text-center"}>
              <Box
                className={"flex flex-row items-center justify-center gap-2"}
              >
                <p className={"text-xl sm:text-base md:text-sm"}>
                  Already have an account?
                </p>
                <button
                  className={
                    "font-bold text-xl text-portland-orange transition-opacity active:opacity-70 sm:text-base md:text-sm"
                  }
                  onClick={() => navigation("/")}
                >
                  Sign In
                </button>
              </Box>
            </Box>
            <SignUpForm
              navigation={navigation}
              isTablet={isTablet}
              isMobile={isMobile}
              setLoading={setLoading}
            />
          </Box>
        </Box>
      </Container>
    </LoadingBackdrop>
  );
}
