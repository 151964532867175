import { Stack } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import size from "react-element-popper/animations/size";
import { Calendar as MultiPickCalendar } from "react-multi-date-picker";

import { dateInArray } from "../../../../helpers";

import right_arrow from "../../../../assets/arrow.svg";
import calendar_img from "../../../../assets/calendar_white.svg";
import clock from "../../../../assets/Clock_small.svg";
import marker from "../../../../assets/location-white.png";

export default function AddScheduleStep2({
  formValues,
  duration,
  dates,
  description,
  whatToCarry,
  cancellationPolicy,
  handlePrevStep,
  handleSubmit,
  disableButton,
  formResData,
}) {
  const [isDescExpanded, setDescExpanded] = React.useState(false);
  const [isWhatToCarryExpanded, setWhatToCarryExpanded] = React.useState(false);
  const { location } = formValues;
  const [details, setDetails] = React.useState([]);
  const [showCalendar, setShowCalendar] = React.useState(false);

  React.useEffect(() => {
    let displayDuration = "";
    let displayDateText = "";
    if (duration instanceof Object) {
      let { startDate, endDate } = duration;
      let { endBy } = formResData;
      startDate = parseISO(startDate);
      endDate = parseISO(endDate);
      endBy = parseISO(endBy);
      displayDateText = `${format(startDate, "d MMM yyyy")} - ${format(
        endBy,
        "d MMM yyyy"
      )}`;
      displayDuration = `${format(startDate, "KK:mm aaa")} - ${format(
        endDate,
        "KK:mm aaa"
      )}`;
    } else {
      const durationSliced = duration.slice(duration.indexOf("("));
      const durationSplit = durationSliced.split("");
      durationSplit.shift();
      durationSplit.pop();
      displayDuration = durationSplit.join("");
      const dateTextSliced = duration.slice(
        duration.indexOf("From"),
        duration.indexOf("(")
      );
      const dateTextSplit = dateTextSliced.split(" ");
      dateTextSplit.shift();
      displayDateText = dateTextSplit.join(" ");
      displayDateText = displayDateText.replace("-", " ");
      displayDateText = displayDateText.replace("to", "-");
    }
    const infoTiles = [
      {
        icon: calendar_img,
        text: displayDateText,
      },
      {
        icon: clock,
        text: displayDuration,
      },
      {
        icon: marker,
        text: location.addressName,
      },
    ];
    setDetails([...infoTiles]);
  }, [duration, formResData, location]);

  return (
    <Stack direction={"column"} spacing={2}>
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          {details.map((detail, idx) => (
            <div
              key={idx}
              className={
                "flex flex-row flex-nowrap items-center gap-2 px-2 min-h-[26px] min-w-[160px] bg-light-brown-coffee rounded-lg"
              }
            >
              <img className={"w-[10px]"} src={detail.icon} alt={""} />
              <p className={"text-[11px] text-white"}>{detail.text}</p>
            </div>
          ))}
        </Stack>
        {dates && (
          <>
            <p
              className={
                "cursor-pointer flex flex-row gap-2 text-[#A9B0C8] text-[13.5px] max-w-[140px]"
              }
              onClick={() => setShowCalendar(!showCalendar)}
            >
              Show All Dates
              <img
                className={"transform rotate-90 w-1"}
                src={right_arrow}
                alt={""}
              />
            </p>
            {showCalendar && (
              <MultiPickCalendar
                className='custom-calendar'
                value={formResData.daysInWeek.map((day) => parseISO(day))}
                // onChange={handleCalendarDaysChange}
                multiple
                numberOfMonths={2}
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                readOnly
                animations={[size()]}
                mapDays={({
                  date,
                  today,
                  selectedDate,
                  currentMonth,
                  isSameDate,
                }) => {
                  let props = {};

                  props.style = {
                    borderRadius: "50%",
                    color: "#481B3A",
                    fontWeight: 600,
                    backgroundColor:
                      date.month.index === currentMonth.index ? "#FFF" : "",
                    width: 30,
                    height: 30,
                  };
                  const dateTransformed = date.toDate();
                  const selectedDatesTransformed = selectedDate.map((day) =>
                    day.toDate()
                  );
                  if (dateInArray(dateTransformed, selectedDatesTransformed)) {
                    props.style = {
                      ...props.style,
                      color: "#FFF",
                      backgroundColor: "#FF5733",
                    };
                  }

                  return props;
                }}
              />
            )}
          </>
        )}
      </Stack>
      <Stack direction={"column"} spacing={3}>
        <div>
          <h4 className={"font-bold text-[14.5px] text-brown-coffee"}>
            Description
          </h4>
          <p className={"font-light text-[13px]"}>
            {description?.length > 175 ? (
              !isDescExpanded ? (
                <>
                  {description.slice(0, 175)}
                  <span
                    className={
                      "text-gray-400 font-light text-[13px] cursor-pointer"
                    }
                    onClick={() => setDescExpanded(true)}
                  >
                    ...see more
                  </span>
                </>
              ) : (
                <span>
                  {description}
                  <br />
                  <span
                    className={
                      "text-gray-400 font-light text-[13px] cursor-pointer"
                    }
                    onClick={() => setDescExpanded(false)}
                  >
                    see less
                  </span>
                </span>
              )
            ) : (
              <span>{description}</span>
            )}
          </p>
        </div>
        {whatToCarry && (
          <div>
            <h4 className={"font-bold text-[14.5px] text-brown-coffee"}>
              What To Carry
            </h4>
            <p className={"font-light text-[13px]"}>
              {whatToCarry?.length > 175 ? (
                !isWhatToCarryExpanded ? (
                  <>
                    {whatToCarry.slice(0, 175)}
                    <span
                      className={
                        "text-gray-400 font-light text-[13px] cursor-pointer"
                      }
                      onClick={() => setWhatToCarryExpanded(true)}
                    >
                      ...see more
                    </span>
                  </>
                ) : (
                  <span>
                    {whatToCarry}
                    <br />
                    <span
                      className={
                        "text-gray-400 font-light text-[13px] cursor-pointer"
                      }
                      onClick={() => setWhatToCarryExpanded(false)}
                    >
                      see less
                    </span>
                  </span>
                )
              ) : (
                <span>{whatToCarry}</span>
              )}
            </p>
          </div>
        )}
        <div>
          <h4 className={"font-bold text-[14.5px] text-brown-coffee"}>
            Cancellation Policy
          </h4>
          {cancellationPolicy.isEnabled ? (
            <p className={"font-light text-[13px]"}>
              Cancellation is free in case booking is cancelled{" "}
              <span className={"font-bold"}>{cancellationPolicy.text}</span>{" "}
              before start of the class.
            </p>
          ) : (
            <p className={"font-light text-[13px]"}>
              Cancellation may take extra charges
            </p>
          )}
        </div>
        <Stack direction={"row"} spacing={1}>
          <button
            className={
              "bg-white h-[48px] w-[49px] grid place-items-center rounded-lg"
            }
            onClick={handlePrevStep}
          >
            <img
              className={"transform rotate-180 w-[8px] h-[16px]"}
              src={right_arrow}
              alt={""}
            />
          </button>
          <button
            className={
              "w-[469px] h-[48px] bg-portland-orange text-white rounded-lg font-semibold text-lg"
            }
            onClick={handleSubmit}
            disabled={disableButton}
          >
            Looks good, Publish it!
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
}
