import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../hooks";
import { userState } from "../redux/reducers/userReducer";

import profile_img from "../assets/avatar_placeholder.png";
import logo from "../assets/DasboardLogo.png";
import logo_w from "../assets/logo-w.svg";
import whatsapp_png from "../assets/WhatsApp.png";

import NewNavBar from "./NewHeadNavBar";
import NewSidebarMenuList from "./NewSidebarMenuList";
import "./Sidebar.css";
import SmallScreenNotAllow from "../modals/error/SmallScreenNotAllow";
import SidebarUserInfo from "./SidebarUserInfo";
import { margin, zIndex } from "tailwindcss-classnames";
import { Divider } from "@mui/material";
import { cutString } from "../helpers";

export default function NeewSidebar({
  children,
  subHeader = null,
  navBarTitleComp,
  showCalendarIcon,
  backgroundColor,
}) {
  const [userToken, setUserToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [calendarDrawerOpen, setCalendarDrawerOpen] = React.useState(false);

  
  const [screenSizeType, setScreenSizeType] = React.useState("");
  React.useEffect(() => {
    if (screenSizeType === "small" || screenSizeType === "medium") {
      // window.location.href = "/small-screen-not-allow";
      // return false;
    }
  }, [screenSizeType]);
  React.useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // const screenWidth = window.outerWidth;
      if (screenWidth < 768) {
        setScreenSizeType("small");
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setScreenSizeType("medium");
      } else {
        setScreenSizeType("large");
      }
    };

    // Initial call to set screen size on component mount
    handleResize();

    // Event listener to update screen size on resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   React.useEffect(() => {
  //     if (isTablet || isMobile) {
  //       setDrawerOpen(false);
  //     }
  //   }, [isTablet, isMobile]);

  if ((userToken === undefined || userToken == "")) {
    // navigation("/home/dashboard");
    window.location.href = "/";
  }

  React.useEffect(() => {
    document.body.style.backgroundColor = backgroundColor ? backgroundColor : "#FFF";
  }, [backgroundColor]);

  const navBarTitleHight = navBarTitleComp != undefined ? "7%" : "10%"

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCalendarDrawer = (status) => {
    setCalendarDrawerOpen(status);
  };

  const onContactClick = () => {
    const number = "+31680068100".replace(/[^\w\s]/gi, "").replace(/ /g, "");
    window.open(`https://web.whatsapp.com/send?phone=${number}`);
  };

  const mystyle = {
    position: "relative",
    width: "229px",
    height: "100%",
    display: "inline-block",
    float: "left",
  };

  const navstyle = {
    width: "100%",
    height: { navBarTitleHight },
    display: "inline-block",
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 1200,
    paddingLeft: '250px',
    // float: "left",
  };

  const outerdiv = {
    width: "100%",
    // minWidth: "1382px",
    // minWidth: "1382px",
    minWidth: "1180px",
    maxWidth: "1382px",
    marginTop: subHeader ? "180px" : "104px",
    zIndex: 0,
    // height: "90%",
    // float: "left",
  };

  const ToolbarStyle = {
    width: " 100%",
    textAlign: "center"
    // height: "80px",
    // position: "relative",
    // display:"flex",
    // flexDirection: "row",
  };

  const Newouterdiv = {
    // display: "flex",
    // flexDirection: "row",
    width: "100%",
  };

  const drawerWidth = 250;


  return (
    (screenSizeType === "small") ? (
      <SmallScreenNotAllow />
    ) : (<div style={Newouterdiv}
      className={`w-full h-full flex flex-row`}
    >
      <div>
        <Drawer
          ModalProps={{
            onBackdropClick: () => setDrawerOpen(false),
            keepMounted: true,
          }}
          open={drawerOpen}
          variant="permanent"
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#481B3A',
            },
          }}
        >
            <div className={"flex flex-col bg-brown-coffee text-white h-screen "}>
              <div className={"flex"}>
                {/* <SidebarUserInfo /> */}
                <div className="w-full flex flex-col justify-center items-center gap-2 border-b-0 border-[#ffffff99] px-3 py-6 cursor-pointer">
                  <img
                    // className={"mask mask-circle inline w-16 h-16 lg:w-16 lg:h-16 min-h-16 max-w-16 min-h-16 max-h-16"}
                    // src={userReduxState.userData?.profilePic ?? profile_img}
                    className={"inline w-36 my-4"}
                    src={logo_w}
                    alt={"Bookzy"}
                  />
                  <Divider className="w-full bg-[#ffffff40]"/>
                </div>
              </div>
              <div
                className={"p-4 flex flex-1 flex-col h-full"}
              // style={{ minHeight: `100%` }}
              >
                {false && (<div style={ToolbarStyle}>
                  <div style={{ padding: `5px` }}>
                    <img
                      // className={"mask mask-circle inline w-16 h-16 lg:w-16 lg:h-16 min-h-16 max-w-16 min-h-16 max-h-16"}
                      // src={userReduxState.userData?.profilePic ?? profile_img}
                      className={"inline w-auto my-12 "}
                      src={logo_w}
                      alt={"Bookzy"}
                    />
                  </div>
                  {/* <div className={"pt-3"}>
                <h3
                  title={userReduxState.userData?.fullName}
                  className={
                    "font-bold text-white text-xl lg:text-lg link-underline link-underline-black cursor-pointer"
                  }
                  onClick={() => navigate("/myprofile")}
                >
                  {cutString(userReduxState.userData?.fullName, 15, true)}
                </h3>
                <h6 className={"text-white text-sm lg:text-base"}>
                  Netherlands
                </h6>
              </div> */}
                </div>)}

                <div
                  className={
                    "grow row-start-2 row-span-6 gap-6 sm:gap-0 place-items-center flex flex-col"
                  }
                >
                  <div className={"row-span-3 self-start flex-1 "} style={{ paddingTop: "20%" }}>
                    <NewSidebarMenuList />
                  </div>
                  <div
                    className={
                      // "row-span-1 lg:mb-2 justify-self-center self-center"
                      ""
                    }
                  >
                    {/* <img
                      // className={"mask mask-circle inline w-16 h-16 lg:w-16 lg:h-16 min-h-16 max-w-16 min-h-16 max-h-16"}
                      // src={userReduxState.userData?.profilePic ?? profile_img}
                      className={"inline w-auto my-12"}
                      src={logo_w}
                      alt={"Bookzy"}
                    /> */}
                    <button
                      className={
                        "flex flex-row justify-center items-center gap-2 my-8 w-[170px] px-2 py-2 1bg-[#5B274B] rounded-xl active:bg-[#481B3A] active:opacity-70 transition ease-in-out duration-100"
                      }
                      onClick={onContactClick}
                    >
                      {/* <img
                        className={"mr-2 w-[27px] h-[27px]"}
                        src={whatsapp_png}
                        alt={""}
                      /> */}
                      <svg width="26" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.3521 12.888C1.35154 15.0798 1.88615 17.2199 2.90272 19.1062L1.25488 25.5511L7.41203 23.8217C9.11502 24.8148 11.0231 25.3352 12.9621 25.3353H12.9672C19.3681 25.3353 24.5787 19.7558 24.5814 12.8978C24.5826 9.57468 23.3756 6.44979 21.1826 4.0988C18.9899 1.748 16.0738 0.452689 12.9667 0.451172C6.56499 0.451172 1.35484 6.03039 1.3522 12.888" fill="url(#paint0_linear_2466_6098)" />
                        <path d="M1.3521 12.888C1.35154 15.0798 1.88615 17.2199 2.90272 19.1062L1.25488 25.5511L7.41203 23.8217C9.11502 24.8148 11.0231 25.3352 12.9621 25.3353H12.9672C19.3681 25.3353 24.5787 19.7558 24.5814 12.8978C24.5826 9.57468 23.3756 6.44979 21.1826 4.0988C18.9899 1.748 16.0738 0.452689 12.9667 0.451172C6.56499 0.451172 1.35484 6.03039 1.3522 12.888" fill="url(#paint1_linear_2466_6098)" />
                        <path d="M0.93791 12.883C0.937249 15.1536 1.49103 17.3702 2.54384 19.324L0.836914 26L7.21484 24.2086C8.97216 25.235 10.9507 25.7761 12.964 25.777H12.9692C19.5998 25.777 24.9975 19.9967 25.0004 12.8933C25.0015 9.4507 23.751 6.21348 21.4797 3.77826C19.208 1.34334 16.1876 0.00141553 12.9692 0C6.3375 0 0.940553 5.77942 0.93791 12.883ZM4.7361 18.9876L4.49796 18.5826C3.49688 16.8775 2.96849 14.9071 2.96924 12.8838C2.97142 6.97949 7.45713 2.17588 12.973 2.17588C15.6442 2.17709 18.1546 3.29253 20.0427 5.31634C21.9308 7.34035 22.9697 10.0309 22.969 12.8925C22.9666 18.7968 18.4808 23.601 12.9692 23.601H12.9653C11.1707 23.6 9.4106 23.0837 7.87565 22.1081L7.51037 21.876L3.72558 22.939L4.7361 18.9875V18.9876Z" fill="url(#paint2_linear_2466_6098)" />
                        <path d="M9.96179 7.49749C9.73658 6.96131 9.49957 6.95049 9.28541 6.94109C9.11003 6.933 8.90955 6.9336 8.70926 6.9336C8.50878 6.9336 8.18304 7.01439 7.90771 7.33642C7.6321 7.65876 6.85547 8.43771 6.85547 10.022C6.85547 11.6064 7.93272 13.1375 8.0829 13.3525C8.23326 13.5672 10.1626 16.9224 13.2181 18.2132C15.7575 19.2858 16.2743 19.0725 16.8254 19.0187C17.3767 18.9651 18.6041 18.24 18.8545 17.488C19.1051 16.7362 19.1051 16.0917 19.03 15.957C18.9548 15.8228 18.7544 15.7423 18.4537 15.5813C18.153 15.4202 16.6751 14.6412 16.3996 14.5337C16.1239 14.4263 15.9236 14.3727 15.7231 14.6952C15.5226 15.0171 14.9469 15.7423 14.7715 15.957C14.5962 16.1723 14.4207 16.1991 14.1202 16.038C13.8194 15.8764 12.8512 15.5368 11.7026 14.4399C10.8089 13.5863 10.2056 12.5322 10.0302 12.2098C9.85485 11.8879 10.0114 11.7134 10.1622 11.5529C10.2973 11.4086 10.4629 11.1769 10.6134 10.9889C10.7632 10.8008 10.8133 10.6667 10.9135 10.4519C11.0138 10.237 10.9636 10.0489 10.8886 9.88782C10.8133 9.72675 10.2291 8.13418 9.96179 7.49749Z" fill="white" />
                        <defs>
                          <linearGradient id="paint0_linear_2466_6098" x1="1167.58" y1="2510.44" x2="1167.58" y2="0.451172" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#1FAF38" />
                            <stop offset="1" stop-color="#60D669" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_2466_6098" x1="1167.58" y1="2510.44" x2="1167.58" y2="0.451172" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#1FAF38" />
                            <stop offset="1" stop-color="#60D669" />
                          </linearGradient>
                          <linearGradient id="paint2_linear_2466_6098" x1="1209.01" y1="2600" x2="1209.01" y2="0" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#F9F9F9" />
                            <stop offset="1" stop-color="white" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <p
                        className={"font-bold text-lg text-white text-center"}
                      >
                        Get Support
                      </p>
                    </button>
                    {/* <img src={logo} alt={""} /> */}
                  </div>
                </div>
              </div>
            </div>
        </Drawer>
      </div>
      <Box
        className="flex-1"
      // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <div style={navstyle} id="bk-appbar">
          <AppBar
            elevation={2}
            sx={{
              width: `100%`,
              height: `100%`,
              ml: `0px`,
              backgroundColor: "white",
              color: "black",
              boxShadow: "none",
              position: "relative",
              filter:
                "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
            }}
          >
            <Toolbar>
              <NewNavBar
                titleComp={navBarTitleComp}
                handleDrawer={handleDrawer}
                showCalendarIcon={showCalendarIcon}
                setCalendarDrawer={(status) => { handleCalendarDrawer(status) }}
              />
            </Toolbar>
          </AppBar>
          {subHeader && (
              <div className="p-3 bg-white drop-shadow-md1" style={{
                width: "100%",
                minWidth: "1180px",
                maxWidth: "1382px",
              }}>
                {subHeader}
              </div>
          )}
        </div>
        <div
          style={outerdiv}
        // className="justify-self-center"
        >{children}</div>
      </Box>
      <div>
        {/* <NewCalendarSidebar
          calendarDrawerOpen={calendarDrawerOpen}
          onBackdropClick={(status) => {
            setCalendarDrawerOpen(status);
          }}
        ></NewCalendarSidebar> */}
      </div>
    </div>)
  );
}
