import { handleUploadImageToS3 } from "./UploadImageToS3";
import { filterObject } from "./FilterObject";
import { minutesToDuration } from "./minutesToDuration";
import { dateInArray } from "./dateInArray";
import { findZipcode } from "./findZipcode";

const cutString = (text, count, insertDots) => {
  return text ? text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "") : "";
}

export {
  handleUploadImageToS3,
  filterObject,
  minutesToDuration,
  dateInArray,
  findZipcode,
  cutString,
};
