import moment from "moment";
import React from "react";

import { useMediaQuery } from "@mui/material";
import schedule_calender from "../../../assets/schedule_calender.svg";
import timerclock from "../../../assets/timerclock.svg";
import FreeSlotPopover from "../venue/FreeSlotPopover";

export default function UserFreeTimeTile({
  handleSessionClick,
  handleEditSlot,
  handleDeleteSlot,
  loading,
  setLoading,
  item,
}) {

  const isLaptopS = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1440px)"
  );
  var duration = moment.duration(moment(item?.slot_date + " " + item?.slot_end_time, 'YYYY-MM-DD h:mm A').diff(moment(item?.slot_date + " " + item?.slot_start_time, 'YYYY-MM-DD h:mm A')));
  var minutes = duration.asMinutes();

  const getResourceName = () => {
    let resourceDetail = { fullName: "" };
    if (item?.resourceId && item?.resource_detail && item?.resource_detail?._id) {
      resourceDetail = item?.resource_detail;
    } else if (item?.resourceId && item?.user_detail && item?.user_detail?.myResource && item?.user_detail?.myResource.length > 0) {
      item?.user_detail?.myResource.map((d) => {
        if (d._id.toString() == item?.resourceId) {
          resourceDetail = d;
        }
      });
    }
    return resourceDetail?.fullName || "";
  }
  return (
    <div
      className={`${"my-3"} p-4 flex flex-row items-start gap-4 cursor-pointer hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl bg-[#D7BB294D]`}
    >
      <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
        <div className={`flex item-top`}>
          <div className={`flex flex-col item-center w-full gap-1`}>
            <h3
              className={`text-[#481B3A] font-bold text-lg capitalize text-ellipsis overflow-hidden max-h-7 break-all`}
              style={{
                display: "inline-block",
                // maxWidth: "285px",
                wordBreak: "nowrap",
              }}
            >
              {item?.title || "Free time slot"} - {getResourceName()}
            </h3>
            <div className={"flex flex-row items-center gap-2"}>
              <img className={"w-4 h-4"} src={schedule_calender} alt={""} />
              <p
                className={`text-granite-gray ${isLaptopS ? "text-xs" : "text-sm"}`}
              >
                {moment(item?.slot_date, 'YYYY-MM-DD').format('DD MMM, ddd')}
              </p>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              <img className={"w-4 h-4 text-[#000000]"} src={timerclock} style={{ fill: "#000" }} alt={""} />
              <p
                className={`text-granite-gray ${isLaptopS ? "text-xs" : "text-sm"}`}
              >
                {/* 'DD MMM, ddd, h:mm A' */}
                {item?.isFullDay ? "All day" : moment(item?.slot_date + " " + item?.slot_start_time, 'YYYY-MM-DD h:mm A').format('h:mm A') + " - " + moment(item?.slot_date + " " + item?.slot_end_time, 'YYYY-MM-DD h:mm A').format('h:mm A')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex flex-col justify-between  items-end gap-8"}>

        <div className={"flex items-center text-[#5B274B] font-bold text-base"}>
          {/* {minutes + " minutes"}&nbsp; */}
          <FreeSlotPopover
            slotDetails={{ ...item }}
            handleEditSlot={handleEditSlot}
            handleDeleteSlot={handleDeleteSlot}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
}
