import {
  Box, Button, Link, Stack, Table, TableBody, TableContainer, TableHead,
  TableRow
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import cogoToast from "cogo-toast";
import { addMinutes, parseISO } from "date-fns";
import moment from "moment";
import React from "react";
import { Calendar as MultiCalendar, DateObject } from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";


import "../../../styles/Calendar.css";


import copyOrange from "../../../assets/copyOrange.svg";
import defaultImage from "../../../assets/defaultImage.svg";
import greenlocation from "../../../assets/greenlocation.svg";
import greenprice from "../../../assets/greenprice.svg";
import greentime from "../../../assets/greentime.svg";
import loader from "../../../assets/loader.svg";
import progressdone from "../../../assets/progressdone.svg";
import shareOrange from "../../../assets/shareOrange.svg";
import successfullclass from "../../../assets/successfullclass.png";
import { minutesToDuration } from "../../../helpers";

export default function ReviewPublishTab({
  formValues,
  formScheduleValues,
  handleFormValueChange,
  setFormValues,
  setTabCurrentState,
  dataSaveProgress,
  defaultDataSaveProgress = { defaultDataSaveProgress },
  setDataSaveProgress = { setDataSaveProgress }
}) {


  const navigate = useNavigate();
  const [courseDates, setCourseDates] = React.useState([new Date()]);

  const getDatesBetween = (startDate, endDate, includeEndDate) => {
    const dates = [];
    const currentDate = startDate;
    while (currentDate < endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (includeEndDate) dates.push(endDate);
    return dates;
  };

  let start_date = new Date();
  let end_date = new Date();
  const [selectedStartDate, setSelectedStartDate] = React.useState(moment());
  const [selectedEndDate, setSelectedEndDate] = React.useState(moment());

  React.useEffect(() => {
    (async () => {

      const { daysInWeek, startDate, endBy, timing } = formScheduleValues;


      let time = formValues.duration;
      const { mins, hours } = minutesToDuration(time);
      const minutes = (mins) ? mins : 0;

      start_date = moment(moment(startDate).format("MM/DD/YYYY") + " " + timing);
      end_date = moment(moment(endBy).format("MM/DD/YYYY") + " " + timing).add({
        hour: hours,
        minute: minutes,
        seconds: 0,
      });
      setSelectedStartDate(moment(moment(startDate).format("MM/DD/YYYY") + " " + timing));
      setSelectedEndDate(moment(moment(endBy).format("MM/DD/YYYY") + " " + timing).add({
        hour: hours,
        minute: minutes,
        seconds: 0,
      }));
      // console.log("startDate DateObject", (startDate instanceof Date));
      // console.log("startDate ", start_date);
      // console.log("endBy DateObject", (endBy instanceof Date));
      // console.log("end_date ", end_date);
      let mapDays = daysInWeek ? daysInWeek.map((day) => parseISO((day instanceof Date) ? day.toISOString() : day)) : [];
      if (formScheduleValues.recurrenceType === "weekly") {
        mapDays = [];
        var currDate = start_date.startOf('day');
        var lastDate = end_date.startOf('day');
        while (currDate.isSameOrBefore(lastDate)) {
          // console.log(currDate.toDate());
          // console.log(currDate.day());
          if (formScheduleValues.selectedDays.indexOf(currDate.day()) > -1) {
            mapDays.push(currDate.toDate());
          }
          currDate.add(1, 'days');
        }
      }
      // console.log("mapDays", mapDays);
      setCourseDates([...mapDays]);
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      border: 0,
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
      margin: "5px 10px",
      // minHeight: "72px",
      borderRadius: "10px",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
  }));

  const startDateFormat = "d MMM, EEE, hh:mmaa";
  const parsedStartDate = parseISO((formScheduleValues.startDate instanceof Date) ? formScheduleValues.startDate.toISOString() : formScheduleValues.startDate);
  // setCourseStartDate(parsedStartDate);

  const endTime = addMinutes(parsedStartDate, formValues.duration);

  console.log("formScheduleValues", formScheduleValues);
  // console.log("DateObject", (formScheduleValues.startDate instanceof Date));
  // console.log("formScheduleValues.startDate", formScheduleValues.startDate);
  // console.log("parsedStartDate", parsedStartDate);
  return (
    <Stack direction={"column"} className={"py-6 px-8"} spacing={2} sx={{ maxWidth: 889 }}>
      <Stack direction={"column"} spacing={3}>
        <div className={"w-full h-[225px]"}>
          {formValues.photo ? (
            <img
              className={
                // "w-full h-[225px] object-cover object-fill rounded-2xl"
                "h-[225px] rounded-2xl"
              }
              src={formValues.photo}
              alt={""}
            />
          ) : (<img
            className={
              "w-full h-[225px] object-cover rounded-2xl"
            }
            src={defaultImage}
            alt={""}
          />)}
        </div>
        <Stack direction={"row"} alignItems={"center"}>
          <div className={"grow"}>
            <h2 className={"font-bold text-[30px] text-brown-coffee"}>
              {formValues.className}
            </h2>
            <p className={"text-[15.35px]"}>{formValues.tagLine}</p>
          </div>
        </Stack>
      </Stack>

      {dataSaveProgress.isStartSubmiting === false ? (
        <>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>Multiple sessions Scheduled for Following Dates</h4>
            <div className={`grid grid-cols-2 w-full my-4`}>

              <Box className=""
                sx={{
                  px: 2,
                  py: 3,
                  border: 0,
                  borderColor: "#00000024",
                  borderRadius: "15px",
                }}
              >
                <MultiCalendar
                  className="bookzy-calendar preview"
                  currentDate={new DateObject(formScheduleValues.startDate)}
                  value={courseDates}
                  weekDays={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
                  readOnly
                  mapDays={({
                    date,
                    today,
                    selectedDate,
                    currentMonth,
                    isSameDate,
                  }) => {
                    let props = {};

                    // const dateTransformed = date.toDate();
                    const dateTransformed = moment(date.toDate()).format("MM/DD/YYYY");
                    const selectedDatesTransformed = selectedDate.map(
                      // (day) => day.toDate()
                      (day) => moment(day.toDate()).format("MM/DD/YYYY")
                    );
                    // console.log(moment(date.toDate()).format("MM/DD/YYYY") + "===" + moment(today).format("MM/DD/YYYY") + "= " + (moment(moment(date.toDate()).format("MM/DD/YYYY")).isSame(moment(today).format("MM/DD/YYYY"))));
                    // console.log("isSameDate", isSameDate);
                    if (moment(moment(date.toDate()).format("MM/DD/YYYY")).isSame(moment(today).format("MM/DD/YYYY"))) {
                      props.className = "default-current-date";
                    }

                    if (selectedDatesTransformed.indexOf(dateTransformed) > -1) {
                      props.className = "selected-date";
                    }

                    return props;
                  }}
                  style={{
                    backgroundColor: "text-brown-coffee",
                    // height: "24px",
                    borderRadius: "8px",
                    fontSize: "24px",
                    padding: "3px 10px"
                  }}
                />
              </Box>
              <Box className="flex flex-col justify-center items-center ">
                <Box className="flex justify-center items-center w-4/5 py-3">
                  <Box className="flex-none">
                    <img
                      className={""}
                      src={greentime}
                      alt={""}
                    />
                  </Box>
                  <Box className="flex-1 pl-2">
                    <div className="text-[#00000099] text-sm">Time </div>
                    <div className="text-[#000000] text-base">
                      {formScheduleValues.startDate ? selectedStartDate.format("h:mm A") + " - " + selectedEndDate.format("h:mm A") : ""}
                    </div>
                  </Box>
                </Box>
                <Box className="flex justify-center items-center w-4/5 py-3">
                  <Box className="flex-none">
                    <img
                      className={""}
                      src={greenlocation}
                      alt={""}
                    />
                  </Box>
                  <Box className="flex-1 pl-2">
                    <div className="text-[#00000099] text-sm">Where </div>
                    <div className="text-[#000000] text-base">
                      <p>{formScheduleValues && formScheduleValues.location ? formScheduleValues.location.addressName : ""}</p>
                      <p>{formScheduleValues && formScheduleValues.location ? formScheduleValues.location.address : ""}</p>
                    </div>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <Box className="flex">
              <Box className="flex-none">
                <img
                  className={""}
                  src={greenprice}
                  alt={""}
                />
              </Box>
              <Box className="flex-1 pl-2">
                <div>Price</div>
                <div>
                  €{formValues.eventPricingList.length ? formValues.eventPricingList[0].amount : 0}
                  {formValues.eventPricingList.length > 1 ? " -- €" + formValues.eventPricingList[formValues.eventPricingList.length - 1].amount + " (Multiple Pricing)" : ""}</div>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                my: 3,
                backgroundColor: "#F4F4F4",
                borderRadius: "15px",
              }}>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="30%" align="center">Pricing Type</StyledTableCell>
                      <StyledTableCell width="30%" align="center">Capacity</StyledTableCell>
                      <StyledTableCell width="30%" align="center">Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formValues.eventPricingList.map((row, i) => (
                      <StyledTableRow key={i} >
                        <StyledTableCell align="center"><Box>{row.ticketType}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>{row.capacity}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>{row.amount}</Box></StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <Box className="flex">
              <Box className="flex-none">
                <img
                  className={""}
                  src={greenprice}
                  alt={""}
                />
              </Box>
              <Box className="flex-1 pl-2">
                <div>Session Passes</div>
                <div>
                  €{formValues.multiSessionPassList.length ? formValues.multiSessionPassList[0].amount : 0}
                  {formValues.multiSessionPassList.length > 1 ? " -- €" + formValues.multiSessionPassList[formValues.multiSessionPassList.length - 1].amount + " (Multiple Passes)" : ""}</div>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                my: 3,
                backgroundColor: "#F4F4F4",
                borderRadius: "15px",
              }}>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="30%" align="center">Pass Name</StyledTableCell>
                      <StyledTableCell width="20%" align="center">Sessions Capacity</StyledTableCell>
                      <StyledTableCell width="20%" align="center">Price</StyledTableCell>
                      <StyledTableCell width="20%" align="center">Validity Days</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formValues.multiSessionPassList.map((row, i) => (
                      <StyledTableRow key={i} >
                        <StyledTableCell align="center"><Box>{row.passName}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>{row.noOfAllowedSessions}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>{row.amount}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>{(row.validUntilConsumed === true) ? <span style={{ p: 0, fontSize: 14, lineHeight: "14px" }}>Until Consumed</span> : parseInt(row.validityDays)}</Box></StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>Desciption</h4>
            <p>{formValues.classDescription}</p>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>What to carry</h4>
            <p>{formValues.whatToCarry}</p>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>Cancellation Policy</h4>
            <p>Cancellation is free in case booking is cancelled <span className="font-semibold">{formValues.cancellationPeriod}</span> before start of the class.</p>

          </Box>
        </>
      ) : (
        <>
          <Box className="flex flex-col justify-center items-center w-full">
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Created Class Format
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.createClass) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Created Pricing
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.createPricing) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Creating Schedule(s) for this Class
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.createSchedule) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Saving in Draft
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.changePublishStatus) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Creating Booking Link
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.creatingBookingLink) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            {dataSaveProgress.creatingBookingLink === true ? (
              <Box className="flex flex-col justify-center items-center w-full mt-5">

                <Box className="flex flex-col justify-center items-center text-center">
                  <img
                    className={"w-[235px  ]"}
                    src={successfullclass}
                    alt={""}
                  />
                  <Box sx={{
                    color: "#481B3A",
                    fontSize: "28px",
                    fontWeight: "700",
                  }}>
                    Congratulations!<br></br>
                    Your Class is now ready for Booking
                  </Box>
                  <Box sx={{
                    color: "#000000B2",
                    fontSize: "20px",
                    my: 3
                  }}>
                    Share the link below to let your customer book
                  </Box>

                </Box>
                <Box className="flex justify-center items-center w-full mt-5"
                  sx={{
                    backgroundColor: "#F4F4F4",
                    border: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    borderRadius: "20px",
                  }}>
                  <Box className="flex-1 pl-2 text-[#000000] my-0 mx-5"
                    sx={{ maxWidth: "680px", overflowX: "auto" }}
                  >
                    {dataSaveProgress.bookingLink}
                  </Box>
                  <Box className="flex flex-none bg-brown-coffee py-3 px-5 rounded-r-2xl">
                    <Link component="button" underline="none" onClick={() => {
                      navigator.clipboard.writeText(dataSaveProgress.bookingLink);
                      cogoToast.success("Copied!");
                    }}>
                      <img
                        className={""}
                        src={copyOrange}
                        alt={""}
                      />
                    </Link>
                    <Link href={dataSaveProgress.bookingLink} underline="none" target="_blank">
                      <img
                        className={"ml-3 mr-5"}
                        src={shareOrange}
                        alt={""}
                      />
                    </Link>
                  </Box>
                </Box>
                <Box className="flex justify-center items-center w-full mt-16">
                  <Button
                    sx={{
                      color: "common.white",
                      backgroundColor: "secondary.mainLight",
                      borderColor: "secondary.mainLight",
                      border: 2,
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "secondary.mainLight",
                        color: "common.white",
                        boxShadow: "none",
                      },
                      fontWeight: 600,
                      borderRadius: 3,
                      width: 231,
                      height: 52,
                      fontSize: 16,
                      marginRight: 3,
                    }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Add another class
                  </Button>
                  <Button
                    sx={{
                      color: "common.white",
                      backgroundColor: "primary.main",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        boxShadow: "none",
                      },
                      fontWeight: 600,
                      borderRadius: 3,
                      width: 231,
                      height: 52,
                      fontSize: 16,
                    }}
                    variant={"contained"}
                    onClick={(e) => {
                      navigate("/myclasses", { replace: true });
                    }}
                  >
                    GoTo Class List
                  </Button>
                </Box>
              </Box>
            ) : ""}
          </Box>
        </>
      )}
    </Stack>
  );
}
