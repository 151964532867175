import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, Divider, IconButton, Stack } from "@mui/material";
import cogoToast from "cogo-toast";
import React from "react";

import booking_svg from "../../../assets/bookingLink.svg";
import copy_squares from "../../../assets/square.on.square.svg";

export default function ServiceBookingLinkModal({
  title,
  subtitle,
  open,
  handleClose,
  bookingLink,
}) {
  return (
    <Dialog
      open={open}
      scroll={"body"}
      PaperProps={{
        sx: {
          borderRadius: 5,
          minWidth: 500,
          maxWidth: 500,
          overflowX: "hidden",
          backgroundColor: "primary.main",
        },
      }}
    >
      {bookingLink ? (<Stack direction={"column"} spacing={6} sx={{ pt: 4, pb: 8, px: 8 }}>
        <Stack direction={"column"} spacing={2}>
          <div className={"flex flex-row items-center justify-between"}>
            <h3 className={"font-bold text-2xl text-white"}>{title !== undefined ? title : `Share Booking Link`}</h3>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon sx={{ color: "#FFF" }} />
            </IconButton>
          </div>
          <Divider variant={"middle"} sx={{ color: "primary.light" }} />
        </Stack>
        <Stack direction={"column"} spacing={1}>
          <img src={booking_svg} alt={""} />
          <p className={"font-medium text-[16px] text-center text-white"}>
            {subtitle !== undefined ? subtitle : `Payment Link Generated`}
          </p>
        </Stack>
        <button
          className={
            "min-w-[340px] h-[58px] bg-white rounded-xl flex flex-row items-center gap-2 px-4 py-2"
          }
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(bookingLink);
              cogoToast.success("Copied!");
            } catch (e) {
              cogoToast.error("Failed to copy!");
            }
          }}
        >
          <span className={"h-[50px] flex-1 font-semibold text-brown-coffee text-ellipsis overflow-hidden cursor-pointer break-all"}>
            {bookingLink}
          </span>
          <span className={"flex flex-col items-center"}>
            <img src={copy_squares} alt={""} />
            <p className={"text-[7px] font-semibold text-black"}>Copy</p>
          </span>
        </button>
      </Stack>) : (
        <Stack direction={"column"} spacing={2} sx={{ pt: 4, pb: 8, px: 8 }}>
          <Stack direction={"column"} spacing={2}>
            <div className={"flex flex-row items-center justify-between"}>
              <h3 className={"font-bold text-2xl text-white"}>&nbsp;</h3>
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon sx={{ color: "#FFF" }} />
              </IconButton>
            </div>
            <Divider variant={"middle"} sx={{ color: "primary.light" }} />
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <p className={"font-medium text-[16px] text-center text-white"}>
              Sorry for the inconvenience, The Payment Link was not Generated. Please try again
            </p>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
}
