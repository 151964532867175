import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";

import { useLocation } from "react-router-dom";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OtpInput from "react-otp-input";

import Logo from "../../components/Logo";
import { LoadingBackdrop } from "../../layouts";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function Verify({ navigation }) {
  const location = useLocation();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [resendSMSSubmitting, setResendSMSSubmitting] = React.useState(false);
  const [numberToken, setNumberToken] = React.useState(
    location?.state?.response?.data?.token
  );

  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  const handleChange = (text) => setOtp(text);

  const handleResendSMS = async () => {
    setResendSMSSubmitting(true);
    setLoading(true);
    const request = await axios.post(
      `${getUrlForUsers()}forgotPassword`,
      {
        username: location.state.countryCode + location.state.phone,
        userType: "teacher",
      }
    );
    if (request.data.statusCode === 200) {
      setLoading(false);
      setResendSMSSubmitting(false);
      setNumberToken(request.data.data.token);
    } else {
      setLoading(false);
      setResendSMSSubmitting(false);
      cogoToast.error(request.data.message);
    }
  };

  const handleWrongNumber = () => {
    navigation(-1, {
      state: {
        formattedPhoneNumber: location.state.formattedPhoneNumber,
        countryName: location.state.countryName,
        countryCode: location.state.countryCode,
        phone: location.state.phone,
      },
    });
  };

  const handleSubmit = async () => {
    setError(false);
    setSubmitting(true);
    setLoading(true);

    const request1 = await axios.put(
      `${getUrlForUsers()}verifyForgotPasswordOtp`,
      {
        code: otp,
        token: numberToken,
      }
    );

    if (request1.data.statusCode === 200) {
      navigation("/ForgotPassword/Reset", {
        state: { verified: true, token: request1.data.data.token },
      });
    } else {
      cogoToast.error(request1.data.message);
      setSubmitting(false);
      setError(true);
      setLoading(false);
      return;
    }
  };

  if (!location.state) {
    navigation("/");
  }

  return (
    <LoadingBackdrop loading={loading}>
      <Logo className={"md:mx-4"} />
      <Box className={"grid grid-flow-row-dense place-items-center"}>
        <h4
          className={"text-4xl sm:text-2xl md:text-2xl pb-3 md:pb-1 font-bold"}
          style={{ color: "#481B3A" }}
        >
          Verify Phone Number
        </h4>
        <Box className={"text-center"}>
          <p className={"sm:text-sm md:text-sm"}>
            Please enter the 4 digit code sent to <br />
            <span style={{ color: "#FF5733" }}>
              {location?.state?.formattedPhoneNumber}
            </span>{" "}
            through SMS
          </p>
        </Box>
        {error && (
          <Typography
            sx={{ fontSize: isMobile ? 14 : isTablet ? 13 : 17, mt: "5%" }}
            color={"red"}
          >
            Incorrect Code, Please try again.
          </Typography>
        )}
        <OtpInput
          containerStyle={{
            marginTop: !error ? "5%" : "2.5%",
            marginBottom: "2.5%",
            marginRight: isMobile ? 0 : isTablet ? 0 : 25,
            marginLeft: isMobile ? 0 : isTablet ? 0 : 25,
          }}
          inputStyle={{
            width: isMobile ? 50 : isTablet ? 50 : 75,
            height: isMobile ? 50 : isTablet ? 50 : 75,
            borderRadius: 6.48248,
            borderWidth: 1.2965,
            borderColor: "#BFD1E5",
            borderStyle: "solid",
            fontSize: isMobile ? "1.5em" : isTablet ? "1em" : "2em",
          }}
          errorStyle={{
            borderColor: "red",
          }}
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<Box sx={{ mx: 1.5 }} />}
          hasErrored={error}
          shouldAutoFocus
          isInputNum
        />
        <p
          className={
            "flex flex-row justify-center items-center text-base sm:text-sm md:text-sm"
          }
        >
          Didn't receive a code?{" "}
          <Button
            onClick={handleResendSMS}
            disabled={resendSMSSubmitting}
            variant={"text"}
            sx={{ color: "#9800FF", textTransform: "none" }}
          >
            <p className={"text-base sm:text-sm md:text-sm"}>Resend SMS</p>
          </Button>
        </p>
        <Box className={"-mt-2 mb-4"}>
          <Button
            variant={"text"}
            sx={{ color: "black", textTransform: "none" }}
            onClick={handleWrongNumber}
          >
            <p className={"text-center text-base sm:text-sm md:text-sm text-[#ff5733]"}>
              Wrong Number
            </p>
          </Button>
        </Box>
        <Box className={"place-items-center"}>
          <Button
            variant={"contained"}
            sx={{
              backgroundColor: "#481B3A",
              textTransform: "none",
              borderRadius: 3,
              width: isMobile ? 278 : isTablet ? 278 : 478,
              height: isMobile ? 56 : isTablet ? 56 : 64,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#481B3A",
              },
            }}
            disabled={submitting}
            onClick={handleSubmit}
          >
            <p className={"text-xl sm:text-base md:text-base"}>Verify Number</p>
          </Button>
        </Box>
      </Box>
    </LoadingBackdrop>
  );
}
