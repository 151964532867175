import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";

import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/IconButton";

import Logo from "../../components/Logo";
import { LoadingBackdrop } from "../../layouts";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function ResetPassword({ navigation }) {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  if (!location.state) {
    navigation("/");
  }

  return (
    <LoadingBackdrop loading={loading}>
      <Logo />
      <div className={"container py-4 mx-auto grid place-items-center"}>
        <h1
          className={
            "my-4 font-extrabold text-5xl text-center text-brown-coffee"
          }
        >
          Change Password
        </h1>
      </div>
      <div className={"container py-4 mx-auto grid place-items-center"}>
        <Formik
          initialValues={{
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.password) {
              errors.password = "Required!";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            setLoading(true);

            const request = await axios.put(
              `${getUrlForUsers()}resetForgotPassword`,
              {
                password: values.password,
                token: location.state.token,
              }
            );

            setLoading(false);
            if (request.data.statusCode === 200) {
              cogoToast.success("Password Changed!");
              navigation("/");
            } else {
              cogoToast.error(request.data.message);
            }
          }}
        >
          {({ submitForm }) => (
            <Form className={"grid grid-flow-row gap-6"}>
              <Field
                component={TextField}
                name={"password"}
                type={passwordVisible ? "text" : "password"}
                placeholder={"Set Password"}
                label={"Set Password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <LockIcon style={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      {passwordVisible ? (
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityIcon style={{ color: "black" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityOffIcon style={{ color: "black" }} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                className={"justify-self-center"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                  },
                  minWidth: 460,
                  maxWidth: 460,
                }}
              />
              <Button
                onClick={submitForm}
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                  color: "white",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: 20,
                  borderRadius: 3,
                  height: 56,
                  width: 460,
                }}
              >
                Continue
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </LoadingBackdrop>
  );
}
