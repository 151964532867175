import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Box, Stack, Popper, Fade, Paper, InputAdornment, useMediaQuery, TextField } from "@mui/material";
import SimpleBarReact from "simplebar-react";
import { useTheme } from "@mui/material/styles";

import CategoryBox from "../../components/CategoryBox";

import select_end_adornment from "../../assets/back.png";
import select_start_adornment from "../../assets/selectAdornment.png";
import "simplebar-react/dist/simplebar.min.css";

import { useLocalStorage } from "../../hooks";
import cogoToast from "cogo-toast";
import { getUrlForStage, getUrlForUsers } from "../../utils/confighelpers";


export default function SchoolProfileSelectDropdown(props) {
  const { catsSelected, setLoading } = props;
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [customCatField, setCustomCatField] = React.useState(false);
  const [customCat, setCustomCat] = React.useState("");
  const anchorEl = React.useRef(null);
  const [allCats, setAllCats] = React.useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  React.useEffect(() => {
    (async () => {
      const config = {
        method: "get",
        url: `${getUrlForUsers()}getCategoryGroup`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        }
      };
      const request = await axios(config);
      setAllCats(request.data.data);
    })();
  }, []);

  const handlePopper = () => {
    setPopperOpen(!popperOpen);
  };

  function CategoryList({ data }) {
    const { _id, categories } = data;
    return (
      <Box className={"pl-8 pr-4 sm:pl-4 md:pl-6"}>
        <h6
          className={
            "text-xl sm:text-base md:text-base font-bold block mt-2 mb-1"
          }
          style={{
            color: "#32282F",
          }}
        >
          {_id}
        </h6>
        <Stack direction={"row"} spcacing={2}>
          {categories.map((element) => (
            <CategoryBox
              key={element.catId}
              remove={false}
              add={true}
              addOnClick={() => props.handleAddCategory(element)}
              label={element.catName}
              vector={element.catImage}
              svgFill={
                Array.isArray(catsSelected) &&
                catsSelected.indexOf(element) !== -1
                  ? "#FFFFFF"
                  : "#481B3A"
              }
              bgColor={
                Array.isArray(catsSelected) &&
                catsSelected.indexOf(element) !== -1
                  ? "#481B3A"
                  : "#FFFFFF"
              }
            />
          ))}
        </Stack>
      </Box>
    );
  }

  const handleDidNotFind = () => {
    setPopperOpen(false);
    setCustomCatField(true);
  };

  const submitCustomCat = async () => {
    if(customCat != "") {
      setLoading(true);
      cogoToast.loading("Loading...").then(async () => {
        const body1 = {
          catName: customCat,
          groupName: "",
          catImage: "",
        };
        const config2 = {
          method: "post",
          url: `${getUrlForStage()}users/addCategory`,
          headers: {
            "x-access-token": userToken.toString(),
            "Access-Control-Allow-Headers": "*",
          },
          data: body1,
        };
        const request = await axios(config2);
        if (request.status === 200) {
          console.log(request.data);
          setLoading(false);
          if (request.data.statusCode === 403) {
            // cogoToast.error(request.data.message);
            return;
          }
          setCustomCat("");
          props.handleAddCategory(request.data.data.catDetail);
          cogoToast.success("Success!");
          setPopperOpen(false);
          setCustomCatField(false);
        } else {
          setLoading(false);
          console.log(request);
        }
      });
    }
  };

  const handleKeyPress = (e) => {
    if(e.keyCode === 13){
      // alert(e.target.value);
      e.target.blur(); 
      submitCustomCat();
      //Write you validation logic here
    }
 }

  return (
    <div>
      <button
        ref={anchorEl}
        className={`w-full sm:w-full px-6 sm:px-2 py-3 sm:py-1 border border-neutral-200 rounded-xl abhi ${
          popperOpen
            ? "shadow-sm shadow-neutral-300 rounded-b-none border-b-0"
            : ""
        } ${
          !customCatField
            ? "block"
            : "hidden"
        }`}
        onClick={handlePopper}
      >
        <Box className={"flex flex-row justify-center items-center"}>
          <Box className={"flex flex-row justify-center items-center"}>
            <img
              className={"ml-2 mr-4 "}
              src={select_start_adornment}
              alt={""}
            />
            <p
              className={
                "text-plain-gray font-light text-base sm:text-sm md:text-sm"
              }
            >
              Select your class categories
            </p>
          </Box>
          <img
            className={`${popperOpen ? "transform rotate-180" : ""} ml-auto`}
            src={select_end_adornment}
            alt={""}
          />
        </Box>
      </button>
      <Box className={`flex my-1 ${
          customCatField
            ? "block"
            : "hidden"
        }`}>
        <TextField 
                  className="flex-1"
                  id="outlined-basic" 
          name="customCat"
          variant="standard"
          value={customCat}
          placeholder="Add your custom Category"
          InputProps={{
            startAdornment: <InputAdornment position={"start"}>
            <img src={select_start_adornment} alt={""} />
          </InputAdornment>,
            disableUnderline: true,
          }}
          sx={{
            px: 4,
            py: 1,
            borderRadius: 3,
            border: 1,
            borderColor: "#EBEBEB",
            fontSize: isMobile ? 14 : isTablet ? 13 : 17,
          }}
          onChange={e => setCustomCat(e.target.value)}
          // onBlur={(e) => submitCustomCat()}
          onKeyDown={(e) => handleKeyPress(e)}
        />
        <button type="button" onClick={() => submitCustomCat()} underline="always" className="border-[3px] border-[#EBEBEB] rounded-lg bg-[#FFFFFF] text-[#2D3766] ml-2 px-5">Add</button>
      </Box>
      <Box className="grid my-1">
        <button type="button" onClick={handleDidNotFind} underline="always" className={`text-[#2D3766] justify-self-end ${!customCatField
          ? "block"
          : "hidden"
          }`}>Did not find your categoy?</button>
        <button type="button" onClick={() => {
                  setPopperOpen(true);
                  setCustomCatField(false);
                }} underline="always" className={`text-[#2D3766] justify-self-end ${customCatField
          ? "block"
          : "hidden"
          }`}>Select from the standard Categories</button>
      </Box>
      <Popper
        id={popperOpen ? "transition-popper" : undefined}
        open={popperOpen}
        anchorEl={anchorEl.current}
        placement={"bottom-start"}
        transition
        modifiers={[
          {
            name: "sameWidth",
            enabled: true,
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            phase: "beforeWrite",
            requires: ["computeStyles"],
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={2}
              variant={"elevation"}
              sx={{
                minHeight: "max-content",
                overflowX: "hidden",
              }}
            >
              <SimpleBarReact className={"max-h-96"}>
                {Array.isArray(allCats) &&
                  allCats.length > 0 &&
                  allCats.map((item, key) => (
                    <CategoryList key={key} data={item} />
                  ))}
              </SimpleBarReact>
              <Box className="flex flex-row justify-center items-center my-5">
              <button onClick={handleDidNotFind} underline="always" className="text-[#2D3766]">Did not find your categoy?</button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
