import React from "react";
import { Button, Dialog, IconButton, Stack, Divider, Box } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import tikkieForBusiness from "../../assets/tikkieForBusiness.svg";

export default function SuccessPaymentProfileModal({
  open,
  handleClose,
  handleOk,
  isEditTikkie,
}) {
  const [showBookingLink, setShowBookingLink] = React.useState(false);
  return (
    <Dialog
      open={open}
      scroll={"body"}
      PaperProps={{
        sx: {
          borderRadius: 5,
          minWidth: 500,
          maxWidth: 500,
          overflowX: "hidden",
          backgroundColor: "primary.main",
        },
      }}
    >
      <Stack direction={"column"} spacing={6} sx={{ pt: 4, pb: 8, px: 8 }}>
        <Stack direction={"column"} spacing={2}>
          <div className={"flex flex-row items-center justify-between"}>
            <h3 className={"font-bold text-2xl text-white"}>Tikkie for Business</h3>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon sx={{ color: "#FFF" }} />
            </IconButton>
          </div>
          <Divider variant={"middle"} sx={{ color: "primary.light" }} />
        </Stack>
        <Stack direction={"column"} justifyContent={"center"} >
          <img src={tikkieForBusiness} alt={""} />
          <h3 className={"font-bold text-lg text-center text-white my-10"}>Tikke Account {isEditTikkie ? "Updated" : "Added"} </h3>
          <p className={"font-medium text-base text-center text-[#FFFFFFB2]"}>
            Well Done!<br></br>
            Your customers will now be able to pay you  directly to your account with 0% commission.
          </p>
          <Box className="flex justify-center items-center mt-5">
            <Button
              sx={{
                color: "primary.main",
                backgroundColor: "common.white",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "common.white",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                width: 131,
                height: 52,
                fontSize: 16,
              }}
              variant={"contained"}
              onClick={() => {
                handleOk();
              }}
            >
              OK
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Dialog>
  );
}
