import React from "react";
import axios from "axios";
import { Dialog, Slide, Button, Divider, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import cogoToast from "cogo-toast";

import { useLocalStorage } from "../hooks";

import booking_svg from "../assets/bookingLink.svg";
import { getUrlForBookings } from "../utils/confighelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function SuccessParticipantModal({
  open,
  handleClose,
  fullName,
  phoneNumber,
  bookingId,
  handleUpdate,
}) {
  const [userToken] = useLocalStorage("userToken");

  const onClickSendTikkie = async (number) => {
    cogoToast.loading("Generating Link");
    const request = await axios.post(
      `${getUrlForBookings()}payments/generateTikkiePmtLink`,
      { bookingId, forceSendLink: true, bookingFor: "Tikkie", },
      { headers: { "x-access-token": userToken } }
    );

    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        const numberCopy = number.replace(/[^\w\s]/gi, "").replace(/ /g, "");
        let url = `https://web.whatsapp.com/send?phone=${numberCopy}`;
        const message = `Hi! Here's the tikkie link for the payment of my session.\n${request.data.generatedUrl}`;
        url += `&text=${encodeURI(message)}&app_absent=0`;
        window.open(url);
      } else {
        cogoToast.error(request.data.message);
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 400,
          maxWidth: 400,
          backgroundColor: "#481B3A",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className={"flex flex-col justify-center content-center gap-6"}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"font-bold text-2xl text-white"}>Add New</h3>
            <IconButton onClick={handleClose}>
              <Close sx={{ color: "#FFF" }} />
            </IconButton>
          </div>
          <Divider />
        </div>
        <img src={booking_svg} alt={""} />
        <p className={"font-medium text-base text-white text-center"}>
          {fullName} added to the Session
        </p>
        <Button
          variant={"contained"}
          color={"secondary"}
          sx={{
            textTransform: "none",
            borderRadius: 4,
            height: 58,
            fontWeight: 600,
            fontSize: 16,
            color: "#FFF",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
          onClick={() => onClickSendTikkie(phoneNumber)}
        >
          Send Tikkie Request
        </Button>
        <p
          className={
            "font-medium text-sm text-white text-center cursor-pointer"
          }
          onClick={() => {
            handleClose();
            handleUpdate();
          }}
        >
          Skip for now
        </p>
      </div>
    </Dialog>
  );
}
