import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function CancelSessionModal({
  open,
  text,
  handleClose,
  handleDelete,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      <DialogTitle>Cancel Session</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={handleDelete}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
