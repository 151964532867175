import axios from "axios";
import React, { useCallback } from "react";

import {
  Box, MenuItem, Select, Table, TableBody, TableContainer, TableHead, useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../hooks";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../redux/reducers/dashboardReducer";
import { clearSchoolData, schoolState } from "../../redux/reducers/schoolReducer";
import * as userActions from "../../redux/reducers/userReducer";
import { userState } from "../../redux/reducers/userReducer";

import { LoadingBackdrop } from "../../layouts";

import StatBox from "../../components/StatBox";

import profile_img from "../../assets/avatar_placeholder.png";
import clock_png from "../../assets/Clock.png";
import filter_img from "../../assets/Filter.png";
import favourite_png from "../../assets/Lesson.png";
import NeewSidebar from "../../components/NewSideBar";
import UpcomingEventActivites from "../businessProfile/UpcomingEventActivites";
import SessionBookingPopover from "../businessProfile/view/SessionBookingPopover";
import RecentBooking from "../venueAndServices/services/booking/RecentBooking";
import UpcomingServicesActivites from "../venueAndServices/services/UpcomingServicesActivites";
import UpcomingClassActivites from "../classes/UpcomingClassActivites";
import RecentClassBooking from "../classes/booking/RecentClassBooking";
import cogoToast from "cogo-toast";
import { getUrlForBookings, getUrlForClasses, getUrlForEvent, getUrlForFoods, getUrlForServices, getUrlForStage, getUrlForUsers } from "../../utils/confighelpers";
import { cutString } from "../../helpers";


export default function Home({ navigation }) {
  const [userToken, setUserToken] = useLocalStorage("userToken");
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReduxState = useSelector(userState);
  const instance = axios.create();
  const [listLoading, setListLoading] = React.useState(false);
  const [bookingFilter, setBookingFilter] = React.useState('all');
  const [mgBoxEnlarged, setMgBoxEnlarged] = React.useState(false);
  const [selectedMgBox, setSelectedMgBox] = React.useState(false);
  const [recentBookingsList, setRecentBookingsList] = React.useState([]);
  const [showViewMore, setShowViewMore] = React.useState(false);
  const [recentBookingsPageNumber, setRecentBookingsPageNumber] = React.useState(1);
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [dashboardStatistics, setDashboardStatistics] = React.useState([
    {
      title: "Favourites",
      data: 0,
      bgColor: "bg-light-brown-coffee",
      icon: favourite_png,
      // update: "+3 this month",
      update: "",
    },
    {
      title: "Bookings",
      data: 0,
      bgColor: "bg-portland-orange",
      icon: clock_png,
      update: "",
    },
    {
      title: "Revenue",
      data: 0,
      bgColor: "bg-lilac",
      icon: clock_png,
      update: "",
    },
  ]);
  const [backgroundForHome, setBackgroundForHome] = React.useState(false);

  const getUserInfo = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config).catch(async (e) => {
      cogoToast.error("Session Has Expired").then(async () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("persist:root");
        dispatch(userActions.clearUserData());
        dispatch(clearSchoolData());
        dispatch(dashboardActions.clearDashboardData());
        await setUserToken("");
        window.location.href = "/";
      });
    });
    if (request.data.statusCode === 200) {
      console.log("data", request.data.data);
      const userData = request.data.data;

      // setProfileDetails((prevValues) => ({
      //   ...prevValues,
      //   google_calendar_details: request.data.data?.google_calendar_details || {},
      // }));
      dispatch(userActions.setUserData(userData));
      // if (values) {
      //   dispatch(
      //     userActions.setUserPhone(values.formattedPhoneNumber)
      //   );
      // }
      if (userData.onBoardingStep === 0) {
        // no need to handel this otp number screen becouse not registerd yet 
        // if (userData.socialType == "normal" && userData.isVerified == false) {
        //   await handleResendSMS(userData, userData.socialType, userData.countryCode, userData.phoneNumber)
        // }
        if (userData.isVerified == true) {
          delete userData._id;
          navigation("/businesstype", {
            state: {
              // ...location.state,
              ...userData,
              name: userData.fullName,
              // socialType: userData.socialType,
              // countryCode: userData.countryCode,
              // phoneNumber: userData.number,
              sentFromSignupComplete: true,
              from: "list",
            },
          })
        } else {
          await Logout("");
          // navigation("/");
        }
      } else if (userData.onBoardingStep === 1) {
        setBackgroundForHome(false)
        navigation("/businessprofilesetup", {
          state: {
            ...userData,
            sentFromSignupComplete: true,
            name: userData.fullName,
            // socialType: location.state.socialType,
            // username: location.state.username,
          },
        })
      } else if (userData.onBoardingStep === 2) {
        // let nextUrl = "/businessprofilesetup/subscription"
        let nextUrl = "/businessprofilesetup/done"
        // if (userData?.businessType === "service" && Object.keys(userData?.openDayAndTime).length === 0) {
        if (userData?.businessType === "service" && userData?.myResource.length === 0) {
          nextUrl = "/myresource/add"
        }
        navigation(nextUrl, {
          state: {
            ...userData,
            from: "registration",
            sentFromVerifyEmail: true,
            address: {}
          },
        })
      } else if (userData.onBoardingStep === 3) {
        if (userData?.businessType === "service" && userData?.myResource.length === 0) {
          navigation("/myresource/add", {
            state: {
              from: "registration",
              sentFromVerifyEmail: true,
              address: userData?.myBusinessProfile?.address || {}
            },
          })
        }
        setBackgroundForHome(true)
        if (request.data.data?.businessType) {
          console.log("userData?.businessType", userData?.businessType);
          console.log("userReduxState?.businessType", userReduxState?.businessType);
          console.log("=", request.data.data?.businessType !== userReduxState.businessType);
          if (request.data.data?.businessType !== userReduxState.businessType) {
            await dispatch(userActions.setBusinessType(request.data.data?.businessType));
            // window.location.reload();
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          }
        } else {
          await dispatch(userActions.setBusinessType("event"));
          // await dispatch(userActions.setBusinessType(""));
          await updateUserProfile()
        }
        // dispatch(
        //   schoolActions.setId(request.data.data.schoolObj.id)
        // );
        // dispatch(
        //   schoolActions.setName(
        //     request.data.data.schoolObj.schoolName
        //   )
        // );
        // dispatch(
        //   schoolActions.setLogo(request.data.data.schoolObj.logo)
        // );
        // dispatch(
        //   schoolActions.setCategories(
        //     request.data.data.seletedCategories
        //   )
        // );
        // setSubmitting(false);
        // dispatch(dashboardActions.setCalendarOpen(false));
        // navigation("/home/dashboard");
      }
    }
    setLoading(false);
  }
  
  const handleResendSMS = (userData, socialType, countryCode, number) => {
    if (socialType == "normal") {
      cogoToast.loading("Loading...").then(async () => {
        let config2 = {
          method: "post",
          url: `${getUrlForUsers()}registration`,
          data: {
            countryCode: countryCode,
            phoneNumber: number,
            socialType: "normal",
            userType: "teacher",
          },
        };
        const request = await axios(config2);
        if (request.status === 200) {
          if (request.data.statusCode === 500) {
            cogoToast.error(request.data.message);
            // setResendSMSSubmitting(false);
            return;
          }
          navigation("/signup/verifynumber", {
            state: {
              username: userData.username,
              // avatar: values.avatar,
              // countryName: values.countryName,
              avatar: "",
              countryName: "",
              countryCode: userData.countryCode,
              number: userData.phoneNumber,
              phoneNumber: userData.phoneNumber,
              formattedPhoneNumber: userData.countryCode + " " + userData.phoneNumber,
              name: userData.name,
              password: userData.password,
              response: request.data,
              socialType: userData.socialType
            },
          });
          // cogoToast.success("Success!").then(() => {
          //   setResendSMSSubmitting(false);
          //   setNumberToken(request.data.data.token);
          // });
        } else {
          cogoToast.error("Oops, there seems to be a problem...");
          // setResendSMSSubmitting(false);
        }
      });
    }
  };
  
  const Logout = () => {
    cogoToast.loading("Logging out...").then(async () => {
         try {
              await axios.put(
                   `${getUrlForUsers()}logoutUser`,
                   {},
                   {
                        headers: {
                             "Content-Type": "application/json",
                             "x-access-token": userToken,
                        },
                   }
              );
         } catch (err) {
              console.log("error", err);
         } finally {
              localStorage.removeItem("userToken");
              localStorage.removeItem("persist:root");
              dispatch(userActions.clearUserData());
              dispatch(clearSchoolData());
              dispatch(dashboardActions.clearDashboardData());
              await setUserToken("");
              // navigate("/", { replace: true });
              window.location.href = "/";
         }
    });
};

  if ((userToken === undefined || userToken == "")) {
    // navigation("/home/dashboard");
    // window.location.href = "/";
  }
  const fetchData = useCallback(async () => {
    if (isMobile || isTablet) {
      // alert("isMobile");
      Logout("");
      return false;
    }
    getDashboardStatistics();
    getUserInfo();

    // return () => clearInterval(unsubscribe);
  }, [])

  React.useEffect(() => {
console.log("getUrlForEvent", (getUrlForEvent()));
console.log("getUrlForUsers", (getUrlForUsers()));
console.log("getUrlForClasses", (getUrlForClasses()));
console.log("getUrlForServices", (getUrlForServices()));
console.log("getUrlForFoods", (getUrlForFoods()));
console.log("isMobile", (isMobile));
console.log("isTablet", (isTablet));
    fetchData();
  }, []);

  // React.useEffect(() => {

  let totalRequest = 0;
  const interceptor = instance.interceptors.request.use(async function (config) {
    totalRequest++;
    // spinning start to show
    await setLoading(true);
    return config
  }, function (error) {
    totalRequest--;
    return Promise.reject(error);
  });

  instance.interceptors.response.use(async function (response) {
    totalRequest--;
    // spinning hide
    if (totalRequest === 0) {
      await setLoading(false);
      // instance.interceptors.request.clear();
      // instance.interceptors.response.clear();
      // instance.interceptors.request.clear();
      // instance.interceptors.response.clear();
    }

    return response;
  }, function (error) {
    totalRequest--;
    return Promise.reject(error);
  });

  // return () => axios.interceptors.response.eject(interceptor);
  //   return () => true;
  // }, []);

  // React.useEffect(() => {
  //   // This should log every time activeUser changes
  //   console.log({ anchorEl, warningText, openWarningDialog });
  // }, [anchorEl, warningText, openWarningDialog]);

  React.useEffect(() => {
    // This should log every time activeUser changes
    // console.log({ loading });
    return () => true;
  }, [loading]);

  React.useEffect(() => {
    if (userReduxState.businessType === "event") {
      getRecentBookings(recentBookingsPageNumber);
    }
  }, [recentBookingsPageNumber, bookingFilter]);


  const updateUserProfile = async () => {
    const requestConfig = {
      method: "put",
      url: `${getUrlForUsers()}updateProfile`,
      headers: {
        "x-access-token": userToken,
      },
      data: {
        businessType: userReduxState.businessType
      },
    };
    console.log("requestConfig", requestConfig);
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {

      }
    }
  };

  const getDashboardStatistics = async () => {
    const config = {
      method: "get",
      url: `${getUrlForUsers()}statistics`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await instance.request(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        setDashboardStatistics([
          {
            title: "Favourites",
            data: request.data.data.totalFavorite,
            bgColor: "bg-light-brown-coffee",
            icon: favourite_png,
            update: "",
          },
          {
            title: "Bookings",
            data: request.data.data.totalBookings,
            bgColor: "bg-portland-orange",
            icon: clock_png,
            update: "",
          },
          {
            title: "Revenue",
            data: request.data.data.totalRevenue,
            bgColor: "bg-lilac",
            icon: clock_png,
            update: "",
          },
        ]);
      }
    }
  };

  const getRecentBookings = async (page = 1) => {
    setShowViewMore(false);
    setListLoading(true);
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForBookings()}getMybookings?teacherId=${userReduxState.userData?._id}&page=${page}&limit=5&filterBy=${bookingFilter}&listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    // const manageClassesRequest = await instance.request(manageClassesConfig);
    const manageClassesRequest = await axios.request(manageClassesConfig);
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.data.data.length) {
        setShowViewMore(true);
        if (recentBookingsPageNumber > 1) {
          setRecentBookingsList([...recentBookingsList, ...manageClassesRequest.data.data.data]);
        } else {
          setRecentBookingsList(manageClassesRequest.data.data.data);
        }
      }
    }
    setListLoading(false);
  };

  const handleFilterChange = (event) => {
    const { target: { value } } = event;
    setRecentBookingsPageNumber(1)
    setBookingFilter(value);
    setRecentBookingsList([])
  };

  const ticketAmount = (row) => {
    let ticketAmount = 0;
    let serviceFees = 0;
    if (row?.totalStripeAmount) {
      return parseFloat(row?.totalStripeAmount || 0).toFixed(2)
    } else if (row?.amount) {
      return parseFloat(row?.amount || 0).toFixed(2)
    } else {
      if (row.ticketsBought && row.ticketsBought.length > 0) {
        row.ticketsBought.map((data) => {
          serviceFees = serviceFees + (parseFloat(data?.serviceFee ? data?.serviceFee : 0) * parseFloat(data?.bookingQty))
          ticketAmount = (parseFloat(ticketAmount) + parseFloat(data.totalPrice))
        })
        // ticketAmount = parseFloat(ticketAmount + parseFloat(serviceFees))
        ticketAmount = parseFloat(ticketAmount).toFixed(2)
      } else {
        ticketAmount = (row.amount ? row.amount : 0);
      }
      return parseFloat(ticketAmount).toFixed(2);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      fontWeight: "bold",
      border: 0,
      padding: "0px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body} > div`]: {
      // backgroundColor: "rgba(244, 244, 244, 0.4)",
      backgroundColor: "#fff",
      padding: "16px",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 11,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
    },
    [`&:last-child div`]: {
      borderRadius: "0 12px 12px 0",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "0px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));

  if (userToken === undefined || !userToken || userToken.length < 1) {
    // navigate("/", { replace: true });
  }

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          Dashboard
        </h3>
      }
      backgroundColor={backgroundForHome ? "#EBEBEB" : "#fff"}
      showCalendarIcon={false}
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            alignItems: "flex-start",
            bgcolor: backgroundForHome ? "#EBEBEB" : "#fff",
            py: 2,
            px: 3,
          }}
        >
          <div className={`bg-[${backgroundForHome ? "#EBEBEB" : "#fff"}]`}>
            {userReduxState.businessType !== "event" && (<div
              // className={`flex flex-row sm:flex-col gap-4 grid-cols-[395px_395px_395px] ${
              className={`flex flex-row gap-4 grid-cols-[330px_330px_330px] ${mgBoxEnlarged ? "transition-all duration-100 hidden" : ""
                // } sm:gap-2 items-center justify-center`}
                } sm:gap-2`}
            >
              {dashboardStatistics.map((stat, idx) => (
                <StatBox {...stat} key={idx} />
              ))}
            </div>)}
            {(userReduxState.businessType === "event") ? (<UpcomingEventActivites
              navigation={navigation}
              loading={loading}
              setLoading={setLoading}
            />) : (userReduxState.businessType === "service") ? <UpcomingServicesActivites
              navigation={navigation}
              loading={loading}
              setLoading={setLoading}
            /> : (userReduxState.businessType === "class") ? <UpcomingClassActivites
              navigation={navigation}
              loading={loading}
              setLoading={setLoading}
            /> : ("")}
            {(userReduxState.businessType === "event") ? (<div
              className={`max-w-[1221px] min-h-[500px] px-5 py-2 pb-10 bg-white rounded-2xl mt-16`}
            >
              <div className={"flex flex-row justify-between items-center my-4"}>
                <div className={"flex flex-col"} style={{ flex: 1 }}>
                  <div className="flex justify-between">
                    <h3
                      className={
                        "text-black text-lg"
                      }
                    >
                      Recent Bookings
                    </h3>
                    <Select
                      disableunderline={true}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            border: 0,
                          },
                        },
                      }}
                      sx={{
                        mr: 2,
                        '.MuiOutlinedInput-input': {
                          borderWidth: 0,
                          p: "0 !important",
                        },
                        '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderWidth: 0,
                        },
                      }}
                      IconComponent={null}
                      renderValue={(value) => (
                        <div className={"flex flex-row items-center gap-3"}>
                          {value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                            return letter.toUpperCase();
                          }) || ""}
                          <img class="w-4" src={filter_img} alt={""} />
                        </div>
                      )}
                      value={bookingFilter}
                      onChange={handleFilterChange}
                    >
                      <MenuItem value={'all'}>All</MenuItem>
                      <MenuItem value={'confirmed'}>Confirmed</MenuItem>
                      <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                      <MenuItem value={'refunded'}>Refunded</MenuItem>
                    </Select>
                  </div>
                  <p className="text-[10px] text-[#B5B5C3]"></p>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">CUSTOMER</StyledTableCell>
                      <StyledTableCell align="left">Booking ID</StyledTableCell>
                      <StyledTableCell align="left">ACTIVITY BOOKED</StyledTableCell>
                      <StyledTableCell align="left">SEATS BOOKED</StyledTableCell>
                      <StyledTableCell align="left">AMOUNT PAID</StyledTableCell>
                      <StyledTableCell width="10%" align="center"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listLoading ? (
                            <StyledTableRow key={0} >
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                                        <p>Loading...</p>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ) : (recentBookingsList.length ? recentBookingsList.map((row, i) => (
                      <StyledTableRow key={i} >
                        <StyledTableCell align="left">
                          <Box>
                            <Box className="flex justify-center items-center w-4/5">
                              <Box className="flex-none">
                                {row?.student_detail?.profilePic ? (<img
                                  className="w-[33px] h-[33px] rounded-full mr-3"
                                  src={row.student_detail.profilePic}
                                  alt={``}
                                />) : (<img
                                  className="w-[33px] h-[33px] rounded-full mr-3"
                                  src={profile_img}
                                  alt={``}
                                />)}
                              </Box>
                              <Box className=" p-0 w-28">
                                <div>{cutString(row.studentName, 10, true)}</div>
                                <div className="text-[#7E00DD]">{cutString((row.student_detail?.countryCode ? row.student_detail.countryCode + "_" + row.student_detail.phoneNumber : "" + row?.student_detail?.username), 10, true)}</div>
                              </Box>
                            </Box>

                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            {row.bookingId}
                            <p><span className={`${(row.status === "Cancelled") ? "text-[#FF5733]" : "text-[#468C11]"}`}>{row.status}</span> | {format(parseISO(row.bookingDate), "MMM d")}</p>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            {row?.eventName || row?.event_schedule_detail?.eventName || ""}
                            <p>{row.event_schedule_detail && row.event_schedule_detail.eventDate ? format(parseISO(row.event_schedule_detail.eventDate), "MMM d") : ""}</p>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            {row.bookingQty ? row.bookingQty : "1"}
                            <p title={row?.ticketsBought ? row.ticketsBought.map((ticket, i) => {
                              return ticket.bookingQty + " x " + ticket.ticketType + " "
                            }).join(" | ") : ""}>
                              {cutString(row?.ticketsBought ? row.ticketsBought.map((ticket, i) => {
                                return ticket.bookingQty + " x " + ticket.ticketType + " "
                              }).join(" | ") : "", 25, true)}

                            </p>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            €{ticketAmount(row)}
                            <p>{(row.paymentStatus.toLowerCase() === "paid") ? "Paid Online" : row.paymentStatus}</p>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            <SessionBookingPopover
                              bookingDetails={row}
                              handleUpdate={() => {
                                getRecentBookings()
                              }}
                              setLoading={setLoading}
                            />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    )) : (
                      <StyledTableRow key={0} >
                        <StyledTableCell align="center" colSpan={6}>
                          <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                            <p>There is no {bookingFilter !== "all" ? bookingFilter + " " : ""}recent bookings</p>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {showViewMore ? (<div className={"flex flex-row justify-center my-4"}>
                <button
                  className={
                    "text-white text-sm cursor-pointer bg-[#3699FF] rounded-lg p-2 px-5"
                  }
                  onClick={() => {
                    setRecentBookingsPageNumber(recentBookingsPageNumber + 1);
                  }}
                >
                  View More
                </button>
              </div>) : ""}
            </div>) : (userReduxState.businessType === "service") ? (
              <RecentBooking
                navigation={navigation}
                loading={setLoading}
                setLoading={setLoading}
              />
            ) : (userReduxState.businessType === "class") ? (
              <RecentClassBooking
                navigation={navigation}
                loading={setLoading}
                setLoading={setLoading}
              />
            ) : ""}
          </div>
        </Box>
      </LoadingBackdrop>} />
  );
}
