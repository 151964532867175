import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import NeewSidebar from "../../../components/NewSideBar";
import { useLocalStorage } from "../../../hooks";
import {
  LoadingBackdrop
} from "../../../layouts";
import ChangePassword from "./ChangePassword";
import ProfileForm from "./ProfileForm";

export default function EditMyProfile() {
  const [userToken] = useLocalStorage("userToken");
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [showChangePassword, setShowChangePassword] = React.useState(false);

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          Edit Profile
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
            minHeight: "100vh",
          }}
          className="grid"
        >
          <div className={"flex flex-col justify-start items-center mt-20"}>
            <div className={"min-w-3xl"}>
              {showChangePassword ? (
                <ChangePassword
                  setLoading={setLoading}
                  onCancel={() => {
                    setShowChangePassword(false);
                  }}
                />) : (<ProfileForm
                  setLoading={setLoading}
                  setShowChangePassword={setShowChangePassword}
                />)}
            </div>
          </div>
        </Box>
      </LoadingBackdrop>} />
  );
}
