import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cogoToast from "cogo-toast";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import NeewSidebar from "../../../components/NewSideBar";
import { useLocalStorage } from "../../../hooks";
import {
  LoadingBackdrop
} from "../../../layouts";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { schoolState } from "../../../redux/reducers/schoolReducer";

import axios from "axios";
import moment from "moment";
import { userState } from "../../../redux/reducers/userReducer";
import MyAvailableTimeForm, { openDayAndTimeObject } from "./MyAvailableTimeForm";
import { getUrlForUsers } from "../../../utils/confighelpers";

export default function MyAvailableTime({ navigation }) {
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");

  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const schoolInfo = dashboardReduxState.schoolData;
  const profileInfo = {
    validityStartDate: moment().format("YYYY-MM-DD"),
    validityEndDate: moment().add(30, 'days').format("YYYY-MM-DD"),
    scheduleWithoutValidity: false,
    openDayAndTime: []
  }
  // console.log("schoolInfo", schoolInfo);

  const [loadingDone, setLoadingDone] = React.useState(false);
  const [isThereChange, setIsThereChange] = React.useState(true);
  const [profileDetails, setProfileDetails] = useState({
    validityStartDate: moment().format("YYYY-MM-DD"),
    validityEndDate: moment().add(30, 'days').format("YYYY-MM-DD"),
    scheduleWithoutValidity: false,
    openDayAndTime: []
  });
  const formik = useFormik({
    initialValues: {
      // ...profileDetails
    },
    validate: (values) => {
      const errors = {};
      // if (!values.schoolName) {
      //   errors.schoolName = "Required!";
      // }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      cogoToast.loading("Loading...").then(async () => {
        setLoading(true);
        console.log("profileDetails", profileDetails);
        const profileUpdateBody = {
          validityStartDate: moment(profileDetails?.validityStartDate).format("YYYY-MM-DD"),
          validityEndDate: moment(profileDetails?.validityEndDate).format("YYYY-MM-DD"),
          scheduleWithoutValidity: profileDetails.scheduleWithoutValidity,
          openDayAndTime: profileDetails.openDayAndTime,
        };
        const profileUpdateConfig = {
          method: "put",
          url: `${getUrlForUsers()}updateProfile`,
          headers: {
            "x-access-token": userToken,
          },
          data: profileUpdateBody,
        };
        console.log("profileUpdateConfig", profileUpdateConfig);
        const profileUpdateRequest = await axios(profileUpdateConfig);
        setLoading(false);
        setIsThereChange(true);
        if (profileUpdateRequest.status === 200) {
          if (profileUpdateRequest.data.statusCode === 200) {
            cogoToast.success("Updated Successfully");
            // navigate("/businessprofile/view");
            return;
          } else {
            setLoading(false);
            cogoToast.error(profileUpdateRequest.data.message);
            console.log(profileUpdateRequest);
            return;
          }
        } else {
          setLoading(false);
          console.log(
            "ERROR SUBMIT FORM, SCHOOL UPDATE:",
            profileUpdateRequest.data
          );
        }
      });
    },
  });

  const getUserInfo = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      console.log("data", request.data.data);
      setProfileDetails((prevValues) => ({
        ...prevValues,
        validityStartDate: moment(request.data.data.validityStartDate).format("YYYY-MM-DD"),
        validityEndDate: moment(request.data.data.validityEndDate).format("YYYY-MM-DD"),
        scheduleWithoutValidity: request.data.data.scheduleWithoutValidity,
        ...(request.data.data?.openDayAndTime.length ? { openDayAndTime: request.data.data.openDayAndTime } : { openDayAndTime: openDayAndTimeObject })
      }));
      setLoadingDone(true);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getUserInfo();
    formik.setValues({
      ...profileDetails,
    });
    setTimeout(() => {
      setIsThereChange(true)
    }, 1 * 1000);
  }, [])

  React.useEffect(() => {
    setIsThereChange(false)
  }, [formik.values])
  React.useEffect(() => {
    formik.setValues(profileDetails);
  }, [profileDetails])

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          My Available Time
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box sx={{ py: 5 }}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Box className={"flex flex-col px-60"}>
              <MyAvailableTimeForm
                profileDetails={profileDetails}
                setProfileDetails={setProfileDetails}
                reloadData={loadingDone}
              />
            </Box>
            <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ my: 5 }}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  border: 2,
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  navigate("/home/dashboard");
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "primary.main",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                disabled={
                  (!(formik.isValid && formik.dirty) && !formik.isSubmitting) || isThereChange
                }
                onClick={formik.submitForm}
              >
                Update
              </Button>
            </Stack>
          </form>

        </ Box>
      </LoadingBackdrop>} />
  );
}
