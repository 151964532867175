import React from "react";
import axios from "axios";
import {
  Dialog,
  Slide,
  Divider,
  IconButton,
  FormControl,
  FormHelperText,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import cogoToast from "cogo-toast";

import { useLocalStorage } from "../hooks";

import marker from "../assets/Location.png";
import { getUrlForClasses, getUrlForEvent, getUrlForUsers } from "../utils/confighelpers";
import { useSelector } from "react-redux";
import { userState } from "../redux/reducers/userReducer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function ChangeLocationModal({
  open,
  handleClose,
  sessionData,
  instanceId,
  handleUpdate,
}) {
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const [userLocations, setUserLocations] = React.useState([]);
  const [sessionDetails, setSessionDetails] = React.useState(null);
  // const [referenceElement, setReferenceElement] = React.useState();
  // const [popperElement, setPopperElement] = React.useState();
  // const { styles, attributes } = usePopper(referenceElement, popperElement);

  React.useEffect(() => {
    (async () => {
      if (open) {
        const locsConfig = {
          method: "get",
          url: `${getUrlForUsers()}listOfLocation`,
          headers: {
            "x-access-token": userToken,
          },
        };
        const requestLocs = await axios(locsConfig);
        setUserLocations([...requestLocs.data.data]);
        console.log("sessionData", sessionData);
        const sessionDetailsConfig = {
          method: "get",
          url:userReduxState.businessType === 'event' ? `${getUrlForEvent()}schedule/get?eventScheduleId=${sessionData._id}`: 
          `${getUrlForClasses()}changeScheduleDetail?reqId=${sessionData._id}&type=${sessionData.isPackage ? "package" : "instance"
            }&instanceId=${instanceId}`,
          headers: {
            "x-access-token": userToken,
          },
        };
        const sessionDetailsRequest = await axios(sessionDetailsConfig);
        setSessionDetails(sessionDetailsRequest.data.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 500,
          maxWidth: 500,
          border: 1,
          borderColor: "#EBEBEB",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className={"flex flex-col gap-6"}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"font-bold text-2xl text-brown-coffee"}>
              Update Location
            </h3>
            <IconButton onClick={handleClose}>
              <Close color={"primary"} />
            </IconButton>
          </div>
          <Divider />
        </div>
        <Formik
          initialValues={{
            location: sessionData.location,
          }}
          validate={(values) => {
            const errors = {};
            if (!Object.keys(values.location).length) {
              errors.location = "Required!";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            cogoToast.loading("Loading...");
            console.log("sessionDetails", sessionDetails);
            console.log("userReduxState.businessType", userReduxState.businessType);
            const body = {              
              ...userReduxState.businessType === 'event' ? { eventScheduleId: sessionDetails._id } : { scheduleId: sessionDetails._id, isPackage: sessionData.isPackage },
              location: {
                locationId: values.location._id,
                address: values.location.address,
                addressName: values.location.addressName,
                houseNumber: values.location.houseNumber,
                city: values.location.city,
                zip: values.location.zip ? values.location.zip : "",
              },
            };
            const config = {
              method: "put",
              url: userReduxState.businessType === 'event' ? `${getUrlForEvent()}schedule/update` : `${getUrlForClasses()}updateScheduleTheClass`,
              headers: {
                "x-access-token": userToken,
              },
              data: body,
            };
            const request = await axios(config);
            if (request.status === 200) {
              if (request.data.statusCode === 200) {
                cogoToast.success("Success!");
                setSubmitting(false);
                handleClose();
                handleUpdate();
              } else {
                setSubmitting(false);
                cogoToast.error(request.data.message);
              }
            }
          }}
        >
          {({ values, errors, setFieldValue, submitForm, isSubmitting }) => (
            <Form>
              <FormControl error={errors["location"] ? true : false}>
                <Select
                  disableUnderline
                  MenuProps={{
                    sx: {
                      "& .MuiMenu-paper": {
                        border: 1,
                        borderColor: "#424242",
                        borderRadius: 3,
                      },
                    },
                  }}
                  renderValue={(value) => (
                    <div className={"flex flex-row items-center gap-3"}>
                      <img src={marker} alt={""} />
                      {value.addressName || (
                        <span className={"font-light text-plain-gray"}>
                          Select Location
                        </span>
                      )}
                    </div>
                  )}
                  value={values.location}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    console.log("newValue", newValue);
                    setFieldValue("location", newValue);
                  }}
                  sx={{
                    mb: 2,
                    borderRadius: 3,
                    minWidth: 420,
                  }}
                >
                  {userLocations.map((loc, idx) => (
                    <MenuItem value={loc} key={idx}>
                      {loc.addressName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.location && (
                  <FormHelperText sx={{ mt: -3 }}>
                    {errors.location}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                variant={"contained"}
                color={"primary"}
                sx={{
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                  width: 420,
                  height: 58,
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "common.white",
                  borderRadius: 3,
                }}
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}
