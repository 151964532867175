import React from "react";

import bell_png from "../assets/bell.png";

export default function NotificationItem({ displayText, timeElapsed }) {
  return (
    <div className={"flex flex-row items-center gap-2 my-4"}>
      <div className={"w-[67.63px] h-[67.63px] rounded-full grid place-items-center"}>
        <img
          className={"w-[30px] rounded-full object-cover"}
          src={bell_png}
          alt={"Bell"}
        />
      </div>
      <div className={"flex flex-col gap-1"}>
        <p className={"text-light-brown-coffee text-[21.04px]"}>
          {displayText}
        </p>
        <p className={"text-[#676767] font-light"}>{timeElapsed} ago</p>
      </div>
    </div>
  );
}
