import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import AddScheduleStep1 from "./AddScheduleStep1";
import AddScheduleStep2 from "./AddScheduleStep2";

export default function AddScheduleStepper({
  activeStep,
  handleRadioValueChange,
  handleRadioValueChange2,
  handleStartDateChange,
  handleEndDateChange,
  handleStartTimeChange,
  formValues,
  formErrors,
  sessionTimings,
  handleLocationChange,
  handleDaysInWeekChange,
  handleNextStep,
  handlePrevStep,
  handleSubmit,
  cancellationPolicy,
  whatToCarry,
  formResData,
  disabledButton,
  clearFormValues,
  handleClearDaysInWeek,
  handleCalendarDaysChange,
  handleChange,
  setLoading,
}) {
  return activeStep === 0 ? (
    <AnimatePresence>
      <motion.div animate={{ x: 0 }} exit={{ x: -800 }}>
        <AddScheduleStep1
          handleRadioValueChange={handleRadioValueChange}
          handleRadioValueChange2={handleRadioValueChange2}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleStartTimeChange={handleStartTimeChange}
          formValues={formValues}
          formErrors={formErrors}
          sessionTimings={sessionTimings}
          handleLocationChange={handleLocationChange}
          handleDaysInWeekChange={handleDaysInWeekChange}
          handleClearDaysInWeek={handleClearDaysInWeek}
          handleCalendarDaysChange={handleCalendarDaysChange}
          handleNextStep={handleNextStep}
          disabledButton={disabledButton}
          clearFormValues={clearFormValues}
          handleChange={handleChange}
          setLoading={setLoading}
        />
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence>
      <motion.div initial={{ x: 800 }} animate={{ x: 0 }}>
        <AddScheduleStep2
          formValues={formValues}
          formResData={formResData}
          duration={
            formResData.dateTimeText
              ? formResData.dateTimeText
              : {
                  startDate: formResData.classDate,
                  endDate: formResData.classEndDate,
                }
          }
          dates={formValues.radioValue === "One Time" ? false : true}
          description={formResData.About}
          whatToCarry={whatToCarry}
          cancellationPolicy={cancellationPolicy}
          handlePrevStep={handlePrevStep}
          handleSubmit={handleSubmit}
          disableButton={disabledButton}
        />
      </motion.div>
    </AnimatePresence>
  );
}
