import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, Dialog, Divider, IconButton, Stack } from "@mui/material";
import cogoToast from "cogo-toast";
import React from "react";

import booking_svg from "../../../../assets/bookingLink.svg";
import share_img from "../../../../assets/share.svg";
import copy_squares from "../../../../assets/square.on.square.svg";
import success_img from "../../../../assets/success (1) 1.png";

export default function SuccessScheduleModal({
  open,
  handleClose,
  handleAddNew,
  bookingLink,
}) {
  const [showBookingLink, setShowBookingLink] = React.useState(false);
  return (
    <Dialog
      open={open}
      scroll={"body"}
      PaperProps={{
        sx: {
          borderRadius: 5,
          minWidth: 500,
          maxWidth: 500,
          overflowX: "hidden",
          backgroundColor: "primary.main",
        },
      }}
    >
      {!showBookingLink ? (
        <div className={"py-8 px-8 min-h-full"}>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <img src={success_img} alt={""} />
            <h3
              className={"font-bold text-lg text-center text-white w-[150px]"}
            >
              Schedule added successfully
            </h3>
            <Button
              variant={"outlined"}
              sx={{
                width: 366.46,
                height: 52.3,
                borderRadius: 3,
                textTransform: "none",
                fontSize: 18,
                color: "#32282F",
                backgroundColor: "#FFF",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
              onClick={handleAddNew}
            >
              Add another Schedule
            </Button>
            <button
              className={
                "w-[366.46px] h-[52.3px] grid grid-cols-[1fr_3fr_1fr] gap-3 place-items-center rounded-lg bg-portland-orange"
              }
              onClick={() => setShowBookingLink(true)}
            >
              <p className={"col-start-2 text-white text-lg"}>
                Share Booking Link
              </p>
              <img className={"col-start-3"} src={share_img} alt={""} />
            </button>
            <h3
              className={"text-lg text-white font-semibold cursor-pointer"}
              onClick={handleClose}
            >
              Done
            </h3>
          </Stack>
        </div>
      ) : (
        <Stack direction={"column"} spacing={6} sx={{ pt: 4, pb: 8, px: 8 }}>
          <Stack direction={"column"} spacing={2}>
            <div className={"flex flex-row items-center justify-between"}>
              <h3 className={"font-bold text-2xl text-white"}>Booking Link</h3>
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon sx={{ color: "#FFF" }} />
              </IconButton>
            </div>
            <Divider variant={"middle"} sx={{ color: "primary.light" }} />
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <img src={booking_svg} alt={""} />
            <p className={"font-medium text-[16px] text-center text-white"}>
              Booking Link Generated
            </p>
          </Stack>
          <button
            className={
              "min-w-[340px] h-[58px] bg-white rounded-xl flex flex-row items-center gap-2 px-4 py-2"
            }
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(bookingLink);
                cogoToast.success("Copied!");
              } catch (e) {
                cogoToast.error("Failed to copy!");
              }
            }}
          >
            <span className={"grow font-semibold text-brown-coffee"}>
              {bookingLink}
            </span>
            <span className={"flex flex-col items-center"}>
              <img src={copy_squares} alt={""} />
              <p className={"text-[7px] font-semibold text-black"}>Copy</p>
            </span>
          </button>
        </Stack>
      )}
    </Dialog>
  );
}
