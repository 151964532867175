import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, CardContent } from "@mui/material";
import React, { useState } from "react";
import ServiceTile from "./ServiceTile";

export default function ServiceListWithDragAndDrop({
  initialData,
  updateData,
  setLoading,
  handleSessionClick,
  getListOfServices,
}) {
  const [categories, setCategories] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [openAccordions, setOpenAccordions] = useState(true);
  const [accordionStates, setAccordionStates] = useState(
    []
    // initialData.map(() => true) // Default all accordions to open
  );

  React.useEffect(() => {
    setAccordionStates(initialData.map(() => true))
    setCategories(initialData)
  }, [initialData]);

  const handleDragStart = (e, item, type, parentId = null) => {
    setDraggedItem({ item, type, parentId });
    // setOpenAccordions(false);
    // setAccordionStates(categories.map(() => false));
    if (type === "category") {
      setAccordionStates(categories.map(() => false)); // Close all accordions during category drag
    }
  };

  const handleDragEnter = (index) => {
    setDragOverIndex(index);
  };

  const handleDragLeave = (index) => {
    // console.log("handleDragLeave", draggedItem === null);    
    // if (draggedItem === null) {
    //   setTimeout(() => {
    //   }, 1000);
    // }
    // setDragOverIndex(null);
  };

  const handleDrop = (e, targetIndex, targetType, targetParentId = null) => {
    e.preventDefault();
    setDragOverIndex(null);
    if (!draggedItem) return;

    const updatedCategories = [...categories];

    if (draggedItem.type === "category" && targetType === "category") {
      // Handle category reordering
      const draggedIndex = updatedCategories.findIndex((cat) => cat._id === draggedItem.item._id);
      const [movedCategory] = updatedCategories.splice(draggedIndex, 1);
      updatedCategories.splice(targetIndex, 0, movedCategory);
    } else if (draggedItem.type === "service" && targetType === "service") {
      const sourceCategoryIndex = updatedCategories.findIndex((cat) => cat._id === draggedItem.parentId);
      const targetCategoryIndex = updatedCategories.findIndex((cat) => cat._id === targetParentId);

      const sourceServices = [...updatedCategories[sourceCategoryIndex].services];
      const targetServices = [...updatedCategories[targetCategoryIndex].services];

      const draggedServiceIndex = sourceServices.findIndex((srv) => srv._id === draggedItem.item._id);
      const [movedService] = sourceServices.splice(draggedServiceIndex, 1);

      if (sourceCategoryIndex === targetCategoryIndex) {
        // Reorder within the same category
        sourceServices.splice(targetIndex, 0, movedService);
        updatedCategories[sourceCategoryIndex].services = sourceServices;
      } else {
        // Move to a different category
        targetServices.splice(targetIndex, 0, movedService);
        updatedCategories[sourceCategoryIndex].services = sourceServices;
        updatedCategories[targetCategoryIndex].services = targetServices;
      }
    }

    setCategories(updatedCategories);
    updateData(updatedCategories);

    setDraggedItem(null);
    // setOpenAccordions(true);
    setAccordionStates(categories.map(() => true));
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    // setOpenAccordions(true); // Reopen all accordions when drag ends
    setAccordionStates(categories.map(() => false));
  };

  const handleDragOver = (e) => e.preventDefault();

  const toggleAccordion = (index) => {
    setAccordionStates((prev) => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div>
      {categories.map((category, categoryIndex) => (
        <div
          key={category._id}
          draggable
          onDragStart={(e) => handleDragStart(e, category, "category")}
          onDragEnter={() => handleDragEnter(categoryIndex)}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e, categoryIndex, "category")}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          style={{
            borderBottom: dragOverIndex === categoryIndex ? "2px dashed #ccc" : "0px solid #ccc",
            borderLeft: "0px solid #f64e60",
            // borderRadius: 4,
            marginBottom: 10,
            padding: 0,
            // backgroundColor: "#f9f9f9",
            // backgroundColor: "#ccc",
          }}
        >
          <Accordion
            defaultExpanded={true}
            expanded={accordionStates[categoryIndex]}
            className="border-0 !shadow-none" sx={{
              borderRadius: '30px',
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
              onClick={() => toggleAccordion(categoryIndex)}
              className="!m-1" sx={{
                // margin: '10px 0px',
                '& .MuiAccordionSummary-content': {
                  margin: '0px 0px',
                },
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <DragIndicatorIcon style={{ marginRight: 8, cursor: "grab", fill: "#f64e60" }} /> */}
                <p className={
                  "font-bold text-brown-coffee text-[24px] block my-2"
                }>{category.name}</p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="!pt-0 !pl-8">
              <div>
                {category.services.map((service, serviceIndex) => {
                  const modifiedData = {
                    popover: true,
                    item: service,
                  };
                  return (
                    <div
                      key={service._id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, service, "service", category._id)}
                      onDragEnter={() => handleDragEnter(serviceIndex)}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, serviceIndex, "service", category._id)}
                      onDragOver={handleDragOver}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // border: dragOverIndex === serviceIndex ? "2px dashed #000" : "0px solid #ddd",
                        borderLeft: "0px solid #2c1ddd",
                        // borderRadius: 4,
                        marginBottom: 8,
                        padding: 0,
                        // backgroundColor: "#fff",
                      }}
                      className="bg-white hover:bg-[#F4F4F4] -shadow-md border-0 border-bright-gray rounded-2xl"
                    >
                      {/* <DragIndicatorIcon style={{ marginRight: 8, cursor: "grab", fill: "#2c1ddd" }} /> */}
                      <CardContent style={{ padding: 0 }} className="w-full">
                        {/* <Typography>{service.serviceName}</Typography> */}
                        <ServiceTile
                          {...modifiedData}
                          key={service._id}
                          setLoading={setLoading}
                          handleSessionClick={() => {
                            handleSessionClick(service._id, service);
                          }}
                          reload={() => {
                            getListOfServices();
                          }}
                        />
                      </CardContent>
                    </div>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};