import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";

import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FormControl, FormHelperText, Button } from "@mui/material";
import PhoneInput from "react-phone-input-2";

import Logo from "../../components/Logo";
import { LoadingBackdrop } from "../../layouts";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function ForgotPassword({ navigation }) {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);

  return (
    <LoadingBackdrop loading={loading}>
      <Logo />
      <div className={"container py-4 mx-auto grid place-items-center"}>
        <div className={"grid grid-rows-2 gap-1 mb-10"}>
          <h1
            className={
              "my-4 font-extrabold text-5xl text-center text-brown-coffee"
            }
          >
            Recover
          </h1>
          <p className={"text-brown-coffee text-lg text-center w-64"}>
            Enter your phone number associated with your account
          </p>
        </div>
        <Formik
          initialValues={{
            countryName:
              location.state !== null ? location.state.countryName : "nl",
            countryCode:
              location.state !== null ? location.state.countryCode : "",
            phone: location.state !== null ? location.state.number : "",
            formattedPhoneNumber:
              location.state !== null
                ? location.state.formattedPhoneNumber
                : "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.phone) {
              errors.phone = "Required!";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            const request = await axios.post(
              `${getUrlForUsers()}forgotPassword`,
              {
                username: values.countryCode + values.phone,
                userType: "teacher",
              }
            );
            if (request.data.statusCode === 200) {
              setLoading(false);
              setSubmitting(false);
              navigation("/ForgotPassword/Verify", {
                state: {
                  formattedPhoneNumber: values.formattedPhoneNumber,
                  countryName: values.countryName,
                  countryCode: values.countryCode,
                  phone: values.phone,
                  response: request.data,
                },
              });
            } else {
              setLoading(false);
              setSubmitting(false);
              cogoToast.error(request.data.message);
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleBlur,
            submitForm,
            isSubmitting,
          }) => (
            <Form>
              <div className={"grid grid-flow-row gap-4 place-items-stretch"}>
                <FormControl error={errors.phone ? true : false}>
                  <Field
                    component={PhoneInput}
                    placeholder={"Phone Number"}
                    value={values.formattedPhoneNumber}
                    onChange={(phone, country, e) => {
                      const numberWithoutDialCode = phone.split("");
                      for (let i = 0; i < country.dialCode.length; i++) {
                        numberWithoutDialCode.shift();
                      }
                      let dialCode = country.dialCode;
                      if (
                        country.format.includes("+") &&
                        !country.dialCode.includes("+")
                      ) {
                        dialCode = "+".concat(country.dialCode);
                      }
                      setFieldValue("countryName", country.countryCode);
                      setFieldValue("countryCode", dialCode);
                      setFieldValue("phone", numberWithoutDialCode.join(""));
                      setFieldValue("formattedPhoneNumber", e.target.value);
                    }}
                    country={values.countryName}
                    onBlur={() => handleBlur("phone")}
                    disabled={isSubmitting}
                    containerClass={
                      "rounded-md justify-self-center max-w-[460px] min-w-[460px] sm:max-w-[290px] sm:min-w-[290px] md:max-w-[340px] md:min-w-[340px] 3xl:max-w-full 3xl:min-w-full"
                    }
                    inputClass={"md:h-10"}
                    inputStyle={{
                      ...(errors.phone && { borderColor: "red" }),
                      borderRadius: 10,
                      fontSize: 16,
                      minWidth: 460,
                      maxWidth: 460,
                    }}
                  />
                  {errors.phone && (
                    <FormHelperText>{errors.phone}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  onClick={submitForm}
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: 20,
                    borderRadius: 3,
                    height: 56,
                    width: 460,
                  }}
                >
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LoadingBackdrop>
  );
}
