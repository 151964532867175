import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { format, addMinutes, parseISO } from "date-fns";

import { useLocalStorage } from "../../hooks";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { userState } from "../../redux/reducers/userReducer";
import { dashboardState } from "../../redux/reducers/dashboardReducer";
import SessionTile from "../../components/SessionTile";


import { useLocation } from "react-router-dom";
import { getUrlForClasses } from "../../utils/confighelpers";


export default function UpcomingClassActivites({
    navigation,
    loading,
    setLoading
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [userToken] = useLocalStorage("userToken");
    const userReduxState = useSelector(userState);
    const dashboardReduxState = useSelector(dashboardState);

    const [scheduledClasses, setSceduledClasses] = React.useState([]);
    const [classList, setClassList] = React.useState([]);



    const handleSessionClick = async (id, classData) => {
        if (dashboardReduxState.calendarSessionId === id) {
            dispatch(dashboardActions.setCalendarSessionId(-1));
            dispatch(dashboardActions.setCalendarOpen(false));
            navigate("/home/dashboard");
            return;
        }
        cogoToast.loading("Loading session details");
        const isPckg = classData.isPackage;
        const config = {
            method: "get",
            url: `${getUrlForClasses()}getScheduleDetail?scheduleclassId=${id}&isPackage=${isPckg}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const request = await axios(config);
        if (request.status === 200) {
            if (request.data.statusCode === 200) {
                dispatch(dashboardActions.setCalendarClassData(classData));
                dispatch(dashboardActions.setCalendarSessionId(id));
                dispatch(dashboardActions.setCalendarSessionData(request.data.data));
                dispatch(dashboardActions.setViewSessionPage(true));
                // navigate("/dashboard/ViewSessionDetails");
                navigate("/myschedule/detail");
            }
        }
    };



    const upcomingSessionsApi = async () => {
        const scheduledClassesConfig = {
            method: "get",
            url: `${getUrlForClasses()}groupScheduledClass?listFor=${userReduxState.businessType}`,
            headers: {
                "x-access-token": userToken.toString(),
            },
        };
        const scheduledClassesRequest = await axios(scheduledClassesConfig);
        if (scheduledClassesRequest.status === 200) {
            let schedClasses = [];
            for (const item of scheduledClassesRequest.data.data) {
                const dtStart = DateTime.fromISO(item.classDate);
                const dtEnd = format(addMinutes(dtStart.ts, item.duration), "hh:mma");

                let ticketQty = 0;
                let ticketsBooked = 0;

                if (item?.eventPricingList && item.eventPricingList.length > 0) {
                    item.eventPricingList.map((booking) => {
                        ticketQty = parseInt(ticketQty) + parseInt(booking.capacity);
                        ticketsBooked = parseInt(ticketsBooked) + parseInt(booking?.ticketsBooked ? booking.ticketsBooked.ticketsBookedConfirmed : 0);
                    })
                } else {
                    if (item.scheduleClassBookings && item.scheduleClassBookings.length > 0) {
                        ticketQty = item.capacity;
                        item.scheduleClassBookings.map((booking) => {
                            if (booking.status === "Cancelled") {
                                return booking;
                            }
                            if (booking.ticketsBought && booking.ticketsBought.length > 0) {
                                booking.ticketsBought.map((ticket) => {
                                    ticketsBooked = parseInt(parseInt(ticketsBooked) + parseInt(ticket.bookingQty))
                                })
                            } else {
                                ticketsBooked = parseInt(ticketsBooked) + parseInt(booking.capacity ? booking.capacity : 0);
                            }
                        })
                    } else {
                        ticketQty = item.capacity;
                        ticketsBooked = item.participants.length;
                    }
                }

                let newClass = {
                    id: item._id,
                    imgSrc: item.classPhoto,
                    title: item.className,
                    time: `${dtStart.toFormat("dd LLL, ccc, hh':'mma")} - ${dtEnd}`,
                    venue: `${item.location.houseNumber}, ${item.location.city}`,
                    totalSeats: item.capacity,
                    // seatsBooked: item.participants.length,
                    // seatsBookedPerc: (item.participants.length / item.capacity) * 100,
                    seatsBooked: ticketsBooked,
                    seatsBookedPerc: (ticketsBooked / ticketQty) * 100,
                    bookedPeople: item.participants,
                    popover: false,
                    sessionObj: item,
                };
                schedClasses.push(newClass);
            }
            setSceduledClasses([...schedClasses]);
        }
    };
    const getListOfClass = async () => {
        let mgClasses = [];
        const manageClassesConfig = {
            method: "get",
            url: `${getUrlForClasses()}listOfClasses?isPackage=${false}&listFor=${userReduxState.businessType}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const manageClassesRequest = await axios(manageClassesConfig);
        if (manageClassesRequest.status === 200) {
            if (manageClassesRequest.data.data.length) {
                for (const item of manageClassesRequest.data.data) {
                    mgClasses.push({
                        label: item.className,
                        id: item._id,
                        data: item
                    });
                }
                setClassList(mgClasses);
            }
        }
    };

    React.useEffect(() => {
        getListOfClass();
        upcomingSessionsApi();
    }, []);


    return (
        <div
            className={`max-w-[1221px] px-5 py-2 pb-10 bg-white rounded-2xl mt-16 `}
        >
            <div
                className={"flex flex-row justify-between items-center my-4"}
            >
                <h3
                    className={
                        "font-extrabold text-brown-coffee text-lg sm:text-base md:text-base"
                    }
                >
                    Upcoming Activites
                </h3>
                <p
                    className={
                        "font-extrabold text-portland-orange text-sm cursor-pointer"
                    }
                    onClick={(e) => {
                        navigate("/myclasses/myschedule", { replace: true });
                    }}
                >
                    View all
                </p>
            </div>
            {loading ? (
                <div className={"h-28 w-[574px] grid place-items-center"}>
                    <CircularProgress color={"secondary"} />
                </div>
            ) : Array.isArray(scheduledClasses) &&
                scheduledClasses.length > 0 ? (
                <div
                    className={
                        // "grid grid-cols-2 sm:grid-cols-1 sm:place-items-center 3xl:grid-cols-3 gap-4"
                        // "grid grid-cols-[600px_600px] gap-4"
                        "grid grid-cols-2 gap-4"
                    }
                >
                    {scheduledClasses.map((session, i) => (
                        <div
                            key={i}
                            className={"cursor-pointer"}
                            onClick={() =>
                                handleSessionClick(
                                    session.sessionObj.instanceId,
                                    session.sessionObj
                                )
                            }
                        >
                            <SessionTile {...session} key={i} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={`h-48 flex justify-center items-center`}>
                    {classList.length ? (<p className={"text-2xl"}>
                        Your upcoming sessions will appear here
                    </p>) : (
                        <Box className="flex flex-col justify-center items-center w-full">
                            <Box className="text-2xl" sx={{
                                color: "#481B3A",
                                mt: 5,
                            }}>
                                No Upcoming Activites Configured
                            </Box>
                            <Button
                                sx={{
                                    color: "common.white",
                                    backgroundColor: "secondary.main",
                                    boxShadow: "none",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "secondary.main",
                                        boxShadow: "none",
                                    },
                                    fontWeight: 600,
                                    borderRadius: 3,
                                    width: 231,
                                    height: 52,
                                    fontSize: 16,
                                    mt: 2,
                                }}
                                variant={"contained"}
                                onClick={() => {
                                    navigate("/myclasses/new");
                                }}
                            >
                                Add a new Class
                            </Button>
                        </Box>
                    )}
                </div>
            )}
        </div>
    );
}
