import React from "react";
import {
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function MarkAsPaidDialog({ open, handleClose, onSubmit }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          border: 1,
          borderColor: "#EBEBEB",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Payment status cannot be changed back to unpaid. Are you sure you want
          to change status to paid?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={onSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
