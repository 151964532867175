import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Logo from "../../components/Logo";
import Container from "../../components/Container";

import undraw_vector from "../../assets/celebration_re_kc9k 1.png";

export default function SchoolProfileUpComplete({ navigation }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  return (
    <Container>
      <Logo />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className={"-mt-24 sm:-mt-0 md:-mt-16 w-[35%] sm:w-[100%] md:w-[60%]"}
          src={undraw_vector}
          alt={"vector"}
        />
        <h4
          className={
            "text-4xl sm:text-2xl md:text-xl font-bold my-4 sm:my-2 md:my-2 text-center"
          }
          style={{
            color: "#481B3A",
          }}
        >
          Congratulations,
          <br />
          Profile Completed!
        </h4>
        <p
          className={
            "text-center text-2xl sm:text-lg md:text-lg my-4 sm:my-2 md:my-2"
          }
          style={{ color: "#481B3A" }}
        >
          Let's continue to your Dashboard!
        </p>
        <Button
          onClick={() => navigation("/home/dashboard")}
          sx={{
            backgroundColor: "#481B3A",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#481B3A",
            },
            fontWeight: "bold",
            width: isMobile ? 270 : isTablet ? 345 : 470,
            height: isMobile ? 50 : isTablet ? 50 : 65,
            fontSize: isMobile ? 15 : 20,
            borderRadius: 3,
            my: 2,
            color: "white",
          }}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}
