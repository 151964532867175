import { Combobox } from "@headlessui/react";
import { Box, FormHelperText, InputAdornment, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ErrorMessage, Field, FormikProvider } from "formik";
import { InputBase } from "formik-mui";
import React from "react";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { usePopper } from "react-popper";

import { useLocalStorage } from "../../hooks";

import email_img from "../../assets/Email.svg";
import individual_profile from "../../assets/individual_profile.svg";
import institute_img from "../../assets/Institute.svg";
import marker_img from "../../assets/location_marker.svg";
import web_img from "../../assets/Website.svg";
import PhoneInput from "react-phone-input-2";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function BusinessProfileForm(props) {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  // const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = false;
  const isTablet = false;
  const [userToken] = useLocalStorage("userToken", "");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = React.useState("");
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const postAddress = (placeDetails, placeGeocode, coordinates) => {
    // let city = placeGeocode.address_components[placeGeocode.address_components.length - 3].long_name;
    let city = placeDetails.description;
    const body = {
      addressType: "current",
      address: placeDetails.description,
      houseNumber: placeDetails.structured_formatting.main_text,
      city: placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name,
      zip: placeGeocode.address_components[
        placeGeocode.address_components.length - 1
      ].types.find((type) => type.startsWith("postal"))
        ? placeGeocode.address_components[
          placeGeocode.address_components.length - 1
        ].long_name
        : "",
      // addressName: city ? city : "Business Address",
      addressName: props.form.values.instituteName ? props.form.values.instituteName : "Business Address",
      location: {
        type: "Point",
        coordinates,
      },
    };

    const config = {
      method: "post",
      url: `${getUrlForUsers()}addLocation`,
      headers: {
        "x-access-token": userToken.toString(),
        "Access-Control-Allow-Headers": "*",
      },
      data: body,
    };

    props.setPostAddressConfig(config);

  };


  const formInputStyle = {
    my: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
    p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
    borderRadius: 3,
    border: 1,
    borderColor: "#EBEBEB",
    fontSize: isMobile ? 14 : isTablet ? 13 : 17,
    // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
    width: "100%",
    maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <FormikProvider value={props.form}>
          <Box
            className={
              "grid grid-cols-2 grid-flow-col w-full"
            }
            sx={{
              // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              width: "100%",
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
            }}
          >
            <Box
              className=""
              sx={{
                my: 1,
                mr: 1,
                p: 2,
                borderRadius: 3,
                border: 1,
                borderColor: "#EBEBEB",
                fontSize: 17,
                "&:hover, &.Mui-focused": {
                  border: 1,
                  borderColor: "#7E00DD"
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0,
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
                // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
                // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
              }}>
              <Field
                // selected={props.form.values.businessProfileType ==='individual'}
                type="radio"
                form={props.form}
                name={"businessProfileType"}
                value={"individual"}
                onClick={(event) => {
                  props.form.setFieldValue("businessProfileType", "individual")
                }}
                sx={{
                  verticalAlign: 'middle',
                  mr: 1
                }}
                id="idIndividual"
                size={isMobile || isTablet ? "small" : "medium"}
              /> <label htmlFor="idIndividual">Individual</label>
            </Box>
            <Box
              className=""
              sx={{
                my: 1,
                ml: 1,
                p: 2,
                borderRadius: 3,
                border: 1,
                borderColor: "#EBEBEB",
                fontSize: 17,
                "&:hover, &.Mui-focused": {
                  border: 1,
                  borderColor: "#7E00DD"
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0,
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
                // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
                // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
              }}>
              <Field
                type="radio"
                form={props.form}
                name={"businessProfileType"}
                value={"business"}
                onClick={(event) => {
                  props.form.setFieldValue("businessProfileType", "individual")
                }}
                sx={{
                  verticalAlign: 'middle',
                  mr: 1
                }}
                id="idBusiness"
                size={isMobile || isTablet ? "small" : "medium"}
              /> <label htmlFor="idBusiness">Business</label>
            </Box>
          </Box>
          <Field
            component={InputBase}
            form={props.form}
            name={"instituteName"}
            placeholder={props.form.values.businessProfileType === 'individual' ? "Host Name" : "Awesome Event Company"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img className={"w-5"} src={props.form.values.businessProfileType === 'individual' ? individual_profile : institute_img} alt={""} />
              </InputAdornment>
            }
            inputProps={{ maxLength: 30 }}
            sx={formInputStyle}
            size={isMobile || isTablet ? "small" : "medium"}
            className={`${props.formErros?.instituteName && props.form.touched?.instituteName ? "!border-red-500" : ""}`}
          />          
          <FormHelperText className="px-3 !text-red-600">
            <ErrorMessage name="instituteName" />
          </FormHelperText>
          {/* {props.formErros?.instituteName && (!!props.formErros.instituteName && <div>{props.formErros.instituteName}</div>)} */}
          <div
            className={`w-full flex flex-row items-center border border-neutral-200 hover:border-[#7E00DD] rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3 ${props.formErros?.contactAddress && props.form.touched?.contactAddress ? "!border-red-500" : ""}`}
            ref={setReferenceElement}
          >
            <img className={"mr-2"} src={marker_img} alt={""} />
            <Combobox
              as={"div"}
              className={"relative"}
              value={props.contactAddress}
              onChange={async (e) => {
                placesService.getDetails(
                  {
                    placeId: e.place_id,
                  },
                  (placeDetails) => {
                    const lat = placeDetails.geometry.location.lat();
                    const lng = placeDetails.geometry.location.lng();
                    const coordinates = [lat, lng];
                    postAddress(e, placeDetails, coordinates);
                  }
                );
                props.setContactAddress(e);
              }}
            >
              <Combobox.Input
                className={
                  "focus:outline-0 w-[30rem] sm:text-sm md:text-sm"
                }
                placeholder={"Business Address"}
                displayValue={(value) => value && value.description}
                onChange={(e) => {
                  getPlacePredictions({ input: e.target.value });
                  setQuery(e.target.value);
                }}
              />
              <Combobox.Options
                className={
                  "mt-0.5 min-w-[620px] max-h-[200px] overflow-y-auto bg-white z-20 border border-zinc-400 rounded-xl p-3 shadow-lg"
                }
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {!isPlacePredictionsLoading &&
                  placePredictions.map((place, idx) => {
                    return (
                      <Combobox.Option
                        className={
                          "py-1 px-1 cursor-pointer hover:bg-zinc-200 rounded-lg sm:text-sm"
                        }
                        key={idx}
                        value={place}
                      >
                        {place.description}
                      </Combobox.Option>
                    );
                  })}
              </Combobox.Options>
            </Combobox>
          </div>          
          <FormHelperText className="px-3 !text-red-600">
            <ErrorMessage name="contactAddress" />
          </FormHelperText>
          {/* <Field
            component={InputBase}
            form={props.form}
            name={"contactNumber"}
            disabled={props.form.values.socialType === 'normal' ? true : false}
            placeholder={"Contact number"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img src={email_img} alt={""} />
              </InputAdornment>
            }
            sx={formInputStyle}
            size={isMobile || isTablet ? "small" : "medium"}
          /> */}
          <Field
            component={PhoneInput}
            inputProps={{
              name: 'contactNumber',
            }}
            name={"contactNumber"}
            specialLabel={""}
            placeholder={"Phone Number"}
            value={props.form.values.formattedPhoneNumber}
            disabled={props.form.values.socialType === 'normal' ? true : false}
            onChange={(phone, country, e) => {
              const numberWithoutDialCode = phone.split("");
              for (let i = 0; i < country.dialCode.length; i++) {
                numberWithoutDialCode.shift();
              }
              let dialCode = country.dialCode;
              if (
                country.format.includes("+") &&
                !country.dialCode.includes("+")
              ) {
                dialCode = "+".concat(country.dialCode);
              }
              console.log("formattedPhoneNumber", e.target.value);
              props.form.setFieldValue("countryName", country.countryCode);
              props.form.setFieldValue("countryCode", dialCode);
              props.form.setFieldValue("phone", numberWithoutDialCode.join(""));
              props.form.setFieldValue("formattedPhoneNumber", e.target.value);
            }}
            country={props.form.values.countryName}
            sx={formInputStyle}
            inputStyle={{
              color: props.form.values.socialType === 'normal' ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.87)",
              borderRadius: 10,
              // border: 1,
              borderColor: props.formErros?.contactNumber && props.form.touched?.contactNumber ? "rgb(239, 68, 68)" : "#EBEBEB",
              fontSize: isMobile ? 14 : isTablet ? 13 : 17,
              // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
              minWidth: "100%",
              maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
              "&:hover, &.Mui-focused": {
                border: 1,
                borderColor: "#7E00DD"
              },
              '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
                borderColor: 'rgba(0, 0, 0, 0)',
              },
            }}            
            className={`${props.formErros?.contactNumber && props.form.touched?.contactNumber ? "!border-red-500" : ""}`}
          // sx={formInputStyle}
          // size={isMobile || isTablet ? "small" : "medium"}
          />
          <FormHelperText className="px-3 !text-red-600">
            <ErrorMessage name="contactNumber" />
          </FormHelperText>
          <Field
            component={InputBase}
            form={props.form}
            name={"contactEmail"}
            disabled={props.form.values.socialType === 'normal' ? false : true}
            placeholder={"Contact Email"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img src={email_img} alt={""} />
              </InputAdornment>
            }
            sx={formInputStyle}
            size={isMobile || isTablet ? "small" : "medium"}
            className={`${props.formErros?.contactEmail && props.form.touched?.contactEmail ? "!border-red-500" : ""}`}
          />
          <FormHelperText className="px-3 !text-red-600">
            <ErrorMessage name="contactEmail" />
          </FormHelperText>
          <Field
            component={InputBase}
            form={props.form}
            name={"website"}
            placeholder={"Your Website (Optional)"}
            startAdornment={
              <InputAdornment position={"start"}>
                <img src={web_img} alt={""} />
              </InputAdornment>
            }
            sx={formInputStyle}
            size={isMobile || isTablet ? "small" : "medium"}
            className={`${props.formErros?.website && props.form.touched?.website ? "!border-red-500" : ""}`}
          />
          <FormHelperText className="px-3 !text-red-600">
            <ErrorMessage name="website" />
          </FormHelperText>
          {/* <Field
            component={InputBase}
            form={props.form}
            name={"aboutInstitute"}
            multiline
            placeholder={
              "Write a few lines about your self, your classes, experiences your customers will have while attending your classes and courses..."
            }
            sx={{
              ...formInputStyle,
              minHeight: 148,
              maxHeight: 148,
              overflowY: "auto",
              flexWrap: "wrap",
              borderRadius: 7,
            }}
            size={isMobile || isTablet ? "small" : "medium"}
          /> */}
        </FormikProvider>
      </Box>
    </div>
  );
}
