import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from '@react-oauth/google';

import Router from "./Router";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Mulish:200,300,400,500,600,700,800,900"],
      },
    });
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GG_APP_ID || ''}
            >
              <Router />
            </GoogleOAuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
