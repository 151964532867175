import { Box, IconButton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { format, parseISO } from "date-fns";
import React from "react";

import { useLocalStorage } from "../../../hooks";
import { userState } from "../../../redux/reducers/userReducer";

import profile_img from "../../../assets/avatar_placeholder.png";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUrlForBookings, getUrlForClasses } from "../../../utils/confighelpers";
import PurchaseSessionPopover from "./PurchaseSessionPopover";
import { cutString } from "../../../helpers";
// import SessionBookingPopover from "./SessionBookingPopover";


export default function PurchasedSessionPass({
    navigation,
    loading,
    setLoading,
    classInfo,
    selectCustomerBooking,
    selectBuyingDetail,
    setPassPurchaseList
}) {
    const location = useLocation();
    const [userToken] = useLocalStorage("userToken");
    const userReduxState = useSelector(userState);

    const [listLoading, setListLoading] = React.useState(false);
    const [recentBookingsList, setRecentBookingsList] = React.useState([]);
    const [showViewMore, setShowViewMore] = React.useState(false);
    const [recentBookingsPageNumber, setRecentBookingsPageNumber] = React.useState(1);
    const [bookingFilter, setBookingFilter] = React.useState('all');

    const getRecentBookings = async (page = 1) => {
        setShowViewMore(false);
        setListLoading(true);
        const manageClassesConfig = {
            method: "get",
            url: `${getUrlForBookings()}getMyPurchases?page=${page}&filterBy=${bookingFilter}&classId=${classInfo.classId}&businessId=${classInfo.addedByUserId}&passId=${classInfo?.selectedPassDetail?.passId}`,
            headers: {
                // "x-access-token": userToken,
            },
        };
        const manageClassesRequest = await axios(manageClassesConfig);
        if (manageClassesRequest.status === 200) {
            if (manageClassesRequest.data.data.data.length) {
                setPassPurchaseList(manageClassesRequest.data.data)
                setShowViewMore(true);
                if (recentBookingsPageNumber > 1) {
                    setRecentBookingsList([...recentBookingsList, ...manageClassesRequest.data.data.data]);
                } else {
                    setRecentBookingsList(manageClassesRequest.data.data.data);
                }
            }
        }
        setListLoading(false);
    };

    const handleFilterChange = (event) => {
        const { target: { value } } = event;
        setRecentBookingsPageNumber(1)
        setBookingFilter(value);
        setRecentBookingsList([])
    };


    const ticketAmount = (row) => {
        if (row?.amount) {
            return parseFloat(row?.amount || 0).toFixed(2)
        } else {
            return row?.totalStripeAmount > 0 ? parseFloat(parseFloat(row.totalStripeAmount) + parseFloat(row.totalPaymentServiceFee)).toFixed(2) : "0"
        }
    }

    React.useEffect(() => {
        if (classInfo?.classId) {
            getRecentBookings(recentBookingsPageNumber);
        }
    }, [recentBookingsPageNumber, bookingFilter, classInfo]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 16,
            fontWeight: "bold",
            border: 0,
            padding: "0px 16px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "#464E5F",
            fontWeight: "bold",
            border: 0,
        },
        [`&.${tableCellClasses.body} > div`]: {
            // backgroundColor: "rgba(244, 244, 244, 0.4)",
            backgroundColor: "#fff",
            padding: "16px",
        },
        [`&.${tableCellClasses.body} div p`]: {
            fontSize: 11,
            color: "#B5B5C3",
            fontWeight: "500",
        },
        [`&:first-child div`]: {
            borderRadius: "12px 0 0 12px",
        },
        [`&:last-child div`]: {
            borderRadius: "0 12px 12px 0",
        },
        padding: "5px 0px 5px 0px",
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        margin: "0px",
        border: 0,
        [`&:hover td div`]: {
            backgroundColor: "#F4F4F4",
        },
    }));

    return (
        <div
            className={`max-w-[1221px] min-h-[500px] px-5 py-2 pb-10 bg-white rounded-2xl mt-10`}
        >
            <div className={"flex flex-row justify-between items-center my-4"}>
                <div className={"flex flex-col"} style={{ flex: 1 }}>
                    <div className="flex justify-between">
                        <h3
                            className={
                                "text-black text-lg"
                            }
                        >
                            Purchase Details
                        </h3>
                    </div>
                    <p className="text-[10px] text-[#B5B5C3]"></p>
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">CUSTOMER</StyledTableCell>
                            <StyledTableCell align="left">REFERENCE ID</StyledTableCell>
                            <StyledTableCell align="left">BOOKING CODE</StyledTableCell>
                            <StyledTableCell align="left">AMOUNT PAID</StyledTableCell>
                            <StyledTableCell align="left">SESSIONS BALANCE</StyledTableCell>
                            <StyledTableCell width="10%" align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listLoading ? (
                            <StyledTableRow key={0} >
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                                        <p>Loading...</p>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ) : (recentBookingsList.length ? recentBookingsList.map((row, i) => (
                            <StyledTableRow key={i} >
                                <StyledTableCell align="left">
                                    <Box>
                                        <Box className="flex justify-center items-center w-4/5">
                                            <Box className="flex-none">
                                                {row?.consumer_detail?.profilePic ? (<img
                                                    className="w-[33px] h-[33px] rounded-full mr-3"
                                                    src={row.consumer_detail.profilePic}
                                                    alt={``}
                                                />) : (<img
                                                    className="w-[33px] h-[33px] rounded-full mr-3"
                                                    src={profile_img}
                                                    alt={``}
                                                />)}
                                            </Box>
                                            <Box className=" p-0 w-28">
                                                <div>{cutString(row?.consumer_detail?.fullName, 10, true)}</div>
                                                <div className="text-[#7E00DD]">{cutString((row.consumer_detail?.countryCode ? row.consumer_detail.countryCode + "_" + row.consumer_detail.phoneNumber : "" + row?.consumer_detail?.username), 10, true)}</div>
                                            </Box>
                                        </Box>

                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        {row.purchaseId}
                                        <p><span className={`${(row.status === "Cancelled") ? "text-[#FF5733]" : "text-[#468C11]"}`}>{row.status}</span> | {format(parseISO(row.purchaseDate), "MMM d")}</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        {row.passVoucherCode}
                                        <p>&nbsp;</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        €{ticketAmount(row)}
                                        <p>{(row.paymentStatus.toLowerCase() === "paid") ? "Paid Online" : row.paymentStatus}</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Box>
                                        {row.total_voucher_unused + "/" + row?.selectedPassDetail?.noOfAllowedSessions}
                                        <p>&nbsp;</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        {/* <IconButton sx={{ p: 0, m: "6px" }}
                                            title={"Show Bookings"}
                                            disabled={row.booking_detail.length <= 0}
                                            onClick={() => {
                                                selectBuyingDetail(row)
                                                selectCustomerBooking(row.booking_detail.map((d) => {
                                                    return {
                                                        ...d,
                                                        student_detail: row?.consumer_detail || {},
                                                        consumer_detail: row?.consumer_detail || {},
                                                    }
                                                }))
                                            }}>
                                            <DateRangeIcon />
                                        </IconButton> */}
                                        <PurchaseSessionPopover
                                            purchasingDetails={row}
                                            handleUpdate={() => {
                                                getRecentBookings()
                                            }}
                                            setLoading={setLoading}
                                        />
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : (
                            <StyledTableRow key={0} >
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                                        <p>There is no bookings</p>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {showViewMore ? (<div className={"flex flex-row justify-center my-4"}>
                <button
                    className={
                        "text-white text-sm cursor-pointer bg-[#3699FF] rounded-lg p-2 px-5"
                    }
                    onClick={() => {
                        setRecentBookingsPageNumber(recentBookingsPageNumber + 1);
                    }}
                >
                    View More
                </button>
            </div>) : ""}
        </div>
    );
}
