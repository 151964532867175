import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Box, Tab } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EventTile from "./EventTile";

import { useLocalStorage } from "../../hooks";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../redux/reducers/dashboardReducer";


import { Button, TextField, useMediaQuery } from '@mui/material';
import NeewSidebar from "../../components/NewSideBar";
import "../../components/Sidebar.css";
import {
  LoadingBackdrop
} from "../../layouts";

import noEventImage from "../../assets/noEventImage.svg";
import { userState } from "../../redux/reducers/userReducer";
import { getUrlForEvent } from "../../utils/confighelpers";

export default function EventList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const anchorEl = React.useRef(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [events, setEvents] = React.useState();
  const [eventDates, setEventDates] = React.useState();
  const [eventDatesRange, setEventDatesRange] = React.useState();
  const [sessions, setSessions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchVal, setSearchValues] = React.useState('');
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const [classList, setClassList] = React.useState([]);
  const [isNoClassAvailable, setIsNoClassAvailable] = React.useState({
    all: true,
    published: true,
    drafts: true,
    cancelled: true,
  });
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfClass();
      setLoading(false);
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.updateSessionComp]);


  const handleSessionClick = async (id, eventData) => {
    dispatch(dashboardActions.setCalendarClassData(eventData));
    dispatch(dashboardActions.setCalendarSessionId(id));
    dispatch(dashboardActions.setCalendarSessionData({ eventId: eventData, allEventSchedules: eventData.allEventSchedules }));
    dispatch(dashboardActions.setViewSessionPage(true));
    navigate("/myevents/detail");


    // console.log("eventData", eventData);
    // cogoToast.loading("Loading session details");
    // const isPckg = eventData.isPackage;
    // const config = {
    //   method: "get",
    //   url: `${getUrlForEvent()}list?eventId=${eventData.id}`,
    //   headers: {
    //     "x-access-token": userToken,
    //   },
    // };
    // const request = await axios(config);
    // console.log("request.data", request.data.data);
    // if (request.status === 200) {
    //   if (request.data.statusCode === 200) {
    //     dispatch(dashboardActions.setCalendarClassData(eventData));
    //     dispatch(dashboardActions.setCalendarSessionId(id));
    //     dispatch(dashboardActions.setCalendarSessionData({ eventId: request.data.data, allEventSchedules: request.data.data.allEventSchedules }));
    //     dispatch(dashboardActions.setViewSessionPage(true));
    //     console.log("event and new are same", JSON.stringify(eventData) === JSON.stringify(request.data.data))
    //     console.log("event and new are same", eventData)
    //     console.log("event and new are same", request.data.data)
    //     navigate("/myevents/detail");
    //   }
    // }
  };


  const calendarOpenFromRedux = React.useMemo(() => {
    const bool = !loading && dashboardReduxState.calendarOpen;
    return bool;
  }, [loading, dashboardReduxState.calendarOpen]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setIsNoClassAvailable({
      all: true,
      published: true,
      drafts: true,
      cancelled: true,
    });
    setTabCurrentState(newValue);
  };

  const getListOfClass = async () => {
    let mgClasses = [];
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForEvent()}list?listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageClassesRequest = await axios(manageClassesConfig);
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.data.length) {
        for (const item of manageClassesRequest.data.data) {
          mgClasses.push({
            label: item.eventName,
            id: item._id,
            data: item
          });
        }
        setClassList(mgClasses);
      }
    }
  };

  const renderClasses = (data, tab = "all") => {
    // setIsNoClassAvailable(true)
    return (
      <Box>
        {data.length > 0 ? (
          data.slice(0).reverse().map((item, idx) => {
            const modifiedData = {
              id: item.data._id,
              imgSrc: item.data.eventPhoto,
              name: item.data.eventName,
              rating: item.data.rating,
              bookingRevenue: item.data.totalBookings.totalRevenue,
              seatsBooked: item.data.totalBookings.totalConfirmedBooking,
              popover: true,
              popoverLine: item.data.isPackage,
              insideDetailView: true,
              classDetail: item.data,
            };

            let isDisplay = false;
            if (
              tab === "all"
              || (tab === "published" && item.data.hasOwnProperty("isPublic") && item.data.isPublic === true)
              || (tab === "drafts" && item.data.hasOwnProperty("isPublic") && item.data.isPublic === false)
              || (tab === "cancelled" && item.data.hasOwnProperty("isDeleted") && item.data.isDeleted === true)
            ) {
              if (isNoClassAvailable[tab]) {
                setIsNoClassAvailable({
                  ...isNoClassAvailable,
                  [tab]: false
                })
              }
              isDisplay = true;
            }
            return (isDisplay) ? (
              <div
                key={idx}
                className={"cursor-pointer"}
              // onClick={() => handleSessionClick(modifiedData.id, item)}
              >
                <EventTile
                  {...modifiedData}
                  key={idx}
                  handleSessionClick={() => {
                    handleSessionClick(modifiedData.id, item.data);
                  }}
                  reloadData={async () => {
                    await getListOfClass();
                  }}
                />
              </div>
            ) : "";
          })
        ) : (
          <div className={"text-black"}>no events are available</div>
        )}
      </Box>
    );
  };

  const onSearchClickHandle = async (eventData) => {
    console.log("eventData", eventData);
    const config = {
      method: "get",
      // url: `${getUrlForEvent()}list?searchclass=${newDate}&eventId=${eventData.id}`,
      url: `${getUrlForEvent()}list?searchclass=${eventData.label}&listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const manageClassesRequest = await axios(config);
    let mgClasses = [];
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.data.length) {
        for (const item of manageClassesRequest.data.data) {
          mgClasses.push({
            label: item.eventName,
            id: item._id,
            data: item
          });
        }
        setClassList(mgClasses);
      }
    }
  };



  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {classList.length ? (<div
            className={"grid grid-cols-2 w-full px-3 pt-5"}
          >
            <div className={"flex flex-col justify-center items-center"}>
              <Autocomplete
                disablePortal
                options={classList}
                value={searchVal}
                onChange={(event: any, newValue: string | null) => {
                  console.log(newValue);
                  if (newValue) {
                    setSearchValues(newValue.label);
                    onSearchClickHandle(newValue);
                  } else {
                    setSearchValues("");
                    onSearchClickHandle({ label: "", id: "", data: {} });
                  }
                }}
                sx={{
                  borderColor: "#EBEBEB",
                  borderRadius: "10px",
                }}
                // renderInput={(params) => <TextField {...params} label="Movie" />}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={"Search..."}
                      value={searchVal}
                      sx={{
                        borderColor: "#EBEBEB",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          borderColor: "#EBEBEB",
                        },
                      }}
                    /* endAdornment={
                      <InputAdornment position={"end"}>
                        <img
                          className={"lg:w-[18px] w-[18px]"}
                          src={search_png}
                          alt={""}
                        />
                      </InputAdornment>
                    } */
                    // endAdornment={InputProps?.endAdornment}
                    />
                  );
                }}
                className="w-full pr-7"
              />
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabCurrentState}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                      fontSize: 18
                    }}>
                      <Tab label="All" value="1" />
                      <Tab label="Published" value="2" />
                      <Tab label="Drafts" value="3" />
                      <Tab label="Cancelled" value="4" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ p: 2 }}>
                    <div className={`self-start px-3`}>
                      {renderClasses(classList, "all")}
                      {(isNoClassAvailable["all"]) ? (<div className={"text-black text-center"}>no events are available</div>) : ""}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className={`self-start px-3`}>
                      {renderClasses(classList, "published")}
                      {(isNoClassAvailable["published"]) ? (<div className={"text-black text-center"}>no events are available</div>) : ""}
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className={`self-start px-3`}>
                      {renderClasses(classList, "drafts")}
                      {(isNoClassAvailable["drafts"]) ? (<div className={"text-black text-center"}>no events are available</div>) : ""}
                    </div>
                  </TabPanel>
                  <TabPanel value="4"><div className={`self-start px-3`}>
                    {renderClasses(classList, "cancelled")}
                    {(isNoClassAvailable["cancelled"]) ? (<div className={"text-black text-center"}>no events are available</div>) : ""}
                  </div></TabPanel>
                </TabContext>
              </Box>
            </div>
            <div className={"flex justify-end items-top"}>
              <div className={"flex-col items-end mr-28"}>
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myevents/new");
                  }}
                >
                  Add New Event
                </Button>
              </div>
            </div>
          </div>) : loading === false ? (
            <Box className="flex flex-col justify-center items-center w-full mt-28">

              <Box className="flex flex-col justify-center items-center text-center">
                <img
                  className={"w-[235px]"}
                  src={noEventImage}
                  alt={""}
                />
                <Box sx={{
                  color: "#481B3A",
                  fontSize: "18px",
                  fontWeight: "700",
                  mt: 3,
                }}>
                  No Events Configured
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-full mt-5">
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myevents/new");
                  }}
                >
                  Add  a new  Event
                </Button>
              </Box>
            </Box>
          ) : ""}
        </Box>
      </LoadingBackdrop>} />
  );
}
