import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Autocomplete, Box, Stack } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { addMinutes, format, parse, parseISO } from "date-fns";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Scroll from "react-scroll";

import CalendarView from "../../components/CalendarView";
import SessionClassTile from "./SessionClassTile";

import { useLocalStorage } from "../../hooks";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../redux/reducers/dashboardReducer";


import { Button, TextField, useMediaQuery } from '@mui/material';
import NeewSidebar from "../../components/NewSideBar";
import "../../components/Sidebar.css";
import {
  LoadingBackdrop
} from "../../layouts";

import noEventImage from "../../assets/noEventImage.svg";
import { userState } from "../../redux/reducers/userReducer";
import { getUrlForClasses } from "../../utils/confighelpers";

export default function ScheduleClassList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const anchorEl = React.useRef(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [events, setEvents] = React.useState();
  const [eventDates, setEventDates] = React.useState();
  const [eventDatesRange, setEventDatesRange] = React.useState();
  const [sessions, setSessions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchVal, setSearchValues] = React.useState('');
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const [classList, setClassList] = React.useState([]);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfClass();
      const date = new Date(selectedDate).toISOString();
      const config = {
        method: "get",
        url: `${getUrlForClasses()}groupScheduledClass?searchDate=${date}&classId=`,
        headers: {
          "x-access-token": userToken.toString(),
        },
      };
      const request = await axios(config);
      if (request.status === 200) {
        const allEvents = { ...request.data.data };
        let allEventDates = [];
        const dateFormat = "yyyy-MM-dd";
        const eventDatesRange = { from: "", to: "" };
        const eventKeys = Object.keys(allEvents);
        const startDate = parse(eventKeys[0], dateFormat, new Date());
        const endDate = parse(
          eventKeys[eventKeys.length - 1],
          dateFormat,
          new Date()
        );
        eventDatesRange.from = startDate;
        eventDatesRange.to = endDate;
        eventKeys.forEach((key) => {
          if (allEvents[key].length > 0) {
            const parsedDate = parse(key, dateFormat, new Date());
            allEventDates.push(parsedDate);
          }
        });
        setEventDatesRange({ ...eventDatesRange });
        setEventDates([...allEventDates]);
        setEvents({ ...allEvents });

        let initialSessionsData = { ...allEvents };
        const todayFormated = format(new Date(selectedDate), dateFormat);
        const idxOfTodayInEvents =
          Object.keys(initialSessionsData).indexOf(todayFormated);
        initialSessionsData = Object.fromEntries(
          Object.entries(initialSessionsData).slice(idxOfTodayInEvents)
        );

        // initialSessionsData = filterObject(
        //   initialSessionsData,
        //   (data) => Array.isArray(data) && data.length > 0
        // );

        setSessions({ ...initialSessionsData });
        setLoading(false);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.updateSessionComp]);

  const onDateClickHandle = async (day) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(day), dateFormat);
    setSelectedDate(day);
    const newDate = new Date(day).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForClasses()}groupScheduledClass?searchDate=${newDate}&classId=`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInEvents = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInEvents)
          );
          // selectedDateSessions = filterObject(
          //   selectedDateSessions,
          //   (data) => Array.isArray(data) && data.length > 0
          // );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };

  const handleSessionClick = async (id, classData) => {
    /* if (dashboardReduxState.calendarSessionId === id) {
      dispatch(dashboardActions.setCalendarSessionId(-1));
      dispatch(dashboardActions.setCalendarOpen(false));
      navigate("/home/dashboard");
      return;
    } */
    cogoToast.loading("Loading session details");
    const isPckg = classData.isPackage;
    const config = {
      method: "get",
      url: `${getUrlForClasses()}getScheduleDetail?scheduleclassId=${id}&isPackage=${isPckg}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        dispatch(dashboardActions.setCalendarClassData(classData));
        dispatch(dashboardActions.setCalendarSessionId(id));
        dispatch(dashboardActions.setCalendarSessionData(request.data.data));
        dispatch(dashboardActions.setViewSessionPage(true));
        console.log("class and new are same", JSON.stringify(classData) === JSON.stringify(request.data.data))
        console.log("class and new are same", classData)
        console.log("class and new are same", request.data.data)
        navigate("/myschedule/detail");
      }
    }
  };

  const renderSessions = (day, data, tab = "all") => {
    const dateFormat = "yyyy-MM-dd";
    const parsedDay = parse(day, dateFormat, new Date());
    const displayDateFormat = "EEEE, LLLL d";
    return (
      <Scroll.Element name={dateFormat} id={"sessionsPopover"} key={day}>
        <Scroll.Element name={day}>
          <p
            className={
              "font-bold text-black text-sm block self-start mt-5 mb-1"
            }
          >
            {format(parsedDay, displayDateFormat)}
          </p>
        </Scroll.Element>
        {data.length > 0 ? (
          data.map((item, idx) => {
            try {
              const dtStart = DateTime.fromISO(item.classDate);
              const dtEnd = format(
                addMinutes(dtStart.ts, item.duration || 0),
                "hh:mma"
              );
              let ticketQty = 0;
              // scheduleClass.scheduleClassBookings
              if (item.scheduleClassBookings && item.scheduleClassBookings.length > 0) {
                item.scheduleClassBookings.map((booking) => {
                  if (booking.status === "Cancelled") {
                    return booking;
                  }
                  if (booking.ticketsBought && booking.ticketsBought.length > 0) {
                    booking.ticketsBought.map((ticket) => {
                      ticketQty = parseInt(parseInt(ticketQty) + parseInt(ticket.bookingQty))
                    })
                  } else {
                    ticketQty = parseInt(ticketQty) + parseInt(booking.capacity ? booking.capacity : 0);
                  }
                })
              }
              const modifiedData = {
                id: item.instanceId,
                imgSrc: item.classPhoto,
                title: item.className,
                schedule: item.scheduleName,
                time: `${dtStart.toFormat("dd LLL, ccc, hh':'mma")} - ${dtEnd}`,
                venue: `${item.location.houseNumber}, ${item.location.city}`,
                totalSeats: item.capacity,
                seatsBooked: ticketQty,
                seatsBookedPerc: (ticketQty / item.capacity) * 100,
                bookedPeople: item.participants,
                popover: true,
                popoverLine: item.isPackage,
                insideDetailView: true,
                item: item,
              };
              return (
                tab === "all"
                || (tab === "published" && item.hasOwnProperty("isPublic") && item.isPublic === true)
                || (tab === "drafts" && item.hasOwnProperty("isPublic") && item.isPublic === false)
              ) ? (
                <div
                  className={"cursor-pointer"}
                // onClick={() => handleSessionClick(modifiedData.id, item)}
                >
                  <SessionClassTile
                    {...modifiedData}
                    key={idx}
                    selectedForSessionDisplay={
                      // dashboardReduxState.calendarSessionId === item.instanceId
                      false
                    }
                    handleSessionClick={() => {
                      handleSessionClick(modifiedData.id, item);
                    }}
                  />
                </div>
              ) : "";
            } catch (error) {
              // const dtStart = DateTime.fromISO(item.classDate);
              console.log("date error", error)
              // console.log("item.duration", dtStart)
              return (<></>);
            }
          })
        ) : (
          <div className={"text-black"}>No Sessions for this day check</div>
        )}
      </Scroll.Element>
    );
  };


  const calendarOpenFromRedux = React.useMemo(() => {
    const bool = !loading && dashboardReduxState.calendarOpen;
    return bool;
  }, [loading, dashboardReduxState.calendarOpen]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabCurrentState(newValue);
  };

  const getListOfClass = async () => {
    let mgClasses = [];
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForClasses()}listOfClasses?isPackage=${false}&listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageClassesRequest = await axios(manageClassesConfig);
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.data.length) {
        for (const item of manageClassesRequest.data.data) {
          mgClasses.push({
            label: item.className,
            id: item._id,
            data: item
          });
        }
        setClassList(mgClasses);
      }
    }
  };

  const onSearchClickHandle = async (classData) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(selectedDate), dateFormat);
    const newDate = new Date(selectedDate).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForClasses()}groupScheduledClass?searchDate=${newDate}&classId=${classData.id}`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInEvents = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInEvents)
          );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };



  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {classList.length ? (<div
            className={"grid grid-cols-2 w-full px-3 pt-5"}
          >
            <div className={"flex flex-col justify-center items-center"}>
              <Autocomplete
                disablePortal
                options={classList}
                value={searchVal}
                onChange={(event: any, newValue: string | null) => {
                  console.log(newValue);
                  if (newValue) {
                    setSearchValues(newValue.label);
                    onSearchClickHandle(newValue);
                  } else {
                    setSearchValues("");
                    onSearchClickHandle({ label: "", id: "", data: {} });
                  }
                }}
                sx={{
                  borderColor: "#EBEBEB",
                  borderRadius: "10px",
                }}
                // renderInput={(params) => <TextField {...params} label="Movie" />}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={"Search..."}
                      value={searchVal}
                      sx={{
                        borderColor: "#EBEBEB",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          borderColor: "#EBEBEB",
                        },
                      }}
                    /* endAdornment={
                      <InputAdornment position={"end"}>
                        <img
                          className={"lg:w-[18px] w-[18px]"}
                          src={search_png}
                          alt={""}
                        />
                      </InputAdornment>
                    } */
                    // endAdornment={InputProps?.endAdornment}
                    />
                  );
                }}
                className="w-full pr-7"
              />
              <AppBar
                position={"sticky"}
                elevation={0}
                sx={{
                  backgroundColor: "#FFFFFF",
                  py: 2,
                  px: 0,
                  pr: 3.5,
                }}
              >
                <Stack direction={"column"} alignItems={"center"}>
                  <CalendarView
                    selectedDate={selectedDate}
                    onDateClickHandle={onDateClickHandle}
                    eventDatesRange={eventDatesRange}
                    eventDates={eventDates}
                  />
                </Stack>
              </AppBar>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabCurrentState}>
                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                      fontSize: 18
                    }}>
                      <Tab label="All" value="1" />
                      <Tab label="Published" value="2" />
                      <Tab label="Drafts" value="3" />
                      <Tab label="Archived" value="4" />
                    </TabList>
                  </Box> */}
                  <TabPanel value="1" sx={{ p: 2 }}>
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "all")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "published")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "drafts")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="4">Archived</TabPanel>
                </TabContext>
              </Box>
            </div>
            <div className={"flex justify-end items-top"}>
              <div className={"flex-col items-end mr-28"}>
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myclasses/new");
                  }}
                >
                  Add New Class
                </Button>
              </div>
            </div>
          </div>) : loading === false ? (
            <Box className="flex flex-col justify-center items-center w-full mt-28">

              <Box className="flex flex-col justify-center items-center text-center">
                <img
                  className={"w-[235px]"}
                  src={noEventImage}
                  alt={""}
                />
                <Box sx={{
                  color: "#481B3A",
                  fontSize: "18px",
                  fontWeight: "700",
                  mt: 3,
                }}>
                  No Classes Configured
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-full mt-5">
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myclasses/new");
                  }}
                >
                  Add  a new  Class
                </Button>
              </Box>
            </Box>
          ) : ""}
        </Box>
      </LoadingBackdrop>} />
  );
}
