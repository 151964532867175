import React from "react";
import { Popover } from "@mui/material";

export default function MGPopover({
  id,
  open,
  anchorEl,
  handleClose,
  options,
  location,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "rgba(250, 250, 250, 0.93)",
          boxShadow: "none",
          borderRadius: 2,
        },
      }}
    >
      <div
        className={`grid ${
          location ? "grid-rows-2" : "grid-rows-4"
        } divide-y w-[250px]`}
      >
        {options.map((opt, idx) => (
          <button
            key={idx}
            className={
              "flex flex-row justify-between items-center cursor-pointer px-4 py-2 active:bg-light-brown-coffee active:text-white"
            }
            onClick={opt.onClick}
          >
            <p className={"text-[17px]"}>{opt.text}</p>
            <img src={opt.icon} alt={""} />
          </button>
        ))}
      </div>
    </Popover>
  );
}
