export const sizes = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
};

// use for styled components
const breakpoints = {
  xs: `@media only screen and (min-width: ${sizes.xs})`,
  sm: `@media only screen and (min-width: ${sizes.sm})`,
  md: `@media only screen and (min-width: ${sizes.md})`,
  lg: `@media only screen and (min-width: ${sizes.lg})`,
  xl: `@media only screen and (min-width: ${sizes.xl})`,
};

export default breakpoints;
