import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
     Dialog,
     Divider,
     IconButton,
     Slide,
     Stack
} from "@mui/material";
import React from "react";

import booking_svg from "../assets/bookingLink.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function BookingEmailConfirmationDialog({ open, handleClose, title, subtitle }) {
  return (
    <Dialog
      open={open}
      scroll={"body"}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: 5,
          minWidth: 500,
          maxWidth: 500,
          overflowX: "hidden",
          backgroundColor: "primary.main",
        },
      }}
    >
      <Stack direction={"column"} spacing={6} sx={{ pt: 4, pb: 8, px: 8 }}>
        <Stack direction={"column"} spacing={2}>
          <div className={"flex flex-row items-center justify-between"}>
            {/* <h3 className={"font-bold text-2xl text-white"}>{title !== undefined ? title : `Booking sent Successfully`}</h3> */}
            <h3 className={"font-bold text-2xl text-white"}>{title !== undefined ? title : ``}</h3>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon sx={{ color: "#FFF" }} />
            </IconButton>
          </div>
          <Divider variant={"middle"} sx={{ color: "primary.light" }} />
        </Stack>
        <Stack direction={"column"} spacing={1}>
          <img src={booking_svg} alt={""} />
          <p className={"font-medium text-[16px] text-center text-white"}>
            {subtitle !== undefined ? subtitle : ""}
          </p>
        </Stack>
      </Stack>
    </Dialog>
  );
}
