import React, { useState, useCallback } from "react";
import IOSSwitch from "../../../components/IOSSwitch";
import ArrowDown from "../../../assets/arrow.svg";
import moment from "moment";
import AddIcon from "../../../assets/addIcon.svg";
import TrashIcon from "../../../assets/Trash_light.svg";
import ReactTimePicker from "./TimePicker";
import copyIcon from "../../../assets/copyIcon.svg";

const dayLabels = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

const DayTimeSelector = ({
  item,
  onToggleSwitch,
  onTimeChange,
  onTimeRangeCopy,
}) => {
  const [expandDaySelector, setExpandDaySelector] = useState(false);

  const timeString = (openTime) => {
    if (!openTime[0]?.startTime) {
      return "";
    }
    let timeStringVal = `${openTime[0]?.startTime}-${openTime[0]?.endTime}`;
    if (openTime[1]?.startTime) {
      timeStringVal += ` | ${openTime[1]?.startTime}-${openTime[1]?.endTime}`;
    }
    return timeStringVal.toUpperCase();
  };

  const handleTimeChange = (time, type, index) => {
    let openTime = [...item?.openTime];
    openTime.forEach((openTimeObj, timeIndex) => {
      if (timeIndex === index) {
        openTimeObj[type] = moment(time).format("h:mm A");
      }
    });
    onTimeChange(openTime);
  };

  const getNextStartTime = useCallback(() => {
    const lastOpenTime = item?.openTime[item?.openTime.length - 1];
    if (lastOpenTime) {
      const lastEndTime = moment(lastOpenTime.endTime, "h:mm A");
      const nextStartTime = lastEndTime.clone().add(1, "hour").format("h:mm A");
      return nextStartTime;
    }
    return "";
  }, [item?.openTime]);

  const handleTimeRangeChange = (index, action) => {
    let openTime = [...item?.openTime];
    if (action === "add") {
      openTime.push({
        startTime: getNextStartTime(),
        endTime: moment(getNextStartTime(), "h:mm A").add(1, "hour").format("h:mm A"),
      });
    } else {
      openTime.splice(index, 1);
    }
    onTimeChange(openTime);
  };

  return (
    <div className="flex flex-col border-2 border-[#EBEBEB] rounded-[10px]">
      <div className="flex flex-row justify-between  items-center pt-[25px] pb-[22px] pl-11 pr-9">
        <div className="flex flex-[0.3] items-center">
          <IOSSwitch
            checked={item?.isOpen}
            onChange={() => {
              onToggleSwitch(item);
            }}
          />
          <p className="text-xl font-medium text-[#481B3A] ml-2">
            {dayLabels[item?.day]}
          </p>
        </div>
        <div className="flex flex-[0.6] justify-center">
          {!expandDaySelector && (
            <p className="text-center">
              {item?.openTime.length > 0
                ? timeString(item?.openTime)
                : "Not Available/closed"}
            </p>
          )}
        </div>
        <div className="flex flex-[0.1] justify-end">
          <img
            src={ArrowDown}
            alt="arrow down"
            className="rotate-90 h-[15px] w-2 cursor-pointer"
            onClick={() => {
              if (item?.openTime?.length > 0) {
                setExpandDaySelector((prev) => !prev);
              }
            }}
          />
        </div>
      </div>
      {expandDaySelector && (
        <div className="flex flex-col pl-11">
          {item?.openTime?.map((time, index) => {
            console.log("time", time);
            return (
              <div
                className="flex flex-row items-center"
                style={{ marginBottom: "11px" }}
                key={index}
              >
                <ReactTimePicker
                  time={time?.startTime}
                  placeholderLabel="Select Start Time"
                  handleTimeChange={(time) =>
                    handleTimeChange(time, "startTime", index)
                  }
                />
                <span style={{ marginLeft: "23px", marginRight: "23px" }}>
                  to
                </span>
                <ReactTimePicker
                  time={time?.endTime}
                  placeholderLabel="Select End Time"
                  handleTimeChange={(time) =>
                    handleTimeChange(time, "endTime", index)
                  }
                />
                {index === 0 ? (
                  <img
                    src={AddIcon}
                    alt="add icon"
                    className="ml-6 h-5 w-5 cursor-pointer"
                    onClick={() => {
                      handleTimeRangeChange(index, "add");
                    }}
                  />
                ) : (
                  <img
                    src={TrashIcon}
                    alt="delete icon"
                    className="ml-6 h-7 w-7 cursor-pointer"
                    onClick={() => {
                      handleTimeRangeChange(index, "delete");
                    }}
                  />
                )}
              </div>
            );
          })}
          {item?.openTime?.length > 0 && (
            <div className="flex justify-end pr-[9px] pb-4 pt-5">
              <div
                className="flex cursor-pointer"
                onClick={() => {
                  onTimeRangeCopy(item);
                }}
              >
                <img alt="copy icon" src={copyIcon} className="h-5 w-[18px]" />
                <span className="text-base leading-[20px] text-[#7E00DD] font-light ml-[11px]">
                  Copy this time to all
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default DayTimeSelector;
