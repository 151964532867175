import React from "react";
import axios from "axios";
import {
  Dialog,
  Slide,
  Button,
  Divider,
  InputAdornment,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form, Field } from "formik";
import { InputBase } from "formik-mui";
import PhoneInput from "react-phone-input-2";
import cogoToast from "cogo-toast";
import "react-phone-input-2/lib/material.css";

import { useLocalStorage } from "../hooks";

import Customer from "../assets/Customer.png";
import SuccessParticipantModal from "./SuccessParticipantModal";
import { getUrlForUsers } from "../utils/confighelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function AddParticipantForm({
  open,
  handleClose,
  scheduleId,
  isPackage,
  handleUpdate,
}) {
  const formikInitialValues = {
    fullName: "",
    countryName: "nl",
    countryCode: "",
    phone: "",
    formattedPhone: "",
  };
  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [bookingId, setBookingId] = React.useState("");
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");

  const handleSuccessOpen = () => setSuccessModalOpen(true);
  const handleSuccessClose = () => setSuccessModalOpen(false);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: 400,
            maxWidth: 400,
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 5,
            p: 5,
            overflowX: "hidden",
          },
        }}
        sx={{
          backdropFilter: "blur(5px)",
        }}
      >
        <div className={"flex flex-col gap-12"}>
          <div className={"flex flex-col gap-2"}>
            <div className={"flex flex-row justify-between items-center"}>
              <h3 className={"font-bold text-2xl text-brown-coffee"}>
                Add New
              </h3>
              <IconButton onClick={handleClose}>
                <Close color={"primary"} />
              </IconButton>
            </div>
            <Divider />
          </div>
          <Formik
            initialValues={formikInitialValues}
            validate={(values) => {
              const errors = {};
              if (!values.fullName) {
                errors.fullName = "Required!";
              }
              if (!values.phone) {
                errors.phone = "Required!";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              cogoToast.loading("Loading...");
              const body = {
                countryCode: values.countryCode,
                phoneNumber: values.phone,
                username: values.countryCode + values.phone,
                fullName: values.fullName,
                scheduleClassId: scheduleId,
                isPackage: isPackage,
                // getPackageDetail: this.props.data.getPackageDetail !=undefined && this.props.data.getPackageDetail !=null ? this.props.data.getPackageDetail : false
              };
              const config = {
                method: "post",
                url: `${getUrlForUsers()}addParticipant`,
                headers: {
                  "x-access-token": userToken,
                },
                data: body,
              };
              const request = await axios(config);
              if (request.status === 200) {
                if (request.data.statusCode === 200) {
                  cogoToast.success("Success!");
                  setFullName(values.fullName);
                  setPhoneNumber(`${values.countryCode.concat(values.phone)}`);
                  for (const member of request.data.data.participants) {
                    if (member.fullName === values.fullName) {
                      setBookingId(member.bookingId);
                    }
                  }
                  setSubmitting(false);
                  resetForm();
                  handleClose();
                  handleSuccessOpen();
                  handleUpdate();
                } else {
                  cogoToast.error(request.data.message);
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              submitForm,
              errors,
              values,
              isSubmitting,
              setFieldValue,
              touched,
              handleBlur,
            }) => (
              <Form>
                <div className={"flex flex-col gap-4"}>
                  <FormControl error={errors["fullName"] ? true : false}>
                    <Field
                      component={InputBase}
                      name={"fullName"}
                      type={"text"}
                      placeholder={"Full Name"}
                      startAdornment={
                        <InputAdornment position={"start"}>
                          <img
                            className={"w-[19px] h-[19px]"}
                            src={Customer}
                            alt={""}
                          />
                        </InputAdornment>
                      }
                      sx={{
                        px: 2,
                        py: 1.5,
                        border: 1,
                        borderRadius: 3,
                        borderColor: errors.fullName ? "red" : "#EBEBEB",
                        fontSize: 17,
                      }}
                    />
                    {errors.fullName && touched.fullName && (
                      <FormHelperText>{errors.fullName}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl error={errors["phone"] ? true : false}>
                    <Field
                      component={PhoneInput}
                      placeholder={"Phone Number"}
                      value={values.formattedPhoneNumber}
                      onChange={(phone, country, e) => {
                        const numberWithoutDialCode = phone.split("");
                        for (let i = 0; i < country.dialCode.length; i++) {
                          numberWithoutDialCode.shift();
                        }
                        let dialCode = country.dialCode;
                        if (
                          country.format.includes("+") &&
                          !country.dialCode.includes("+")
                        ) {
                          dialCode = "+".concat(country.dialCode);
                        }
                        setFieldValue("countryName", country.countryCode);
                        setFieldValue("countryCode", dialCode);
                        setFieldValue("phone", numberWithoutDialCode.join(""));
                        setFieldValue("formattedPhoneNumber", e.target.value);
                      }}
                      country={values.countryName}
                      onBlur={() => handleBlur("phone")}
                      disabled={isSubmitting}
                      containerClass={"rounded-md"}
                      inputClass={"md:h-10"}
                      inputStyle={{
                        borderColor: errors.phone ? "red" : "#EBEBEB",
                        fontSize: 16,
                        borderRadius: 10,
                        width: "100%",
                      }}
                    />
                    {errors.phone && touched.phone && (
                      <FormHelperText>{errors.phone}</FormHelperText>
                    )}
                  </FormControl>
                  <p className={"font-light text-sm text-center my-2"}>
                    This participant will be added to the class/course unpaid.
                    You can send Tikkie request for payments in the next step.
                  </p>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{
                      textTransform: "none",
                      borderRadius: 4,
                      mt: 6,
                      height: 58,
                      fontWeight: 600,
                      fontSize: 16,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                      },
                    }}
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Add Participant
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
      <SuccessParticipantModal
        open={successModalOpen}
        handleClose={handleSuccessClose}
        fullName={fullName}
        phoneNumber={phoneNumber}
        bookingId={bookingId}
        handleUpdate={handleUpdate}
      />
    </>
  );
}
