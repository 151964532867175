import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Popover } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ChangeDateTimeModal from "../../../../components/ChangeDateTimeModal";
import ChangeLocationModal from "../../../../components/ChangeLocationModal";

import Alert from "../../../../assets/Alert.png";
import { useLocalStorage } from "../../../../hooks";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";

export default function EditScheduleClassPopover({
  scheduleClassDetails,
  handleUpdate,
}) {
  const [dateTimeModalOpen, setDateTimeModalOpen] = React.useState(false);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDateTimeModalOpen = () => {
    setDateTimeModalOpen(true)
    handleClose()
  }
  const handleDateTimeModalClose = () => setDateTimeModalOpen(false);

  const handleLocationModalOpen = () => {
    setLocationModalOpen(true)
    handleClose()
  }
  const handleLocationModalClose = () => setLocationModalOpen(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const popoverOptions = [
    {
      text: "Change Schedule",
      onClick: handleDateTimeModalOpen,
      disabled: false,
      icon: Alert,
    },
    {
      text: "Update Location",
      onClick: handleLocationModalOpen,
      disabled: false,
      icon: Alert,
    },
  ];

  return (
    <Box>
      <IconButton sx={{ p: 0, m: "10.2px" }} onClick={handleOpen}>
        <MoreVertIcon sx={{ color: "black" }} />
      </IconButton>
      <Popover
        id={popoverId}
        open={(scheduleClassDetails?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(scheduleClassDetails?.eventStatus?.toLowerCase()) > -1) ? false : popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          my: 1,
          "& .MuiPopover-paper": {
            boxShadow: "none",
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 3,
          },
        }}
      >
        <div className={"flex flex-col gap-3 py-5"}>
          {popoverOptions.map((opt, idx) => (
            <button
              key={idx}
              disabled={opt.disabled}
              className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${opt.disabled
                ? ""
                : "active:bg-light-brown-coffee active:text-white"
                } disabled:opacity-60 disabled:cursor-default`}
              onClick={opt.onClick}
            >
              <p className={"text-sm"}>{opt.text}</p>
              {/* <img className={"w-4 bg-brown-coffee rounded-full"} src={opt.icon} alt={""} /> */}
            </button>
          ))}
        </div>
      </Popover>

      <ChangeDateTimeModal
        open={dateTimeModalOpen}
        handleClose={handleDateTimeModalClose}
        sessionData={scheduleClassDetails}
        instanceId={scheduleClassDetails._id}
        handleUpdate={handleUpdate}
      />
      <ChangeLocationModal
        open={locationModalOpen}
        handleClose={handleLocationModalClose}
        sessionData={scheduleClassDetails}
        instanceId={scheduleClassDetails._id}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
}
