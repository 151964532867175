import React from "react";
import axios from "axios";
import moment from "moment";
import {
  Dialog,
  Slide,
  Divider,
  IconButton,
  FormControl,
  FormHelperText,
  // InputBase,
  Button,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Calendar as MultiCalendar, DateObject } from "react-multi-date-picker";
import { Formik, Form } from "formik";
import { format, set, parseISO } from "date-fns";
import cogoToast from "cogo-toast";
import TimeInput from "./TimeInput";
import "../styles/Calendar.css";
import { useLocalStorage } from "../hooks";
import { getUrlForClasses, getUrlForEvent } from "../utils/confighelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function ChangeDateTimeModal({
  open,
  handleClose,
  sessionData,
  instanceId,
  handleUpdate,
}) {
  const [userToken] = useLocalStorage("userToken");
  const [sessionDetails, setSessionDetails] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      if (open) {
        const sessionDetailsConfig = {
          method: "get",
          url: `${(sessionData.hasOwnProperty("eventId") ? `${getUrlForEvent()}changeEventScheduleDetail` : `${getUrlForClasses()}changeScheduleDetail`)}?reqId=${sessionData._id}&type=${sessionData.isPackage ? "package" : "instance"
            }&instanceId=${instanceId}`,
          headers: {
            "x-access-token": userToken,
          },
        };
        const sessionDetailsRequest = await axios(sessionDetailsConfig);
        setSessionDetails(sessionDetailsRequest?.data?.data);
      }
    })();
  }, [instanceId, sessionData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 500,
          maxWidth: 500,
          border: 1,
          borderColor: "#EBEBEB",
          borderRadius: 5,
          p: 5,
          overflowX: "hidden",
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <div className={"flex flex-col gap-6"}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"font-bold text-2xl text-brown-coffee"}>
              Change Schedule
            </h3>
            <IconButton onClick={handleClose}>
              <Close color={"primary"} />
            </IconButton>
          </div>
          <Divider />
        </div>
        <Formik
          initialValues={{
            classDate: parseISO(sessionData.hasOwnProperty("eventId") ? sessionData.eventDate : sessionData.classDate),
            startTime: parseISO(sessionData.hasOwnProperty("eventId") ? sessionData.eventDate : sessionData.classDate),
          }}
          validate={(values) => {
            const errors = {};
            if (!values.classDate) {
              errors.classDate = "Required!";
            }
            if (!values.startTime) {
              errors.startTime = "Required!";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            cogoToast.loading("Loading...");
            const classDateTransformed =
              values.classDate instanceof DateObject
                ? values.classDate.toDate()
                : values.classDate;

            let startDate = set(values.classDate, {
              hours: Number(format(values.startTime, "k")),
              minutes: Number(format(values.startTime, "m")),
            })
            const body = {
              // isPackage: sessionData.isPackage,
              isPackage: false,
              // scheduleId: sessionDetails._id,
              scheduleId: instanceId,
              ...(sessionData.hasOwnProperty("eventId") ? { eventId: sessionData.eventId._id, eventScheduleId: instanceId, eventDate: startDate } : { classId: sessionData.classId._id, scheduleId: instanceId, classDate: startDate }),
              meridiem: format(values.startTime, "a"),
              timing: format(values.startTime, "KK:mm a"),
              // classDate: set(values.classDate, {
              //   hours: Number(format(values.startTime, "k")),
              //   minutes: Number(format(values.startTime, "m")),
              // }),
            };
            const config = {
              method: "put",
              url: `${(sessionData.hasOwnProperty("eventId") ? `${getUrlForEvent()}schedule/update` : `${getUrlForClasses()}updateScheduleTheClass`)}`,
              headers: {
                "x-access-token": userToken,
              },
              data: body,
            };
            // console.log("classDateTransformed", classDateTransformed);
            // console.log("body", body);
            const request = await axios(config);
            if (request.status === 200) {
              if (request.data.statusCode === 200) {
                cogoToast.success("Success!");
                setSubmitting(false);
                handleClose();
                handleUpdate();
              } else {
                setSubmitting(false);
                cogoToast.error(request.data.message);
              }
            }
          }}
        >
          {({ values, setFieldValue, errors, submitForm, isSubmitting }) => (
            <Form>
              <Stack direction={"column"} spacing={3}>
                <FormControl error={errors.classDate ? true : false}>
                  <MultiCalendar
                    className='bookzy-calendar bookzy-border'
                    minDate={new Date()}
                    value={values.classDate}
                    onChange={(date) => {
                      const dateSelected = date.toDate();
                      setFieldValue("classDate", dateSelected);
                    }}
                    weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                    mapDays={({
                      date,
                      today,
                      selectedDate,
                      currentMonth,
                      isSameDate,
                    }) => {
                      let props = {};


                      if (isSameDate(date, today)) {
                        props.className = "default-current-date";
                        props.style = {
                          ...props.style,
                          border: "1px solid #777",
                        };
                      }
                      if (isSameDate(date, selectedDate)) {
                        props.className = "selected-date";
                      }

                      return props;
                    }}
                  />
                  {errors.classDate && (
                    <FormHelperText>{errors.classDate}</FormHelperText>
                  )}
                </FormControl>
                <FormControl error={errors.startTime ? true : false}>
                  <TimeInput
                    value={values.startTime ? moment(values.startTime) : ""}
                    placeholder={"Select Start Time"}
                    onChange={(time, timeString) => {
                      setFieldValue("startTime", time.toDate());
                    }}
                    status={errors.startTime ? "error" : undefined}
                    size={"large"}
                    popupStyle={{
                      zIndex: 9999,
                    }}
                    style={{
                      fontSize: 20,
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      borderColor: errors.startTime ? "#d32f2f" : "#BBBBBBCC",
                      borderRadius: 10,
                      minWidth: 312.95,
                      minHeight: 56.2,
                    }}
                  />
                  {errors.startTime && (
                    <FormHelperText>{errors.startTime}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                    width: 434,
                    height: 58,
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "common.white",
                    borderRadius: 3,
                  }}
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}
