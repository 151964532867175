import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputBase, Link, Tab, useMediaQuery } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../hooks";
import { dashboardState } from "../../redux/reducers/dashboardReducer";
import { userState } from "../../redux/reducers/userReducer";

import NeewSidebar from "../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../layouts";

import { schoolState } from "../../redux/reducers/schoolReducer";

import tikkieLogo from "../../assets/tikkieLogo.svg";
import stripLogo from "../../assets/strip.png";
import IOSSwitch from "../../components/IOSSwitch";
import WarningDialog from "../confirmationModals/WarningDialog";
import SuccessPaymentProfileModal from "./SuccessPaymentProfileModal";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StripeCreatePaymentProfileModal from "./StripeCreatePaymentProfileModal";
import StripPaymentMethod from "./StripPaymentMethod";
import { defaultAvailablePaymentMethods } from "../../utils/constants";
import { getUrlForBookings, getUrlForClasses, getUrlForUsers } from "../../utils/confighelpers";

export default function BusinessPaymentProfile({ navigation, profileFor }) {
  const [userToken] = useLocalStorage("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [loading, setLoading] = React.useState(false);
  const [successMgModalOpen, setSuccessMgModalOpen] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setIsThereChange(false)
  };

  const bodyData = {
    businessId: "",
    isEnabled: false,
    accountHolderName: "",
    appToken: "",
    paymentType: "Tikkie",
    addedByUserId: userReduxState.userData?._id,
    profileDetails: {
      accountHolderName: "",
      appToken: "",
    },
  };
  const stripeBodyData = {
    businessId: "",
    isEnabled: false,
    paymentType: "Stripe",
    profileDetails: {},
    stripeDetails: {
      // stripeAcId: "",
      // stripeEmail: "",
      // stripeCountry: "",
      // stripeOnBoardindStatus: "",
      // paymentMethod: {
      //   ideal: true,
      //   applePay: false,
      //   googlePay: false,
      //   creditCard: false,
      // }
    },
    addedByUserId: userReduxState.userData?._id,
  };

  const [formValues, setFormValues] = React.useState(bodyData);
  const [stripeFormValues, setStripeFormValues] = React.useState(stripeBodyData);
  const [isEditStripe, setIsEditStripe] = React.useState(false);
  const [isThereStripeChange, setIsThereStripeChange] = React.useState(true);
  const [createStripeDialog, setCreateStripeDialog] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [isEditTikkie, setIsEditTikkie] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const [warningText, setWarningText] = React.useState("");
  const [isThereChange, setIsThereChange] = React.useState(true);
  const schoolReduxState = useSelector(schoolState);
  const [tabValue, setTabValue] = React.useState('stripe');
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState(defaultAvailablePaymentMethods);

  const handleOpenWarningDialog = () => {
    // handleClose();
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = () => setOpenWarningDialog(false);

  const saveTikkiePaymentProfile = async () => {
    setSubmitting(true);
    setIsThereChange(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    const errors = {};
    if (!formValues.accountHolderName) {
      errors.accountHolderName = "Required!";
    }
    if (!formValues.appToken) {
      errors.appToken = "Required!";
    }

    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      setIsThereChange(false);
      cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    let body = {
      isEnabled: formValues.isEnabled,
      paymentType: formValues.paymentType,
      accountHolderName: formValues.accountHolderName,
      appToken: formValues.appToken,
      // // paymentType: formValues.paymentType,

      // myConnectedPaymentProfiles: {
      //   tikkie: {
      //     isEnabled: formValues.isEnabled,
      //     profileDetails: {
      //       accountHolderName: formValues.accountHolderName,
      //       appToken: formValues.appToken,
      //     },
      //   }
      // }
    };

    if (isEditTikkie) {
      body = {
        ...body,
        // businessId: formValues.businessId,
      }
    }

    console.log("saveTikkiePaymentProfile", body);
    savePaymentProfile("tikkie", isEditTikkie, body);
    setSubmitting(false);
    setIsThereChange(true);
  };
  const saveStripePaymentProfile = async () => {
    setSubmitting(true);
    setIsThereStripeChange(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    const errors = {};
    // if (!stripeFormValues.stripeDetails.paymentMethod.ideal
    if (!stripeFormValues.profileDetails.paymentMethod.ideal
      && !stripeFormValues.profileDetails.paymentMethod.applePay
      && !stripeFormValues.profileDetails.paymentMethod.googlePay
      && !stripeFormValues.profileDetails.paymentMethod.creditCard) {
      errors.paymentMethod = "At least one payment Method needs to be enabled.";
    }
    if (!stripeFormValues.profileDetails.stripeAcId) {
      errors.stripeAcId = "Please connect with stripe account!";
    }

    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      setIsThereStripeChange(false);
      cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    let body = {
      myConnectedPaymentProfiles: {
        stripe: {
          isEnabled: stripeFormValues.isEnabled,
          // paymentType: stripeFormValues.paymentType,
          // stripeDetails: stripeFormValues.stripeDetails,
          profileDetails: stripeFormValues.profileDetails,
        }
      }
    };

    if (isEditStripe) {
      body = {
        ...body,
      }
    }

    console.log("saveStripePaymentProfile", body);
    savePaymentProfile("stripe", isEditStripe, body);
    setSubmitting(false);
    setIsThereStripeChange(true);
  };

  const savePaymentProfile = async (editFor, isEdit, body) => {

    let config = {
      method: "post",
      url: `${getUrlForBookings()}payments/addPaymentProfile`,
      // method: "put",
      // url: `${getUrlForUsers()}updateProfile`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    if (isEdit) {
      config = {
        method: "PATCH",
        url: `${getUrlForBookings()}payments/updatePaymentProfile`,
        // method: "put",
        // url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken,
        },
        data: {
          ...body,
          // businessId: stripeFormValues.businessId,
        },
      }
      if (editFor === "stripe") {
        config = {
          ...config,
          method: "put",
          url: `${getUrlForUsers()}updateProfile`,
        }
      }
    }

    console.log("savePaymentProfile", config);
    setLoading(true);
    const request = await axios(config);
    // const request = {};

    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        if (isEdit) {
          cogoToast.success(request.data.message || "Profile successfully updated!");
        } else {
          setOpenSuccessModal(true);
        }
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error("Something is wrong please try again");
        // cogoToast.error(request.data.message);
      }
    }

  };

  const deletePaymentProfile = async (isPublish) => {
    setSubmitting(true);
    cogoToast.loading("Loading...");
    setLoading(true);

    let body = {
      addedByUserId: formValues.addedByUserId,
      paymentType: formValues.paymentType,
    };

    let config = {
      method: "delete",
      url: `${getUrlForBookings()}payments/deletePaymentProfile`,
      headers: {
        "x-access-token": userToken,
        'Content-Type': 'application/json'
      },
      data: body,
    };

    console.log("deletePaymentProfile", config);
    setLoading(false);
    const request = await axios(config);

    setLoading(false);
    setSubmitting(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        cogoToast.success("Success!");
        setIsEditTikkie(false)
        setFormValues(bodyData)
        handleCloseWarningDialog();
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error("Something is wrong please try again");
        handleCloseWarningDialog();
      }
    }

  };

  const getPaymentProfile = async () => {
    setLoading(true);
    const manageClassesConfig = {
      method: "get",
      // url: `${getUrlForClasses()}getPaymentProfile?to=all&teacherID=${userReduxState.userData?._id}`,
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageClassesRequest = await axios(manageClassesConfig);
    if (manageClassesRequest.status === 200) {
      if (manageClassesRequest.data.statusCode === 403) {
        // setWarningText(manageClassesRequest.data.message.errors ? "Something is wrong please try again" : manageClassesRequest.data.message);
        setIsEditTikkie(false)
        setFormValues(bodyData)
        setIsThereChange(false)
      } else if (manageClassesRequest.data.statusCode === 200) {
        console.log("data", manageClassesRequest.data.data);
        console.log(manageClassesRequest.data.data.paymentType === "Tikkie");
        /* if (Array.isArray(manageClassesRequest.data.data)) {
          manageClassesRequest.data.data.map((d) => {
            if (d.paymentType === "Tikkie") {
              setIsEditTikkie(true);
              setFormValues({
                businessId: d._id,
                isEnabled: d.isEnabled,
                accountHolderName: d.accountHolderName,
                appToken: d.appToken,
                paymentType: d.paymentType,
                addedByUserId: userReduxState.userData?._id,
              })
              setIsThereChange(true)
            } else if (d.paymentType === "Stripe") {
              setIsEditStripe(true);
              setStripeFormValues({
                businessId: d._id,
                isEnabled: d.isEnabled,
                paymentType: d.paymentType,
                // stripeDetails: d.stripeDetails,
                stripeDetails: {
                  ...d.stripeDetails,
                  paymentMethod: {
                    ...d.stripeDetails?.paymentMethod,
                    ideal: d.stripeDetails?.paymentMethod?.ideal || false,
                    applePay: d.stripeDetails?.paymentMethod?.applePay || false,
                    googlePay: d.stripeDetails?.paymentMethod?.googlePay || false,
                    creditCard: d.stripeDetails?.paymentMethod?.creditCard || false,
                  },
                },
                addedByUserId: userReduxState.userData?._id,
              })
              setIsThereStripeChange(true)
            }
            return d;
          });
        } else */
        // if (manageClassesRequest.data.data.paymentType === "Stripe") {
        if (manageClassesRequest.data.data?.myConnectedPaymentProfiles?.stripe) {
          let stripeprofileDetails = manageClassesRequest.data.data.myConnectedPaymentProfiles.stripe
          // setIsEditStripe(true);
          setIsEditStripe(stripeprofileDetails?.profileDetails?.stripeAcId && stripeprofileDetails.profileDetails.stripeAcId !== "");
          setStripeFormValues({
            // businessId: manageClassesRequest.data.data._id,
            businessId: null,
            isEnabled: stripeprofileDetails.isEnabled,
            paymentType: "Stripe",
            // stripeDetails: manageClassesRequest.data.data.stripeDetails,
            // stripeDetails: {
            profileDetails: {
              ...stripeprofileDetails.profileDetails,
              stripeAcId: stripeprofileDetails?.profileDetails?.stripeAcId || "",
              stripeEmail: stripeprofileDetails?.profileDetails?.stripeEmail || "",
              stripeCountry: stripeprofileDetails?.profileDetails?.stripeCountry || "",
              stripeOnBoardindStatus: stripeprofileDetails?.profileDetails?.stripeOnBoardindStatus || "",
              paymentMethod: {
                ...stripeprofileDetails.profileDetails?.paymentMethod,
                ideal: stripeprofileDetails.profileDetails?.paymentMethod?.ideal?.enable ? stripeprofileDetails.profileDetails?.paymentMethod?.ideal : { enable: false, chargeToCustomer: false },
                applePay: stripeprofileDetails.profileDetails?.paymentMethod?.applePay?.enable ? stripeprofileDetails.profileDetails?.paymentMethod?.applePay : { enable: false, chargeToCustomer: false },
                googlePay: stripeprofileDetails.profileDetails?.paymentMethod?.googlePay?.enable ? stripeprofileDetails.profileDetails?.paymentMethod?.googlePay : { enable: false, chargeToCustomer: false },
                creditCard: stripeprofileDetails.profileDetails?.paymentMethod?.creditCard?.enable ? stripeprofileDetails.profileDetails?.paymentMethod?.creditCard : { enable: false, chargeToCustomer: false },
              },
            },
            addedByUserId: userReduxState.userData?._id,
          })
          setIsThereStripeChange(true)
        }
        if (manageClassesRequest.data.data?.myConnectedPaymentProfiles?.tikkie) {
          let tikkieprofileDetails = manageClassesRequest.data.data.myConnectedPaymentProfiles.tikkie
          // setIsEditTikkie(true);
          setIsEditTikkie(tikkieprofileDetails?.profileDetails?.accountHolderName && tikkieprofileDetails.profileDetails.accountHolderName !== "");
          setFormValues({
            // businessId: manageClassesRequest.data.data._id,
            businessId: null,
            isEnabled: tikkieprofileDetails.isEnabled,
            accountHolderName: tikkieprofileDetails.profileDetails.accountHolderName,
            appToken: tikkieprofileDetails.profileDetails.appToken,
            paymentType: "Tikkie",
            addedByUserId: userReduxState.userData?._id,
          })
          setIsThereChange(true)
        }
      }
    }
    await getAvailablePaymentMethods();
    setLoading(false);
  };

  const getAvailablePaymentMethods = async () => {
    const requestConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/getAvailablePaymentMethods?teacherID=${userReduxState.userData?._id}&paymentMethodsFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        if (response.data.data) {
          setAvailablePaymentMethods(response.data.data)
        }
      }
    }
  };

  const createStripConnectedAC = async (body) => {
    // var child = window.open('https://www.plus2net.com/javascript_tutorial/window-child.html', '', 'toolbar=0,status=0,width=626,height=436');
    // child.onunload = function () { console.log('Child window closed'); };
    // var childWindow = window.open('https://abhijitsojitra.com', 'Stripe Onboarding', 'toolbar=0,status=0,width=626,height=436');
    // childWindow.document.location.href ='https://google.com';
    // var timer = setInterval(checkChild, 500);
    // setTimeout(() => {
    //   childWindow.close()
    // }, 5000);

    // var timer = setInterval(function () {
    //   if (childWindow.closed) {
    //     clearInterval(timer);
    //     alert('closed');
    //   }
    // }, 1000);
    // return;
    setLoading(true);
    const managemanageStripConnectedACConfig = {
      method: "post",
      url: `${getUrlForBookings()}payments/createConnectedAC`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const manageStripConnectedAC = await axios(managemanageStripConnectedACConfig);
    console.log("StripConnectedAC data", manageStripConnectedAC.data);
    if (manageStripConnectedAC.status === 200) {
      if (manageStripConnectedAC.data.statusCode === 200) {
        if (manageStripConnectedAC.data.data.on_boarding_url !== "") {

          console.log("StripConnectedAC url", manageStripConnectedAC.data.data.on_boarding_url);
          var childStripeWindow = window.open(manageStripConnectedAC.data.data.on_boarding_url, 'Stripe Onboarding', 'toolbar=0,status=0,width=800,height=600');

          var timer = setInterval(function () {

            if (childStripeWindow && childStripeWindow?.closed) {
              setLoading(false);
              clearInterval(timer);
              clearInterval(timeInterval);
              // alert('closed');
            }
          }, 1000);
          if (childStripeWindow) {
            childStripeWindow.focus();
          }
          // childStripeWindow.addEventListener("beforeunload", function(event) {
          //   console.log("beforeunload", event);
          //   // event.returnValue = "Are you sure that you want to cancel the transaction";
          // });
          var timeInterval = setInterval(async function () {
            // console.log("childWindow.document.", childWindow.document);
            // console.log("childWindow.document.", childWindow.document.location.href);
            // console.log("childWindow.document.", childWindow.document.href);
            await checkStripConnectedProcess(timeInterval, childStripeWindow)
          }, 1000);
          // window.open("https://www.plus2net.com/javascript_tutorial/window-child.html","Ratting","width=550,height=550,left=150,top=200,toolbar=0,status=0,");
        }
      }
    }
    // setLoading(false);
  };

  const checkStripConnectedProcess = async (timeInterval, childStripeWindow) => {
    const managemanageStripConnectedACConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/stripeStatus`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageStripConnectedAC = await axios(managemanageStripConnectedACConfig);
    console.log("StripConnectedAC data", manageStripConnectedAC.data);
    if (manageStripConnectedAC.status === 200) {
      if (manageStripConnectedAC.data.statusCode === 200) {
        if (manageStripConnectedAC.data.data.stripeOnBoardindStatus == "SUCCESS") {
          try {
            if (childStripeWindow) {
              childStripeWindow.close()
            }
          } catch (error) {
            console.error(error);
          }
          clearInterval(timeInterval);
          setLoading(false);
          setCreateStripeDialog(false);
          getPaymentProfile();
        }
      }
    }
  };

  const formLableStyle = {
    my: 1,
    color: "#32282F",
    fontSize: "18px",
    fontWeight: "600",
  };

  const formInputStyle = {
    p: 2,
    borderRadius: 4,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 696,
    maxWidth: 696,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    getPaymentProfile();
  }, []);

  React.useEffect(() => {
    console.log("stripeFormValues", stripeFormValues);
    console.log("stripeFormValues?.profileDetails?.stripeAcId", stripeFormValues?.profileDetails?.stripeAcId);
  }, [stripeFormValues]);

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          Payment Profile
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            pt: 16,
            pl: 16,
            pb: 10,
            pr: 2,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"
        >
          <Box className="w-3/5">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Stripe" value="stripe" />
                    {(userReduxState.businessType !== "event") ? (<Tab label="Tikkie" value="tikkie" />):null}
                  </TabList>
                </Box>
                <TabPanel value="tikkie">
                  <Box className="flex justify-center items-top py-3">
                    <Box className="flex-none">
                      <img
                        className={"align-top mr-2"}
                        src={tikkieLogo}
                        alt={""}
                      />
                    </Box>
                    <Box className="flex-1 pl-2">
                      <div className="text-[#32282F] text-lg">Accept online payments via Tikkie  </div>
                      <p className="text-[#32282FCC] text-lg mt-4">
                        Add your Tikkie business account here to accept online payments, and  get paid directly into your account. Manage all from a single app
                      </p>
                      <p className="text-[#6C738C] text-sm mt-5">
                        No processing fee | {availablePaymentMethods?.tikkie?.processingFeeApplicable ? (availablePaymentMethods?.tikkie?.paymentGatewayServiceFee?.paymentProcessingPercentage || 0) : 0}% commision
                      </p>
                      <div className="border-t-2 mt-8 mb-12"></div>
                      {availablePaymentMethods?.tikkie?.allowAccess ? (<Box className="px-3">
                        <Box className="flex justify-center items-center p-4 bg-[#F4F4F4] rounded-2xl">
                          <Box className="flex-1 text-[#32282F]">
                            Enable Payments via Tikkie
                          </Box>
                          <Box className="flex-none">

                            <IOSSwitch
                              className={"self-start justify-self-end"}
                              name={"enablePaymentsViaTikkie"}
                              type={"checkbox"}
                              checked={formValues.isEnabled}
                              onChange={(e) => {
                                console.log("checked", e.target.checked);
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  isEnabled: e.target.checked,
                                }))
                                setIsThereChange(false)
                              }}
                            />
                          </Box>
                        </Box>
                        <FormHelperText className="pt-5">By enabling this, you agree that all payments and refunds will be handled between you and your customer directly via your Tikkie account, while Bookzy app will only act as digital enabler for those payments.</FormHelperText>
                        <Box className="mt-5">
                          <FormControl error={formErrors["accountHolderName"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Name on the Tikkie Account</FormLabel>
                            <InputBase
                              name={"accountHolderName"}
                              placeholder={"e.g. Bookzy Incorporation"}
                              disabled={!formValues.isEnabled}
                              value={formValues.accountHolderName}
                              onChange={handleFormValueChange}
                              classes={formInputClasses}
                              sx={formInputStyle}
                            />
                            {formErrors["accountHolderName"] && (
                              <FormHelperText>{formErrors["accountHolderName"]}</FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        <Box className="mt-5">
                          <FormControl error={formErrors["appToken"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Enter your Tikkie appToken </FormLabel>
                            <InputBase
                              name={"appToken"}
                              placeholder={"e.g. f1912413-6be9-4816-964c-8bba43d9e10f"}
                              disabled={!formValues.isEnabled}
                              value={typeof formValues.appToken == "string" ? formValues.appToken : ""}
                              onChange={handleFormValueChange}
                              classes={formInputClasses}
                              sx={formInputStyle}
                            />
                            {formErrors["appToken"] && (
                              <FormHelperText>{formErrors["appToken"]}</FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        <Box className="mt-5 cursor-pointer">
                          <Link className="text-lg cursor-pointer" href="https://bmcapp.s3.eu-west-1.amazonaws.com/teacher/handleiding_token+aanmaken_V2+(1).pdf" underline="none" target="_blank">
                            Where to find this?
                            <svg className="inline align-middle cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.001 20H6.00098C4.89641 20 4.00098 19.1046 4.00098 18V7C4.00098 5.89543 4.89641 5 6.00098 5H10.001V7H6.00098V18H17.001V14H19.001V18C19.001 19.1046 18.1055 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z" fill="#2E3A59" />
                            </svg>
                          </Link>
                        </Box>
                        <Box className="flex justify-center items-center mt-20">
                          <Button
                            sx={{
                              color: "secondary.mainLight",
                              backgroundColor: "common.white",
                              borderColor: "secondary.mainLight",
                              border: 2,
                              boxShadow: "none",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "secondary.mainLight",
                                color: "common.white",
                                boxShadow: "none",
                              },
                              fontWeight: 600,
                              borderRadius: 3,
                              width: 136,
                              height: 52,
                              fontSize: 16,
                              mr: 3,
                            }}
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{
                              color: "common.white",
                              backgroundColor: "primary.main",
                              boxShadow: "none",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                              },
                              fontWeight: 600,
                              borderRadius: 3,
                              width: 231,
                              height: 52,
                              fontSize: 16,
                            }}
                            variant={"contained"}
                            // disabled={(!isSubmitting) ? isThereChange : isSubmitting}
                            disabled={isThereChange}
                            onClick={() => {
                              saveTikkiePaymentProfile();
                            }}
                          >
                            {isEditTikkie ? "Update" : "Add my Tikkie  Account"}
                          </Button>
                        </Box>
                        <Box className="flex justify-center items-center mt-10">
                          <Link className="text-lg text-[#32282FCC] cursor-pointer" href="https://www.abnamro.nl/nl/zakelijk/producten/zakelijke-rekening/openen.html" underline="none" target="_blank">
                            Do not have a Tikkie Business Account? <span className="text-[#FF5733CC]">Click here</span>
                            <svg className="inline align-middle cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.001 20H6.00098C4.89641 20 4.00098 19.1046 4.00098 18V7C4.00098 5.89543 4.89641 5 6.00098 5H10.001V7H6.00098V18H17.001V14H19.001V18C19.001 19.1046 18.1055 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z" fill="#2E3A59" />
                            </svg>
                          </Link>
                        </Box>
                      </Box>) : (
                        <Box className="px-3">
                          <p className="text-[#32282FCC] text-lg mt-4">
                            You need to subscribe to a paid subscription to enable payments through Tikkie
                          </p>
                          <Box className="flex justify-center items-center mt-20">
                            <Button
                              sx={{
                                color: "common.white",
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                  boxShadow: "none",
                                },
                                fontWeight: 600,
                                borderRadius: 3,
                                width: 231,
                                height: 52,
                                fontSize: 16,
                              }}
                              variant={"contained"}
                              onClick={() => {
                                navigate("/businessprofilesetup/subscription", {
                                  state: {
                                    origin: "paymentprofile",
                                  },
                                  replace: true
                                });
                              }}
                            >
                              Go to Subscription
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="stripe">
                  <Box className="flex justify-center items-top py-3">
                    <Box className="flex-none">
                      <img
                        className={"align-top mr-2 rounded-md"}
                        src={stripLogo}
                        alt={""}
                      />
                    </Box>
                    <Box className="flex-1 pl-2">
                      <div className="text-[#32282F] text-lg">Accept online payments via Stripe  </div>
                      <p className="text-[#32282FCC] text-lg mt-4">
                        Add your Stripe business account here to accept online payments, and  get paid directly into your account. Manage all from a single app
                      </p>
                      {/* <p className="text-[#6C738C] text-sm mt-5">
                        Total 5% processing fee + commision
                      </p> */}
                      <div className="border-t-2 mt-8 mb-12"></div>
                      <Box className="px-3">
                        <Box className="flex justify-center items-center p-4 bg-[#F4F4F4] rounded-2xl">
                          <Box className="flex-1 text-[#32282F]">
                            Enable Payments via Stripe
                          </Box>
                          <Box className="flex-none">

                            <IOSSwitch
                              className={"self-start justify-self-end"}
                              name={"enablePaymentsViaStripe"}
                              type={"checkbox"}
                              checked={stripeFormValues.isEnabled}
                              onChange={(e) => {
                                console.log("checked", e.target.checked);
                                setStripeFormValues((prevValues) => ({
                                  ...prevValues,
                                  isEnabled: e.target.checked,
                                }))
                                setIsThereStripeChange(false)
                              }}
                            />
                          </Box>
                        </Box>
                        <FormHelperText className="pt-5">By enabling this, you agree that all payments and refunds will be handled between you and your customer automatically via your Stripe account.</FormHelperText>
                        {stripeFormValues?.profileDetails?.stripeAcId && stripeFormValues?.profileDetails?.stripeAcId !== "" && stripeFormValues?.profileDetails?.stripeOnBoardindStatus == "SUCCESS" ? (<>
                          <Box className="mt-5">
                            <FormControl>
                              <FormLabel sx={formLableStyle}>Name on the Stripe Account Id</FormLabel>
                              <InputBase
                                placeholder={"e.g. Bookzy Incorporation"}
                                disabled={true}
                                value={stripeFormValues?.profileDetails?.stripeAcId}
                                classes={formInputClasses}
                                sx={formInputStyle}
                              />
                            </FormControl>
                          </Box>
                          <Box className="mt-5">
                            <FormControl>
                              <FormLabel sx={formLableStyle}>Email on the Stripe Account</FormLabel>
                              <InputBase
                                disabled={true}
                                value={stripeFormValues?.profileDetails?.stripeEmail}
                                classes={formInputClasses}
                                sx={formInputStyle}
                              />
                            </FormControl>
                          </Box>
                          <Box className="mt-5">
                            <FormControl>
                              <FormLabel sx={formLableStyle}>Country on the Stripe Account</FormLabel>
                              <InputBase
                                disabled={true}
                                value={stripeFormValues?.profileDetails?.stripeCountry}
                                classes={formInputClasses}
                                sx={formInputStyle}
                              />
                            </FormControl>
                          </Box>
                          <Box className="mt-5 w-full">
                            <FormControl className="w-full gap-2" error={formErrors["paymentMethod"] ? true : false}>
                              <FormLabel sx={formLableStyle}>Payment Method</FormLabel>
                              <StripPaymentMethod
                                title="Ideal"
                                subTitle={`Payment processing fee: ${((availablePaymentMethods?.ideal?.paymentGatewayServiceFee?.paymentProcessingPercentage > 0 ? availablePaymentMethods?.ideal?.paymentGatewayServiceFee?.paymentProcessingPercentage + "% +" : "") || "")} ${(availablePaymentMethods?.ideal?.paymentGatewayServiceFee?.paymentProcessingFlatFee || 0)} Euro.`}
                                infoMessage=""
                                defaultSwitch={stripeFormValues?.profileDetails?.paymentMethod?.ideal?.enable || false}
                                defaultChargeToCustomerSwitch={stripeFormValues?.profileDetails?.paymentMethod?.ideal?.chargeToCustomer || false}
                                disabled={!stripeFormValues.isEnabled}
                                onSwitchChange={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        ideal: {
                                          ...prevValues.profileDetails?.paymentMethod.ideal,
                                          enable: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                                onSwitchChangeChargeToCustomer={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        ideal: {
                                          ...prevValues.profileDetails?.paymentMethod.ideal,
                                          chargeToCustomer: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                              />
                              <StripPaymentMethod
                                title="Apple Pay/Google Pay"
                                subTitle={`Payment processing fee: ${((availablePaymentMethods?.applePay?.paymentGatewayServiceFee?.paymentProcessingPercentage > 0 ? availablePaymentMethods?.applePay?.paymentGatewayServiceFee?.paymentProcessingPercentage + "% +" : "") || "")} ${(availablePaymentMethods?.applePay?.paymentGatewayServiceFee?.paymentProcessingFlatFee || 0)} Euro.`}
                                infoMessage=""
                                defaultSwitch={stripeFormValues?.profileDetails?.paymentMethod?.applePay?.enable || false}
                                defaultChargeToCustomerSwitch={stripeFormValues?.profileDetails?.paymentMethod?.applePay?.chargeToCustomer || false}
                                disabled={!stripeFormValues.isEnabled}
                                onSwitchChange={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        applePay: {
                                          ...prevValues.profileDetails?.paymentMethod.applePay,
                                          enable: e.target.checked,
                                        },
                                        googlePay: {
                                          ...prevValues.profileDetails?.paymentMethod.googlePay,
                                          enable: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                                onSwitchChangeChargeToCustomer={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        applePay: {
                                          ...prevValues.profileDetails?.paymentMethod.applePay,
                                          chargeToCustomer: e.target.checked,
                                        },
                                        googlePay: {
                                          ...prevValues.profileDetails?.paymentMethod.googlePay,
                                          chargeToCustomer: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                              />
                              <StripPaymentMethod
                                title="Credit Card"
                                subTitle={`Payment processing fee: ${((availablePaymentMethods?.creditCard?.paymentGatewayServiceFee?.paymentProcessingPercentage > 0 ? availablePaymentMethods?.creditCard?.paymentGatewayServiceFee?.paymentProcessingPercentage + "% +" : "") || "")} ${(availablePaymentMethods?.creditCard?.paymentGatewayServiceFee?.paymentProcessingFlatFee || 0)} Euro.`}
                                infoMessage=""
                                defaultSwitch={stripeFormValues?.profileDetails?.paymentMethod?.creditCard?.enable || false}
                                defaultChargeToCustomerSwitch={stripeFormValues?.profileDetails?.paymentMethod?.creditCard?.chargeToCustomer || false}
                                disabled={!stripeFormValues.isEnabled}
                                onSwitchChange={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        creditCard: {
                                          ...prevValues.profileDetails?.paymentMethod.creditCard,
                                          enable: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                                onSwitchChangeChargeToCustomer={(e) => {
                                  console.log("checked", e.target.checked);
                                  setStripeFormValues((prevValues) => ({
                                    ...prevValues,
                                    // stripeDetails: {
                                    profileDetails: {
                                      ...prevValues.profileDetails,
                                      paymentMethod: {
                                        ...prevValues.profileDetails?.paymentMethod,
                                        creditCard: {
                                          ...prevValues.profileDetails?.paymentMethod.creditCard,
                                          chargeToCustomer: e.target.checked,
                                        },
                                      },
                                    },
                                  }))
                                  setIsThereStripeChange(false)
                                }}
                              />
                              {formErrors["paymentMethod"] && (
                                <FormHelperText>{formErrors["paymentMethod"]}</FormHelperText>
                              )}
                              {formErrors["stripeAcId"] && (
                                <FormHelperText>{formErrors["stripeAcId"]}</FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                          {/* <Box className="mt-5 cursor-pointer">
                            <Link className="text-lg cursor-pointer" href="#" underline="none" target="_blank">
                              Where to find this?
                              <svg className="inline align-middle cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.001 20H6.00098C4.89641 20 4.00098 19.1046 4.00098 18V7C4.00098 5.89543 4.89641 5 6.00098 5H10.001V7H6.00098V18H17.001V14H19.001V18C19.001 19.1046 18.1055 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z" fill="#2E3A59" />
                              </svg>
                            </Link>
                          </Box> */}
                          <Box className="flex justify-center items-center mt-20">
                            <Button
                              sx={{
                                color: "secondary.mainLight",
                                backgroundColor: "common.white",
                                borderColor: "secondary.mainLight",
                                border: 2,
                                boxShadow: "none",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "secondary.mainLight",
                                  color: "common.white",
                                  boxShadow: "none",
                                },
                                fontWeight: 600,
                                borderRadius: 3,
                                width: 136,
                                height: 52,
                                fontSize: 16,
                                mr: 3,
                              }}
                              onClick={() => {
                                navigate(-1)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              sx={{
                                color: "common.white",
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                  boxShadow: "none",
                                },
                                fontWeight: 600,
                                borderRadius: 3,
                                width: 231,
                                height: 52,
                                fontSize: 16,
                              }}
                              variant={"contained"}
                              // disabled={(!isSubmitting) ? isThereChange : isSubmitting}
                              disabled={isThereStripeChange}
                              onClick={() => {
                                saveStripePaymentProfile();
                              }}
                            >
                              {isEditStripe ? "Update" : "Add my Stripe  Account"}
                            </Button>
                          </Box>
                        </>) : stripeFormValues.isEnabled ? (
                          <Box className="flex justify-center items-center mt-20">
                            <Button
                              sx={{
                                color: "common.white",
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                  boxShadow: "none",
                                },
                                fontWeight: 600,
                                borderRadius: 3,
                                width: 231,
                                height: 52,
                                fontSize: 16,
                              }}
                              variant={"contained"}
                              // disabled={(!isSubmitting) ? isThereStripeChange : isSubmitting}
                              disabled={isSubmitting}
                              onClick={() => {
                                // saveStripePaymentProfile();
                                setCreateStripeDialog(true);
                              }}
                            >
                              {"Add my Stripe  Account"}
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>

            <SuccessPaymentProfileModal
              open={openSuccessModal}
              handleOk={() => {
                navigate("/home/dashboard", { replace: true });
              }}
              handleClose={() => {
                setOpenSuccessModal(false);
              }}
              isEditTikkie={isEditTikkie}
            />
            <StripeCreatePaymentProfileModal
              loading={loading}
              open={createStripeDialog}
              onClose={() => {
                setCreateStripeDialog(false);
              }}
              onSubmit={async (data) => {
                // deletePaymentProfile()
                console.log("data", data);
                setCreateStripeDialog(false);
                await createStripConnectedAC(data);

                // setCreateStripeDialog(false);
              }}
            />
            <WarningDialog
              open={openWarningDialog}
              text={warningText}
              onClose={() => {
                handleCloseWarningDialog();
              }}
              onSubmit={async () => {
                deletePaymentProfile()
              }}
            />
          </Box>
        </Box>
      </LoadingBackdrop>} />
  );
}
