import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Popover, useMediaQuery, Box } from "@mui/material";
import cogoToast from "cogo-toast";
import moment from "moment";

import { useLocalStorage } from "../../hooks";
import { schoolState } from "../../redux/reducers/schoolReducer";
import { userState } from "../../redux/reducers/userReducer";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { useTheme } from "@mui/material/styles";

import CategoryBox from "../../components/CategoryBox";
import {
    BaseFrameLayout,
    LoadingBackdrop,
} from "../../layouts";
import dropdownbottom from "../../assets/dropdownbottom.svg";
import charetleft from "../../assets/charetleft.svg";
import { useNavigate, useLocation } from "react-router-dom";
import NeewSidebar from "../../components/NewSideBar";
import ActionPopover from "./ActionPopover";
import DeleteDialog from "../../components/DeleteDialog";
import { getUrlForBookings } from "../../utils/confighelpers";
import { displayAvailablePaymentMethods } from "../../utils/constants";


export default function EventBookingDetails({ navigation }) {
    const location = useLocation();
    const [userToken] = useLocalStorage("userToken");
    const [bookingInfo, setBookingInfo] = React.useState();
    const [loading, setLoading] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [delDialogOpen, setDelDialogOpen] = React.useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const fetchBookingsDetails = async () => {
        console.log("location.state.bookingId", location.state.bookingId);
        setLoading(true)
        const config = {
            method: "get",
            url: `${getUrlForBookings()}getBookingDetails/${location.state.bookingId}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const request = await axios(config);
        if (request.status === 200) {
            if (request.data.statusCode === 200) {
                setBookingInfo(request.data.data)
            }
        }
        setLoading(false)
    }

    React.useEffect(() => {
        fetchBookingsDetails()
    }, [])

    const calculateBoughtTickets = () => {
        let totalBookedTickets = 0;

        if (bookingInfo && bookingInfo?.ticketsBought?.length) {
            bookingInfo?.ticketsBought.forEach((item) => {
                totalBookedTickets = totalBookedTickets + parseInt(item.bookingQty)
            })
        }

        return totalBookedTickets
    }

    const calculateBoughtAddons = () => {
        let totalBookedAddons = 0;

        if (bookingInfo && bookingInfo?.addonsBought?.length) {
            bookingInfo?.addonsBought.forEach((item) => {
                totalBookedAddons = totalBookedAddons + parseInt(item.bookingQty)
            })
        }

        return totalBookedAddons
    }

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        let serviceFees = 0;

        if (bookingInfo?.totalStripeAmount) {
            return parseFloat(bookingInfo?.totalStripeAmount || 0).toFixed(2)
        } else if (bookingInfo?.amount) {
            return parseFloat(bookingInfo?.amount || 0).toFixed(2)
        } else {
            if (bookingInfo && bookingInfo?.ticketsBought?.length) {
                bookingInfo?.ticketsBought.forEach((item) => {
                    serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
                    totalPrice = totalPrice + parseFloat(item.totalPrice)
                })
            }

            // return parseFloat(totalPrice + serviceFees).toFixed(2)
            return parseFloat(totalPrice).toFixed(2)
        }
    }

    const calculateUsersReceiveTotalPrice = () => {
        let amountToUser = 0;

        if (bookingInfo && bookingInfo?.ticketsBought?.length) {
            bookingInfo?.ticketsBought.forEach((item) => {
                amountToUser = amountToUser + parseFloat(item?.amountToUser || 0)
            })
        }

        if (bookingInfo && bookingInfo?.addonsBought?.length) {
            bookingInfo?.addonsBought.forEach((item) => {
                amountToUser = amountToUser + parseFloat(item?.amountToUser || 0)
            })
        }

        return parseFloat(amountToUser).toFixed(2)

    }

    const calculateServiceFees = () => {
        let serviceFees = 0;

        if (bookingInfo && bookingInfo?.ticketsBought?.length) {
            bookingInfo?.ticketsBought.forEach((item) => {
                // serviceFees = serviceFees + (parseFloat((item?.serviceFee) ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
                serviceFees = serviceFees + (parseFloat((item?.serviceFee) ? item?.serviceFee : 0))
            })
        }

        if (bookingInfo && bookingInfo?.addonsBought?.length) {
            bookingInfo?.addonsBought.forEach((item) => {
                // serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0) * parseFloat(item?.bookingQty))
                serviceFees = serviceFees + (parseFloat(item?.serviceFee ? item?.serviceFee : 0))
            })
        }
        
        return parseFloat(serviceFees).toFixed(2)
    }

    const calculatePaymentServiceFees = () => {

        if (bookingInfo?.totalPaymentServiceFee) {
            return parseFloat(bookingInfo?.totalPaymentServiceFee).toFixed(2)
        }
        return parseFloat(0).toFixed(2)
    }

    const getStudentInfo = () => {
        let studentDetails = {}

        // if (bookingInfo && bookingInfo?.event_schedule_detail?.participants?.length) {
        //     studentDetails = bookingInfo?.event_schedule_detail?.participants.find((item) => item?.bookingId === bookingInfo.bookingId)
        // }

        if (bookingInfo) {
            studentDetails = bookingInfo?.student_detail
        }

        return studentDetails;
    }

    const handleDelete = async () => {
        setDelDialogOpen(false)
        if (bookingInfo) {
            setLoading(true);
            cogoToast.loading("Loading...");
            const body = {
                bookingId: bookingInfo?.bookingId,
                eventScheduleId: bookingInfo?.eventScheduleId,
                type: "remove",
                isPackage: bookingInfo?.isPackage,
                participantId: bookingInfo?.studentId,
                familyMemberId: "",
                isRefundApplicable: true,
            };
            const config = {
                method: "post",
                url: `${getUrlForBookings()}cancelEventBooking`, // condation is not checked for event
                headers: {
                    "x-access-token": userToken,
                },
                data: body,
            };
            const request = await axios(config);
            setLoading(false);
            if (request.status === 200) {
                if (request.data.statusCode === 200) {
                    console.log(request);
                    cogoToast.success("Removed Participant");
                    await fetchBookingsDetails();
                } else {
                    console.log(request);
                    cogoToast.error(request.data.message);
                }
            }
        }
    };

    const getPaymentStatus = () => {
        let paymentStatus = bookingInfo?.paymentStatus;
        // if ((bookingInfo?.paymentStatus == "Paid" || bookingInfo?.paymentStatus == "Free") && bookingInfo.paymentMethod == "NA" && parseInt(bookingInfo.amount) <= 0) {
        //     paymentStatus = 'Free';
        // }
        var displayContent = (<button
            className={
                "h-[30px] bg-[#5B274B] rounded-lg ml-4"
            }
            onClick={() => null}
            style={{ padding: '0px 10px' }}>
            <p className={"font-bold text-sm text-white text-center"}>
                {paymentStatus}

            </p>
        </button>)
        if (bookingInfo?.status == "Cancelled" && (bookingInfo?.paymentStatus == "Unpaid" || bookingInfo?.paymentStatus == "Not-refunded") && bookingInfo.paymentMode != "Online") {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>Customer never paid for it</span></span>);
        } else if (bookingInfo?.status == "Cancelled" && bookingInfo?.paymentStatus == "Not-refunded" && bookingInfo.paymentMode == "NA" && parseInt(bookingInfo.amount) <= 0) {
            displayContent = (<span>{displayContent}<br></br> <span className="ml-4" style={{ color: '#00000099' }}>It was a free service</span></span>);
        }

        return displayContent
    };

    const getPaymentMethod = () => {
        if (bookingInfo?.paymentMethod && bookingInfo?.paymentMethod !== 'NA') {
            return Object.keys(displayAvailablePaymentMethods).indexOf(bookingInfo?.paymentMethod) >= 0 ? displayAvailablePaymentMethods[bookingInfo?.paymentMethod] : "UNKNOWN";
        } /* else if (bookingInfo?.paymentStatus == "Unpaid" && bookingInfo.paymentMethod == "NA" && bookingInfo.paymentMode != "Online" && parseInt(bookingInfo.amount) > 0) {
            return 'pay at venue';
        } else if ((bookingInfo?.paymentStatus == "Paid" || bookingInfo?.paymentStatus == "Free") && bookingInfo.paymentMethod == "NA" && parseInt(bookingInfo.amount) <= 0) {
            //return 'Free';
            return 'Not Applicable';
        } */
        return 'UNKNOWN';
    };

    console.log("bookingInfo", bookingInfo)

    return (
        <NeewSidebar
            navBarTitleComp={
                <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigation(-1)}>
                    <img src={charetleft} className="inline align-baseline mr-3 cursor-pointer" />
                    <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
                        Booking Details
                    </h3>
                </span>
            }
            children={<LoadingBackdrop loading={loading}>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        py: 2,
                        px: 3,
                        overflowX: "hidden",
                        minHeight: "100vh",
                    }}
                    className="grid"
                >
                    <Box className="flex flex-col w-full">
                        <Box className="flex justify-end p-3">
                            <Box className="flex-none">
                                <button
                                    className={"bg-[#3699FF] text-white rounded w-[160px] p-3"}
                                    onClick={handlePopoverOpen}
                                >
                                    <span className={"flex flex-row items-center"}>
                                        <p className={"w-full"}>Action</p>
                                        <img src={dropdownbottom} alt={""} />
                                    </span>
                                </button>
                            </Box>
                            <Box className="pl-2">
                                <button
                                    className={"bg-brown-coffee text-white rounded w-[210px] p-3 text-center"}
                                    disabled={bookingInfo?.status == "Cancelled" ? true : moment(bookingInfo?.eventId && bookingInfo?.event_schedule_detail ? bookingInfo?.event_schedule_detail.eventDate : bookingInfo?.event_schedule_detail.eventDate).subtract({ hours: 0 }).isBefore()}
                                    style={(bookingInfo?.status == "Cancelled" || moment(bookingInfo?.eventId && bookingInfo?.event_schedule_detail ? bookingInfo?.event_schedule_detail.eventDate : bookingInfo?.event_schedule_detail.eventDate).subtract({ hours: 0 }).isBefore()) ? { backgroundColor: '#D3D3D3' } : null}
                                    // onClick={handleDelete}
                                    onClick={() => setDelDialogOpen(true)}
                                >
                                    Cancel Booking
                                </button>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Booking ID</p>
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold', color: '#00000099' }}>: {bookingInfo?.bookingId}</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((bookingInfo?.status && bookingInfo?.status === "Cancelled") ? { color: '#FF4040' } : {}) }}>{bookingInfo?.status}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Booking Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{moment(bookingInfo?.bookingDate).format('DD-MM-YYYY')}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Cancellation Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.status?.toLowerCase() === "cancelled" ? moment(bookingInfo?.cancellationDate).format('DD-MM-YYYY') : 'Not Applicable'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', color: 'black' }}>Payment Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'left' }}>
                                    <span>:</span>
                                    {getPaymentStatus(bookingInfo)}
                                    {/* <button
                                        className={
                                            "h-[30px] bg-[#5B274B] rounded-lg ml-4"
                                        }
                                        onClick={() => null}
                                        style={{ padding: '0px 10px' }}>
                                        <p className={"font-bold text-sm text-white text-center"}>
                                            {bookingInfo?.paymentStatus}
                                        </p>
                                    </button> */}
                                </span>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Customer Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Full Name</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getStudentInfo()?.fullName ? getStudentInfo()?.fullName : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Email</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#7E00DD' }}>{getStudentInfo()?.email ? getStudentInfo()?.email : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Mobile Number</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{getStudentInfo()?.username ? "" + getStudentInfo()?.username : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>City</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.event_schedule_detail?.location?.city}</span>
                                </span>
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 50px' }}>
                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Purchase Details</p>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Booked</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{bookingInfo?.event_schedule_detail?.eventName ? bookingInfo?.event_schedule_detail?.eventName : (bookingInfo?.eventName || "")}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Status</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', ...((bookingInfo?.event_schedule_detail && bookingInfo?.event_schedule_detail?.eventStatus === "Cancelled") ? { color: '#FF4040' } : {}) }}>{bookingInfo?.event_schedule_detail?.eventStatus ? (bookingInfo?.event_schedule_detail?.eventStatus === "Cancelled" ? "This event is cancelled" : bookingInfo?.event_schedule_detail?.eventStatus) : 'UNKNOWN'}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Activity Date</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#7E00DD' }}>{moment(bookingInfo?.event_schedule_detail?.eventDate).format('DD-MM-YYYY')}</span>
                                </span>
                            </Box>
                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Tickets Bought</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{calculateBoughtTickets()}</span>
                                </span>
                            </Box>

                            {bookingInfo?.ticketsBought?.length ? bookingInfo?.ticketsBought?.map((ticketInfo) => <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', paddingLeft: '20px' }}>{ticketInfo?.ticketType}</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{parseFloat(ticketInfo?.bookingQty * (parseFloat(parseFloat(ticketInfo?.ticketAmount ? ticketInfo?.ticketAmount : 0) + parseFloat(((ticketInfo?.absorbServiceFee == false) ? (ticketInfo?.serviceFee * ticketInfo?.bookingQty) : 0))))).toFixed(2)} <span style={{ color: '#00000099' }}>{`(${ticketInfo?.bookingQty} X €${parseFloat(parseFloat(ticketInfo?.ticketAmount ? ticketInfo?.ticketAmount : 0) + parseFloat(((ticketInfo?.absorbServiceFee == false) ? (ticketInfo?.serviceFee * ticketInfo?.bookingQty) : 0))).toFixed(2)})`}</span></span>
                                </span>
                            </Box>) : null}

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Add-ons Bought</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>{calculateBoughtAddons()}</span>
                                </span>
                            </Box>

                            {bookingInfo?.addonsBought?.length ? bookingInfo?.addonsBought?.map((addonInfo) => <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px', paddingLeft: '20px' }}>{addonInfo?.addonName}</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{parseFloat(addonInfo?.bookingQty * (parseFloat(parseFloat(addonInfo?.ticketAmount ? addonInfo?.ticketAmount : 0) + parseFloat(addonInfo?.serviceFee * addonInfo?.bookingQty)))).toFixed(2)} <span style={{ color: '#00000099' }}> {`(${addonInfo?.bookingQty} X €${parseFloat(parseFloat(addonInfo?.ticketAmount ? addonInfo?.ticketAmount : 0) + parseFloat(addonInfo?.serviceFee * addonInfo?.bookingQty)).toFixed(2)})`}</span></span>
                                </span>
                            </Box>) : null}

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Processing Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculatePaymentServiceFees()}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Method</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right', textTransform: 'capitalize' }}>
                                    <span>:</span>
                                    {/* <span style={{ marginLeft: '20px' }}>{bookingInfo?.paymentMethod && bookingInfo?.paymentMethod !== 'NA' ? bookingInfo?.paymentMethod : 'UNKNOWN'}</span> */}
                                    <span style={{ marginLeft: '20px' }}> {getPaymentMethod()}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <Box className="flex px-3 py-1 rounded bg-[#D9D9D9]">
                                    <p style={{ fontSize: '16px', lineHeight: '24px', width: '188px' }}>Total Amount Paid</p>
                                    <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                        <span>:</span>
                                        <span style={{ marginLeft: '20px' }}>€{calculateTotalPrice()} <span style={{ color: '#00000099' }}>Including VAT & Service Fee</span></span>
                                    </span>
                                </Box>
                            </Box>

                            <Box className="flex mb-10">
                                <p style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 'bold' }}>Your payout Details</p>
                            </Box>

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Amount Customer Paid</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculateTotalPrice()}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Platform Service Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#FF5733' }}>- €{calculateServiceFees() + ` (${calculateBoughtTickets()} X €${parseFloat(calculateServiceFees() / calculateBoughtTickets()).toFixed(2)})`}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>Payment Processing Fee</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px', color: '#FF5733' }}>- €{calculatePaymentServiceFees()}</span>
                                </span>
                            </Box>

                            <Box className="flex mb-5">
                                <Box className="flex px-3 py-1 rounded bg-[#D9D9D9]">
                                    <p style={{ fontSize: '16px', lineHeight: '24px', width: '188px' }}>Expected Payout</p>
                                    <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                        <span>:</span>
                                        <span style={{ marginLeft: '20px' }}>€{calculateUsersReceiveTotalPrice()} <span style={{ color: '#00000099' }}>Including VAT & Service Fee</span></span>
                                    </span>
                                </Box>
                            </Box>

                            {/* <Box className="flex mb-5">
                                <p style={{ fontSize: '16px', lineHeight: '24px', width: '200px' }}>What you get</p>
                                <span style={{ fontSize: '16px', lineHeight: '24px', textAlign: 'right' }}>
                                    <span>:</span>
                                    <span style={{ marginLeft: '20px' }}>€{calculateUsersReceiveTotalPrice()} </span>
                                </span>
                            </Box> */}
                        </Box>

                    </Box>
                    {bookingInfo ? <ActionPopover
                        bookingDetails={bookingInfo}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleUpdate={fetchBookingsDetails}
                        setLoading={setLoading}
                    /> : null}

                    <DeleteDialog
                        open={delDialogOpen}
                        title={"WARNING"}
                        // message={"Are you sure you want to cancel this booking?"}
                        message={"Cancellation will lead to full refund to the customer. Are you sure you want to cancel this booking?"}
                        type={""}
                        handleClose={() => setDelDialogOpen(false)}
                        handleDelete={() => handleDelete()}
                    />
                </Box>
            </LoadingBackdrop>} />
    );
}
