import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useMediaQuery } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import NeewSidebar from "../../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../../layouts";
import GeneralTab from "./GeneralTab";
import PoliciesTab from "./PoliciesTab";
import PricingTab from "./PricingTab";
import ScheduleTab from "./schedule/ScheduleTab";

import { minutesToDuration } from "../../../helpers";
import { schoolState } from "../../../redux/reducers/schoolReducer";


import cancel_img from "../../../assets/Cancel Pay.svg";
import info_i from "../../../assets/i.svg";
import invite_img from "../../../assets/Invite Code.svg";
import payment from "../../../assets/Payment.svg";
import { getUrlForEvent, getUrlForStage } from "../../../utils/confighelpers";

const popperModifiers = { placement: "bottom" };

export default function EditBusinessProfileEvent({ navigation, profileFor }) {
  const [userToken] = useLocalStorage("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const [loading, setLoading] = React.useState(false);
  const [successMgModalOpen, setSuccessMgModalOpen] = React.useState(false);
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  // console.log("dashboardReduxState.calendarSessionData", dashboardReduxState.calendarSessionData)

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  // const title = " Add New " + (location.state ? location.state.title : "")
  const title = " Edit " + profileFor.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  })
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabCurrentState(newValue);
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const sessionDataToDisplay = dashboardReduxState.calendarSessionData;
  const eventInfo = sessionDataToDisplay.eventId
  const edit = null;
  const editData = {};
  const durationObj = minutesToDuration(eventInfo.duration);
  const classBodyData = {
    eventId: eventInfo._id,
    photo: eventInfo.eventPhoto,
    eventCategory: {},
    categories: {},
    eventName: eventInfo.eventName,
    tagLine: eventInfo.tagLines,
    classDescription: eventInfo.description,
    price: eventInfo.cost,
    seats: eventInfo.capacity,
    duration: durationObj,
    durationstring: `${durationObj.hours} hr ${durationObj.mins} min`,
    sessions: "",
    whatToCarry: eventInfo.whatToCarry,
    freeCancellation: eventInfo.eventCancelationPolicy?.isEnabled ? true : false,
    cancellationPeriod: eventInfo.eventCancelationPolicy?.minutes === 60
      ? "1hr"
      : eventInfo.eventCancelationPolicy?.minutes === 1440
        ? "24hrs"
        : eventInfo.eventCancelationPolicy?.minutes === 2880
          ? "48hrs"
          : eventInfo.eventCancelationPolicy?.minutes === 10080
            ? "1 week"
            : "",
    inviteCodeOnlyBooking: eventInfo.isPrivate ? true : false,
    paymentAtVenue: eventInfo.paymentAtVenue ? eventInfo.paymentAtVenue : false,
    isEvent: eventInfo.isEvent ? eventInfo.isEvent : true,
    eventPricingList: eventInfo.eventPricingList ? eventInfo.eventPricingList : [],
    addonsPricingList: eventInfo.addonsPricingList ? eventInfo.addonsPricingList : [],
  };
  const [formValues, setFormValues] = React.useState(classBodyData);
  const [formScheduleValues, setFormScheduleValues] = React.useState({});
  const [defaultScheduleData, setDefaultScheduleData] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [catRefEl, setCatRefEl] = React.useState();
  const [catPopperEl, setCatPopperEl] = React.useState();
  const catPopper = usePopper(catRefEl, catPopperEl, popperModifiers);
  const [sesRefEl, setSesRefEl] = React.useState();
  const [sesPopperEl, setSesPopperEl] = React.useState();
  const sesPopper = usePopper(sesRefEl, sesPopperEl, popperModifiers);
  const [durRefEl, setDurRefEl] = React.useState();
  const [durPopperEl, setDurPopperEl] = React.useState();
  const durPopper = usePopper(durRefEl, durPopperEl, popperModifiers);
  const [seatsRefEl, setSeatsRefEl] = React.useState();
  const [seatsPopperEl, setSeatsPopperEl] = React.useState();
  const seatsPopper = usePopper(seatsRefEl, seatsPopperEl, popperModifiers);
  const schoolReduxState = useSelector(schoolState);

  const defaultDataSaveProgress = {
    isStartSubmiting: false,
    isButtonShow: true,
  };
  const [dataSaveProgress, setDataSaveProgress] = React.useState(defaultDataSaveProgress);

  const handleUpdate = async (isPublish = true) => {
    setSubmitting(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    console.log("formValues", formValues);
    const errors = {};
    if (!formValues.photo) {
      errors.photo = "Required!";
    }
    if (!Object.keys(formValues.eventCategory).length) {
      errors.eventCategory = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.eventName) {
      errors.eventName = "Required!";
    }
    if (!formValues.classDescription) {
      errors.classDescription = "Required!";
    } else if (formValues.classDescription.length < 5) {
      errors.classDescription = `Enter at least 5 letters to explain your ${title}`;
    }
    if (!formValues.duration) {
      errors.duration = "Required";
    }
    /* if (title === "Course" && !formValues.sessions) {
      errors.sessions = "Required!";
    } */
    if (formValues.freeCancellation) {
      if (!formValues.cancellationPeriod) {
        errors.cancellationPeriod = "Required!";
      }
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    const durationSplit = formValues.durationstring.split(" ");
    const durationInNum =
      Number(durationSplit[0]) * 60 + Number(durationSplit[2]);

    const cancelationPolicy =
      formValues.cancellationPeriod === "1hr"
        ? { isEnabled: true, text: "1 hour", minutes: 60 }
        : formValues.cancellationPeriod === "24hrs"
          ? { isEnabled: true, text: "24 hours", minutes: 1440 }
          : formValues.cancellationPeriod === "48hrs"
            ? { isEnabled: true, text: "48 hours", minutes: 2880 }
            : formValues.cancellationPeriod === "1 week"
              ? { isEnabled: true, text: "1 week", minutes: 10080 }
              : null;

    let body = {
      eventId: formValues.eventId,
      isPackage: profileFor === "course" ? true : false,
      eventCategory: formValues.eventCategory._id,
      catId: formValues.categories._id,
      eventType: formValues.categories.name,
      eventName: formValues.eventName,
      description: formValues.classDescription,
      cost: formValues.price,
      tagLines: formValues.tagLine,
      capacity: formValues.seats,
      duration: durationInNum,
      whatToCarry: formValues.whatToCarry,
      eventPhoto: formValues.photo,
      ...(profileFor === "course" && { sessionInPackage: formValues.sessions }),
      isPrivate: formValues.inviteCodeOnlyBooking,
      eventCancelationPolicy: formValues.freeCancellation
        ? cancelationPolicy
        : { isEnabled: false },
      paymentAtVenue: formValues.paymentAtVenue,
      isEvent: formValues.isEvent,
      addonsPricingList: formValues.addonsPricingList,
      isPublic: isPublish,
    };

    if (!edit) {
      //add
    } else {
      body = {
        eventId: editData._id,
      };
    }
    let config = {};

    config = {
      method: "put",
      url: `${getUrlForEvent()}update`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };

    console.log("saveClass", config);
    setDataSaveProgress((prevValues) => ({
      ...prevValues,
      isStartSubmiting: true,
    }));
    const request = await axios(config);

    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        let eventData = request.data.data;
        fetchScheduleDetails();
        setSubmitting(false);
        setLoading(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          isStartSubmiting: false,
        }));

        /** **/
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error(request.data.message);
        setLoading(false);
        setSubmitting(false);
      }
    } else {
      setLoading(false);
    }
  };
  const clearFormValues = () => {
    setFormValues(classBodyData);
    setFormScheduleValues({});
  };

  const fetchScheduleDetails = async () => {
    setLoading(true);
    const isPckg = sessionDataToDisplay.isPackage;
    const config = {
      method: "get",
      url: `${getUrlForEvent()}schedule/get?eventScheduleId=${sessionDataToDisplay._id}&isPackage=${isPckg}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        dispatch(dashboardActions.setCalendarSessionId(sessionDataToDisplay._id));
        dispatch(dashboardActions.setCalendarSessionData(request.data.data));
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setDataSaveProgress(defaultDataSaveProgress);
    fetchScheduleDetails();
  }, []);
  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid min-w-[1382px] max-w-[1382px]"
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabCurrentState}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                  fontSize: 18
                }}>
                  <Tab label="General" value="1" />
                  <Tab label="Pricing" value="2" />
                  <Tab label="Policies" value="3" />
                  <Tab label="Schedule" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <GeneralTab
                  profileFor={profileFor}
                  title={title}
                  openSuccessModal={() => setSuccessMgModalOpen(true)}
                  edit={true}
                  editData={null}
                  setLoading={setLoading}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  formValidationErrors={formErrors}
                  setTabCurrentState={setTabCurrentState}
                  handleUpdate={handleUpdate}
                  handleCancel={() => {
                    dispatch(dashboardActions.setCalendarSessionId(-1));
                    dispatch(dashboardActions.setCalendarOpen(false));
                    navigate("/home/dashboard");
                  }}
                />
              </TabPanel>
              <TabPanel value="2">
                <PricingTab
                  edit={true}
                  setLoading={setLoading}
                  profileFor={profileFor}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  handleUpdate={handleUpdate}
                />
              </TabPanel>
              <TabPanel value="3">
                <PoliciesTab
                  edit={true}
                  setLoading={setLoading}
                  formErrors={formErrors}
                  cancel_img={cancel_img}
                  info_i={info_i}
                  formValues={formValues}
                  handleFormValueChange={handleFormValueChange}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  payment={payment}
                  invite_img={invite_img}
                  handleUpdate={handleUpdate}
                  handleCancel={() => {
                    dispatch(dashboardActions.setCalendarSessionId(-1));
                    dispatch(dashboardActions.setCalendarOpen(false));
                    navigate("/home/dashboard");
                  }}
                />
              </TabPanel>
              <TabPanel value="4">
                <ScheduleTab
                  edit={true}
                  reloadScheduleDetails={fetchScheduleDetails}
                  duration={formValues.duration}
                  classObj={formValues}
                  setLoading={setLoading}
                  formErrors={formErrors}
                  formScheduleValues={formScheduleValues}
                  setFormScheduleValues={setFormScheduleValues}
                  setTabCurrentState={setTabCurrentState}
                  defaultDataSaveProgress={defaultDataSaveProgress}
                  dataSaveProgress={dataSaveProgress}
                  setDataSaveProgress={setDataSaveProgress}
                  defaultScheduleData={defaultScheduleData}
                  setDefaultScheduleData={setDefaultScheduleData}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </LoadingBackdrop>} />
  );
}
