import moment from "moment";
import React from "react";

import { Box, useMediaQuery } from "@mui/material";
import profile_img from "../../../assets/avatar_placeholder.png";
import euro_circle from "../../../assets/euro_circle.svg";
import location_png from "../../../assets/location.svg";
import schedule_calender from "../../../assets/schedule_calender.svg";
import { cutString } from "../../../helpers";

export default function ScheduleTile({
  handleSessionClick,
  item,
  userDetails,
}) {

  const isLaptopS = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1440px)"
  );
  const slotsDisplay = {
    // "15": "15 minutes",
    "15": "15 min",
    "30": "30 min",
    "45": "45 min",
    "60": "60 min",
    "75": "1 hours 15 min",
    "90": "1 hours 30 min",
    "105": "1 hours 45 min",
    "120": "2 hours",
    "135": "2 hours 15 min",
    "150": "2 hours 30 min",
    "165": "2 hours 45 min",
    "180": "3 hours",
    "195": "3 hours 15 min",
    "210": "3 hours 30 min",
    "225": "3 hours 45 min",
    "240": "4 hours",
    "255": "4 hours 15 min",
    "270": "4 hours 30 min",
    "285": "4 hours 45 min",
    "300": "5 hours",
    "315": "5 hours 15 min",
    "330": "5 hours 30 min",
    "345": "5 hours 45 min",
    "360": "6 hours",
    "375": "6 hours 15 min",
    "390": "6 hours 30 min",
    "405": "6 hours 45 min",
    "420": "7 hours",
    "435": "7 hours 15 min",
    "450": "7 hours 30 min",
    "465": "7 hours 45 min",
    "480": "8 hours",
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const ticketAmount = (row) => {
    if (row?.amount) {
      return parseFloat(row?.amount || 0).toFixed(2)
    } else {
      return row?.servicesBought ? parseFloat(row.servicesBought.totalPrice).toFixed(2) : "0"
    }
  }

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  const getResourceName = () => {
    let resourceDetail = { fullName: "" };
    if (item?.resourceId && item?.resource_detail && item?.resource_detail?._id) {
      resourceDetail = item?.resource_detail;
    } else if (item?.resourceId && item?.teacher_detail && item?.teacher_detail?.myResource && item?.teacher_detail?.myResource.length > 0) {
      item?.teacher_detail?.myResource.map((d) => {
        if (d._id.toString() == item?.resourceId) {
          resourceDetail = d;
        }
      });
    }
    return (<>
      {resourceDetail?.fullName}
      <img className={`${isLaptopS ? "w-4 h-4" : "w-6 h-6"} ml-2 inline rounded-full`} src={resourceDetail.profilePic ?? profile_img} alt={""} />
    </>) || "";
  }

  const getPaymentStatus = () => {
    let paymentStatus = item?.paymentStatus;
    var statusColor = " border-[#FF4040] text-[#FF4040] "
    if (item?.paymentStatus.toLowerCase() === "paid") {
      statusColor = " border-[#584CF4] text-[#584CF4] "
    } else if (item?.status == "Cancelled" && (item?.paymentStatus == "Unpaid" || item?.paymentStatus == "Not-refunded") && item.paymentMode != "Online") {
      // statusColor = " bg-[#FF4040] text-[#FF4040] "
    } else if (item?.status == "Cancelled" && item?.paymentStatus == "Not-refunded" && item.paymentMode == "NA" && parseInt(item.amount) <= 0) {
      // statusColor = " bg-[#FF4040] text-[#FF4040] "
    }
    var displayContent = (<button
      className={
        `h-[30px] border ${statusColor} rounded-lg ml-4`
      }
      onClick={() => null}
      style={{ padding: '0px 10px' }}>
      <p className={"font-bold text-sm text-center"}>
        {paymentStatus}

      </p>
    </button>)

    return displayContent
  };

  return (

    <div>
      <div
        className={`my-3 p-4 flex flex-col gap-2 items-start cursor-pointer bg-white hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl`}
        onClick={() => { handleSessionClick(); }}
      >
        <div className="flex flex-row w-full justify-center items-center">

          <div className="flex-1 text-sm text-[#481B3A] font-bold">
            {moment(item?.slot_date + " " + item?.slot_start_time, 'YYYY-MM-DD h:mm A').format('H:mm')}
            -{moment(item?.slot_date + " " + item?.slot_end_time, 'YYYY-MM-DD h:mm A').format('H:mm')}
          </div>
          <div className="flex">
            <div
              className={`px-2 text-xs text-[#000]`}
            >
              {cutString(item?.student_detail.fullName, isLaptopS ? 9 : 15, true)}
              <img className={`${isLaptopS ? "w-4 h-4" : "w-6 h-6"} ml-2 inline rounded-full`} src={item?.student_detail.profilePic ?? profile_img} alt={""} />
            </div>
          </div>
        </div>
        <div className="px-2 border-t-[0.5px] border-[#00000052] w-full rounded-2xl"></div>
        <div className="flex flex-row w-full justify-center items-center">

          <div className="w-full flex flex-row items-top gap-4 grow">
            <div
              className=""
            >
              <img
                className={`w-[107.28px] max-w-[107.28px] h-[77px] rounded-xl object-fill`}
                src={item?.service_detail.servicePhoto}
                alt={item?.service_detail.serviceName}
              />
            </div>
            <div className={`flex-1 item-top`}>
              <div className={`flex flex-col item-center w-full gap-1`}>
                <h3
                  className={`text-[#481B3A]" font-bold text-lg capitalize text-ellipsis overflow-hidden max-h-7 break-all`}
                  style={{
                    display: "block",
                    maxWidth: "285px",
                    wordBreak: "nowrap",
                  }}
                >
                  {cutString(item?.service_detail.serviceName, isLaptopS ? 15 : 25, true)}
                </h3>
                <p className={`text-[#880088] text-xs`}>Duration - {slotsDisplay[item?.servicesBought?.duration]} - €{ticketAmount(item)}</p>
                <div
                  className={`text-xs text-[#000]`}
                >
                  With {getResourceName()}
                </div>
              </div>
            </div>
            <div className={"flex flex-col justify-end items-end gap-8"}>
              <div className={"flex items-center text-[#5B274B] font-bold text-base"}>
                {getPaymentStatus()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`${"my-3"} p-4 flex flex-row items-start gap-4 cursor-pointer bg-white hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl`}
      >
        <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
          <div
            className=""
          >
            <img
              className={`${"w-[127.28px] max-w-[127.28px] h-[101px] rounded-xl"
                } object-fill`}
              src={item?.service_detail.servicePhoto}
              alt={"session img"}
            />
          </div>
          <div className={`flex item-top`}>
            <div className={`flex flex-col item-center w-full gap-1`}>
              <h3
                className={`text-[#481B3A]" font-bold text-lg capitalize text-ellipsis overflow-hidden max-h-7 break-all`}
                style={{
                  display: "block",
                  maxWidth: "285px",
                  wordBreak: "nowrap",
                }}
              >
                {cutString(item?.service_detail.serviceName, isLaptopS ? 15 : 25, true)}
              </h3>
              <p className={`text-[#7E00DD] text-xs`}>With {getResourceName()}</p>
              <div className={"flex flex-row items-center gap-2"}>
                <img className={"w-4 h-4"} src={schedule_calender} alt={""} />
                <p
                  className={`text-granite-gray ${isLaptopS ? "text-xs" : "text-sm"}`}
                >
                  {moment(item?.slot_date + " " + item?.slot_start_time, 'YYYY-MM-DD h:mm A').format('DD MMM, ddd, h:mm A')}
                  -{moment(item?.slot_date + " " + item?.slot_end_time, 'YYYY-MM-DD h:mm A').format('h:mm A')}
                </p>
              </div>
              <div className={"flex flex-row items-center gap-2"}>
                <img className={"w-4 h-4"} src={location_png} alt={""} />
                <p
                  className={`text-granite-gray ${isLaptopS ? "text-xs" : "text-sm"}`}
                >
                  {cutString(item?.venue_detail.venueName, 30, true)}
                </p>
              </div>
              <div className={"flex flex-row items-center gap-2"}>
                <img className={"w-4 h-4"} src={euro_circle} alt={""} />
                <p
                  className={`text-granite-gray ${isLaptopS ? "text-xs" : "text-sm"}`}
                >
                  €{ticketAmount(item)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex flex-col justify-between  items-end gap-8"}>

          <div className={"flex items-center text-[#5B274B] font-bold text-base"}>
            {slotsDisplay[item?.servicesBought?.duration]}&nbsp;
          </div>
          <div className={"flex items-center text-brown-coffee"}>
            <Box
              className={`p-1 px-2 mt-2 text-xs rounded-md bg-[#F3F6F9] text-[#000]`}
              sx={{}}
            >
              {cutString(item?.student_detail.fullName, isLaptopS ? 9 : 15, true)}
              <img className={`${isLaptopS ? "w-4 h-4" : "w-6 h-6"} ml-2 inline rounded-full`} src={item?.student_detail.profilePic ?? profile_img} alt={""} />
            </Box>
          </div>
        </div>
      </div> */}
    </div>
  );
}
