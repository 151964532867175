import React from "react";
import { Box, Stack, Avatar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconTint from "react-icon-tint";

import cross_svg from "../assets/cross.svg";

export default function CategoryBoxSelected(props) {
  const {
    add,
    addOnClick,
    label,
    vector,
    bgColor,
    svgFill,
    remove,
    removeOnClick,
    elementData,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  return (
    <div onClick={add ? addOnClick : undefined}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: bgColor,
            borderRadius: 4,
            border: 1,
            borderColor: "ButtonShadow",
            cursor: add && "pointer",
            p: 1.5,
            mr: 1,
            mt: 1,
            // height: isMobile ? 59 : isTablet ? 69 : 89,
          }}
        >
          {label && (
            <p
              className={`sm:text-sm md:text-xs font-bold text-center`}
              style={{
                // color: "#481B3A",
                color: svgFill
              }}
            >
              {label}
            </p>
          )}

          {remove && (
            <button
              onClick={remove ? removeOnClick : undefined}
              style={{ color: "white", }}
            >
              <Avatar
                sx={{
                  backgroundColor: bgColor,
                  width: 20,
                  height: 20,
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                <img src={cross_svg} alt={""} />
              </Avatar>
            </button>
          )}
        </Box>
      </Box>
    </div>
  );
}
