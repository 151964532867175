import React from "react";
import {
  startOfWeek,
  format,
  addDays,
  // getWeek,
  lastDayOfWeek,
  isSameDay,
} from "date-fns";
import { Collapse } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelectedDate",
})(({ theme, isSelectedDate, isEventDate }) => ({
  ...(isSelectedDate && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.7,
    },
  }),
  ...(isEventDate && {
    color: "#73d",
    fontWeight: 700,
  }),
}));

export default function CalendarView({
  selectedDate,
  onDateClickHandle,
  eventDatesRange,
  eventDates,
}) {
  // eslint-disable-next-line no-unused-vars
  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  // const [currentWeek, setCurrentWeek] = React.useState(getWeek(currentMonth));
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(selectedDate ?? currentMonth, {
      weekStartsOn: 0,
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className={
            "font-medium text-granite-gray text-sm sm:text-xs grid place-items-center w-9 h-9"
          }
          key={i}
        >
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return (
      <div className={"flex flex-row gap-4 sm:gap-2 lg:gap-2"}>{days}</div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDays = React.useMemo(renderDays, [selectedDate]);

  const renderCells = () => {
    const startDate = startOfWeek(selectedDate ?? currentMonth, {
      weekStartsOn: 0,
    });
    const endDate = lastDayOfWeek(selectedDate ?? currentMonth, {
      weekStartsOn: 0,
    });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        // const cloneDay = day;
        days.push(
          <div
            className={`font-semibold text-lg sm:text-xs grid place-items-center ${
              isSameDay(day, selectedDate ?? new Date())
                ? "bg-portland-orange rounded-full text-white"
                : "text-light-brown-coffee"
            } w-9 h-9 sm:w-6 sm:h-6`}
            key={day}
          >
            <span>{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className={"flex flex-row gap-4 sm:gap-3 lg:gap-2"} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div>{rows}</div>;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCells = React.useMemo(renderCells, [selectedDate]);

  const renderPickersDay = (day, selectedDates, pickersDayProps) => {
    if (!selectedDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    let eventDay;
    let isEventDate;
    for (let i = 0; i <= eventDates.length; i++) {
      eventDay = eventDates[i];
      isEventDate = isSameDay(day, eventDay);
    }
    const isSelectedDate = isSameDay(day, selectedDate);

    return (
      <CustomPickersDay
        isSelectedDate={isSelectedDate}
        isEventDate={isEventDate}
        {...pickersDayProps}
      />
    );
  };

  return (
    <div className={"bg-white border border-lilac rounded-xl py-2 px-6 w-full flex justify-center items-center"}>
      <div className={"cursor-pointer inline-block"} onClick={() => setCollapseOpen(true)}>
        <div
          className={`${
            collapseOpen === false ? "block" : "hidden"
          } flex flex-row items-center gap-2`}
        >
          {memoizedDays}
          <ExpandLess color={"primary"} />
        </div>
        <div
          className={`${
            collapseOpen === false ? "block" : "hidden"
          } flex flex-row items-center gap-2`}
        >
          {memoizedCells}
          <ExpandMore color={"primary"} />
        </div>
      </div>
      <Collapse
        in={collapseOpen}
        timeout={"auto"}
        unmountOnExit
        sx={{ "& .MuiCalendarPicker-root": { color: "common.black" } }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CalendarPicker
            value={selectedDate}
            onChange={(day) => {
              onDateClickHandle(day);
              setCollapseOpen(false);
            }}
            allowSameDateSelection
            // minDate={eventDatesRange.from}
            // maxDate={eventDatesRange.to}
            renderDay={renderPickersDay}
          />
        </LocalizationProvider>
      </Collapse>
    </div>
  );
}
