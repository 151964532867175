import { Combobox } from "@headlessui/react";
import { Button, Checkbox, FormControlLabel, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useState } from "react";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Calendar } from "react-multi-date-picker";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import institute_img from "../../../assets/Institute.svg";
import ArrowDown from "../../../assets/arrow.svg";
import CalendarIcon from "../../../assets/calender_black.svg";
import marker_img from "../../../assets/location_marker.svg";
import NeewSidebar from "../../../components/NewSideBar";
import { useLocalStorage } from "../../../hooks";
import { LoadingBackdrop } from "../../../layouts";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";
import AddEditFreeSlotModal from "./AddEditFreeSlotModal";
import DayTimeSelector from "./DayTimeSelector";
import FreeSlotPopover from "./FreeSlotPopover";
import MyAvailableTimeForm from "../../settings/myAvailableTime/MyAvailableTimeForm";
import { getUrlForServices } from "../../../utils/confighelpers";


const openDayAndTimeObject = [
  {
    day: "monday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "tuesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "wednesday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "thursday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "friday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "saturday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
  {
    day: "sunday",
    isOpen: true,
    openTime: [
      {
        startTime: "9:00 AM",
        endTime: "05:00 PM",
      },
    ],
  },
];

const AddVenue = ({ navigation }) => {

  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const isLaptopS = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1440px)"
  );
  const [loading, setLoading] = React.useState(false);
  const [loadingDone, setLoadingDone] = React.useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [venueDetails, setVenueDetails] = useState({ 
    venueName: "", 
    validityStartDate: new Date(),
    validityEndDate: moment().add(30, 'days').format("YYYY-MM-DD"),
    scheduleWithoutValidity: false,
    openDayAndTime: openDayAndTimeObject });
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    // endDate: new Date(),
    endDate: moment().add(30, 'days').format("YYYY-MM-DD"),
  });
  const [openDayAndTime, setOpenDayAndTime] = useState(openDayAndTimeObject);
  const [busyDayAndTime, setBusyDayAndTime] = useState([
    // {
    //   slot_date: new Date(),
    //   slot_start_time: "7:00 PM",
    //   slot_end_time: "10:00 PM",
    // }
  ]);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [query, setQuery] = useState("");
  const userReduxState = useSelector(userState);
  const [address, setAddress] = useState(location.state !== null && location.state?.address ? location.state.address : {});
  const [userToken] = useLocalStorage("userToken");
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const [edit, setEdit] = React.useState(false);
  const [editValues, setEditValues] = React.useState({});
  const [createFreeSlotDialog, setCreateFreeSlotDialog] = React.useState({
    isEdit: false,
    open: false,
    text: "",
    data: {},
  });
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });

  const onDateRangeSelect = (startDate, endDate) => {
    setVenueDetails((prevValues) => ({
      ...prevValues,
      validityStartDate: startDate,
      validityEndDate: endDate
    }));
    // setDateRange({ startDate, endDate });
    setOpenCalendar(false);
  };

  const renderDatePicker = () => {
    return (
      <>
        <div className="flex flex-col mt-7">
          <p className="text-base leading-[18px] font-normal text-black/[0.6]">
            Enter Validity period for this schedule
          </p>
          <div className="flex justify-between items-center mt-[11px] pr-[11px] pl-[27px] pt-[27px] pb-[23px] border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-[19px]"
            onClick={() => {
              if (venueDetails.scheduleWithoutValidity) {
                setOpenCalendar((prev) => false);
              } else {
                setOpenCalendar((prev) => !prev);
              }
            }}
          >
            <div className="flex flex-1">
              <img
                src={CalendarIcon}
                alt="calendar icon"
                className="h-[22px] w-[18px] mr-[17px]"
              />
              <input
                className={`mr-20 w-full bg-transparent text-lg leading-[22px] font-normal ${venueDetails.scheduleWithoutValidity ? "text-[#EBEBEB]" : "text-[#212121]"}`}
                disabled
                placeholder="Select Validatity period"
                value={
                  venueDetails?.validityStartDate && venueDetails.validityEndDate
                    ? `${moment(venueDetails?.validityStartDate).format(
                      "Do-MMM-YYYY"
                    )} to ${moment(venueDetails?.validityEndDate).format("Do-MMM-YYYY")}`
                    : ""
                }
              />
            </div>
            <div className="flex justify-end">
              <img
                src={ArrowDown}
                alt="arrow down"
                className="rotate-90 h-[15px] w-2 cursor-pointer"
              />
            </div>
          </div>
        </div>
        {openCalendar && (
          <Calendar
            className="bookzy-calendar bookzy-border bookzy-range-calendar"
            range
            rangeHover
            minDate={new Date()}
            value={[venueDetails?.validityStartDate, venueDetails?.validityEndDate]}
            numberOfMonths={2}
            onChange={(event) => {
              const startDate = event?.[0]?.toDate();
              const endDate = event?.[1]?.toDate();
              if (startDate && endDate) {
                onDateRangeSelect(startDate, endDate);
              }
            }}
          />
        )}
      </>
    );
  };

  const onToggleSwitch = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((obj) => {
      if (obj.day === item.day) {
        let isOpen = !obj.isOpen;
        obj.isOpen = isOpen;
        if (isOpen) {
          obj.openTime = [
            {
              startTime: "9:00 AM",
              endTime: "05:00 PM",
            },
          ];
        } else obj.openTime = [];
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeChange = (openTime, index) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj, timeIndex) => {
      if (index === timeIndex) {
        dayAndTimeObj.openTime = openTime;
      }
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const onTimeRangeCopy = (item) => {
    let dayTimeSchedule = JSON.parse(JSON.stringify(openDayAndTime));
    dayTimeSchedule.forEach((dayAndTimeObj) => {
      dayAndTimeObj.openTime = item.openTime;
    });
    setOpenDayAndTime(dayTimeSchedule);
  };

  const postAddress = (placeDetails, placeGeocode, coordinates) => {
    let city =
      placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name;
    const address = {
      addressType: "current",
      address: placeDetails.description,
      description: placeDetails.description,
      houseNumber: placeDetails.structured_formatting.main_text,
      city: placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name,
      zip: placeGeocode.address_components[
        placeGeocode.address_components.length - 1
      ].types.find((type) => type.startsWith("postal"))
        ? placeGeocode.address_components[
          placeGeocode.address_components.length - 1
        ].long_name
        : "",
      addressName: city ? city : "Business Address",
      location: {
        type: "Point",
        coordinates,
      },
    };
    setAddress(address);
  };

  const handleAddVenue = async () => {

    if (venueDetails.venueName === "") {
      cogoToast.error("Please enter venue name");
      return;
    }
    if (Object.keys(address).length === 0) {
      cogoToast.error("Please enter work location address");
      return;
    }
    openDayAndTime?.forEach((item) => {
      item?.openTime?.forEach((timings) => {
        if (!timings?.startTime || !timings?.endTime) {
          cogoToast.error("Please select timings");
          return;
        }
      });
    });
    const payloadBody = {
      addedByUserId: userReduxState.userData?._id,
      venueName: venueDetails.venueName || "",
      about: "",
      scheduleWithoutValidity: venueDetails.scheduleWithoutValidity || false,
      validityStartDate: moment(venueDetails?.validityStartDate).format("YYYY-MM-DD"),
      validityEndDate: moment(venueDetails?.validityEndDate).format("YYYY-MM-DD"),
      address: address,
      openDayAndTime: venueDetails?.openDayAndTime,
      // busyDayAndTime: busyDayAndTime,
    };
    let config = {};
    if (edit) {
      config = {
        method: "post",
        url: `${getUrlForServices()}venue/update/${editValues._id}`,
        headers: {
          "x-access-token": userToken,
        },
        data: payloadBody,
      };
    } else {
      config = {
        method: "post",
        url: `${getUrlForServices()}venue/add`,
        headers: {
          "x-access-token": userToken,
        },
        data: payloadBody,
      };
    }
    setLoading(true)
    const request = await axios(config);
    setLoading(false)
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        cogoToast.success(request.data.message);
        if (location.state?.from === "list") {
          navigation("/myvenue/");
        } else {
          navigation("/businessprofilesetup/done");
          // navigation("/businessprofilesetup/subscription");
        }
      } else {
        cogoToast.error(request.data.message);
      }
    }
  };

  const renderLayout = (layoutType = "full", children) => {
    return layoutType == "full" ? (
      <LoadingBackdrop>
        <div>
          <div className="py-[35px] px-[46px] shadow-[0_4px_7px_-0px_rgba(0,0,0,0.02)]">
            <p className="text-[#481B3A] text-4xl leading-[44px] font-extrabold">
              Add Your Business Hours
            </p>
          </div>
          <div className="grid w-full justify-items-center min-w-[1440px]">
            <div className={`${isLaptopS ? "w-3/5" : "w-3/6"} `}>
              {children}
            </div>
          </div>
        </div>
      </LoadingBackdrop>
    ) : (
      <NeewSidebar
        navBarTitleComp={
          <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
            Add new Venue
          </h3>
        }
        children={<LoadingBackdrop loading={loading}>
          {children}
        </LoadingBackdrop>} />
    );
  }

  const getVenueInfo = async (id) => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForServices()}venue/list?venueId=${id}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      let address = request.data.data.address;
      address.description = address.address;
      setVenueDetails(request.data.data);
      setAddress(address);
      // setDateRange({ startDate: request.data.data.validityStartDate, endDate: request.data.data.validityEndDate });
      setOpenDayAndTime(request.data.data.openDayAndTime)
      // setBusyDayAndTime(request.data.data?.busyDayAndTime || [])
      setEditValues((prevValues) => ({
        ...request.data.data
      }));
      setLoadingDone(true)
    }
    setLoading(false);
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setVenueDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // const checkTimeValid = async (slotDetails) => {
  //   cogoToast.loading("Loading...");
  //   // setLoading(true);
  //   const body = {
  //     venueId: editValues?._id || "",
  //     slot_date: moment(slotDetails.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
  //     slot_start_time: moment(slotDetails.slot_start_time, "h:mm A").format("h:mm A"),
  //     slot_end_time: moment(slotDetails.slot_end_time, "h:mm A").format("h:mm A"),
  //   };
  //   console.log("body", body);
  //   const config = {
  //     method: "post",
  //     url: `${getUrlForServices()}venue/bookingIsAvailable`,
  //     headers: {
  //       "x-access-token": userToken,
  //     },
  //     data: body,
  //   };
  //   const request = await axios(config);
  //   console.log("request", request);
  //   // setLoading(false);
  //   if (request.status === 200) {
  //     if (request.data.statusCode === 200) {
  //       return request.data.data.length > 0;
  //     }
  //   }
  //   return true;
  // }


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      fontWeight: "bold",
      border: 0,
      padding: "10px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body} > div`]: {
      // backgroundColor: "rgba(244, 244, 244, 0.4)",
      backgroundColor: "#fff",
      padding: "16px",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 11,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
    },
    [`&:last-child > div`]: {
      borderRadius: "0 12px 12px 0",
      padding: "8px",
    },
    [`&:last-child > div button`]: {
      marggin: "0px",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "0px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));

  console.log("location.state", location.state);
  console.log("location.state address", address);
  React.useEffect(() => {
    console.log("venueId", dashboardReduxState.venueId);
    if (dashboardReduxState.venueId !== undefined && dashboardReduxState.venueId !== -1 && dashboardReduxState.venueId !== "") {
      setEdit(true)
      getVenueInfo(dashboardReduxState.venueId);
    }
  }, []);
  return (renderLayout(location.state?.from === "list" ? "section" : "full",
    (<div className="">
      <div className={`flex flex-col mx-auto ${location.state.from === "list" ? "px-60" : ""}`}>
        <p className="text-base leading-[18px] font-normal text-black/[0.6] mt-[119px]">
          Venue Name
        </p>
        <div
          className={`relative w-full flex flex-row items-center border-2 border-neutral-200 hover:border-[#7E00DD] rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3`}
          ref={setReferenceElement}
        >
          <img className={"mr-2"} src={institute_img} alt={""} />
          <InputBase
            name={"venueName"}
            placeholder={"Give a name to this work location"}
            value={venueDetails.venueName}
            inputProps={{ maxLength: 30 }}
            onChange={handleFormValueChange}
            className={`focus:outline-0 w-full sm:text-sm md:text-sm`}
          />
        </div>
        <p className="text-base leading-[18px] font-normal text-black/[0.6] mt-7">
          Work Location Address
        </p>
        <div
          className={`relative w-full flex flex-row items-center border-2 border-neutral-200 hover:border-[#7E00DD] rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3`}
          ref={setReferenceElement}
        >
          <img className={"mr-2"} src={marker_img} alt={""} />
          <Combobox
            as={"div"}
            className={"flex-1"}
            value={address}
            onChange={async (e) => {
              placesService.getDetails(
                {
                  placeId: e.place_id,
                },
                (placeDetails) => {
                  const lat = placeDetails.geometry.location.lat();
                  const lng = placeDetails.geometry.location.lng();
                  const coordinates = [lat, lng];
                  postAddress(e, placeDetails, coordinates);
                }
              );
            }}
          >
            <Combobox.Input
              className={"focus:outline-0 w-full sm:text-sm md:text-sm"}
              placeholder={"Select Location"}
              displayValue={(value) => value && (value.description || value?.address)}
              onChange={(e) => {
                getPlacePredictions({ input: e.target.value });
                setQuery(e.target.value);
              }}
            />
            <Combobox.Options
              className={
                "mt-0.5 min-w-[365px] w-full max-h-[200px] overflow-y-auto bg-white z-20 border border-zinc-400 rounded-xl p-3 shadow-lg"
              }
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {!isPlacePredictionsLoading &&
                placePredictions.map((place, idx) => {
                  return (
                    <Combobox.Option
                      className={
                        "py-1 px-1 cursor-pointer hover:bg-zinc-200 rounded-lg sm:text-sm"
                      }
                      key={idx}
                      value={place}
                    >
                      {place.description}
                    </Combobox.Option>
                  );
                })}
            </Combobox.Options>
          </Combobox>
        </div>
        {location.state?.from === "registration" && <MyAvailableTimeForm
          profileDetails={venueDetails}
          setProfileDetails={setVenueDetails}
          reloadData={loadingDone}
        />}
        {/* {renderDatePicker()}
        <div className="flex flex-col gap-3 mt-8">
          <FormControlLabel control={
            <Checkbox
              name="scheduleWithoutValidity"
              checked={venueDetails.scheduleWithoutValidity}
              onChange={(event) => {
                const { name, checked } = event.target;
                setVenueDetails((prevValues) => ({
                  ...prevValues,
                  [name]: checked,
                }));
                setDateRange({
                  startDate: moment().format("YYYY-MM-DD"),
                  endDate: moment().add(10, 'years').format("YYYY-MM-DD")
                });

              }}
            />
          } label="Apply this schedule without any specific validity period" />
        </div>
        <div className="flex flex-col gap-3 mt-8">
          <p className="text-base leading-[18px] font-normal text-black/[0.6]">
            Set Working Hours For This Location
          </p>
          {openDayAndTime?.map((item, index) => {
            return (
              <DayTimeSelector
                key={index}
                item={item}
                onToggleSwitch={onToggleSwitch}
                onTimeRangeCopy={onTimeRangeCopy}
                onTimeChange={(openTime) => onTimeChange(openTime, index)}
              />
            );
          })}
        </div> */}
        {false && (<div className="flex flex-col gap-3 mt-8" style={{ display: "none"}}>
          <div className="flex items-center justify-between gap-3">
            <p className="text-base leading-[18px] font-normal text-black/[0.6]">
              Free or unavailable slot
            </p>
            <Button
              sx={{
                color: "common.white",
                backgroundColor: "secondary.main",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                fontSize: 14,
              }}
              variant={"contained"}
              onClick={() => {
                setCreateFreeSlotDialog((prevValues) => ({
                  ...prevValues,
                  isEdit: false,
                  data: {
                    // slot_date: moment().format("YYYY-DD-MM"),
                    slot_date: moment(),
                    slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                    slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                  },
                  text: "",
                  open: true,
                }));
              }}
            >
              Add New Free Slot
            </Button>
          </div>
          <TableContainer className="border-2 border-[#EBEBEB] rounded-[10px]">
            <Table sx={{ widht: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Start time</StyledTableCell>
                  <StyledTableCell align="center">End time</StyledTableCell>
                  <StyledTableCell width="10%" align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {busyDayAndTime.length ? busyDayAndTime.map((row, i) => (
                  <StyledTableRow key={i} >
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(row.slot_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(moment(row.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + row.slot_start_time, 'YYYY-MM-DD h:mm A').format('h:mm A') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box>
                        {row.slot_date ? moment(moment(row.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + row.slot_end_time, 'YYYY-MM-DD h:mm A').format('h:mm A') : ""}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box>
                        <FreeSlotPopover
                          key={i}
                          slotDetails={{ venueId: editValues?._id || "", ...row }}
                          handleEditSlot={(data) => {
                            setCreateFreeSlotDialog((prevValues) => ({
                              ...prevValues,
                              isEdit: i + 1,
                              data: {
                                // slot_date: moment().format("YYYY-DD-MM"),
                                slot_date: moment(data.slot_date, 'YYYY-MM-DD'),
                                slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                                slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                              },
                              text: "",
                              open: true,
                            }));
                          }}
                          handleDeleteSlot={(data) => {
                            setBusyDayAndTime(busyDayAndTime.filter((bdata, bi) => bi !== i))
                          }}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )) : (
                  <StyledTableRow key={0} >
                    <StyledTableCell align="center" colSpan={4}>
                      <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                        <p>There is no busy slots</p>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <AddEditFreeSlotModal
            isEdit={createFreeSlotDialog.isEdit}
            loading={loading}
            text={createFreeSlotDialog.text || ""}
            open={createFreeSlotDialog.open}
            data={createFreeSlotDialog.data}
            onClose={() => {
              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
            onSubmit={async (data) => {
              // deletePaymentProfile()
              console.log("data", data);

              // if (edit) {
              //   if (editValues?._id !== "" && await checkTimeValid(data)) {
              //     setCreateFreeSlotDialog((prevValues) => ({
              //       ...prevValues,
              //       text: "You are not allowed to modify free slot, you have bookings available at this time",
              //     }));
              //     return true;
              //   }
              // }

              // if (createFreeSlotDialog.isEdit) {
              //   setBusyDayAndTime(busyDayAndTime.map((bdata, i) => {
              //     if (i === (createFreeSlotDialog.isEdit - 1)) {
              //       return {
              //         slot_date: moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              //         slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //         slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //       }
              //     }
              //     return bdata;
              //   }))
              // } else {
              //   setBusyDayAndTime([...busyDayAndTime, {
              //     slot_date: moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              //     slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //     slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
              //   }])
              // }

              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
          />
        </div>)}
      </div>
      <div className={`flex items-center ${location.state?.from === "list" ? "justify-end" : "justify-center"} px-60 pb-[55px] mt-48`}>
        {(location.state?.from === "list") ? (
          <button
            className="border border-[#F64E60] py-3 px-9 rounded-lg"
            onClick={() => {
              navigation("/myvenue/");
            }}
          >
            <p className="text-[#F64E60] text-base font-semibold">Back</p>
          </button>) : ""}
        <button
          className="bg-[#481B3A] rounded-2xl py-4 px-[72px] ml-5"
          onClick={() => {
            handleAddVenue();
          }}
        >
          <p className="text-base leading-[19px] text-white font-semibold">
            {edit ? "Update" : "Continue"}
          </p>
        </button>
      </div>
    </div>
    ))
  );
};

export default AddVenue;
