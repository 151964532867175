import { Box, Rating, useMediaQuery } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import {
  LoadingBackdrop
} from "../../../layouts";
import ViewSessionPage from "./ViewSessionPage";

import { useNavigate } from "react-router-dom";
import booked_img from "../../../assets/Booked.png";
import charetleft from "../../../assets/charetleft.svg";
import session_img from "../../../assets/Courses.svg";
import money_bag from "../../../assets/Euro_Bag_Dark.svg";
import seats_img from "../../../assets/SeatsDark.svg";
import NeewSidebar from "../../../components/NewSideBar";
import { getUrlForClasses } from "../../../utils/confighelpers";

export default function BusinessProfileClassSession({ navigation }) {
  const dispatch = useDispatch();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [infoTiles, setInfoTiles] = React.useState([]);
  const [isDescExpanded, setDescExpanded] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(true);
  const [backState, setBackState] = React.useState(0);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  React.useEffect(() => {
    if (dashboardReduxState.viewSessionPage) {
      const { cost, rating, totalBookings, isPackage } =
        dashboardReduxState.calendarClassData;
      const dataTiles = [
        { prefix: cost, icon: money_bag, suffix: "/Seat" },
        {
          prefix: rating,
          comp: (
            <Rating
              sx={{
                color: "primary.light",
                fontSize: 17,
              }}
              value={
                Array.isArray(rating) ? (rating.length ? rating[0] : 0) : rating
              }
              precision={0.5}
              readOnly
            />
          ),
          suffix: `${Array.isArray(rating) ? (rating.length ? rating[0] : 0) : rating
            }`,
        },
        {
          prefix: `Booked ${totalBookings}`,
          icon: booked_img,
          suffix: " times",
        },
      ];
      if (!isPackage) {
        const { capacity } = dashboardReduxState.calendarClassData;
        dataTiles.unshift({
          prefix: capacity,
          icon: seats_img,
          suffix: " Seats",
        });
      } else if (isPackage) {
        const { daysInWeek } = dashboardReduxState.calendarSessionData;
        dataTiles.unshift({
          prefix: daysInWeek?.length,
          icon: session_img,
          suffix: " Sessions",
        });
      }
      setInfoTiles([...dataTiles]);
    }
  }, [
    dashboardReduxState.calendarClassData,
    dashboardReduxState.calendarSessionData,
    dashboardReduxState.viewSessionPage,
  ]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const isPckg = dashboardReduxState.calendarClassData.isPackage;
      const config = {
        method: "get",
        url: `${getUrlForClasses()}getScheduleDetail?scheduleclassId=${dashboardReduxState.calendarSessionId}&isPackage=${isPckg}`,
        headers: {
          "x-access-token": userToken,
        },
      };
      const request = await axios(config);
      if (request.status === 200) {
        if (request.data.statusCode === 200) {
          dispatch(dashboardActions.setCalendarClassData(request.data.data));
          dispatch(dashboardActions.setCalendarSessionData(request.data.data));
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardReduxState.updateSessionComp]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  const handleBackToHome = () => {
    dispatch(dashboardActions.setCalendarSessionId(-1));
    dispatch(dashboardActions.setCalendarClassData({}));
    dispatch(dashboardActions.setCalendarSessionData({}));
    dispatch(dashboardActions.setViewSessionPage(false));
    dispatch(dashboardActions.setCalendarOpen(false));
    navigation("/home/dashboard");
  };

  const onBackClick = () => {
    if (backState === 0) {
      navigate("/myclasses/myschedule", { replace: true });
    } else {
      setBackState(0);
    }
  };

  console.log(dashboardReduxState.calendarClassData);

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee content-center"}>
          <img src={charetleft} alt="" className="inline align-baseline mr-8 cursor-pointer" onClick={() => {
            onBackClick();
          }} />{dashboardReduxState.calendarClassData.className} {backState == 1 ? " | Bookings":""}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            // py: 2,
            // px: 3,
            overflowX: "hidden",
            overflowY: "hidden",
          }}
          // className="min-w-[1382px] max-w-[1382px]"
        >
          {/* <div className={"max-w-3xl"}> */}
          <div className={""}>
            <ViewSessionPage
              sessionRoute
              dataToDisplay={dashboardReduxState.calendarClassData}
              sessionDataToDisplay={dashboardReduxState.calendarSessionData}
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              popoverAnchorEl={anchorEl}
              infoTiles={infoTiles}
              isDescExpanded={isDescExpanded}
              setDescExpanded={setDescExpanded}
              popoverId={popoverId}
              popoverMenuOpen={popoverMenuOpen}
              handleBackToHome={handleBackToHome}
              instanceId={dashboardReduxState.calendarClassData.instanceId}
              setLoading={setLoading}
              backState={backState}
              setBackState={setBackState}
            />
          </div>
        </Box>
      </LoadingBackdrop>} />
  );
}
