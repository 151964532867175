import { Box, Button, FormHelperText, FormLabel, InputBase, Tab, useMediaQuery, FormControl } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";

import NeewSidebar from "../../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../../layouts";

import { schoolState } from "../../../redux/reducers/schoolReducer";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useGoogleLogin } from "@react-oauth/google";
import { getUrlForUsers } from "../../../utils/confighelpers";
import shareOrange from "../../../assets/shareOrange.svg";
import copyOrange from "../../../assets/copyOrange.svg";

export default function ManageOnlineBooking({ navigation, profileFor }) {
  const [userToken] = useLocalStorage("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const schoolReduxState = useSelector(schoolState);
  const [loading, setLoading] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [formErrors, setFormErrors] = React.useState({});

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  const [isThereChange, setIsThereChange] = React.useState(true);
  const [profileDetails, setProfileDetails] = React.useState({
    maxDaysAllowedForBooking: 0,
  });

  const saveuserProfile = async () => {

    setLoading(true);
    console.log("profileDetails", profileDetails);
    const profileUpdateConfig = {
      method: "put",
      url: `${getUrlForUsers()}updateProfile`,
      headers: {
        "x-access-token": userToken,
      },
      data: profileDetails,
    };
    console.log("profileUpdateConfig", profileUpdateConfig);
    const profileUpdateRequest = await axios(profileUpdateConfig);
    setLoading(false);
    setIsThereChange(true);
    if (profileUpdateRequest.status === 200) {
      if (profileUpdateRequest.data.statusCode === 200) {
        cogoToast.success("Updated Successfully");
        console.log("profileUpdateRequest.data.data", profileUpdateRequest.data.data);
        setProfileDetails((prevValues) => ({
          ...prevValues,
          maxDaysAllowedForBooking: profileUpdateRequest.data.data?.maxDaysAllowedForBooking || "",
        }));
        return;
      } else {
        setLoading(false);
        cogoToast.error(profileUpdateRequest.data.message);
        console.log(profileUpdateRequest);
        return;
      }
    } else {
      setLoading(false);
      console.log(
        "ERROR SUBMIT FORM, SCHOOL UPDATE:",
        profileUpdateRequest.data
      );
    }

  };

  const getUserInfo = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      // console.log("data", request.data.data);
      setProfileDetails((prevValues) => ({
        ...prevValues,
        maxDaysAllowedForBooking: request.data.data?.maxDaysAllowedForBooking || "",
      }));
    }
    setLoading(false);
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setProfileDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: "5px",
    border: 1,
    borderRadius: 3,
    borderColor: "rgba(0, 0, 0, 0.04)",
    // borderColor: formErrors["serviceName"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 262,
    maxWidth: 262,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const bookingLink = (userReduxState.userData?.appUrl ? userReduxState.userData?.appUrl : process.env.REACT_APP_WEBAPP_URL) + userReduxState.userData?.myBookingLink;
  // const codeBlockData = `<script src="iframeWidget.js?iframe_src=${bookingLink}"></script>`;
  const codeBlockData = `<script src="https://www.bookzyme.com/iframeWidget.js?iframe_src=${encodeURIComponent(bookingLink)}"></script>`;

  React.useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          Manage Online Booking
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            pt: 8,
            pl: 12,
            pb: 10,
            pr: 2,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"
        >
          <Box className="w-3/5">
            <Box sx={{ width: '100%', typography: 'body1' }}>

              <Box className="flex flex-col justify-start gap-5 w-full mt-5 border-b border-[#F4F4F4] pb-12">
                <Box className={"flex-none text-[16px] text-[#000000B2]"}>Share the link below to let your customer book</Box>
                <Box className="flex justify-center items-center w-full"
                >
                  <Box className="flex-1 p-3 pl-4 text-[#000000] my-0 mx-0 text-ellipsis overflow-hidden cursor-pointer break-all"
                    sx={{
                      backgroundColor: "#F4F4F4",
                      border: 1,
                      borderColor: "rgba(0, 0, 0, 0.14)",
                      borderRadius: "12px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(bookingLink);
                      cogoToast.success("Copied!");
                    }}
                  >
                    {bookingLink}
                  </Box>
                  <Box className="flex flex-none 1bg-brown-coffee py-2 px-8"
                    sx={{
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 2,
                        fontSize: 14,
                        px: 2,
                        py: 0.5,
                        mr: 2,
                        width: 120,
                      }}
                      variant={"contained"}
                      onClick={() => {
                        navigator.clipboard.writeText(bookingLink);
                        cogoToast.success("Copied!");
                      }}
                    >
                      <svg className="mr-2" width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.289 23.5039H4.98244C4.43008 23.5231 3.89455 23.3121 3.50374 22.9213C3.11293 22.5305 2.90193 21.995 2.92113 21.4426V11.1361C2.90193 10.5837 3.11293 10.0482 3.50374 9.6574C3.89455 9.26659 4.43008 9.05559 4.98244 9.07478H9.10506V4.95216C9.08586 4.39981 9.29686 3.86428 9.68767 3.47347C10.0785 3.08266 10.614 2.87166 11.1664 2.89085H21.4729C22.0253 2.87166 22.5608 3.08266 22.9516 3.47347C23.3424 3.86428 23.5534 4.39981 23.5342 4.95216V15.2587C23.5531 15.811 23.342 16.3463 22.9513 16.7371C22.5605 17.1278 22.0252 17.3389 21.4729 17.32H17.3503V21.4426C17.3692 21.9949 17.1581 22.5303 16.7673 22.921C16.3766 23.3117 15.8413 23.5228 15.289 23.5039ZM4.98244 11.1361V21.4426H15.289V17.32H11.1664C10.6141 17.3389 10.0788 17.1278 9.68801 16.7371C9.29727 16.3463 9.08617 15.811 9.10506 15.2587V11.1361H4.98244ZM11.1664 4.95216V15.2587H21.4729V4.95216H11.1664Z" fill="#FFF" />
                      </svg> Copy
                    </Button>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 2,
                        fontSize: 14,
                        px: 2,
                        py: 0.5,
                        width: 120,
                      }}
                      variant={"contained"}
                      onClick={() => {
                        window.open(bookingLink, '_blank');
                      }}
                    >
                      <svg className="mr-2" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5216 20.6135H6.18436C5.04593 20.6135 4.12305 19.6906 4.12305 18.5522V7.21501C4.12305 6.07658 5.04593 5.1537 6.18436 5.1537H10.307V7.21501H6.18436V18.5522H17.5216V14.4296H19.5829V18.5522C19.5829 19.6906 18.66 20.6135 17.5216 20.6135ZM12.0591 14.1276L10.6059 12.6703L17.0918 6.18436H13.3989V4.12305H20.6135V11.3376H18.5522V7.64273L12.0591 14.1276Z" fill="white" />
                      </svg>
                      share
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className="flex flex-col justify-start gap-5 w-full mt-12 border-b border-[#F4F4F4] pb-12">
                <Box className={"flex-none text-[16px] text-[#000000B2]"}>Max number of days customers can book in advance</Box>
                <Box className={"flex justify-center items-center w-full gap-2"}>
                  <Box className="flex-1 flex gap-8 justify-start ">
                    <FormControl error={formErrors["serviceName"] ? true : false}>
                      {/* <FormLabel sx={formLableStyle}>Max Days Allowed For Booking: &nbsp;</FormLabel> */}
                      <InputBase
                        name={"maxDaysAllowedForBooking"}
                        placeholder={"Max Days Allowed For Booking"}
                        value={profileDetails.maxDaysAllowedForBooking}
                        onChange={handleFormValueChange}
                        classes={formInputClasses}
                        sx={formInputStyle}
                      />
                      {formErrors["serviceName"] && (
                        <FormHelperText>{formErrors["serviceName"]}</FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        fontSize: 14,
                        px: 4,
                        py: 1,
                      }}
                      variant={"contained"}
                      onClick={async () => {
                        await saveuserProfile();
                      }}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className="flex flex-col justify-start w-full mt-12 border-b-0 border-[#F4F4F4] pb-12">
                <p className={"flex-none text-[16px] text-[#000000B2]"}>Add booking widget to your website</p>
                <Box className="flex justify-center items-center w-full mt-5">
                  <Box className="flex-1 text-[#000000] my-0 mx-0 text-ellipsis overflow-hidden cursor-pointer break-all"

                    onClick={() => {
                      navigator.clipboard.writeText(codeBlockData);
                      cogoToast.success("Copied!");
                    }}
                  >
                    <InputBase
                      name={"description"}
                      value={codeBlockData}
                      classes={formInputClasses}
                      multiline={true}
                      rows={4}
                      // sx={formInputStyle}
                      className="w-full"
                      sx={{
                        p: 2,
                        backgroundColor: "#F4F4F4",
                        border: 1,
                        borderColor: "rgba(0, 0, 0, 0.14)",
                        borderRadius: "12px",
                      }}
                    />
                    {/* <textarea rows={3} >
                      <code className="javascript">
                      {codeBlockData}
                      </code>
                    </textarea> */}
                  </Box>
                  <Box className="flex flex-none py-2 px-8"
                    sx={{
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 2,
                        fontSize: 14,
                        px: 2,
                        py: 0.5,
                        mr: 2,
                        width: 120,
                      }}
                      variant={"contained"}
                      onClick={() => {
                        navigator.clipboard.writeText(codeBlockData);
                        cogoToast.success("Copied!");
                      }}
                    >
                      <svg className="mr-2" width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.289 23.5039H4.98244C4.43008 23.5231 3.89455 23.3121 3.50374 22.9213C3.11293 22.5305 2.90193 21.995 2.92113 21.4426V11.1361C2.90193 10.5837 3.11293 10.0482 3.50374 9.6574C3.89455 9.26659 4.43008 9.05559 4.98244 9.07478H9.10506V4.95216C9.08586 4.39981 9.29686 3.86428 9.68767 3.47347C10.0785 3.08266 10.614 2.87166 11.1664 2.89085H21.4729C22.0253 2.87166 22.5608 3.08266 22.9516 3.47347C23.3424 3.86428 23.5534 4.39981 23.5342 4.95216V15.2587C23.5531 15.811 23.342 16.3463 22.9513 16.7371C22.5605 17.1278 22.0252 17.3389 21.4729 17.32H17.3503V21.4426C17.3692 21.9949 17.1581 22.5303 16.7673 22.921C16.3766 23.3117 15.8413 23.5228 15.289 23.5039ZM4.98244 11.1361V21.4426H15.289V17.32H11.1664C10.6141 17.3389 10.0788 17.1278 9.68801 16.7371C9.29727 16.3463 9.08617 15.811 9.10506 15.2587V11.1361H4.98244ZM11.1664 4.95216V15.2587H21.4729V4.95216H11.1664Z" fill="#FFF" />
                      </svg> Copy
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>
      </LoadingBackdrop>} />
  );
}
