import React from "react";
import { Box } from "@mui/material";

import { styled } from "@mui/system";

import breakpoints from "../utils/breakpoints";
import logo from "../assets/logo.svg";

const ResponsiveLogo = styled("img")`
  ${breakpoints.xs} {
    height: 50%;
    width: 50%;
  }

  ${breakpoints.sm} {
    height: 30%;
    width: 30%;
  }

  ${breakpoints.md} {
    height: 15%;
    width: 15%;
  }
`;

export default function Logo({ className, imageClassName }) {
  return (
    <Box
      className={className}
      sx={{
        my: 3,
        px: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 3,
          xl: 3,
        },
        display: {
          xs: "flex",
          sm: "flex",
          md: "block",
          lg: "block",
          xl: "block",
        },
        justifyContent: {
          xs: "center",
          sm: "center",
        },
        alignItems: {
          xs: "center",
          sm: "center",
        },
      }}
    >
      <ResponsiveLogo src={logo} className={imageClassName} />
    </Box>
  );
}
