import { Combobox } from "@headlessui/react";
import { Box, FormControl, FormLabel, InputAdornment, MenuItem, Select, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { Field, FormikProvider } from "formik";
import { InputBase } from "formik-mui";
import React from "react";
import useGoogleAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";

import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import email_img from "../../../assets/Email.svg";
import individual_profile from "../../../assets/individual_profile.svg";
import institute_img from "../../../assets/Institute.svg";
import marker_img from "../../../assets/location_marker.svg";
import web_img from "../../../assets/Website.svg";
import { findZipcode } from "../../../helpers";
import PhoneInput from "react-phone-input-2";
import { getUrlForUsers } from "../../../utils/confighelpers";

export default function BusinessProfileForm(props) {
  const theme = useTheme();
  const dashboardReduxState = useSelector(dashboardState);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [userToken] = useLocalStorage("userToken", "");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogleAutocomplete({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = React.useState("");
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const postAddress = (placeDetails, placeGeocode, coordinates) => {
    const zipcode = findZipcode(placeGeocode.address_components);
    let city = placeGeocode.address_components[
      placeGeocode.address_components.length - 3
    ].long_name;
    const locationUpdateBody = {
      ...(dashboardReduxState.schoolData?.locationObj?._id ? {locationId: dashboardReduxState.schoolData?.locationObj?._id || ""} : { }),
      addressType: "manually",
      address: placeDetails.description,
      houseNumber: placeDetails.structured_formatting.main_text,
      city: placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name,
      zip: zipcode,
      // addressName: dashboardReduxState.schoolData.locationObj.addressName,
      addressName: city ? city : "Business Address",
      location: {
        type: "Point",
        coordinates,
      },
    };

    const locationUpdateConfig = {
      method: "put",
      url: `${getUrlForUsers()}${dashboardReduxState.schoolData?.locationObj?._id ? "updateLocation" : "addLocation" }`,
      headers: {
        "x-access-token": userToken.toString(),
        "Access-Control-Allow-Headers": "*",
      },
      data: locationUpdateBody,
    };
    props.setUpdateLocationConfig(locationUpdateConfig);
    props.setLocationChanged(true);





    /* const body = {
      addressType: "current",
      address: placeDetails.description,
      houseNumber: placeDetails.structured_formatting.main_text,
      city: placeGeocode.address_components[
        placeGeocode.address_components.length - 3
      ].long_name,
      zip: placeGeocode.address_components[
        placeGeocode.address_components.length - 1
      ].types.find((type) => type.startsWith("postal"))
        ? placeGeocode.address_components[
          placeGeocode.address_components.length - 1
        ].long_name
        : "",
      addressName: "Business Address",
      location: {
        type: "Point",
        coordinates,
      },
    };

    const config = {
      method: "post",
      url: `${getUrlForUsers()}addLocation`,
      headers: {
        "x-access-token": userToken.toString(),
        "Access-Control-Allow-Headers": "*",
      },
      data: body,
    };

    props.setPostAddressConfig(config); */

  };

  const [businessTypeOpts, setBusinessTypeOpts] = React.useState([]);

  /* React.useEffect(() => {
    (async () => {
      const config = {
        method: "get",
        url: `${getUrlForStage()}users/getBusinessTypes`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        }
      };
      const request = await axios(config);
      setBusinessTypeOpts(request.data.data);
      // props.form.setFieldValue("businessTypes", props.form.values.businessTypes);
    })();
  }, []); */

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    // m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
    p: 2,
    borderRadius: 3,
    // border: 1,
    // borderColor: "#EBEBEB",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    fontSize: 17,
    // minWidth: 620,
    minWidth: 520,
    maxWidth: 620,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  // {console.log("props.form.address", props.form.values)}
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormikProvider value={props.form}>
          <Box
            className={
              "grid grid-cols-2 grid-flow-col w-full"
            }
            sx={{
              minWidth: isMobile ? 290 : isTablet ? 340 : 520,
              maxWidth: isMobile ? 290 : isTablet ? 340 : 520,
            }}
          >
            <Box
              className={`${(props.form.values.businessProfileType === 'individual' ? "bg-[#0000000A]" : "")}`}
              sx={{
                my: 1,
                mr: 1,
                p: 2,
                borderRadius: 3,
                border: 1,
                borderColor: "#EBEBEB",
                fontSize: 17,
                "&:hover, &.Mui-focused": {
                  border: 1,
                  borderColor: "#7E00DD"
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0,
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
                // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
                // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
              }}>
              <Field
                // selected={props.form.values.businessProfileType ==='individual'}
                type="radio"
                form={props.form}
                name={"businessProfileType"}
                value={"individual"}
                onClick={(event) => {
                  props.form.setFieldValue("businessProfileType", "individual")
                }}
                sx={{
                  verticalAlign: 'middle',
                  mr: 1
                }}
                id="idIndividual"
                size={isMobile || isTablet ? "small" : "medium"}
              /> <label htmlFor="idIndividual">Individual</label>
            </Box>
            <Box
              className={`${(props.form.values.businessProfileType === 'business' ? "bg-[#0000000A]" : "")}`}
              sx={{
                my: 1,
                ml: 1,
                p: 2,
                borderRadius: 3,
                border: 1,
                borderColor: "#EBEBEB",
                fontSize: 17,
                "&:hover, &.Mui-focused": {
                  border: 1,
                  borderColor: "#7E00DD"
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0,
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
                // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
                // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
              }}>
              <Field
                type="radio"
                form={props.form}
                name={"businessProfileType"}
                value={"business"}
                onClick={(event) => {
                  props.form.setFieldValue("businessProfileType", "individual")
                }}
                sx={{
                  verticalAlign: 'middle',
                  mr: 1
                }}
                id="idBusiness"
                size={isMobile || isTablet ? "small" : "medium"}
              /> <label htmlFor="idBusiness">Business</label>
            </Box>
          </Box>
          <FormControl>
            <FormLabel sx={formLableStyle}>Name</FormLabel>
            <Field
              component={InputBase}
              form={props.form}
              name={"businessName"}
              placeholder={props.form.values.businessProfileType === 'individual' ? "Host Name" : "Awesome Event Company"}
              startAdornment={
                <InputAdornment position={"start"}>
                  <img className={"w-5"} src={props.form.values.businessProfileType === 'individual' ? individual_profile : institute_img} alt={""} />
                </InputAdornment>
              }
              inputProps={{ maxLength: 30 }}
              sx={formInputStyle}
              size={isMobile || isTablet ? "small" : "medium"}
            />
          </FormControl>
          {/* <FormControl>
            <FormLabel sx={formLableStyle}>Business type</FormLabel>
            <Select
              disableUnderline
              displayEmpty
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54807 0.0212092C1.175 0.0965433 0.835097 0.329394 0.656853 0.623882C0.589061 0.735885 0.553223 0.864309 0.553223 0.99523V3.00376V5.03121C0.553223 5.14967 0.586238 5.26578 0.648563 5.36651C0.793645 5.61306 0.959454 5.75688 1.24547 5.88357C1.41764 5.96033 1.60404 6 1.79255 6H4.18029H6.56802C6.75653 6 6.94293 5.96033 7.1151 5.88357C7.40526 5.75345 7.52962 5.6473 7.69543 5.38705C7.76851 5.27302 7.80735 5.14043 7.80735 5.00499V3.00376V1.05278C7.80735 0.884639 7.75838 0.720134 7.66641 0.579367C7.51304 0.343091 7.33894 0.20612 7.04048 0.0862708C6.91736 0.0342855 6.78512 0.00733042 6.65147 0.00697708L4.26319 0.000663757C2.83724 -0.00276041 1.61854 0.00751209 1.54807 0.0212092Z" fill="#212121" />
                    <path d="M12.4294 0.0212092C12.0564 0.0965433 11.7164 0.329394 11.5382 0.623882C11.4704 0.735885 11.4346 0.864309 11.4346 0.99523V3.00376V5.03121C11.4346 5.14967 11.4676 5.26578 11.5299 5.36651C11.675 5.61306 11.8408 5.75688 12.1268 5.88357C12.299 5.96033 12.4854 6 12.6739 6H15.0616H17.4494C17.6379 6 17.8243 5.96033 17.9964 5.88357C18.2866 5.75345 18.411 5.6473 18.5768 5.38705C18.6499 5.27302 18.6887 5.14043 18.6887 5.00499V3.00376V1.05278C18.6887 0.884639 18.6397 0.720134 18.5478 0.579367C18.3944 0.343091 18.2203 0.20612 17.9218 0.0862708C17.7987 0.0342855 17.6665 0.00733042 17.5328 0.00697708L15.1445 0.000663757C13.7186 -0.00276041 12.4999 0.00751209 12.4294 0.0212092Z" fill="#212121" />
                    <path d="M1.54807 9.02121C1.175 9.09654 0.835097 9.32939 0.656853 9.62388C0.589061 9.73589 0.553223 9.86431 0.553223 9.99523V12.0038V14.0312C0.553223 14.1497 0.586238 14.2658 0.648563 14.3665C0.793645 14.6131 0.959454 14.7569 1.24547 14.8836C1.41764 14.9603 1.60404 15 1.79255 15H4.18029H6.56802C6.75653 15 6.94293 14.9603 7.1151 14.8836C7.40526 14.7535 7.52962 14.6473 7.69543 14.3871C7.76851 14.273 7.80735 14.1404 7.80735 14.005V12.0038V10.0528C7.80735 9.88464 7.75838 9.72013 7.66641 9.57937C7.51304 9.34309 7.33894 9.20612 7.04048 9.08627C6.91736 9.03429 6.78512 9.00733 6.65147 9.00698L4.26319 9.00066C2.83724 8.99724 1.61854 9.00751 1.54807 9.02121Z" fill="#212121" />
                    <path d="M14.5842 9.01748C14.3363 9.04482 13.8363 9.16098 13.5967 9.24982C11.8737 9.88191 11.0226 11.4912 11.6299 12.9808C11.9687 13.8214 12.8571 14.5559 13.8735 14.8361C16.1791 15.4785 18.5425 14.1596 18.6829 12.154C18.7862 10.6575 17.4888 9.30448 15.6998 9.04482C15.3651 8.99698 14.8817 8.98673 14.5842 9.01748Z" fill="#212121" />
                  </svg>
                  {value !== undefined ? value.name : (
                    <span className={"font-light text-plain-gray"}>
                      Choose what describes your business
                    </span>
                  )}
                </div>
              )}
              value={props.form.values.businessTypes}
              onChange={(event) => {
                props.form.setFieldValue("businessTypes", event.target.value);
                console.log(props.form.values.businessTypes);
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
                pl: 0,
              }}
            >
              {businessTypeOpts.map((category, idx) => (
                <MenuItem value={category} key={idx}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <FormControl>
            <FormLabel sx={formLableStyle}>About </FormLabel>
            <Field
              component={InputBase}
              form={props.form}
              name={"about"}
              multiline
              placeholder={
                "Write a few lines about your self, your classes, experiences your customers will have while attending your classes and courses..."
              }
              sx={{
                ...formInputStyle,
                minHeight: 170,
                maxHeight: 278,
                overflowY: "auto",
                flexWrap: "wrap",
                borderRadius: 7,
                verticalAlign: "top",
                alignItems: "start",
              }}
              size={isMobile || isTablet ? "small" : "medium"}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={formLableStyle}>Business Address</FormLabel>
            <div
              className={`min-w-[526px] flex flex-row items-center border border-neutral-200 hover:border-[#7E00DD] rounded-xl my-2 sm:my-1 md:my-0 py-5 sm:py-1.5 md:py-[0.65rem] px-4 sm:px-1 md:px-3 bg-[#0000000A]`}
              ref={setReferenceElement}
            >
              <img className={"mr-2"} src={marker_img} alt={""} />
              <Combobox
                as={"div"}
                className={"relative"}
                // value={props.form.values.locationObj}
                value={props.form.values.address}
                onChange={async (e) => {
                  placesService.getDetails(
                    {
                      placeId: e.place_id,
                    },
                    (placeDetails) => {
                      const lat = placeDetails.geometry.location.lat();
                      const lng = placeDetails.geometry.location.lng();
                      const coordinates = [lat, lng];
                      postAddress(e, placeDetails, coordinates);
                    }
                  );
                  console.log("e", e);
                  props.form.setFieldValue("locationObj", e)
                  props.form.setFieldValue("address", e)
                  props.setContactAddress(e);
                }}
              >
                <Combobox.Input
                  className={
                    "focus:outline-0 w-[30rem] sm:text-sm md:text-sm bg-transparent"
                  }
                  placeholder={"Business Address"}
                  displayValue={(value) => value && value.description
                    ? value.description
                    : value.address}
                  onChange={(e) => {
                    getPlacePredictions({ input: e.target.value });
                    setQuery(e.target.value);
                  }}
                />
                <Combobox.Options
                  className={
                    "mt-0.5 min-w-[520px] max-h-[200px] overflow-y-auto bg-white z-20 border border-zinc-400 rounded-xl p-3 shadow-lg"
                  }
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {!isPlacePredictionsLoading &&
                    placePredictions.map((place, idx) => {
                      { console.log("place", place) }
                      return (
                        <Combobox.Option
                          className={
                            "py-1 px-1 cursor-pointer hover:bg-zinc-200 rounded-lg sm:text-sm"
                          }
                          key={idx}
                          value={place}
                        >
                          {place.description}
                        </Combobox.Option>
                      );
                    })}
                </Combobox.Options>
              </Combobox>
            </div>
          </FormControl>
          <FormControl>
            <FormLabel sx={formLableStyle}>Business Number</FormLabel>

            <Field
              component={PhoneInput}
              inputProps={{
                name: 'workNumber',
              }}
              name={"workNumber"}
              specialLabel={""}
              placeholder={"Phone Number"}
              value={props.form.values.workNumber}
              // disabled={props.form.values.socialType === 'normal' ? true : false}
              onChange={(phone, country, e) => {
                const numberWithoutDialCode = phone.split("");
                for (let i = 0; i < country.dialCode.length; i++) {
                  numberWithoutDialCode.shift();
                }
                let dialCode = country.dialCode;
                if (
                  country.format.includes("+") &&
                  !country.dialCode.includes("+")
                ) {
                  dialCode = "+".concat(country.dialCode);
                }
                console.log("formattedPhoneNumber", e.target.value);
                props.form.setFieldValue("countryName", country.countryCode);
                props.form.setFieldValue("countryCode", dialCode);
                props.form.setFieldValue("phone", numberWithoutDialCode.join(""));
                props.form.setFieldValue("formattedPhoneNumber", e.target.value);
                props.form.setFieldValue("workNumber", e.target.value);
              }}
              country={props.form.values.countryName}
              inputStyle={{
                color: "rgba(0, 0, 0, 0.87)",
                borderRadius: 10,
                borderColor: "#EBEBEB",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                fontSize: isMobile ? 14 : isTablet ? 13 : 17,
                // minWidth: "100%",
                minWidth: 520,
                maxWidth: isMobile ? 290 : isTablet ? 340 : 520,
                "&:hover, &.Mui-focused": {
                  border: 1,
                  borderColor: "#7E00DD"
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0,
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={formLableStyle}>Business Email</FormLabel>
            <Field
              component={InputBase}
              form={props.form}
              name={"workEmail"}
              placeholder={"Contact Email"}
              startAdornment={
                <InputAdornment position={"start"}>
                  <img src={email_img} alt={""} />
                </InputAdornment>
              }
              sx={formInputStyle}
              size={isMobile || isTablet ? "small" : "medium"}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={formLableStyle}>Business Website</FormLabel>
            <Field
              component={InputBase}
              form={props.form}
              name={"workWebsite"}
              placeholder={"Your Website (Optional)"}
              startAdornment={
                <InputAdornment position={"start"}>
                  <img src={web_img} alt={""} />
                </InputAdornment>
              }
              sx={formInputStyle}
              size={isMobile || isTablet ? "small" : "medium"}
            />
          </FormControl>
        </FormikProvider>
      </Box>
    </div>
  );
}
