import {
  Box, Button, Link, Stack, Table, TableBody, TableContainer, TableHead,
  TableRow
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import cogoToast from "cogo-toast";
import React from "react";
import { useNavigate } from "react-router-dom";

import copyOrange from "../../../../assets/copyOrange.svg";
import defaultImage from "../../../../assets/defaultImage.svg";
import greenlocation from "../../../../assets/greenlocation.svg";
import greenprice from "../../../../assets/greenprice.svg";
import loader from "../../../../assets/loader.svg";
import progressdone from "../../../../assets/progressdone.svg";
import shareOrange from "../../../../assets/shareOrange.svg";
import successfullclass from "../../../../assets/successfullclass.png";

export default function ReviewPublishTab({
  formValues,
  formScheduleValues,
  handleFormValueChange,
  setFormValues,
  setTabCurrentState,
  dataSaveProgress,
  defaultDataSaveProgress = { defaultDataSaveProgress },
  setDataSaveProgress = { setDataSaveProgress }
}) {


  const navigate = useNavigate();

  const slotsDisplay = {
    "15": "15 minutes",
    "30": "30 minutes",
    "45": "45 minutes",
    "60": "60 minutes",
    "75": "1 hours 15 minutes",
    "90": "1 hours 30 minutes",
    "105": "1 hours 45 minutes",
    "120": "2 hours",
    "135": "2 hours 15 minutes",
    "150": "2 hours 30 minutes",
    "165": "2 hours 45 minutes",
    "180": "3 hours",
    "195": "3 hours 15 minutes",
    "210": "3 hours 30 minutes",
    "225": "3 hours 45 minutes",
    "240": "4 hours",
    "255": "4 hours 15 minutes",
    "270": "4 hours 30 minutes",
    "285": "4 hours 45 minutes",
    "300": "5 hours",
    "315": "5 hours 15 minutes",
    "330": "5 hours 30 minutes",
    "345": "5 hours 45 minutes",
    "360": "6 hours",
    "375": "6 hours 15 minutes",
    "390": "6 hours 30 minutes",
    "405": "6 hours 45 minutes",
    "420": "7 hours",
    "435": "7 hours 15 minutes",
    "450": "7 hours 30 minutes",
    "465": "7 hours 45 minutes",
    "480": "8 hours",
  }

  const [isDescExpanded, setIsDescExpanded] = React.useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      border: 0,
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
      margin: "5px 10px",
      // minHeight: "72px",
      borderRadius: "10px",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
  }));

  // console.log("formValues", formValues);
  return (
    <Stack direction={"column"} className={"py-6 px-8"} spacing={2} sx={{ maxWidth: 889 }}>
      <Box className="grid grid-cols-2 gap-3">
        <div className={"w-full h-[225px]"}>
          {formValues.servicePhoto ? (
            <img
              className={
                "w-full h-[225px] object-cover object-fill rounded-2xl"
              }
              src={formValues.servicePhoto}
              alt={""}
            />
          ) : (<img
            className={
              "w-full h-[225px] object-cover rounded-2xl"
            }
            src={defaultImage}
            alt={""}
          />)}
        </div>
        <div className={"break-words"}>
          <h2 className={"font-bold text-[30px] text-brown-coffee"}>
            {formValues.serviceName}
          </h2>
          <p>
            {formValues.description?.length > 175 ? (
              !isDescExpanded ? (
                <>
                  {formValues.description.slice(0, 175)}
                  <span
                    className={
                      "text-gray-400 font-light text-base cursor-pointer"
                    }
                    onClick={() => setIsDescExpanded(true)}
                  >
                    ...see more
                  </span>
                </>
              ) : (
                <span>
                  {formValues.description}
                  <br />
                  <span
                    className={
                      "text-gray-400 font-light text-base cursor-pointer"
                    }
                    onClick={() => setIsDescExpanded(false)}
                  >
                    see less
                  </span>
                </span>
              )
            ) : (
              <span>{formValues.description}</span>
            )}
          </p>
        </div>
      </Box>

      {dataSaveProgress.isStartSubmiting === false ? (
        <>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 0,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>Available locations</h4>
            <div className={`grid grid-cols-1 w-full my-4`}>
              <Box className="flex flex-col justify-center items-start">
                <Box className="flex justify-center items-start w-4/5 py-3">
                  <Box className="flex-none">
                    <img
                      className={""}
                      src={greenlocation}
                      alt={""}
                    />
                  </Box>
                  <Box className="flex-1 pl-2">
                    <div className="text-[#00000099] text-sm">Where </div>
                    <div className="text-[#000000] text-base">
                      <p className="text-[#00000099]">{formValues && formValues.venueDetail ? formValues.venueDetail.address.addressName : ""}</p>
                      <p>{formValues && formValues.venueDetail ? formValues.venueDetail.address.address : ""}</p>
                    </div>
                    {/* <div className="text-[#7E00DD] text-base">
                      <p>See business hours</p>
                    </div> */}
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <Box className="flex">
              <Box className="flex-none">
                <img
                  className={""}
                  src={greenprice}
                  alt={""}
                />
              </Box>
              <Box className="flex-1 pl-2">
                <div>Price</div>
                <div>
                  €{formValues.slots.length ? formValues.slots[0].price : 0}
                  {formValues.slots.length > 1 ? " -- €" + formValues.slots[formValues.slots.length - 1].price : ""}</div>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                my: 3,
                backgroundColor: "#F4F4F4",
                borderRadius: "15px",
              }}>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="30%" align="center">Slot Duration</StyledTableCell>
                      <StyledTableCell width="30%" align="center">Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formValues.slots.map((row, i) => (
                      <StyledTableRow key={i} >
                        <StyledTableCell align="center"><Box>{slotsDisplay[row.duration]}</Box></StyledTableCell>
                        <StyledTableCell align="center"><Box>€{row.price}</Box></StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              border: 1,
              borderColor: "#00000024",
              borderRadius: "15px",
            }}>
            <h4 className={"font-bold text-[20px] text-[#481B3A]"}>Cancellation Policy</h4>
            {
              formValues.freeCancellation ? (
                <p>
                  Cancellation is free in case booking is cancelled <span className="font-semibold">{formValues.cancellationPeriod}</span> before start of the service.
                </p>
              ) : (
                <p>Cancellation is not free the service.</p>
              )
            }
            <>
              <h4 className={"font-bold text-[20px] text-[#481B3A] mt-10"}>
                Payments Policy
              </h4>
              <p>
                <img className="inline w-6" src={progressdone} alt={""} />{" "}
                {formValues.paymentAtVenue? "Customers can pay at venue" : "Offine Payments not allowed for this service"}
              </p>
            </>
          </Box>
        </>
      ) : (
        <>
          <Box className="flex flex-col justify-center items-center w-full">
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Created Services Format
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.createServices) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Created Pricing
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.createPricing) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Saving in Draft
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.changePublishStatus) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            <Box className="flex justify-center items-center w-4/5 py-3">
              <Box className="flex-1 pl-2 text-[#00000099]">
                Creating Booking Link
              </Box>
              <Box className="flex-none">
                <img
                  className={""}
                  src={(dataSaveProgress.creatingBookingLink) ? progressdone : loader}
                  alt={""}
                />
              </Box>
            </Box>
            {dataSaveProgress.creatingBookingLink === true ? (
              <Box className="flex flex-col justify-center items-center w-full mt-5">

                <Box className="flex flex-col justify-center items-center text-center">
                  <img
                    className={"w-[235px  ]"}
                    src={successfullclass}
                    alt={""}
                  />
                  <Box sx={{
                    color: "#481B3A",
                    fontSize: "28px",
                    fontWeight: "700",
                  }}>
                    Congratulations!<br></br>
                    Your Service is now ready for Booking
                  </Box>
                  {/* <Box sx={{
                    color: "#000000B2",
                    fontSize: "20px",
                    my: 3
                  }}>
                    Share the link below to let your customer book
                  </Box> */}

                </Box>
                {/* <Box className="flex justify-center items-center w-full mt-5"
                  sx={{
                    backgroundColor: "#F4F4F4",
                    border: 1,
                    borderColor: "rgba(0, 0, 0, 0.14)",
                    borderRadius: "20px",
                  }}>
                  <Box className="flex-1 pl-2 text-[#000000] my-0 mx-5"
                    sx={{ maxWidth: "680px", overflowX: "auto" }}
                  >
                    {dataSaveProgress.bookingLink}
                  </Box>
                  <Box className="flex flex-none bg-brown-coffee py-3 px-5 rounded-r-2xl">
                    <Link component="button" underline="none" onClick={() => {
                      navigator.clipboard.writeText(dataSaveProgress.bookingLink);
                      cogoToast.success("Copied!");
                    }}>
                      <img
                        className={""}
                        src={copyOrange}
                        alt={""}
                      />
                    </Link>
                    <Link href={dataSaveProgress.bookingLink} underline="none" target="_blank">
                      <img
                        className={"ml-3 mr-5"}
                        src={shareOrange}
                        alt={""}
                      />
                    </Link>
                  </Box>
                </Box> */}
                <Box className="flex justify-center items-center w-full mt-16">
                  <Button
                    sx={{
                      color: "common.white",
                      backgroundColor: "secondary.mainLight",
                      borderColor: "secondary.mainLight",
                      border: 2,
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "secondary.mainLight",
                        color: "common.white",
                        boxShadow: "none",
                      },
                      fontWeight: 600,
                      borderRadius: 3,
                      width: 231,
                      height: 52,
                      fontSize: 16,
                      marginRight: 3,
                    }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Add another service
                  </Button>
                  <Button
                    sx={{
                      color: "common.white",
                      backgroundColor: "primary.main",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        boxShadow: "none",
                      },
                      fontWeight: 600,
                      borderRadius: 3,
                      width: 231,
                      height: 52,
                      fontSize: 16,
                    }}
                    variant={"contained"}
                    onClick={(e) => {
                      navigate("/myservices", { replace: true });
                    }}
                  >
                    GoTo Service List
                  </Button>
                </Box>
              </Box>
            ) : ""}
          </Box>
        </>
      )}
    </Stack>
  );
}
