import numeral from "numeral";
import React from "react";

import graph_png from "../../../assets/graph.png";

// numeral.register("locale", "fr", {
//   delimiters: {
//     thousands: ' ',
//     decimal: ','
//   },
//   abbreviations: {
//     thousand: 'k',
//     million: 'm',
//     billion: 'b',
//     trillion: 't'
//   },
//   ordinal: function (number) {
//     return number === 1 ? 'er' : 'ème';
//   },
//   currency: {
//     symbol: '€',
//   },
// });

// numeral.locale("fr");

export default function StatBox({ bgColor, title, icon, update, data }) {
  return (
    <div
      // className={`min-h-[48px] min-w-[395px] w-1/3 sm:min-w-[330px] sm:max-w-[330px] h-48 sm:h-28 md:h-36 lg:h-28 ${bgColor} rounded-2xl relative`}
      className={`max-w-[395px] h-32 ${bgColor} rounded-2xl relative`}
    >
      <div className={"flex justify-center"}>
        <img
          className={"object-cover w-full h-full absolute"}
          src={graph_png}
          alt={""}
        />
      </div>
      <div
        className={
          // "grid grid-cols-3 px-6 py-4 gap-12 sm:gap-0 md:gap-10 lg:gap-2"
          "grid grid-cols-3 px-6 py-4 gap-2"
        }
      >
        <h1
          className={
            "font-semibold text-xl sm:text-xl md:text-xl lg:text-xl text-white col-start-1 col-span-2"
          }
        >
          {title}
        </h1>
        <img
          className={
            "w-[18px] h-5 md:w-4 col-start-3 row-end-1 self-center justify-self-end"
          }
          src={icon}
          alt={""}
        />
        <div className={"stat col-start-1 col-span-2 grid grid-col-3"}>
          <div
            className={
              // "stat-value -ml-5 text-5xl sm:text-4xl md:text-3xl lg:text-3xl text-white"
              "stat-value -ml-5 text-4xl text-white"
            }
          >
            {numeral(data).format(title === "Revenue" ? "$0 a" : "0 a")}
          </div>
          <div
            className={
              "stat-desc col-start-2 mb-1 text-white self-end justify-self-center"
            }
          >
            {update}
          </div>
        </div>
      </div>
    </div>
  );
}
