import React from "react";
import { Box, Stack, Avatar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconTint from "react-icon-tint";

import minus_png from "../assets/Minus.png";

export default function CategoryBox(props) {
  const {
    add,
    addOnClick,
    label,
    vector,
    bgColor,
    svgFill,
    remove,
    removeOnClick,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  return (
    <div onClick={add ? addOnClick : undefined}>
      <Box sx={{ my: 1, mx: 1 }}>
        <Stack direction={"column"} spacing={isMobile ? 1 : 2}>
          <Stack direction={"row"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: bgColor,
                borderRadius: 3,
                border: 1,
                borderColor: "ButtonShadow",
                cursor: add && "pointer",
                width: isMobile ? 69 : isTablet ? 79 : 99,
                height: isMobile ? 59 : isTablet ? 69 : 89,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <img
                  style={{
                    width: isMobile ? 39 : isTablet ? 49 : 69,
                    height: isMobile ? 39 : isTablet ? 49 : 69,
                  }}
                  src={vector}
                  color={svgFill}
                  alt={""}
                /> */}
                <IconTint
                  src={vector}
                  color={svgFill}
                  maxWidth={isMobile ? 39 : isTablet ? 49 : 69}
                  maxHeight={isMobile ? 39 : isTablet ? 49 : 69}
                />
              </Box>
            </Box>
            {remove && (
              <div
                onClick={remove ? removeOnClick : undefined}
                style={{ color: "white", transform: "translate(-75%, -7.5%)" }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "#FF5733",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                >
                  <img src={minus_png} alt={""} />
                </Avatar>
              </div>
            )}
          </Stack>
          {label && (
            <p
              className={`${
                remove && isMobile
                  ? "-translate-x-3"
                  : isTablet
                  ? "-translate-x-3"
                  : "-translate-x-5"
              } text-base sm:text-sm md:text-xs font-bold text-center`}
              style={{
                color: "#481B3A",
              }}
            >
              {label}
            </p>
          )}
        </Stack>
      </Box>
    </div>
  );
}
