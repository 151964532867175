import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, useMediaQuery } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../../hooks";
import * as dashboardActions from "../../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";

import NeewSidebar from "../../../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../../../layouts";
import GeneralTab from "./GeneralTab";
import PoliciesTab from "./PoliciesTab";
import PricingTab from "./PricingTab";
import PricingTab2 from "./PricingTab2";
import ReviewPublishTab from "./ReviewPublishTab";

import { minutesToDuration } from "../../../../helpers";
import { schoolState } from "../../../../redux/reducers/schoolReducer";


import cancel_img from "../../../../assets/Cancel Pay.svg";
import info_i from "../../../../assets/i.svg";
import invite_img from "../../../../assets/Invite Code.svg";
import payment from "../../../../assets/Payment.svg";
import { getUrlForServices, getUrlForStage } from "../../../../utils/confighelpers";


export default function AddServices({ navigation, profileFor, title }) {
  const [userToken] = useLocalStorage("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const [loading, setLoading] = React.useState(false);
  const [successMgModalOpen, setSuccessMgModalOpen] = React.useState(false);
  const [tabCurrentState, setTabCurrentState] = React.useState('1');

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }


  const handleTabChange = (event, newValue) => {
    setTabCurrentState(newValue);
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const [edit, setEdit] = React.useState(false);
  const editData = {};
  const classCancelationPolicy = {
    60: "1hr",
    1440: "24hrs",
    2880: "48hrs",
    10080: "1 week",
  };
  const classBodyData = {
    servicePhoto: "",
    serviceName: "",
    serviceType: {},
    categories: {},
    serviceAvailableFor: "all_genders",
    serviceAvailableForAgeGroup: "adults_only",
    description: "",
    venueDetail: "",
    slots: [],
    freeCancellation: true,
    cancellationPeriod: "24hrs",
    paymentAtVenue: false,
  };
  const [formValues, setFormValues] = React.useState(classBodyData);
  const [formScheduleValues, setFormScheduleValues] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const schoolReduxState = useSelector(schoolState);

  const defaultDataSaveProgress = {
    isStartSubmiting: false,
    isButtonShow: true,
    createServices: false,
    createPricing: false,
    createSchedule: false,
    changePublishStatus: false,
    creatingBookingLink: false,
    bookingLink: "https://Bookzyme.com/",
  };
  const [dataSaveProgress, setDataSaveProgress] = React.useState(defaultDataSaveProgress);

  const saveClass = async (isPublish = false) => {
    setSubmitting(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    const errors = {};
    if (!formValues.servicePhoto) {
      errors.servicePhoto = "Required!";
    }
    if (!formValues.serviceName) {
      errors.serviceName = "Required!";
    }
    if (!Object.keys(formValues.serviceType).length) {
      errors.serviceType = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.serviceAvailableFor) {
      errors.serviceAvailableFor = "Required!";
    }
    if (!formValues.serviceAvailableForAgeGroup) {
      errors.serviceAvailableForAgeGroup = "Required!";
    }
    if (!formValues.description) {
      errors.description = "Required!";
    } else if (formValues.description.length < 5) {
      errors.description = `Enter at least 5 letters to explain your ${title}`;
    }
    if (!formValues.venueDetail) {
      errors.venueDetail = "Required";
    }
    /* if (title === "Course" && !formValues.sessions) {
      errors.sessions = "Required!";
    } */
    if (formValues.freeCancellation) {
      if (!formValues.cancellationPeriod) {
        errors.cancellationPeriod = "Required!";
      }
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    const cancelationPolicy =
      formValues.cancellationPeriod === "1hr"
        ? { isEnabled: true, text: "1 hour", minutes: 60 }
        : formValues.cancellationPeriod === "24hrs"
          ? { isEnabled: true, text: "24 hours", minutes: 1440 }
          : formValues.cancellationPeriod === "48hrs"
            ? { isEnabled: true, text: "48 hours", minutes: 2880 }
            : formValues.cancellationPeriod === "1 week"
              ? { isEnabled: true, text: "1 week", minutes: 10080 }
              : null;

    let body = {
      servicePhoto: formValues.servicePhoto,
      serviceName: formValues.serviceName,
      serviceType: formValues.serviceType._id,
      categoryId: formValues.categories._id,
      serviceAvailableFor: formValues.serviceAvailableFor,
      serviceAvailableForAgeGroup: formValues.serviceAvailableForAgeGroup,
      description: formValues.description,
      venue_id: formValues.venueDetail._id,
      slots: formValues.slots,
      classCancelationPolicy: formValues.freeCancellation
        ? cancelationPolicy
        : { isEnabled: false },
      paymentAtVenue: formValues.paymentAtVenue,
    };

    if (!edit) {
      body.serviceStatus = (isPublish) ? "Published" : "Draft"
      //add
    } else {
      body.serviceStatus = formValues.serviceStatus;
      // body = {
      //   id: editData._id,
      // };
    }
    let config = {};
    if (!edit) {
      config = {
        method: "post",
        url: `${getUrlForServices()}addService`,
        headers: {
          "x-access-token": userToken,
        },
        data: body,
      };
    } else {
      config = {
        method: "post",
        url: `${getUrlForServices()}updateService/` + formValues._id,
        headers: {
          "x-access-token": userToken,
        },
        data: body,
      };
    }
    setDataSaveProgress((prevValues) => ({
      ...prevValues,
      isStartSubmiting: true,
      isButtonShow: false,
    }));
    const request = await axios(config);

    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        let servicesData = request.data.data;
        setSubmitting(false);
        setLoading(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          isButtonShow: edit ? true : false,
          createServices: true,
          createPricing: true,
          changePublishStatus: true,
          creatingBookingLink: true,
          bookingLink: servicesData.bookingLink,
        }));

        // if (edit) {
        //   // clearFormValues();
        //   dispatch(dashboardActions.setCalendarSessionId(-1));
        //   dispatch(dashboardActions.setCalendarOpen(false));
        //   navigate("/home/dashboard");
        //   return;
        // }
        // clearFormValues();
        // openSuccessModal();
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error(request.data.message);
        setLoading(false);
        setSubmitting(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          isButtonShow: true,
        }));
      }
    } else {
      setDataSaveProgress((prevValues) => ({
        ...prevValues,
        isButtonShow: true,
      }));
    }
  };

  const getServiceInfo = async (id) => {
    // setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForServices()}list?serviceId=${id}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      setFormValues((prevValues) => ({
        ...prevValues,
        ...request.data.data,
        serviceType: request.data.data.service_type_detail,
        categories: request.data.data.category_detail,
        venueDetail: request.data.data.venue_detail,
        freeCancellation: request.data.data.classCancelationPolicy?.isEnabled,
        cancellationPeriod: classCancelationPolicy[request.data.data.classCancelationPolicy?.minutes],
      }));
    }
    setLoading(false);
  }

  React.useEffect(() => {
      (async () => {
      setDataSaveProgress(defaultDataSaveProgress);
      if (dashboardReduxState.serviceId && dashboardReduxState.serviceId !== -1 && dashboardReduxState.serviceId !== "") {
        await getServiceInfo(dashboardReduxState.serviceId);
        setEdit(true)
      }
    })();
  }, []);

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          className="grid"
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabCurrentState}>
              {dataSaveProgress.isButtonShow === true ? (<Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                <TabList onChange={(event, value) => { if (edit) { handleTabChange(event, value) } }} aria-label="lab API tabs example" sx={{
                  fontSize: 18
                }}>
                  <Tab label="General" value="1" />
                  <Tab label="Slot pricing" value="2" />
                  <Tab label="Policies" value="3" />
                  {edit === false ? (<Tab label="Review & Publish" value="4" />) : ""}
                </TabList>
              </Box>) : ""}
              <TabPanel value="1">
                <GeneralTab
                  profileFor={profileFor}
                  title={title}
                  openSuccessModal={() => setSuccessMgModalOpen(true)}
                  edit={edit}
                  setLoading={setLoading}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  formValidationErrors={formErrors}
                  setTabCurrentState={setTabCurrentState}
                  handleUpdate={() => {
                    saveClass()
                  }}
                  handleCancel={() => {
                    dispatch(dashboardActions.setServiceId(-1));
                    navigate("/myservices");
                  }}
                />
              </TabPanel>
              <TabPanel value="2">
                <PricingTab
                  edit={edit}
                  profileFor={profileFor}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  handleUpdate={() => {
                    saveClass()
                  }}
                  handleCancel={() => {
                    dispatch(dashboardActions.setServiceId(-1));
                    navigate("/myservices");
                  }}
                />
              </TabPanel>
              <TabPanel value="3">
                <PoliciesTab
                  edit={edit}
                  formErrors={formErrors}
                  cancel_img={cancel_img}
                  info_i={info_i}
                  formValues={formValues}
                  handleFormValueChange={handleFormValueChange}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  payment={payment}
                  invite_img={invite_img}
                  handleUpdate={() => {
                    saveClass()
                  }}
                  handleCancel={() => {
                    dispatch(dashboardActions.setServiceId(-1));
                    navigate("/myservices");
                  }}
                />
              </TabPanel>
              {edit === false ? (<TabPanel value="4">
                <ReviewPublishTab
                  formValues={formValues}
                  formScheduleValues={formScheduleValues}
                  handleFormValueChange={handleFormValueChange}
                  setTabCurrentState={setTabCurrentState}
                  defaultDataSaveProgress={defaultDataSaveProgress}
                  dataSaveProgress={dataSaveProgress}
                  setDataSaveProgress={setDataSaveProgress}
                />
                {dataSaveProgress.isButtonShow === true ? (
                  <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ maxWidth: 889, pt: 5 }}>
                    <Button
                      sx={{
                        color: "secondary.mainLight",
                        backgroundColor: "common.white",
                        borderColor: "secondary.mainLight",
                        border: 2,
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          color: "common.white",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      onClick={() => {
                        setTabCurrentState("3");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      variant={"contained"}
                      // disabled={isSubmitting}
                      onClick={() => {
                        saveClass(false);
                      }}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "primary.main",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "primary.main",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      variant={"contained"}
                      // disabled={isSubmitting}
                      onClick={() => {
                        saveClass(true);
                      }}
                    >
                      Create & publish
                    </Button>
                  </Stack>
                ) : ""}
              </TabPanel>) : ""}
            </TabContext>
          </Box>
        </Box>
      </LoadingBackdrop>} />
  );
}
