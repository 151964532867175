import { createSlice } from "@reduxjs/toolkit";

export const schoolSlice = createSlice({
  name: "school",
  initialState: {
    schoolId: "",
    schoolName: "",
    schoolLogo: "",
    schoolCover: "",
    schoolCategories: [],
    schoolLocation: "",
    schoolEmail: "",
    schoolWebsite: "",
    schoolAbout: "",
    schoolLinks: {
      facebook: "",
      instagram: "",
      youtube: "",
      linkedIn: "",
      twitter: "",
    },
  },
  reducers: {
    setId: (state, action) => {
      state.schoolId = action.payload;
    },
    setName: (state, action) => {
      state.schoolName = action.payload;
    },
    setLogo: (state, action) => {
      state.schoolLogo = action.payload;
    },
    setCover: (state, action) => {
      state.schoolCover = action.payload;
    },
    setCategories: (state, action) => {
      state.schoolCategories = [...action.payload];
    },
    setLocation: (state, action) => {
      state.schoolLocation = action.payload;
    },
    setEmail: (state, action) => {
      state.schoolEmail = action.payload;
    },
    setWebsite: (state, action) => {
      state.schoolWebsite = action.payload;
    },
    setAbout: (state, action) => {
      state.schoolAbout = action.payload;
    },
    setLinks: (state, action) => {
      state.schoolLinks = action.payload;
    },
    clearSchoolData: (state, action) =>
    {
      const initialState = {
        schoolId: "",
        schoolName: "",
        schoolLogo: "",
        schoolCover: "",
        schoolCategories: [],
        schoolLocation: "",
        schoolEmail: "",
        schoolWebsite: "",
        schoolAbout: "",
        schoolLinks: {
          facebook: "",
          instagram: "",
          youtube: "",
          linkedIn: "",
          twitter: "",
        }
      };
      Object.keys(initialState).forEach((value) =>
      {
        if(value === "schoolCategories")
        {
          state.schoolCategories = [];
        }
        else if(value === "schoolLinks")
        {
          state.schoolLinks = {
            facebook: "",
            instagram: "",
            youtube: "",
            linkedIn: "",
            twitter: "",
          }
        }
        else
        {
          state[value] = "";
        }
      })
    }
  },
});

export const {
  setId,
  setName,
  setLogo,
  setCover,
  setCategories,
  setLocation,
  setEmail,
  setWebsite,
  setAbout,
  setLinks,
  clearSchoolData
} = schoolSlice.actions;

export const schoolState = (state) => state.school;

export default schoolSlice.reducer;
