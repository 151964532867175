import { Box, MenuItem, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment";
import React from "react";

import { useLocalStorage } from "../../../../hooks";
import { userState } from "../../../../redux/reducers/userReducer";

import profile_img from "../../../../assets/avatar_placeholder.png";
import filter_img from "../../../../assets/Filter.png";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SessionBookingPopover from "./SessionBookingPopover";
import { getUrlForBookings } from "../../../../utils/confighelpers";
import { cutString } from "../../../../helpers";


export default function RecentBooking({
    navigation,
    loading,
    setLoading
}) {
    const location = useLocation();
    const [userToken] = useLocalStorage("userToken");
    const userReduxState = useSelector(userState);
    const [bookingInfo, setBookingInfo] = React.useState();
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [listLoading, setListLoading] = React.useState(false);

    const [recentBookingsList, setRecentBookingsList] = React.useState([]);
    const [showViewMore, setShowViewMore] = React.useState(false);
    const [recentBookingsPageNumber, setRecentBookingsPageNumber] = React.useState(1);
    const [bookingFilter, setBookingFilter] = React.useState('all');

    const getRecentBookings = async (page = 1) => {
        setShowViewMore(false);
        setListLoading(true);
        const manageClassesConfig = {
            method: "get",
            url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&page=${page}&limit=5&filterBy=${bookingFilter}`,
            headers: {
                "x-access-token": userToken,
            },
        };
        const manageClassesRequest = await axios(manageClassesConfig);
        if (manageClassesRequest.status === 200) {
            if (manageClassesRequest.data.data.data.length) {
                setShowViewMore(true);
                if (recentBookingsPageNumber > 1) {
                    setRecentBookingsList([...recentBookingsList, ...manageClassesRequest.data.data.data]);
                } else {
                    setRecentBookingsList(manageClassesRequest.data.data.data);
                }
            }
        } else {
        }
        setListLoading(false);
    };

    const handleFilterChange = (event) => {
        const { target: { value } } = event;
        setRecentBookingsPageNumber(1)
        setBookingFilter(value);
        setRecentBookingsList([])
    };

    const ticketAmount = (row) => {
        if (row?.amount) {
            return parseFloat(row?.amount || 0).toFixed(2)
        } else {
            return row?.servicesBought ? parseFloat(row.servicesBought.totalPrice).toFixed(2) : "0"
        }
    }

    React.useEffect(() => {
        getRecentBookings(recentBookingsPageNumber);
    }, [recentBookingsPageNumber, bookingFilter]);

    const slotsDisplay = {
        "15": "15 minutes",
        "30": "30 minutes",
        "45": "45 minutes",
        "60": "60 minutes",
        "75": "1 hours 15 minutes",
        "90": "1 hours 30 minutes",
        "105": "1 hours 45 minutes",
        "120": "2 hours",
        "135": "2 hours 15 minutes",
        "150": "2 hours 30 minutes",
        "165": "2 hours 45 minutes",
        "180": "3 hours",
        "195": "3 hours 15 minutes",
        "210": "3 hours 30 minutes",
        "225": "3 hours 45 minutes",
        "240": "4 hours",
        "255": "4 hours 15 minutes",
        "270": "4 hours 30 minutes",
        "285": "4 hours 45 minutes",
        "300": "5 hours",
        "315": "5 hours 15 minutes",
        "330": "5 hours 30 minutes",
        "345": "5 hours 45 minutes",
        "360": "6 hours",
        "375": "6 hours 15 minutes",
        "390": "6 hours 30 minutes",
        "405": "6 hours 45 minutes",
        "420": "7 hours",
        "435": "7 hours 15 minutes",
        "450": "7 hours 30 minutes",
        "465": "7 hours 45 minutes",
        "480": "8 hours",
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 16,
            fontWeight: "bold",
            border: 0,
            padding: "0px 16px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "#464E5F",
            fontWeight: "bold",
            border: 0,
        },
        [`&.${tableCellClasses.body} > div`]: {
            // backgroundColor: "rgba(244, 244, 244, 0.4)",
            backgroundColor: "#fff",
            padding: "16px",
        },
        [`&.${tableCellClasses.body} div p`]: {
            fontSize: 11,
            color: "#B5B5C3",
            fontWeight: "500",
        },
        [`&:first-child div`]: {
            borderRadius: "12px 0 0 12px",
        },
        [`&:last-child div`]: {
            borderRadius: "0 12px 12px 0",
        },
        padding: "5px 0px 5px 0px",
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        margin: "0px",
        border: 0,
        [`&:hover td div`]: {
            backgroundColor: "#F4F4F4",
        },
    }));

    return (
        <div
            className={`max-w-[1221px] min-h-[500px] px-5 py-2 pb-10 bg-white rounded-2xl mt-16`}
        >
            <div className={"flex flex-row justify-between items-center my-4"}>
                <div className={"flex flex-col"} style={{ flex: 1 }}>
                    <div className="flex justify-between">
                        <h3
                            className={
                                "text-black text-lg"
                            }
                        >
                            Recent Bookings
                        </h3>
                        <Select
                            disableunderline={true}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        border: 0,
                                    },
                                },
                            }}
                            sx={{
                                mr: 2,
                                '.MuiOutlinedInput-input': {
                                    borderWidth: 0,
                                    p: "0 !important",
                                },
                                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 0,
                                },
                            }}
                            IconComponent={null}
                            renderValue={(value) => (
                                <div className={"flex flex-row items-center gap-3"}>
                                    {value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                                        return letter.toUpperCase();
                                    }) || ""}
                                    <img class="w-4" src={filter_img} alt={""} />
                                </div>
                            )}
                            value={bookingFilter}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            <MenuItem value={'confirmed'}>Confirmed</MenuItem>
                            <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                            <MenuItem value={'refunded'}>Refunded</MenuItem>
                        </Select>
                    </div>
                    <p className="text-[10px] text-[#B5B5C3]"></p>
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">CUSTOMER</StyledTableCell>
                            <StyledTableCell align="left">BOOKING ID</StyledTableCell>
                            <StyledTableCell align="left">ACTIVITY BOOKED</StyledTableCell>
                            <StyledTableCell align="left">AMOUNT DUE</StyledTableCell>
                            <StyledTableCell width="10%" align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listLoading ? (
                            <StyledTableRow key={0} >
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                                        <p>Loading...</p>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ) : (recentBookingsList.length ? recentBookingsList.map((row, i) => (
                            <StyledTableRow key={i} >
                                <StyledTableCell align="left">
                                    <Box>
                                        <Box className="flex justify-center items-center w-4/5">
                                            <Box className="flex-none">
                                                {row?.student_detail?.profilePic ? (<img
                                                    className="w-[33px] h-[33px] rounded-full mr-3"
                                                    src={row.student_detail.profilePic}
                                                    alt={``}
                                                />) : (<img
                                                    className="w-[33px] h-[33px] rounded-full mr-3"
                                                    src={profile_img}
                                                    alt={``}
                                                />)}
                                            </Box>
                                            <Box className=" p-0 w-28">
                                                <div>{cutString(row.studentName, 10, true)}</div>
                                                <div className="text-[#7E00DD]">{cutString((row.student_detail?.countryCode ? row.student_detail.countryCode + " " + row.student_detail.phoneNumber : "" + row?.student_detail?.username), 10, true)}</div>
                                            </Box>
                                        </Box>

                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        {row.bookingId}
                                        <p><span className={`${(row.status === "Cancelled") ? "text-[#FF5733]" : "text-[#468C11]"}`}>{row.status}</span> | {format(parseISO(row.bookingDate), "MMM d")}</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        {row?.service_detail?.serviceName}
                                        <p>{row.slot_date ? format(parseISO(row.slot_date), "MMM d") : ""} | {row.slot_date ? moment(row.slot_date + " " + row.slot_start_time, 'YYYY-MM-DD h:mm A').format('k:mm') + " - " + moment(row.slot_date + " " + row.slot_end_time, 'YYYY-MM-DD h:mm A').format('k:mm') : ""}</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        €{ticketAmount(row)}
                                        <p>{(row.paymentStatus.toLowerCase() === "paid") ? "Paid Online" : row.paymentStatus}</p>
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Box>
                                        <SessionBookingPopover
                                            key={i}
                                            bookingDetails={row}
                                            handleUpdate={() => {
                                                getRecentBookings()
                                            }}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : (
                            <StyledTableRow key={0} >
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box className="rounded-2xl" sx={{ borderRadius: "12px !important" }}>
                                        <p>There is no {bookingFilter !== "all" ? bookingFilter + " " : ""}recent bookings</p>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {showViewMore ? (<div className={"flex flex-row justify-center my-4"}>
                <button
                    className={
                        "text-white text-sm cursor-pointer bg-[#3699FF] rounded-lg p-2 px-5"
                    }
                    onClick={() => {
                        setRecentBookingsPageNumber(recentBookingsPageNumber + 1);
                    }}
                >
                    View More
                </button>
            </div>) : ""}
        </div>
    );
}
