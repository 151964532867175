import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cogoToast from "cogo-toast";
import React from "react";
import { useLocation } from "react-router-dom";
import Container from "../../components/Container";

import { useDispatch, useSelector } from "react-redux";
import bg from "../../assets/bg.png";
import graph_png from "../../assets/graph.png";
import { LoadingBackdrop } from "../../layouts";
import * as userActions from "../../redux/reducers/userReducer";
import { userState } from "../../redux/reducers/userReducer";
import { useLocalStorage } from "../../hooks";
import axios from "axios";
import { getUrlForUsers } from "../../utils/confighelpers";
import { clearSchoolData } from "../../redux/reducers/schoolReducer";
import { clearDashboardData } from "../../redux/reducers/dashboardReducer";

const OnBoardingHeader = ({ navigation, title, logoutCallBack }) => {
  const theme = useTheme();
  const location = useLocation();
  const userReduxState = useSelector(userState);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [userToken, setUserToken] = useLocalStorage("userToken");
  const [selectDashbord, setSelectDashbord] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const Logout = () => {
    cogoToast.loading("Logging out...").then(async () => {
      try {
        await axios.put(
          `${getUrlForUsers()}logoutUser`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userToken,
            },
          }
        );
      } catch (err) {
        console.log("error", err);
      } finally {
        localStorage.removeItem("userToken");
        localStorage.removeItem("persist:root");
        dispatch(userActions.clearUserData());
        dispatch(clearSchoolData());
        dispatch(clearDashboardData());
        await setUserToken("");
        // navigate("/", { replace: true });
        window.location.href = "/";
      }
    });
  };

  console.log("location.state", location.state);
  return (
    <LoadingBackdrop loading={loading}>
      <div
        className={
          // "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
          "1h-16 1md:h-12 shadow-sm shadow-neutral-200 flex flex-row justify-between items-center"
        }
      >
        {typeof title == "string" ? (<h4
          className={
            "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
          }
          sx={{ color: "#481B3A" }}
        >
          {title}
        </h4>) : (
          <div className="flex-1">
            {title}
          </div>)}
        <Button
          sx={{
            backgroundColor: "#481B3A",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#481B3A",
            },
            fontWeight: "bold",
            // width: isMobile ? 270 : isTablet ? 345 : 256,
            // height: isMobile ? 50 : isTablet ? 50 : 65,
            fontSize: isMobile ? 10 : 15,
            borderRadius: 3,
            mx: 2,
            px: 3,
            py: 0.5,
            color: "white",
          }}
          onClick={async () => {
            await Logout()
            await logoutCallBack()
          }}
        >
          Start Over
        </Button>
      </div>
    </LoadingBackdrop>
  );
};

export default OnBoardingHeader;
